<template>
    <v-card class="mt-3">
        <v-toolbar>
            <v-toolbar-title
                :style="
                    this.$vuetify.display.lgAndUp ? 'max-width: 200px;' : ''
                "
            >
                Casa Verde
            </v-toolbar-title>
            <v-row
                style="max-height: 100%; overflow: hidden"
                v-if="this.$vuetify.display.lgAndUp"
            >
                <v-col cols="12" sm="12" md="6">
                    <autocomplete-filter
                        v-model="selectedContractStatuses"
                        :items="contractStatusOptions"
                        title="Filtreaza Status"
                        @filter-changed="applyFilter"
                    />
                </v-col>
                <v-col
                    v-if="
                        userPermissions.greenHouseSolarManagement
                            .canViewAllClients
                    "
                    cols="12"
                    sm="12"
                    md="6"
                >
                    <autocomplete-filter
                        v-model="selectedAgent"
                        :items="agentOptions"
                        title="Filtreaza dupa agent"
                        @filter-changed="applyAgentFilter"
                    />
                </v-col>
            </v-row>
            <v-toolbar-items>
                <v-btn
                    color="success"
                    @click="navigateToCreateClient"
                    :disabled="
                        !userPermissions.greenHouseSolarManagement.canEditClient
                    "
                >
                    <v-icon>mdi-plus</v-icon>
                    Adaugă Client
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-row v-if="this.$vuetify.display.mdAndDown">
                <v-col cols="12" sm="12" md="6">
                    <autocomplete-filter
                        v-model="selectedContractStatuses"
                        :items="contractStatusOptions"
                        title="Filtreaza Status"
                        @filter-changed="applyFilter"
                    />
                </v-col>
                <v-col
                    v-if="
                        userPermissions.greenHouseSolarManagement
                            .canViewAllClients
                    "
                    cols="12"
                    sm="12"
                    md="6"
                >
                    <autocomplete-filter
                        v-model="selectedAgent"
                        :items="agentOptions"
                        title="Filtreaza dupa agent"
                        @filter-changed="applyAgentFilter"
                    />
                </v-col>
            </v-row>
            <div class="table-responsive mt-3">
                <v-data-table
                    :headers="headers"
                    :items="filteredClients"
                    item-value="id"
                    :hover="true"
                    dense
                    class="elevation-1"
                    hide-no-data
                >
                    <template v-slot:item="{ item, index }">
                        <tr
                            @click="navigateToEditClient(item.id)"
                            style="cursor: pointer"
                        >
                            <td>{{ index + 1 }}</td>
                            <td
                                v-if="
                                    userPermissions.greenHouseSolarManagement
                                        .canViewAllClients
                                "
                            >
                                {{ item.agent_full_name || "Necunoscut" }}
                            </td>
                            <td>
                                {{
                                    item.contract_status?.startsWith("Semnat")
                                        ? "Semnat"
                                        : item.contract_status
                                }}
                                <v-tooltip location="top" activator="parent">
                                    <span
                                        v-if="
                                            item.latest_status?.startsWith(
                                                'Semnat'
                                            )
                                        "
                                    >
                                        {{ item.latest_status }}
                                    </span>
                                    <span v-else>
                                        Modificat de:
                                        {{ item.status_modified_by }}<br />
                                        Data: {{ item.status_modified_at }}
                                    </span>
                                </v-tooltip>
                            </td>
                            <td>{{ formatDate(item.file_approval_date) }}</td>
                            <td>{{ item.last_name }} {{ item.first_name }}</td>

                            <td>
                                <div class="ellipsis-text">
                                    <template v-if="item.city">
                                        {{ item.city }}</template
                                    >
                                    <template
                                        v-if="
                                            getRegionName(regions, item.region)
                                        "
                                        >,
                                        {{
                                            getRegionName(regions, item.region)
                                        }}
                                    </template>
                                </div>
                                <v-tooltip location="top" activator="parent">
                                    <span>
                                        {{ formatStreet(item.street) }}
                                        <template v-if="item.number">
                                            nr. {{ item.number }}</template
                                        >
                                        <template v-if="item.apartment"
                                            >, Ap.
                                            {{ item.apartment }}</template
                                        >
                                        <template v-if="item.city"
                                            >, {{ item.city }}</template
                                        >
                                        <template v-if="item.postal_code"
                                            >, {{ item.postal_code }}</template
                                        >
                                        <template
                                            v-if="
                                                getRegionName(
                                                    regions,
                                                    item.region
                                                )
                                            "
                                            >,
                                            {{
                                                getRegionName(
                                                    regions,
                                                    item.region
                                                )
                                            }}</template
                                        >
                                    </span>
                                </v-tooltip>
                            </td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.phone }}</td>

                            <td>{{ item.panel_count }}</td>
                            <td>{{ item.panel_type }}</td>
                            <td>{{ item.panel_power }} kW</td>
                            <td>{{ item.connection }}</td>

                            <td>{{ item.inverter_type }}</td>
                            <td>{{ item.inverter_power }}</td>

                            <td>{{ item.battery_model }}</td>
                            <td>{{ item.battery_quantity }}</td>
                            <td>{{ item.battery_power }} kW</td>

                            <td>
                                <v-btn
                                    variant="plain"
                                    color="red"
                                    @click.stop="openDeleteDialog(item)"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-card-text>
        <!-- Delete Confirmation Dialog -->
        <v-dialog v-model="isDeleteDialogVisible" max-width="500px">
            <v-card>
                <v-card-title class="headline">
                    Confirmare ștergere
                </v-card-title>
                <v-card-text>
                    Ești sigur că vrei să ștergi acest client? Această acțiune
                    nu poate fi anulată.
                </v-card-text>
                <v-card-actions class="d-flex flex-row justify-content-end">
                    <v-btn color="grey" text @click="closeDeleteDialog"
                        >Anulează</v-btn
                    >
                    <v-btn color="red" text @click="deleteClient">Șterge</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";
import { loadRegionsAndSubregions, getRegionName } from "@/utils/utils";
import { mapGetters } from "vuex";
import AutocompleteFilter from "@/components/common/AutocompleteFilter.vue";

export default {
    name: "GreenHouseSolarClientsList",
    components: { AutocompleteFilter },
    data() {
        return {
            clients: [],
            regions: [],
            cities: [],
            isDeleteDialogVisible: false,
            clientToDelete: null,
            selectedAgent: [],
            selectedContractStatuses: [], // Stores selected contract statuses
            allContractStatuses: [
                { text: "Eligibil", value: "Eligibil" },
                { text: "Aprobat", value: "Aprobat" },
                { text: "Trimis", value: "Trimis" },
                { text: "Semnat", value: "Semnat" },
                { text: "Nesemnat", value: "Nesemnat" },
                {
                    text: "Factură avans achitată",
                    value: "Factură avans achitată",
                },
                { text: "Anexă marfă trimisă", value: "Anexă marfă trimisă" },
                {
                    text: "Certificat de racordare",
                    value: "Certificat de racordare",
                },
                { text: "Are Ofertă", value: "__has_offer_document__" }, // Custom flag
                { text: "Factură finală", value: "Factură finală" },
                { text: "Decontare depusă", value: "Decontare depusă" },
                { text: "Decontat", value: "Decontat" },
            ],
        };
    },
    computed: {
        ...mapGetters(["userPermissions"]),
        headers() {
            const baseHeaders = [
                { title: "Nr. Crt.", key: "nrCrt", align: "center" },
                { title: "Status", key: "contract_status" },
                { title: "Data aprobare dosar", key: "file_approval_date" },
                { title: "Nume", key: "first_name" },
                { title: "Adresă", key: "address" },
                { title: "Email", key: "email" },
                { title: "Telefon", key: "phone" },

                { title: "Nr. Panouri", key: "panel_count", width: "50px" },
                { title: "Tip Panouri", key: "panel_type" },
                { title: "Putere totală Panouri", key: "panel_power" },
                { title: "Branșament", key: "connection" },
                { title: "Tip invertor", key: "inverter_type" },
                { title: "Putere invertor", key: "inverter_power" },

                { title: "Model Baterie", key: "battery_model" },
                { title: "Cantitate Baterie", key: "battery_quantity" },
                { title: "Putere totală Baterie", key: "battery_power" },

                { title: "Acțiuni", key: "actions" },
            ];

            if (
                this.userPermissions.greenHouseSolarManagement.canViewAllClients
            ) {
                baseHeaders.splice(1, 0, { title: "Agent", key: "agent" }); // insert after index 0 (Nr. Crt.)
            }

            return baseHeaders;
        },

        /**
         * Returns a list of contract statuses for filtering.
         * Ensures that all statuses are displayed, even if no client currently has them.
         * @returns {Array<{text: string, value: string}>} Predefined list of contract statuses.
         */
        contractStatusOptions() {
            return this.allContractStatuses;
        },
        /**
         * Extracts a unique list of agents from the fetched clients.
         * - If the user has permission to view all clients, all agents are listed.
         */
        agentOptions() {
            if (
                this.userPermissions.greenHouseSolarManagement.canViewAllClients
            ) {
                return [
                    ...new Map(
                        this.clients.map((client) => [
                            client.agent_full_name, // Use full name as the key (no agent_id)
                            {
                                text: client.agent_full_name,
                                value: client.agent_full_name,
                            },
                        ])
                    ).values(),
                ];
            }
            return [];
        },

        /**
         * Filters clients based on selected agent and contract status.
         */
        filteredClients() {
            let clients = this.clients;

            if (this.selectedContractStatuses.length > 0) {
                clients = clients.filter((client) => {
                    const statusMatches = this.selectedContractStatuses.some(
                        (selectedStatus) => {
                            if (selectedStatus === "Semnat") {
                                return client.contract_status
                                    ?.toLowerCase()
                                    .startsWith("semnat");
                            }
                            return client.contract_status === selectedStatus;
                        }
                    );

                    const hasOfferDoc =
                        this.selectedContractStatuses.includes(
                            "__has_offer_document__"
                        ) && client.has_offer_document;

                    return statusMatches || hasOfferDoc;
                });
            }

            if (this.selectedAgent.length > 0) {
                clients = clients.filter((client) =>
                    this.selectedAgent.includes(client.agent_full_name)
                );
            }

            return clients;
        },
    },
    async mounted() {
        try {
            const { regions, subregions } = await loadRegionsAndSubregions();
            this.regions = regions;
            this.cities = subregions;
        } catch (error) {
            console.error("Error loading regions and subregions:", error);
        }
        await this.fetchClients();
        await this.refreshContractStatuses();
    },
    methods: {
        applyAgentFilter(selectedAgent) {
            console.log("Filter applied:", selectedAgent);
        },
        applyFilter(selectedValues) {
            console.log("Filter applied:", selectedValues);
        },
        getRegionName,
        /**
         * Fetches the content type ID from the API and assigns it to `contentTypeId`.
         * Logs an error if the request fails.
         *
         * @async
         * @returns {Promise<void>} Resolves when the content type ID is fetched and assigned.
         */
        async fetchContentTypeId() {
            try {
                const response = await apiClient.get("/api/content-types/");
                this.contentTypeId = response.data.greenhousesolarclient; // Adjust key if needed
            } catch (error) {
                console.error("Error fetching content type ID:", error);
            }
        },
        /**
         * Fetches the list of greenhouse solar clients from the API.
         * If the user has permission to view all clients, all clients are retrieved.
         * Otherwise, only the clients assigned to the logged-in user are fetched.
         *
         * @async
         * @returns {Promise<void>} Resolves when clients are fetched and stored in `this.clients`.
         */
        async fetchClients() {
            try {
                const canViewAllClients =
                    this.userPermissions?.greenHouseSolarManagement
                        ?.canViewAllClients;

                let params = {};
                if (!canViewAllClients) {
                    const userId = sessionStorage.getItem("userId");
                    params.user_id = userId;
                }

                // Fetch clients
                const response = await apiClient.get(
                    "/api/greenhouse-solar-clients/",
                    {
                        params,
                    }
                );
                this.clients = response.data.map((client) => {
                    client.has_offer_document = (client.attachments || []).some(
                        (attachment) => attachment.document_type === "offer"
                    );
                    // Normalize legacy status
                    if (client.contract_status === "Factură avans") {
                        client.contract_status = "Factură avans achitată";
                    }
                    return client;
                });
                // Ensure we have content type ID before fetching history
                if (!this.contentTypeId) {
                    await this.fetchContentTypeId();
                }

                // Fetch status history for each client in parallel
                await Promise.all(
                    this.clients.map(async (client) => {
                        try {
                            const historyResponse = await apiClient.get(
                                `/api/field-history/${this.contentTypeId}/${client.id}/`
                            );
                            const statusHistory = historyResponse.data.history;

                            if (statusHistory.length > 0) {
                                // Sort by latest change
                                const latestStatus = statusHistory.sort(
                                    (a, b) =>
                                        new Date(b.changed_at) -
                                        new Date(a.changed_at)
                                )[0];

                                // Format date with hour and minutes
                                const formattedDate = new Date(
                                    latestStatus.changed_at
                                ).toLocaleString("ro-RO", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                });

                                // Attach latest status details to the client
                                client.latest_status = latestStatus.new_value;
                                client.status_modified_by =
                                    latestStatus.changed_by;
                                client.status_modified_at = formattedDate;
                            } else {
                                client.latest_status = client.contract_status;
                                client.status_modified_by = "Unknown";
                                client.status_modified_at = null;
                            }
                        } catch (error) {
                            console.error(
                                `Error fetching status history for client ${client.id}:`,
                                error
                            );
                        }
                    })
                );
            } catch (error) {
                console.error("Error fetching clients:", error);
            }
        },
        formatDate(date) {
            if (!date) return "";
            const d = new Date(date);
            return d.toLocaleDateString("ro-RO", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
        },
        /**
         * Conditionally prepends "Str." to the street name if it doesn't start with "Strada", "Str.", or "Sat".
         *
         * @param {string} street - The name of the street to format.
         * @returns {string} - The formatted street name.
         */
        formatStreet(street) {
            if (/^(Strada|Str\.|Sat)/i.test(street)) {
                return street;
            }
            return `Str. ${street}`;
        },
        navigateToCreateClient() {
            this.$router.push({ name: "GreenHouseSolarClientCreate" });
        },
        navigateToEditClient(greenHouseClientId) {
            this.$router.push({
                name: "GreenHouseSolarClientCreate",
                params: { clientId: greenHouseClientId },
            });
        },
        openDeleteDialog(client) {
            this.clientToDelete = client;
            this.isDeleteDialogVisible = true;
        },
        closeDeleteDialog() {
            this.isDeleteDialogVisible = false;
            this.clientToDelete = null;
        },
        async deleteClient() {
            try {
                await apiClient.delete(
                    `/api/greenhouse-solar-clients/${this.clientToDelete.id}/`
                );
                this.clients = this.clients.filter(
                    (client) => client.id !== this.clientToDelete.id
                );
                this.closeDeleteDialog();
            } catch (error) {
                console.error("Error deleting client:", error);
                this.closeDeleteDialog();
            }
        },
        async refreshContractStatuses() {
            const clients = [...this.clients]; // clone for reactivity

            const contentTypesRes = await apiClient.get("/api/content-types/");
            const contentTypeId = contentTypesRes.data.greenhousesolarclient;

            for (const client of clients) {
                try {
                    const accessRes = await apiClient.get(
                        `/api/pdf/get-contract-access/${client.id}/`
                    );
                    const access = accessRes.data;

                    // 1. If signed, set status to "Semnat la..."
                    if (access.is_signed) {
                        const signedDate = new Date(
                            access.signed_at
                        ).toLocaleString("ro-RO");
                        const newStatus = `Semnat la: ${signedDate} IP: ${access.signed_ip}`;

                        const historyRes = await apiClient.get(
                            `/api/field-history/${contentTypeId}/${client.id}/`
                        );
                        const statusChanged = this.hasStatusChangedAfterSigning(
                            historyRes.data.history,
                            newStatus
                        );

                        if (
                            !statusChanged &&
                            client.contract_status !== newStatus
                        ) {
                            await apiClient.put(
                                `/api/greenhouse-solar-clients/${client.id}/`,
                                {
                                    ...client,
                                    contract_status: newStatus,
                                }
                            );
                            client.contract_status = newStatus;
                        }

                        continue; // Skip the rest if already signed
                    }

                    // 2. If token exists, validate it
                    if (access.token) {
                        try {
                            await apiClient.get("/api/verify-token/", {
                                params: { token: access.token },
                            });

                            // 3. If token is valid and contract is sent but not signed
                            if (
                                access.is_sent &&
                                !access.is_signed &&
                                client.contract_status !== "Trimis"
                            ) {
                                const historyRes = await apiClient.get(
                                    `/api/field-history/${contentTypeId}/${client.id}/`
                                );
                                const statusChanged =
                                    this.hasStatusChangedAfterSigning(
                                        historyRes.data.history,
                                        "Trimis"
                                    );

                                if (!statusChanged) {
                                    await apiClient.put(
                                        `/api/greenhouse-solar-clients/${client.id}/`,
                                        {
                                            ...client,
                                            contract_status: "Trimis",
                                        }
                                    );
                                    client.contract_status = "Trimis";
                                }
                            }
                        } catch (error) {
                            const errorMessage =
                                error?.response?.data?.message || "";

                            const historyRes = await apiClient.get(
                                `/api/field-history/${contentTypeId}/${client.id}/`
                            );
                            const statusChanged =
                                this.hasStatusChangedAfterSigning(
                                    historyRes.data.history,
                                    "Nesemnat"
                                );

                            if (
                                errorMessage.includes("expired") &&
                                !access.is_signed &&
                                !statusChanged &&
                                client.contract_status !== "Nesemnat"
                            ) {
                                await apiClient.put(
                                    `/api/greenhouse-solar-clients/${client.id}/`,
                                    {
                                        ...client,
                                        contract_status: "Nesemnat",
                                    }
                                );
                                client.contract_status = "Nesemnat";
                            }
                        }
                    }
                } catch (error) {
                    console.error(`Error for client ${client.id}:`, error);
                }
            }

            this.clients = clients; // update state
        },

        hasStatusChangedAfterSigning(history, signedStatus) {
            if (!history?.length || !signedStatus) return false;

            const signedEntry = history.find(
                (entry) => entry.new_value === signedStatus
            );
            if (!signedEntry) return false;

            const signedTime = new Date(signedEntry.changed_at);

            return history.some((entry) => {
                const entryTime = new Date(entry.changed_at);
                return (
                    entryTime > signedTime &&
                    entry.field_name === "contract_status" &&
                    !entry.new_value.startsWith("Semnat")
                );
            });
        },
    },
};
</script>

<style scoped>
/* Add any additional styling if required */
</style>
