<template>
    <v-card>
        <v-card-title>Creare intermediar</v-card-title>

        <v-card-text>
            <!-- Name Field -->
            <v-text-field
                label="Nume:"
                v-model="newIntermediary.name"
                :error-messages="nameError"
                outlined
                dense
            />

            <!-- Phone Field with inline error -->
            <v-text-field
                label="Telefon:"
                v-model="newIntermediary.phone"
                :error-messages="phoneError"
                :rules="[validatePhone]"
                outlined
                dense
            />

            <!-- Email -->
            <v-text-field
                label="Email:"
                v-model="newIntermediary.email"
                :rules="[validateEmail]"
                outlined
                dense
            />

            <!-- is_global Checkbox -->
            <v-checkbox
                v-model="newIntermediary.is_global"
                label="Disponibil pentru toți agenții"
                dense
                hide-details
            />

            <!-- Button to Submit -->
            <v-row class="my-2 mr-1 justify-end">
                <v-btn @click="addIntermediary" color="primary">
                    Adaugă intermediar
                </v-btn>
            </v-row>
        </v-card-text>
    </v-card>
</template>


<script>
import apiClient from "@/utils/apiClient";
import {showAlertModal} from "@/utils/utils";
import {mapGetters} from "vuex";

export default {
    name: "CreateIntermediary",
    props: {
        onClientCreation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            newIntermediary: {
                name: "",
                phone: "",
                email: "",
                agent_id: "",
                is_global: false,
            },
            error: "",
            phoneError: "",
            nameError: "",
        };
    },
    emits: ['intermediary-created'],
    computed: {
        ...mapGetters(['userRole']),
        isSuperuser() {
            return this.userRole.includes('superuser');
        }
    },
    methods: {
        /**
         * Validates the phone number.
         * - Blank is allowed.
         * - If provided, it must either be exactly 10 digits
         *   or a plus-prefixed number with 11 digits.
         *
         * @returns {true|string} True if valid, or an error message.
         */
        validatePhone() {
            const phone = this.newIntermediary.phone.trim();
            if (!phone) {
                return true; // Blank is allowed
            }
            if (!/^\d{10}$/.test(phone) && !/^\+\d{11}$/.test(phone)) {
                return 'Introduceti un număr de telefon valid, format din 10 cifre sau începând cu + și 11 cifre.';
            }
            return true;
        },
        /**
         * Validates the email address.
         * - Blank is allowed.
         * - If provided, it must match a basic email regex.
         *
         * @returns {true|string} True if valid, or an error message.
         */
        validateEmail() {
            const email = this.newIntermediary.email.trim();
            if (!email) {
                return true; // Blank is allowed
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                return 'Introduceti un email valid.';
            }
            return true;
        },
        /**
         * Emits an event to save intermediary data whenever a field is blurred or updated.
         * Assigns the current user ID to the intermediary before emitting the event.
         *
         * @function saveIntermediary
         * @emits save-intermediary
         */
        saveIntermediary() {
            this.newIntermediary.agent_id = parseInt(sessionStorage.getItem('userId'));
            this.$emit("save-intermediary", this.newIntermediary);
        },
        /**
         * Adds a new intermediary by sending a POST request to the backend API.
         * Validates that the intermediary's name is not empty, and upon success,
         * updates local storage, clears form fields, and emits a creation event.
         *
         * @async
         * @function addIntermediary
         * @returns {Promise<void>} Completes after the intermediary is created and
         *   the local data is refreshed. If an error occurs, displays an alert modal.
         * @emits intermediary-created
         */
        async addIntermediary() {
            // Clear existing errors
            this.nameError = "";
            this.phoneError = "";
            this.emailError = "";

            // Validate the name field (cannot be blank)
            if (!this.newIntermediary.name.trim()) {
                this.nameError = "Numele intermediarului nu poate fi gol.";
                return;
            }

            // Validate phone
            const phoneValidation = this.validatePhone();
            if (phoneValidation !== true) {
                this.phoneError = phoneValidation;
                return;
            }

            // Validate email
            const emailValidation = this.validateEmail();
            if (emailValidation !== true) {
                this.emailError = emailValidation;
                return;
            }

            // Set agent_id from session storage
            this.newIntermediary.agent_id = parseInt(sessionStorage.getItem('userId')) || null;

            try {
                const response = await apiClient.post('/api/create_intermediary/', this.newIntermediary);
                const savedIntermediary = response.data;
                const intermediaries = JSON.parse(sessionStorage.getItem("intermediaries") || "[]");
                intermediaries.push(savedIntermediary);
                sessionStorage.setItem("intermediaries", JSON.stringify(intermediaries));

                // Clear the input fields
                this.newIntermediary.name = "";
                this.newIntermediary.phone = "";
                this.newIntermediary.email = "";
                this.newIntermediary.is_global = false;

                // Emit event so that parent components can refresh their data
                this.$emit("intermediary-created");
            } catch (error) {
                console.error("Error adding intermediary:", error.response ? error.response.data : error.message);
                // If the server returns a 400 error, show its message inline for phone-related issues.
                if (error.response && error.response.status === 400 && error.response.data) {
                    this.phoneError = error.response.data.message;
                } else {
                    showAlertModal(
                        this.$store,
                        'A apărut o eroare la adăugarea intermediarului. Vă rugăm să încercați din nou.',
                        'danger',
                        12000,
                        error
                    );
                }
            }
        },
    }
}
</script>

<style scoped>

</style>