<template>
    <v-card :elevation="3" class="mt-3">
        <v-card-title>
            <v-container style="padding: 0">
                <v-row
                    align="center"
                    :class="{
                        'justify-space-between': $vuetify.display.mdAndUp,
                        'justify-start': $vuetify.display.smAndDown,
                    }"
                >
                    <!-- Left Section: Title & Tooltip -->
                    <v-col cols="12" md="6" class="d-flex align-center">
                        <span class="font-weight-bold text-h6"
                            >Generare contract</span
                        >
                        <v-tooltip location="top center">
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    size="small"
                                    class="ml-2"
                                >
                                    mdi-information-outline
                                </v-icon>
                            </template>
                            <p class="text-center">
                                Din această secțiune contractul poate fi generat
                                cu datele clientului și poate fi și trimis
                                acestuia pe adresa de mail.
                            </p>
                        </v-tooltip>
                    </v-col>

                    <!-- Right Section: Status & History -->
                    <v-col
                        cols="12"
                        md="6"
                        class="d-flex align-center"
                        :class="{
                            'justify-end': $vuetify.display.mdAndUp,
                            'justify-start': $vuetify.display.smAndDown,
                        }"
                    >
                        <v-chip class="mr-2" :color="statusColor">
                            Status Contract:
                            {{ displayContractStatus || "N/A" }}
                        </v-chip>

                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <v-icon size="small" v-bind="props"
                                    >mdi-information-outline
                                </v-icon>
                            </template>

                            <v-container class="pa-2">
                                <template v-if="lastHistoryEntry">
                                    <p>
                                        <strong>{{
                                            lastHistoryEntry.field
                                        }}</strong>
                                        {{ lastHistoryEntry.old_value }} →
                                        {{ lastHistoryEntry.new_value }}
                                    </p>
                                    <p>
                                        Modificat de:
                                        <strong>{{
                                            lastHistoryEntry.changed_by
                                        }}</strong>
                                        la
                                        <strong>{{
                                            new Date(
                                                lastHistoryEntry.changed_at
                                            ).toLocaleString("ro-RO")
                                        }}</strong>
                                    </p>
                                </template>

                                <p v-else class="text-center text-grey">
                                    Nicio modificare înregistrată
                                </p>
                            </v-container>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-title>

        <!-- Checkbox for "Decontare depusă" -->
        <v-checkbox
            v-if="client.contract_status === 'Factură finală'"
            v-model="isDecontareDepusa"
            label="Decontare depusă"
            @change="updateStatus('Decontare depusă')"
            hide-details
        ></v-checkbox>

        <v-checkbox
            v-if="client.contract_status === 'Decontare depusă'"
            v-model="isDecontat"
            label="Decontat"
            @change="updateStatus('Decontat')"
            hide-details
        ></v-checkbox>
        <!-- <v-card-subtitle v-if="contractAccess?.is_signed">
          <v-alert
            icon="mdi-check"
            title="Pentru acest client există deja un contract trimis și semnat."
            color="success"
            density="compact"
            variant="outlined"
          >
          </v-alert>
        </v-card-subtitle> -->
        <v-card-text class="mt-5">
            <v-text-field
                v-model="contractValue"
                label="Valoare contract"
                type="number"
                density="compact"
                min="0"
                :disabled="contractAccess && contractAccess?.is_signed"
                variant="outlined"
                required
                :error-messages="
                    contractValue
                        ? ''
                        : 'Valoarea contractului este obligatorie'
                "
                :error="!contractValue"
                :readonly="!userPermissions"
                @input="validateValoareContract"
            ></v-text-field>
            <v-btn
                :disabled="!contractValue"
                @click="saveContractValue"
                color="success"
                class="mt-3 mr-2"
                v-if="contractValue !== originalContractValue"
            >
                Salvează valoarea contractului
            </v-btn>
            <v-btn
                :disabled="
                    !contractValue ||
                    !originalContractValue ||
                    contractValue !== originalContractValue ||
                    !client.offer_kw ||
                    !client.offer_storage_kw
                "
                @click="generatePDFContract"
                class="mt-3"
            >
                Vizualizează contract
            </v-btn>
            <v-btn
                :disabled="
                    !contractValue ||
                    !originalContractValue ||
                    (contractAccess && contractAccess?.is_sent) ||
                    this.client.contract_status === 'Trimis' ||
                    this.contractAccess.is_signed ||
                    contractValue !== originalContractValue ||
                    !client.offer_kw ||
                    !client.offer_storage_kw ||
                    !userPermissions
                "
                @click="sendEmail"
                class="ml-3 mt-3"
                :loading="isLoading"
            >
                Trimite contractul
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import { createPDFContract, showAlertModal } from "@/utils/utils";
import apiClient from "@/utils/apiClient";

export default {
    name: "ClientContract",
    components: {},
    data() {
        return {
            requiredFieldsErrors: {},
            isClientUpdated: true,
            isLoading: false,
            contractValue: null,
            originalContractValue: null,
            contractAccess: {
                is_signed: false,
                is_accessed: false,
            },
            previousStatus: "",
            isDecontareDepusa: false,
            isDecontat: false,
            localClient: JSON.parse(JSON.stringify(this.client)),
        };
    },
    props: {
        client: {
            type: Object,
        },
        userPermissions: {
            type: Boolean,
            default: false,
        },
        statusHistory: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        /**
         * Returns the display status, showing only "Semnat" if it contains signing details.
         * @returns {string}
         */
        displayContractStatus() {
            if (this.client.contract_status?.startsWith("Semnat")) {
                return "Semnat";
            }
            return this.client.contract_status || "N/A";
        },
        hasStatusChangedAfterSigning() {
            if (!this.statusHistory.length) return false;

            // Initial statuses that can be overridden by the backend
            const initialStatuses = ["Nesemnat", "Semnat"];

            // Function to check if a status matches "Semnat" (even if it has a date/IP)
            const isSemnatStatus = (status) => status.startsWith("Semnat"); // Covers cases like "Semnat la: <date> IP: <ip>"

            // Sort history chronologically (oldest to newest)
            const sortedHistory = [...this.statusHistory].sort(
                (a, b) => new Date(a.changed_at) - new Date(b.changed_at)
            );

            // Find the last occurrence where the status was "Nesemnat" or "Semnat"
            const lastSignedEntry = [...sortedHistory]
                .reverse()
                .find(
                    (entry) =>
                        initialStatuses.includes(entry.new_value) ||
                        isSemnatStatus(entry.new_value)
                );

            if (!lastSignedEntry) {
                // If "Nesemnat" or "Semnat" was never set, the backend should not override
                return false;
            }

            // Check if there are any changes **after** this last "Nesemnat" or "Semnat"
            return sortedHistory.some(
                (entry) =>
                    new Date(entry.changed_at) >
                        new Date(lastSignedEntry.changed_at) &&
                    !initialStatuses.includes(entry.new_value) &&
                    !isSemnatStatus(entry.new_value)
            );
        },
        lastHistoryEntry() {
            if (!this.statusHistory.length) return null;

            // Sort by `changed_at` (latest first) to ensure we get the most recent change
            const sortedHistory = [...this.statusHistory].sort(
                (a, b) => new Date(a.changed_at) - new Date(b.changed_at)
            );

            return sortedHistory[sortedHistory.length - 1]; // Get the latest entry
        },
        statusColor() {
            const status = String(this.client.contract_status || ""); // Ensure it's a string

            if (status.includes("Semnat la")) {
                return "green"; // Covers "Semnat la <date> <ip>"
            }

            if (
                [
                    "Factură avans achitată",
                    "Anexă marfă trimisă",
                    "Certificat de racordare",
                    "Factură finală",
                    "Decontare depusă",
                    "Decontat",
                ].includes(status)
            ) {
                return "orange";
            }

            switch (status) {
                case "Aprobat":
                    return "purple";
                case "Trimis":
                    return "blue";
                case "Nesemnat":
                    return "red";
                default:
                    return "gray";
            }
        },
    },
    async mounted() {
        if (this.client) {
            setTimeout(async () => {
                // Fetch ContractAccess data
                await this.fetchContractAccess();
                await this.checkContractToken(); //  Check if contract link is expired
            }, 250);
        }
    },
    methods: {
        /**
         * Converts a date to ISO format if it exists.
         * @param {string | Date | null} date - The date to convert.
         * @returns {string | null} - The ISO string or null if the date is invalid.
         */
        toISODate(date) {
            if (!date) return null;
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, "0");
            const day = String(d.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        /**
         * Checks the validity of the contract token by making an API request.
         * If the token is invalid or expired, it updates the status accordingly.
         *
         * @async
         * @returns {Promise<void>} Resolves when the check is complete.
         */
        async checkContractToken() {
            if (
                !this.client ||
                !this.contractAccess ||
                !this.contractAccess.token
            ) {
                return;
            }

            try {
                await apiClient.get("/api/verify-token/", {
                    params: { token: this.contractAccess.token },
                });
            } catch (error) {
                if (error.response) {
                    const errorMessage = error.response.data.message || "";

                    if (
                        error.response.status === 401 ||
                        error.response.status === 404
                    ) {


                        if (
                            !this.hasStatusChangedAfterSigning &&
                            this.client.contract_status !== "Nesemnat"
                        ) {
                            if (errorMessage.includes("Token expired") && !this.contractAccess.is_signed ) {
                                this.emitUpdatedStatus("Nesemnat");
                            }
                        }
                        return; // Prevents logging errors we already handled
                    }
                }
            }
        },

        /**
         * Emits the updated contract status to the parent.
         * @param {string} newStatus - The new contract status.
         */
        emitUpdatedStatus(newStatus) {
            this.$emit("update-contract-status", newStatus);
        },

        /**
         * Handles contract status updates for:
         * - Aprobat (Approved)
         * - Decontare depusă (Settlement submitted)
         * - Decontat (Settled)
         */
        updateStatus(statusType) {
            switch (statusType) {
                case "Decontare depusă":
                    if (this.isDecontareDepusa) {
                        this.emitUpdatedStatus("Decontare depusă");
                    }
                    break;

                case "Decontat":
                    if (this.isDecontat) {
                        this.emitUpdatedStatus("Decontat");
                    }
                    break;

                default:
                    console.warn("Invalid status type:", statusType);
            }
        },

        /**
         * Fetches the contract access data for the current client by making an API call.
         * If the data is found, initializes local state with the contract value and sign status.
         * Otherwise, it resets local data to null.
         *
         * @async
         * @function fetchContractAccess
         * @returns {Promise<void>} Resolves when the contract access data has been fetched and applied to component state.
         */
        async fetchContractAccess() {
            try {
                const response = await apiClient.get(
                    `/api/pdf/get-contract-access/${this.client.id}/`
                );
                if (response && response.data) {
                    this.contractAccess = response.data;
                    this.contractValue = this.contractAccess.contract_value;
                    this.originalContractValue =
                        this.contractAccess.contract_value;

                    //  If the contract is signed, update status and store details
                    if (!this.hasStatusChangedAfterSigning) {
                        if (this.contractAccess.is_signed) {
                            // Convert the date to a human-readable format
                            const date = new Date(
                                this.contractAccess.signed_at
                            );
                            const formattedDate = date.toLocaleString("ro-RO", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: false,
                            });

                            // Create the formatted string
                            const signedInfo = `Semnat la: ${formattedDate} IP: ${this.contractAccess.signed_ip}`;

                            if (
                                !this.hasStatusChangedAfterSigning &&
                                this.client.contract_status !== signedInfo
                            ) {
                                this.emitUpdatedStatus(signedInfo);
                            }
                        }
                    }
                } else {
                    this.contractAccess = null;
                    this.contractValue = null;
                    this.originalContractValue = null;
                }
            } catch (error) {
                const message = error?.response?.data?.error;

                if (
                    !message?.includes(
                        "ContractAccess not found for this client"
                    )
                ) {
                    console.error("Error fetching ContractAccess data:", error);
                    showAlertModal(
                        this.$store,
                        "Eroare la obținerea contractului. Încercați din nou.",
                        "danger",
                        5000
                    );
                } else {
                    // If the specific error is "not found", it's a valid state, so silently handle it
                    this.contractAccess = null;
                    this.contractValue = null;
                    this.originalContractValue = null;
                }
            }
        },
        /**
         * Saves or updates the contract value for the current client.
         * If the contract access record exists, it patches the existing record.
         * Otherwise, it creates a new one.
         *
         * @async
         * @function saveContractValue
         * @returns {Promise<void>} Resolves after the contract value is successfully saved or updated in the backend.
         */
        async saveContractValue() {
            if (!this.contractValue) {
                showAlertModal(
                    this.$store,
                    "Valoarea contractului este obligatorie!",
                    "warning",
                    5000
                );
                return;
            }

            try {
                this.isLoading = true;

                if (this.contractAccess?.id) {
                    // Update existing ContractAccess
                    const response = await apiClient.patch(
                        `/api/pdf/update-contract-access/${this.client.id}/`,
                        {
                            contract_value: this.contractValue,
                        }
                    );

                    if (response.status === 200) {
                        this.originalContractValue = this.contractValue;
                        showAlertModal(
                            this.$store,
                            "Valoarea contractului a fost actualizată!",
                            "success",
                            5000
                        );
                    }
                } else {
                    // Create new ContractAccess
                    const response = await apiClient.post(
                        `/api/pdf/update-contract-access/${this.client.id}/`,
                        {
                            contract_value: this.contractValue,
                        }
                    );

                    if (response.status === 201) {
                        this.contractAccess = response.data;
                        this.originalContractValue = this.contractValue;
                        showAlertModal(
                            this.$store,
                            "Valoarea contractului a fost salvată!",
                            "success",
                            5000
                        );
                    }
                }

                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                console.error("Error saving contract value:", error);
                showAlertModal(
                    this.$store,
                    "A apărut o eroare la salvarea valorii contractului.",
                    "danger",
                    5000
                );
            }
        },
        /**
         * Validates the contract value (valoare contract) to ensure it is a non-negative number.
         * Resets the contract value to null if it is invalid (NaN or negative).
         *
         * @function validateValoareContract
         * @returns {void} Does not return anything; it updates local component state directly.
         */
        validateValoareContract() {
            if (this.contractValue < 0) {
                this.contractValue = 0;
            } else if (!this.contractValue || isNaN(this.contractValue)) {
                showAlertModal(
                    this.$store,
                    "Valoarea contractului trebuie să fie un număr valid!",
                    "warning",
                    5000
                );
                this.contractValue = null; // Reset if invalid
            }
        },
        /**
         * Generates a PDF for the contract and opens it in a new tab.
         * Constructs the URL for the PDF and opens it directly using the createPDFContract helper function.
         *
         * The method makes use of the createPDFContract function, passing the client ID to generate and display the contract.
         * In case of an error during the PDF generation, it logs the error to the console.
         */
        async generatePDFContract() {
            if (this.contractAccess?.is_signed) {
                let url = `${process.env.VUE_APP_API_BASE_URL}/media/${this.contractAccess.contract_path}`;
                window.open(url, "_blank");
                return;
            }
            if (!this.contractValue) {
                showAlertModal(
                    this.$store,
                    "Vă rugăm introduceți o valoare pentru contract!",
                    "warning",
                    5000
                );
                return;
            }

            // Save contract value if modified
            if (this.contractValue !== this.originalContractValue) {
                await this.saveContractValue();
            }

            try {
                await createPDFContract(this.client.id, 3, this.contractValue);
            } catch (error) {
                showAlertModal(
                    this.$store,
                    "A apărut o eroare la generarea contractului.",
                    "danger",
                    12000,
                    error
                );
            }
        },
        /**
         * Sends an email with a link to access the contract to the client.
         * The email includes a link to the contract, which the client can access after entering an OTP
         * that will be sent separately via SMS when they attempt to sign the contract.
         * If successful, it displays a success modal to the user. In case of errors, it displays an error modal.
         *
         * Sends an API request to the backend to:
         * - Generate a new contract PDF.
         * - Delete any previous contract records for this client.
         * - Send an email to the client's email address with a link to view the contract.
         *
         * @async
         * @function sendEmail
         * @returns {Promise<void>} Displays a success or error alert modal based on the response status.
         */
        async sendEmail() {
            // Save contract value if modified
            if (this.contractValue !== this.originalContractValue) {
                await this.saveContractValue();
            }
            if (!this.contractValue) {
                showAlertModal(
                    this.$store,
                    "Vă rugăm introduceți o valoare pentru contract!",
                    "warning",
                    5000
                );
                return;
            }
            try {
                this.isLoading = true;
                const response = await apiClient.post("/api/pdf/send-email/", {
                    phoneNumber: this.client.phone,
                    email: this.client.email,
                    client_id: this.client.id,
                    document_type: 3,
                    contract_value: this.contractValue,
                    message:
                        "Your contract has been generated and sent to your email.",
                });

                if (response.status === 200) {
                    this.isLoading = false;
                    this.emitUpdatedStatus("Trimis");

                    showAlertModal(
                        this.$store,
                        "Contractul a fost trimis pe mail-ul clientului, iar agentul a fost notificat prin email.",
                        "success",
                        5000
                    );
                } else {
                    this.isLoading = false;
                    showAlertModal(
                        this.$store,
                        "A apărut o eroare! Vă rugăm reîncercați!",
                        "danger",
                        12000
                    );
                }
            } catch (error) {
                this.isLoading = false;
                showAlertModal(
                    this.$store,
                    "A apărut o eroare la trimiterea mail-ului! Vă rugăm să încercați din nou după reîmprospătarea paginii!",
                    "danger",
                    12000,
                    error
                );
            }
        },
    },
};
</script>
