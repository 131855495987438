<template>
    <v-btn
        icon
        variant="plain"
        :class="'d-flex flex-row w-100 justify-content-end ' + color"
        @click="openDialog"
    >
        <v-tooltip
            activator="parent"
            location="top"
        ><span v-html="plenumsTooltip"></span>
        </v-tooltip>
        <span
            :class="color"
            v-for="(plenum, index) in groupedPlenums"
            :key="index"
        >
			{{ plenum.shortName }}{{ plenum.size }}({{ plenum.count }})<span v-if="index < groupedPlenums.length - 1"
        >,</span
        >
		</span>
        <!-- Plenum Modal -->
        <v-dialog
            v-model="dialog"
            width="auto"
        >
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        Plenumuri & Circuite
                        <span class="fs-6" style="font-weight: 550">
                                ({{ roomInfo.floor_name }} :
                                {{ roomInfo.name }})
                        </span>
                        <v-toolbar-title color="red">
                            <h6
                                v-if="actualCircuits !== circuitCount"
                                class="mb-0"
                                style="color: red"
                            >
                                necesar de circuite: {{ circuitCount }}
                                <span>- total circuite: {{ actualCircuits }} </span>
                            </h6>
                            <h6 v-else class="mb-0">necesar de circuite: {{ circuitCount }}
                            </h6>
                        </v-toolbar-title>
                    </v-toolbar-title>
                    <v-toolbar-items>
                        <v-btn
                            v-if="hasRightsToAddPlenum"
                            @click="addPlenum()"
                            height="48px"
                        >
                            Adaugă plenum
                        </v-btn>
                        <v-btn
                            color="primary"
                            icon
                            height="48px"
                            @click="closeDialog"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div class="overflow-y-auto">
                    <v-data-table
                        v-if="localPlenums && localPlenums.length > 0"
                        :headers="getHeaders"
                        :group-by="isMixedRoom ? [{ key: 'air' }] : []"
                        :items="localPlenums"
                        fixed-header
                        :items-per-page="50"
                    >
                        <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }">
                            <tr>
                                <td :colspan="columns.length">
                                    <template :ref="(el) => {
                                        if (!isGroupOpen(item))
                                            toggleGroup(item);
                                        }">
                                    </template>
                                    {{ item.value === 1 ? 'Proaspăt' : 'Viciat' }}
                                </td>
                            </tr>
                        </template>
                        <template v-slot:[`item.index`]="{ item }">
							<span>{{
                                    this.localPlenums.filter((p) => p.air === item.air).indexOf(item) + 1
                                }}</span>
                        </template>
                        <template v-slot:[`item.air`]="{ item }">
                            <v-select
                                :items="airTypes"
                                v-model="item.air"
                                item-title="name"
                                item-value="type"
                                density="compact"
                                :variant="item.air === 3 ? 'outlined' : 'underlined'"
                                :color="item.air === 3 ? 'danger' : 'black'"
                                @update:model-value="updateField(item, 'air', false)"
                                hide-details
                            >
                            </v-select>
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                            <v-select
                                style="width: 200px"
                                variant="underlined"
                                v-model="item.type"
                                :items="options"
                                item-title="name"
                                density="compact"
                                item-value="type"
                                @focus="savePreviousValue(item, 'type')"
                                @update:model-value="updateField(item, 'type', false)"
                                hide-details
                            ></v-select>
                        </template>
                        <template v-slot:[`item.circuit_length`]="{ item }">
                            <div>
                                <v-text-field
                                    type="number"
                                    variant="underlined"
                                    density="compact"
                                    v-model="item.circuit_length"
                                    :class="
                                        item.is_manually_edited
                                            ? 'no-arrows text-right text-warning suffix-color'
                                            : 'no-arrows text-right'
                                    "
                                    hide-details
                                    suffix="ml"
                                    @focus="savePreviousValue(item, 'circuit_length')"
                                    @blur="updateField(item, 'circuit_length', true)"
                                    @keyup.enter="updateField(item, 'circuit_length', true)"
                                >
                                </v-text-field>
                                <v-tooltip
                                    v-if="item.is_manually_edited"
                                    location="top"
                                    activator="parent"
                                >
                                    Plenumul a fost editat manual
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:[`item.size`]="{ item }">
                            <div>
                                <v-select
                                    variant="underlined"
                                    v-model="item.size"
                                    density="compact"
                                    :items="availableSizes(item.type)"
                                    :class="
										item.is_manually_edited
											? 'no-arrows text-right text-warning suffix-color'
											: 'no-arrows text-right'
									"
                                    :disabled="!this.userPermissions.plenumManagement.canEditPlenumSize"
                                    @focus="savePreviousValue(item, 'size')"
                                    @update:modelValue="updateField(item, 'size', true)"
                                    hide-details
                                >
                                </v-select>
                                <v-tooltip
                                    v-if="item.is_manually_edited"
                                    location="top"
                                    activator="parent"
                                >
                                    Plenumul a fost editat manual
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:[`item.grate`]="{ item }">
                            <v-select
                                v-model="item.grate"
                                variant="underlined"
                                density="compact"
                                hide-details
                                :items="grateTypes"
                                @update:model-value="updateField(item, 'grate', false)"
                                @focus="() => {savePreviousValue(item, 'grate'); filterGrateTypes(item);}"
                            ></v-select>
                        </template>
                        <template v-slot:[`item.flow`]="{ item }">
                            <span>{{ formatNumber(this, item.flow, false) }} m<sup>3</sup>/h</span>
                        </template>
                        <template v-slot:[`item.airSpeed`]="{ item }">
                            <span>{{ formatNumber(this, item.airSpeed, false) }} m/s</span>
                        </template>
                        <template v-slot:[`item.pressureLossPerLength`]="{ item }">
                            <span>{{ formatNumber(this, item.pressureLossPerLength, false) }} Pa</span>
                        </template>
                        <template v-slot:[`item.pressureLossPerGrill`]="{ item }">
                            <span>{{ formatNumber(this, item.pressureLossPerGrill, false) }} Pa</span>
                        </template>
                        <template v-slot:[`item.pressureLossPerPlenum`]="{ item }">
                            <span>{{ formatNumber(this, item.pressureLossPerPlenum, false) }} Pa</span>
                        </template>
                        <template v-slot:[`item.pressureLossPerSection`]="{ item }">
                            <span>{{ formatNumber(this, item.pressureLossPerSection, false) }} Pa</span>
                        </template>
                        <template
                            v-if="this.userPermissions.plenumManagement.canDeletePlenum"
                            v-slot:[`item.actions`]="{ item }"
                        >
                            <v-icon
                                color="danger"
                                variant="flat"
                                @click="removePlenum(item.localId)"
                            >mdi-delete
                            </v-icon>
                        </template>
                        <template v-slot:bottom>
                            <template
                                v-for="(air, index) in getRoomPlenumsAirTypes"
                                :key="index"
                            >
                                <v-toolbar :title="`Totaluri ${isMixedRoom ? air.name : ''}`">
                                    <v-chip
                                        color="black"
                                        text-color="white"
                                        class="ma-2"
                                        size="large"
                                        variant="outlined"
                                    >
                                        Pierdere de presiune pe camera:
                                        {{ formatNumber(this, getPlenumsPressureLossByAirType(air.type), false) }}
                                        Pa
                                    </v-chip>
                                </v-toolbar>
                                <v-divider
                                    style="opacity: 0.4"
                                    class="my-0"
                                    v-if="index < getRoomPlenumsAirTypes.length - 1"
                                ></v-divider>
                            </template>
                        </template>
                    </v-data-table>
                    <v-expand-transition>
                        <v-card
                            v-if="showChangeDialog && !hidePlenumTypeChangeDialog"
                            class="px-10"
                        >
                            <v-card-text class="pb-0">
                                <v-row>
                                    <v-col cols="auto">
                                        <v-icon
                                            class="pt-10"
                                            color="warning"
                                            size="x-large"
                                        >
                                            mdi-alert-circle-outline
                                        </v-icon>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-radio-group
                                            v-model="changeOptions"
                                            v-if="singlePlenumChange"
                                            class="mx-10 pt-6"
                                        >
                                            <v-radio
                                                class="pb-2"
                                                @click="showChangeDialog = false"
                                                value="one"
                                            >
                                                <template v-slot:label>
                                                    Schimb tipul plenumului selectat în
                                                    {{ getPlenumName(futureValues.type) }}
                                                </template>
                                            </v-radio>
                                            <v-radio
                                                class="pb-2"
                                                value="two"
                                            >
                                                <template v-slot:label>
                                                    Schimb toate plenumurile de tip
                                                    {{ getPlenumName(previousValues) }}
                                                    {{ previousValues.grate }} în tipul
                                                    {{ getPlenumName(futureValues.type) }} cu grilaj
                                                    <v-select
                                                        :items="getCompatibleGrates(futureValues.type)"
                                                        v-model="futureValues.grate"
                                                        item-value="grate"
                                                        item-text="grate"
                                                        dense="compact"
                                                        hide-details
                                                        variant="outlined"
                                                        class="mx-2"
                                                        style="
															font-weight: 700;
															color: var(--bs-dark);
															max-width: 150px;
														"
                                                    >
                                                    </v-select>
                                                </template>
                                            </v-radio>
                                            <v-radio
                                                class="pb-2"
                                                value="three"
                                            >
                                                <template v-slot:label>
                                                    Schimb tipul tuturor plenumurilor în
                                                    {{ getPlenumName(futureValues.type) }} cu grilaj
                                                    <v-select
                                                        :items="getCompatibleGrates(futureValues.type)"
                                                        v-model="futureValues.grate"
                                                        item-value="grate"
                                                        item-text="grate"
                                                        dense="compact"
                                                        hide-details
                                                        variant="outlined"
                                                        class="mx-2"
                                                        style="font-weight: 700; max-width: 150px"
                                                    >
                                                    </v-select>
                                                </template>
                                            </v-radio>
                                            <v-radio
                                                class="pb-2"
                                                value="room"
                                            >
                                                <template v-slot:label>
                                                    Schimb tipul tuturor plenumurilor din această cameră în
                                                    {{ getPlenumName(futureValues.type) }} cu grilaj
                                                    <v-select
                                                        :items="getCompatibleGrates(futureValues.type)"
                                                        v-model="futureValues.grate"
                                                        item-value="grate"
                                                        item-text="grate"
                                                        dense="compact"
                                                        hide-details
                                                        variant="outlined"
                                                        class="mx-2"
                                                        style="font-weight: 700; max-width: 150px"
                                                    >
                                                    </v-select>
                                                </template>
                                            </v-radio>
                                            <v-radio
                                                class="pb-2"
                                                value="floor"
                                            >
                                                <template v-slot:label>
                                                    Schimb tipul tuturor plenumurilor de pe acest etaj în
                                                    {{ getPlenumName(futureValues.type) }} cu grilaj
                                                    <v-select
                                                        :items="getCompatibleGrates(futureValues.type)"
                                                        v-model="futureValues.grate"
                                                        item-value="grate"
                                                        item-text="grate"
                                                        dense="compact"
                                                        hide-details
                                                        variant="outlined"
                                                        class="mx-2"
                                                        style="font-weight: 700; max-width: 150px"
                                                    >
                                                    </v-select>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="pb-6">
                                <v-checkbox
                                    v-model="requestHidePlenumTypeChangeDialog"
                                    :label="'Nu afișa acest mesaj timp de ' + this.hideDelayMinutes + ' minute'"
                                    hide-details
                                />
                                <v-btn
                                    color="danger"
                                    variant="text"
                                    @click="showChangeDialog = false"
                                    class="mx-4"
                                >
                                    Anulează
                                </v-btn>
                                <v-btn
                                    variant="text"
                                    color="success"
                                    @click="
										changePlenumType(
											previousPlenumValues[focusedLocalId].type,
											futureValues.type,
											futureValues.grate,
											changeOptions
										)
									"
                                >
                                    Aplică selecția
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-card
                            v-if="showChangeGrateDialog && !hideGrateChangeDialog"
                            class="px-10"
                        >
                            <v-card-text class="pb-0">
                                <v-row>
                                    <v-col cols="auto">
                                        <v-icon
                                            class="pt-10"
                                            color="warning"
                                            size="x-large"
                                        >
                                            mdi-alert-circle-outline
                                        </v-icon>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-radio-group
                                            v-model="changeOptions"
                                            class="mx-10 pt-6"
                                        >
                                            <v-radio
                                                value="one"
                                                class="pb-2"
                                            >
                                                <template v-slot:label>
                                                    Schimbă grilajul plenumului selectat din
                                                    <strong>&nbsp;{{
                                                            previousPlenumValues[focusedLocalId].grate
                                                        }}&nbsp;</strong> în
                                                    <strong>&nbsp;{{ futureValues.grate }}&nbsp;</strong>
                                                </template>
                                            </v-radio>
                                            <v-radio
                                                value="two"
                                                class="pb-2"
                                            >
                                                <template v-slot:label>
                                                    Schimbă grilajele pentru toate plenumurile cu grila
                                                    <strong>&nbsp;{{
                                                            previousPlenumValues[focusedLocalId].grate
                                                        }}&nbsp;</strong> în
                                                    <strong>&nbsp;{{ futureValues.grate }}&nbsp;</strong>
                                                </template>
                                            </v-radio>
                                            <v-radio
                                                value="three"
                                                class="pb-2"
                                            >
                                                <template v-slot:label>
                                                    Schimbă grilajele pentru toate plenumurile în
                                                    <strong>&nbsp;{{ futureValues.grate }}&nbsp;</strong>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="pb-6">
                                <v-checkbox
                                    v-model="requestHideGrateChangeDialog"
                                    :label="'Nu afișa acest mesaj timp de ' + this.hideDelayMinutes + ' minute'"
                                    hide-details
                                />
                                <v-btn
                                    color="danger"
                                    variant="text"
                                    @click="showChangeGrateDialog = false"
                                >
                                    Anulează
                                </v-btn>
                                <v-btn
                                    variant="text"
                                    color="success"
                                    @click="changePlenumGrate(changeOptions, futureValues.grate)"
                                >
                                    Aplică selecția
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-expand-transition>
                </div>
            </v-card>
        </v-dialog>
        <v-icon> mdi-pencil</v-icon>
    </v-btn>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {formatNumber, showAlertModal} from "@/utils/utils";
import {maxFlow77Tube, pressureLoss77Tube, maxFlow, pressureLoss} from "@/VMC/vmcData";
import store from "@/store";
import {mapGetters} from "vuex";
import {cloneDeep} from "lodash";

const AIR_TYPES = [
    {type: 1, name: "Proaspăt"},
    {type: 2, name: "Viciat"},
];

const OPTIONS_75 = [
    {type: 1, name: "Modular", sizes: [1, 2, 3], autoSize: [1, 2], shortName: "MO"},
    {type: 2, name: "Metalic spate", sizes: [1, 2], autoSize: [1, 2], shortName: "MS"},
    {type: 3, name: "Metalic lateral", sizes: [1, 2], autoSize: [1, 2], shortName: "ML"},
    {type: 4, name: "Design spate", sizes: [1, 2], autoSize: [1, 2], shortName: "DS"},
    {type: 5, name: "Design lateral", sizes: [1, 2], autoSize: [1, 2], shortName: "DL"},
    {type: 6, name: "Dublu tavan modular", sizes: [1, 2], autoSize: [1, 2], shortName: "TMO"},
    {type: 7, name: "Dublu tavan metalic", sizes: [1, 2], autoSize: [1, 2], shortName: "TME"},
];

const PLENUM_GRATES_75 = {
    1: {grateTypes: ["Design", "Rotund", "Difuzor rotund", "Difuzor patrăt"], defaultGrate: "Rotund"},
    2: {grateTypes: ["Găurit"], defaultGrate: "Găurit"},
    3: {grateTypes: ["Găurit"], defaultGrate: "Găurit"},
    4: {grateTypes: ["Design"], defaultGrate: "Design"},
    5: {grateTypes: ["Design"], defaultGrate: "Design"},
    6: {grateTypes: ["Rotund", "Design", "Difuzor rotund", "Difuzor patrăt"], defaultGrate: "Rotund"},
    7: {grateTypes: ["Rotund", "Design", "Difuzor rotund", "Difuzor patrăt"], defaultGrate: "Rotund"},
};

const OPTIONS_90 = [{type: 8, name: "Scurt", sizes: [1, 2], autoSize: [1, 2], shortName: "SC"}];

const PLENUM_GRATES_90 = {
    8: {grateTypes: ["Design", "Rotund", "Difuzor rotund", "Difuzor patrăt"], defaultGrate: "Rotund"},
};

const OPTIONS_117 = [
    {type: 9, name: "Dublu tavan", sizes: [1, 2], autoSize: [1, 2], shortName: "DT"},
    {type: 10, name: "Dublu podea", sizes: [1, 2], autoSize: [1, 2], shortName: "DP"},
];

const PLENUM_GRATES_117 = {
    9: {grateTypes: ["Design", "Rotund", "Difuzor rotund", "Difuzor patrăt"], defaultGrate: "Rotund"},
    10: {grateTypes: ["Podea"], defaultGrate: "Podea"},
}

export default {
    components: {},
    props: {
        room: {
            type: Object,
        },
        color: {
            type: String,
        },
        reset: {
            type: Boolean,
        },
        hideDelayMinutes: {
            type: Number,
        },
        hidePlenumTypeChangeDialog: {
            type: Boolean,
        },
        previousPlenumType: {
            type: Number,
            default: 6,
        },
        previousPlenumGrate: {
            type: String,
        },
        selectedTubingOption: {
            type: Number,
        },
        pressureLossLimit: {
            type: Number,
        },
    },
    emits: [
        "update-plenums",
        "change-plenum-type-or-grate",
        "update-circuit-count",
        "update-pressure-loss",
        "reset-done",
        "request-hide-plenum-type-change-dialog",
    ],
    mounted() {
        this.initializePlenums();
    },
    data() {
        return {
            isDebug: false,
            circuitCount: 0,
            grateTypes: [],
            airTypes: AIR_TYPES,
            calculatedValues: false,
            dialog: false,
            localCircuits: [],
            localPlenums: [],
            oldPlenums: [],
            roomInfo: null,
            isPlenumsEdited: false,
            initialCircuitLength: 15,
            airSpeedConstant: 12.69,
            editOrder: [],
            changeOptions: "two",
            singlePlenumChange: true,
            previousValues: {},
            futureValues: {},
            showChangeDialog: false,
            requestHidePlenumTypeChangeDialog: false,
            requestHideGrateChangeDialog: false,
            showChangeGrateDialog: false,
            hideGrateChangeDialog: false,
            is_manually_edited: false,
            preferredType: 5,
            options: OPTIONS_75,
            plenumGrateMapping: PLENUM_GRATES_75,
            pressureLoss: pressureLoss77Tube,
            maxFlow: maxFlow77Tube,
            previousPlenumValues: {},
            focusedLocalId: null,
        };
    },
    computed: {
        /**
         * Computes the room flow based on air volume and actual circuits.
         *
         * @returns {number} Returns the calculated room flow.
         */
        roomFlow() {
            return this.roomInfo.air_volume / this.actualCircuits;
        },
        /**
         * Computes the air speed in the room based on room flow and a constant.
         *
         * @returns {number} Returns the calculated air speed.
         */
        airSpeed() {
            return this.roomFlow / this.airSpeedConstant;
        },
        ...mapGetters(["userPermissions"]),
        /**
         * Checks if the user has the rights to add a plenum.
         * A user has these rights if they are either a super user or a technical user.
         *
         * @returns {boolean} Returns true if the user is a super user or a technical user, false otherwise.
         */
        hasRightsToAddPlenum() {
            return this.userPermissions.plenumManagement.canAddPlenum;
        },
        /**
         * Generates the headers for the plenum table.
         * The headers vary depending on whether the room has mixed air.
         *
         * @returns {Array} Returns an array of header objects for the table.
         */
        getHeaders() {
            let headers = [
                {title: "Actiuni", align: "center", key: "actions", sortable: false},
                {title: "Nr.", key: "index"},
                {title: "Tip", key: "type"},
                {title: "Lungime", key: "circuit_length"},
                {title: "Mărime", key: "size"},
                {title: "Grilă", key: "grate"},
                {title: "Debit", key: "flow"},
                {title: "Viteza aerului", key: "airSpeed"},
                {
                    title: "Pierdere de presiune",
                    key: "pressureLoss",
                    align: 'center',
                    children: [
                        {title: "Pe grilă", key: "pressureLossPerGrill"},
                        {title: "Pe plenum", key: "pressureLossPerPlenum"},
                        {title: "Pe secțiune", key: "pressureLossPerSection"},
                    ],
                },
            ];

            if (this.roomInfo.air === 3) {
                headers.splice(2, 0, {title: "Aer", key: "air"});
            }

            return headers;
        },
        /**
         * Computes the room ID, preferring the local ID if available.
         *
         * @returns {string} Returns the room's local ID or raw local ID.
         */
        roomId() {
            return this.roomInfo.localId !== undefined ? this.roomInfo.localId : this.room.localId;
        },
        /**
         * Generates a tooltip for the grouped plenums.
         *
         * @returns {string} Returns a string containing HTML with plenum details.
         */
        plenumsTooltip() {
            return this.groupedPlenums
                .map((plenum) => `Plenum ${plenum.name} de ${plenum.size}, cantitate: ${plenum.count} `)
                .join("<br/>");
        },
        /**
         * Computes the actual number of circuits from the local plenums.
         *
         * @returns {number} Returns the total number of circuits.
         */
        actualCircuits() {
            return this.localPlenums.reduce((acc, plenum) => {
                return acc + plenum.size;
            }, 0);
        },
        /**
         * Groups plenums by their name and size, summing their counts.
         *
         * @returns {Array} Returns an array of grouped plenum objects.
         */
        groupedPlenums() {
            const group = this.room.plenums.reduce((acc, plenum) => {
                const key = `${plenum.shortName}${plenum.size}`;
                if (!acc[key]) {
                    acc[key] = {
                        name: plenum.name,
                        shortName: plenum.shortName ? plenum.shortName : plenum.short_name,
                        size: plenum.size,
                        count: 0,
                    };
                }
                acc[key].count += plenum.count;
                return acc;
            }, {});

            return Object.values(group);
        },
        /**
         * Filters the room's plenums by air type and returns the air types used.
         *
         * @returns {Array} Returns an array of air types used in the room's plenums.
         */
        getRoomPlenumsAirTypes() {
            return this.airTypes.filter((airType) => this.localPlenums.some((plenum) => plenum.air === airType.type));
        },
        /**
         * Checks if the room is a mixed air room.
         *
         * @returns {boolean} Returns true if the room has mixed air, false otherwise.
         */
        isMixedRoom() {
            return this.roomInfo.air === 3;
        },
    },
    methods: {
        /**
         * Initializes plenums based on room information.
         * Sets room information, checks if any plenums are manually edited, and recalculates circuits and plenums.
         * Updates local plenums and ventilation coefficients, and initializes grate types.
         */
        initializePlenums() {
            this.setTubingValues(this.selectedTubingOption);
            this.roomInfo = JSON.parse(JSON.stringify(this.room));
            if (this.roomInfo.plenums.some((plenum) => plenum.is_manually_edited)) {
                this.isPlenumsEdited = true;
            }
            if (this.room.circuit_no_VMC === 0 && this.room.air_volume > 0) {
                this.localPlenums = this.recalculateCircuitsAndPlenums();
            } else {
                this.localPlenums = this.roomInfo.plenums;
                this.circuitCount = this.roomInfo.circuit_no_VMC;
            }
            this.localPlenums.forEach((plenum) => {
                // add plenum id to orderEdit array
                this.recordEdit(plenum.localId, false);
                plenum.isEdited = false;
            });
            this.calculateVentilationCoefficients();
            this.setPlenumDetails();
            this.updatePlenums(false);
            this.initializeGrateTypes(this.localPlenums);

            if (!this.isPlenumsEdited) {
                this.recalculateCircuitsAndPlenums();
            }
        },
        /**
         * Changes the type of a plenum.
         * Sets the preferred type, updates plenum details, and recalculates plenums.
         *
         * @param {Object} info - Information about the plenum type change.
         */
        changePlenum(info) {
            this.setPreferredType(info.type);
            this.setPlenumDetails();
            this.updatePlenums(true);
        },
        /**
         * Gets the name of a plenum type.
         *
         * @param {number} type - The type of the plenum.
         * @returns {string} - The name of the plenum type or an error message if not found.
         */
        getPlenumName(type) {
            const option = this.options.find((option) => option.type === type);
            if (option) {
                return option.name;
            } else {
                console.error(`Plenum type ${type} not found.`);
                return "Unknown Type"; // Return a default value or message
            }
        },
        /**
         * Gets compatible grate types for a given plenum type.
         *
         * @param {number} type - The type of the plenum.
         * @returns {Array} - An array of compatible grate types.
         */
        getCompatibleGrates(type) {
            let mapping = this.plenumGrateMapping[type];
            return mapping ? mapping.grateTypes : [];
        },
        /**
         * Changes the type of plenums based on a specified location condition.
         * This method emits an event with necessary change details, which can affect either a specific type of plenums
         * or all plenums, depending on the user's selection. It also manages the visibility of dialogs related to changes.
         *
         * @param {number} previousType - The initial type of the plenums before the change.
         * @param {number} futureType - The new type to be assigned to the plenums.
         * @param {string} grate - The grate associated with the plenum type, potentially affected by the type change.
         * @param {string} location - The location condition determining the scope of the change:
         *                            'two' changes plenums of the same type, 'three' changes all plenums.
         */
        changePlenumType(previousType, futureType, grate, location) {
            if (this.requestHidePlenumTypeChangeDialog) {
                this.$emit("request-hide-plenum-type-change-dialog");
            }
            let action;
            const roomId = this.roomInfo.id ? this.roomInfo.id : this.roomInfo.localId; // Ensure you have the current room ID
            const floor = this.roomInfo.floor ? this.roomInfo.floor : this.room.floor; // Ensure you have the current floor ID
            switch (location) {
                case "two":
                    action = "change-same-plenum-type";
                    break;
                case "three":
                    action = "change-all-plenum-type";
                    break;
                case "room":
                    action = "change-room-plenum-type";
                    break;
                case "floor":
                    action = "change-floor-plenum-type";
                    break;
            }
            if (action) {
                this.$emit("change-plenum-type-or-grate", {action, previousType, futureType, grate, roomId, floor});
            }
            this.showChangeDialog = false; // Close the dialog after applying changes
            this.requestHidePlenumTypeChangeDialog = false;
        },
        /**
         * Changes the grate of plenums based on a specified location condition.
         * This method handles changing the grate for either a single plenum, all plenums of a similar type,
         * or all plenums irrespective of type. It emits events to indicate these changes and manages dialog visibility.
         *
         * @param {string} location - Determines the scope of the change: 'one' for a single plenum, 'two' for all of same type,
         *                            'three' for all plenums.
         * @param {string} grate - The new grate to be assigned to the plenum(s).
         */
        changePlenumGrate(location, grate) {
            if (this.requestHideGrateChangeDialog) {
                this.$emit("request-hide-plenum-type-change-dialog");
            }
            let previousGrate = this.previousPlenumValues[this.focusedLocalId].grate;
            let futureGrate = this.futureValues.grate;
            let action;
            switch (location) {
                case "one":
                    // Change grate only for the selected plenum
                    action = "change-single-plenum-grate";
                    this.$emit("change-plenum-type-or-grate", {action, previousGrate, futureGrate, grate});
                    break;
                case "two":
                    // Change grate for all plenums of the same previous type
                    action = "change-same-plenum-grate";
                    this.$emit("change-plenum-type-or-grate", {action, previousGrate, futureGrate, grate});
                    break;
                case "three":
                    // Change grate for all plenums
                    action = "change-all-plenum-grate";
                    this.$emit("change-plenum-type-or-grate", {
                        action,
                        previousGrate,
                        futureGrate,
                        grate,
                        plenumGrateMapping: this.plenumGrateMapping,
                    });
                    break;
            }
            this.showChangeGrateDialog = false; // Close the dialog after applying changes
            this.requestHideGrateChangeDialog = false;
        },
        /**
         * Deletes plenums if the actual number of circuits exceeds the calculated circuit count.
         * Updates plenums based on circuits to delete and plenums to delete.
         *
         * @param {number} actualCircuits - The actual number of circuits.
         * @param {number} circuitCount - The calculated circuit count.
         * @param {Array} plenums - The list of plenums.
         * @returns {Array} - Updated list of plenums.
         */
        deletePlenums(actualCircuits, circuitCount, plenums) {
            // logic to delete plenums if the actual number of circuits is higher than the calculated circuit count
            let circuitsToDelete = actualCircuits - circuitCount;
            let plenumsToDelete = [];
            // Sort the plenums in ascending order based on their circuit length and diminish their size by circuitsToDelete.
            plenums
                .sort((a, b) => a.circuit_length - b.circuit_length)
                .forEach((plenum) => {
                    if (circuitsToDelete > 0) {
                        if (plenum.size > circuitsToDelete) {
                            plenum.size -= circuitsToDelete;
                            circuitsToDelete = 0;
                        } else {
                            circuitsToDelete -= plenum.size;
                            plenumsToDelete.push(plenum.localId);
                        }
                    }
                });
            return plenums.filter((plenum) => !plenumsToDelete.includes(plenum.localId));
        },
        /**
         * Resets plenums to their initial state.
         * Recalculates circuits and plenums, updates ventilation coefficients, and emits a reset event.
         */
        resetPlenums() {
            this.isPlenumsEdited = false;
            this.localPlenums = this.recalculateCircuitsAndPlenums();
            this.localPlenums.forEach((plenum) => {
                // add plenum id to orderEdit array
                this.recordEdit(plenum.localId, false);
                plenum.isEdited = false;
            });
            this.calculateVentilationCoefficients();
            this.setPlenumDetails();
            this.updatePlenums(false);
            this.initializeGrateTypes(this.localPlenums);
            this.$nextTick(() => {
                this.$emit("reset-done");
            });
        },
        /**
         * Recalculates circuits and plenums based on room information.
         * Handles mixed air type balancing and optionally resets manually edited flags.
         *
         * @param {boolean} matchOldLengths - Whether to match old circuit lengths.
         * @param {boolean} resetIsManuallyEditedPlenum - Whether to reset the manually edited flag.
         * @returns {Array} - The recalculated list of plenums.
         */
        recalculateCircuitsAndPlenums(matchOldLengths = false, resetIsManuallyEditedPlenum = false) {
            let totalPlenums = [];
            let totalCircuits = 0;
            if (this.roomInfo.air !== 3) {
                totalCircuits = this.calculateCircuits(this.localPlenums);
                this.circuitCount = totalCircuits;
                totalPlenums = this.calculatePlenums(this.localPlenums);
            } else {
                this.airTypes.forEach((airType) => {
                    let plenums = this.localPlenums.filter((plenum) => plenum.air === airType.type);
                    let newCircuits = this.calculateCircuits(plenums);
                    this.circuitCount = newCircuits;
                    let newPlenums = this.calculatePlenums(plenums).map((plenum) => {
                        plenum.air = airType.type;
                        return plenum;
                    });
                    totalPlenums.push(...newPlenums);
                    totalCircuits += newCircuits;
                });
            }
            this.circuitCount = totalCircuits;

            if (matchOldLengths) {
                this.matchAndAssignCircuitLengths(totalPlenums);
            }

            // reset all of the plenums' is_manually_edited flag if the resetIsManuallyEditedPlenum parameter is true
            if (resetIsManuallyEditedPlenum) {
                totalPlenums = this.resetPlenumsManuallyEditedFlag(totalPlenums);
            }

            return totalPlenums;
        },
        /**
         * Resets the manually edited flag for each plenum in the provided array.
         *
         * @param {Array} plenums - The array of plenums to reset.
         * @returns {Array} - The updated array of plenums.
         */
        resetPlenumsManuallyEditedFlag(plenums) {
            plenums.forEach((plenum) => {
                plenum.is_manually_edited = false;
            });
            return plenums;
        },
        /**
         * Matches and assigns circuit lengths from old plenums to new plenums based on specified conditions.
         * Only assigns lengths that are not the default value, unless there are fewer old plenums than new ones,
         * in which case the highest valid length is used.
         *
         * @param {Array} totalPlenums - The array of new plenums where circuit lengths need to be assigned.
         */
        matchAndAssignCircuitLengths(totalPlenums) {
            let sortedOldPlenums = [...this.localPlenums];
            let highestValidCircuitLength =
                sortedOldPlenums.find((plenum) => plenum.circuit_length !== 15)?.circuit_length ||
                this.initialCircuitLength;

            totalPlenums.forEach((plenum, index) => {
                if (index < sortedOldPlenums.length && sortedOldPlenums[index].circuit_length !== 15) {
                    plenum.circuit_length = sortedOldPlenums[index].circuit_length;
                } else if (index >= sortedOldPlenums.length) {
                    plenum.circuit_length = highestValidCircuitLength;
                }
            });
        },
        /**
         * Records the edit of a plenum by updating its position in the editOrder array.
         * If the plenumId already exists in editOrder, it is removed and then re-added to either
         * the beginning or the end of the array based on the addToEnd parameter.
         * In debug mode, it logs the presence of plenumIds in the localPlenums array.
         *
         * @param {string} plenumId - The identifier of the plenum that has been edited.
         * @param {boolean} addToEnd - Determines whether the plenumId is added to the end (true) or the beginning (false) of the editOrder array.
         */
        recordEdit(plenumId, addToEnd) {
            // Get the index of the plenumId in localPlenums
            let index = this.localPlenums.findIndex((element) => element.localId === plenumId);

            // If the plenumId exists in editOrder, remove it.
            if (index !== -1) {
                this.editOrder.splice(index, 1);
            }

            // Add the plenumId based on addToEnd.
            if (addToEnd) {
                this.editOrder.push(plenumId);
            } else {
                this.editOrder.unshift(plenumId);
            }
            // console log id-s from editOrder which do not appear in localPlenums
            if (this.isDebug) {
                this.editOrder.forEach((id) => {
                    let foundPlenum = this.localPlenums.find((element) => element.localId === id);
                    if (!foundPlenum) {
                        console.log("id not found in localPlenums: " + id);
                    } else {
                        console.log("id found in localPlenums: " + id);
                    }
                });
            }
        },
        /**
         * Calculates the number of circuits for each room based on the air volume.
         * If the air type is transfer or undefined (4, 5), it sets the number of circuits to 0.
         *
         * @param {Array} plenums - The array of plenums to calculate circuits for.
         * @returns {number} - The calculated number of circuits.
         */
        calculateCircuits(plenums) {
            let room = this.roomInfo;
            //Room volume is divided in 2 for mixed air rooms
            let roomVolume = room.air !== 3 ? room.air_volume : room.air_volume / 2;
            let length = this.initialCircuitLength;
            let circuitCount = 0;
            if (room.air !== 4 && room.air !== 5) {
                if (this.isPlenumsEdited === true && plenums.length)
                    //Get the plenum with the highest circuit length
                    length = Math.max(...plenums.map((p) => p.circuit_length));

                switch (this.selectedTubingOption) {
                    case 75:
                        circuitCount = this.calculateCircuitCount(length, roomVolume, 39.8, 5);
                        break;
                    case 90:
                        circuitCount = this.calculateCircuitCount(length, roomVolume, 55, 4.04);
                        break;
                    case 117:
                        circuitCount = this.calculateCircuitCount(length, roomVolume, 42, 4.96);
                        break;
                }
            }
            return circuitCount;
        },
        /**
         * Calculates the circuit count based on the length, room volume, and given coefficients.
         *
         * @param {number} length - The length of the tube.
         * @param {number} roomVolume - The volume of the room.
         * @param {number} coefficient1 - The first coefficient in the formula.
         * @param {number} coefficient2 - The second coefficient in the formula.
         * @returns {number} - The calculated circuit count.
         */
        calculateCircuitCount(length, roomVolume, coefficient1, coefficient2) {
            let possibleTubes = this.$store.state.vmcPossibleTubes;
            let circuitCount = 0;
            let valueFound = false;
            let result = 0;
            let tryAgain = true;

            for (let i = 0; i < possibleTubes.length; i++) {
                let coefficient = (roomVolume / possibleTubes[i] / coefficient1) ** 2 * coefficient2;
                result = length * coefficient;
                let airVolume = this.roomInfo.air !== 3 ? this.roomInfo.air_volume : this.roomInfo.air_volume / 2;
                let airSpeed = airVolume / possibleTubes[i] / this.airSpeedConstant;
                if (result < this.pressureLossLimit && airSpeed < 3.2 && tryAgain) {
                    circuitCount = possibleTubes[i];
                    valueFound = true;
                    tryAgain = false;
                    break;
                }
            }

            if (!valueFound && roomVolume) {
                showAlertModal(
                    store,
                    `Pierderea de presiune este mai mare decat ${this.pressureLossLimit} pentru volumul de ${roomVolume} m3 și ${length} ml de tub. Pierderea este: ${result} Pa/m`,
                    "warning",
                    5000
                );
            }

            return circuitCount;
        },
        /**
         * Gets the actual circuit count from the provided array of plenums.
         *
         * @param {Array} plenums - The array of plenums to calculate the circuit count from.
         * @returns {number} - The actual circuit count.
         */
        getPlenumsActualCircuitCount(plenums) {
            return plenums.reduce((total, plenum) => (total += plenum.size), 0);
        },
        /**
         * Calculates the pressure loss for plenums by air type.
         *
         * @param {number} air - The air type to filter plenums by.
         * @returns {number} - The total pressure loss for the specified air type.
         */
        getPlenumsPressureLossByAirType(air) {
            return this.localPlenums
                .filter((p) => p.air === air)
                .reduce((total, p) => (total += p.pressureLossPerSection), 0);
        },
        /**
         * Pushes optimized plenums into the provided array based on the number of circuits and sizes.
         * Handles special cases for mixed air rooms.
         *
         * @param {number} circuits - The number of circuits to allocate.
         * @param {Array} sizes - The available sizes for plenums.
         * @param {Array} plenums - The array of plenums to push into.
         * @returns {Array} - The updated array of plenums.
         */
        pushOptimizedPlenum(circuits, sizes, plenums) {
            let sortedOptions = JSON.parse(JSON.stringify(sizes.sort((a, b) => b - a))); // descending
            // Special case for room.air === 3 (Mixed)
            sortedOptions.forEach((option) => {
                let count = Math.floor(circuits / option);
                if (count > 0) {
                    if (circuits - count * option >= 0) {
                        for (let i = 0; i < count; i++) {
                            this.pushDefaultPlenum(plenums, option);
                        }
                    }
                    circuits -= count * option;
                }
            });
            return plenums;
        },
        /**
         * Calculates the needed plenums for a room based on various factors.
         * Handles deletion and reallocation of circuits as needed.
         *
         * @param {Array} plenums - The array of plenums to calculate for.
         * @returns {Array} - The updated array of plenums.
         */
        calculatePlenums(plenums) {
            let sizes = [];
            if (this.options[this.preferredType]) {
                sizes = this.options[this.preferredType].autoSize;
            } else {
                console.error(`Invalid preferredType: ${this.preferredType}`);
            }

            let newPlenums = [];
            let actualCircuits = this.getPlenumsActualCircuitCount(plenums);

            let circuits = this.circuitCount;

            if ((actualCircuits > this.circuitCount) && !this.reset) {
                plenums = this.deletePlenums(actualCircuits, this.circuitCount, plenums);
                this.allocateCircuitsToPlenums(plenums);
                plenums = this.pushOptimizedPlenum(circuits, sizes, newPlenums);
                return plenums;
            } else {
                if (this.isPlenumsEdited === true) {
                    this.allocateCircuitsToPlenums(plenums);
                    circuits = this.circuitCount - this.getPlenumsActualCircuitCount(plenums);
                }
            }
            newPlenums = this.pushOptimizedPlenum(circuits, sizes, newPlenums);
            if (this.isPlenumsEdited === true) {
                newPlenums.unshift(...plenums);
            }
            return newPlenums;
        },

        /**
         * Allocates circuits to plenums based on their size and circuit length.
         *
         * @param {Array} plenums - The array of plenums to allocate circuits to.
         */
        allocateCircuitsToPlenums(plenums) {
            //sum up all the sizes of each plenum available in the room.plenums array
            let unallocatedCircuits = this.circuitCount - this.getPlenumsActualCircuitCount(plenums);
            // start with the plenum which is has the longest circuit_length
            plenums.sort((a, b) => {
                return a.circuit_length - b.circuit_length;
            });
            plenums.forEach((plenum) => {
                if (plenum.size === 1 && unallocatedCircuits >= 0) {
                    //Check if there is a plenum with lower circuit_length that has more circuits
                    let smallerPlenum = plenums.find(
                        (p) => parseFloat(p.circuit_length) < parseFloat(plenum.circuit_length) && p.size >= 2
                    );
                    //If there are still unallocated circuits use them
                    if (unallocatedCircuits > 0) {
                        plenum.size++;
                        unallocatedCircuits--;
                        //Else, take a circuit from the plenum with the least circuit_length and allocate it
                    } else if (smallerPlenum && unallocatedCircuits === 0) {
                        plenum.size++;
                        smallerPlenum.size--;
                    }
                }
            });
        },
        /**
         * Adds a default plenum to the beginning of the provided array of plenums.
         * The new plenum is created with default properties such as a unique local ID, type, name, short name,
         * circuit length, air type, grate, count, and size. The type and grate are determined based on the selected
         * tubing option and previous values if available.
         *
         * @param {Array} plenums - The array of plenums to which the new plenum will be added.
         * @param {number} size - The size of the new plenum.
         */
        pushDefaultPlenum(plenums, size) {
            let selectedType;
            if (this.selectedTubingOption === 75) {
                selectedType = this.previousPlenumType
                    ? this.previousPlenumType
                    : this.options[this.preferredType].type;
            } else {
                selectedType = this.options[this.preferredType].type;
            }

            let selectedOption = this.options.find((option) => option.type === selectedType);
            if (!selectedOption) {
                if (this.selectedTubingOption === 75) {
                    selectedOption = this.options[5];
                } else {
                    selectedOption = this.options[0];
                }
                console.info(`Invalid selectedType: ${selectedType}, reverted back to default.`);
            }
            const grateType = this.previousPlenumGrate
                ? this.previousPlenumGrate
                : this.getDefaultGrate(selectedOption.type);

            plenums.unshift({
                localId: uuidv4(),
                type: selectedOption.type,
                name: selectedOption.name,
                shortName: selectedOption.shortName,
                circuit_length: this.initialCircuitLength,
                is_manually_edited: false,
                air: this.roomInfo.air !== 3 ? this.roomInfo.air : 1,
                grate: grateType,
                count: 1,
                size: size,
            });
        },
        /**
         * Gets the default grate for a given plenum type.
         *
         * @param {number} type - The type of the plenum.
         * @returns {string} - The default grate for the plenum type.
         */
        getDefaultGrate(type) {
            const mapping = this.plenumGrateMapping[type];
            return mapping ? mapping.defaultGrate : "";
        },
        /**
         * Initializes grate types for each plenum in the provided array.
         *
         * @param {Array} plenums - The array of plenums to initialize grate types for.
         * @returns {Array} - The updated array of plenums.
         */
        initializeGrateTypes(plenums) {
            plenums.forEach((plenum) => {
                if (!plenum.grate) {
                    // Get the mapping object for this plenum type
                    let mapping = this.plenumGrateMapping[plenum.type];

                    // Check if the mapping exists for this plenum type
                    if (mapping) {
                        // Set the .grate property of the plenum to the defaultGrate property
                        // in the matching plenumGrateMapping object
                        plenum.grate = mapping.defaultGrate;
                    } else {
                        // Handle the case where there is no mapping for this plenum type
                        // For example, you can set plenum.grate to a default value or log an error message
                        console.warn(`No grate mapping found for plenum type: ${plenum.type}`);
                    }
                }
                return plenum;
            });
            return plenums;
        },
        /**
         * Prepares for showing a change dialog by setting up previous and future values based on the specified property.
         * For a 'type' change, it assigns the old and new values to component state properties, updates the future values' grate field
         * based on the default grate for its plenum type, and sets a flag to show the type change dialog.
         * For a 'grate' change, it assigns old and new grate values and sets a flag to show the grate change dialog.
         *
         * @param {Object} oldVal - The old values, typically representing the current state before a change.
         * @param {Object} newVal - The new values, representing the state after a change.
         * @param {string} property - The property that is changing, either 'type' or 'grate', determining which dialog to show.
         */
        revealChangeDialog(oldVal, newVal, property) {
            switch (property) {
                case "type":
                    this.showChangeGrateDialog = false; // Ensure grate dialog is closed
                    this.previousValues = oldVal;
                    this.futureValues = newVal;
                    // Automatically assign a default grate when the plenum type changes
                    this.futureValues.grate =
                        this.plenumGrateMapping[this.futureValues.type].defaultGrate;
                    this.showChangeDialog = true; // Show type change dialog
                    break;
                case "grate":
                    this.showChangeDialog = false; // Ensure type dialog is closed
                    this.previousValues = oldVal;
                    this.futureValues = newVal;
                    // Grate changes do not require auto-assignment of default values
                    this.showChangeGrateDialog = true; // Show grate change dialog
                    break;
            }
        },
        /**
         * Filters grate types based on the given plenum type and updates the local plenums accordingly.
         * It finds the plenum in the localPlenums array by matching the localId, and then updates its type.
         * Additionally, if the plenum type has changed, it sets the grate to a default value based on the new type.
         * This function also updates other relevant details about the plenum.
         *
         * @param item
         * @param {boolean} [changedType=false] - Indicates whether the plenum type has changed, defaults to false.
         */
        filterGrateTypes(item, changedType = false) {
            let plenum = item;
            let plenumType = item.type;
            let foundPlenumIndex = this.localPlenums.findIndex((element) => element.localId === plenum.localId);
            if (foundPlenumIndex !== -1) {
                this.localPlenums[foundPlenumIndex].type = plenumType;

                let mapping = this.plenumGrateMapping[plenumType];
                if (mapping) {
                    this.grateTypes = mapping.grateTypes;
                    if (changedType) {
                        this.localPlenums[foundPlenumIndex].grate = mapping.defaultGrate;
                    }
                }
                this.setPreferredType(plenumType);
                this.setPlenumDetails();
            }
        },
        formatNumber,
        /**
         * Calculates the flow of air for a given plenum in the room.
         * Special handling is applied for mixed air rooms.
         *
         * @param {Object} plenum - The plenum object to calculate room flow for.
         * @returns {number} - The calculated room flow.
         */
        calculateRoomFlow(plenum) {
            if (this.roomInfo.air !== 3) return this.roomFlow;
            let plenumAirTypeCircuits = this.localPlenums
                .filter((p) => p.air === plenum.air)
                .reduce((acc, p) => (acc += p.size), 0);
            return this.roomInfo.air_volume / 2 / plenumAirTypeCircuits;
        },
        /**
         * Calculates the air speed for a given room.
         *
         * @returns {number} - The calculated room air speed.
         */
        calculateRoomAirSpeed(plenum) {
            return this.calculateRoomFlow(plenum) / this.airSpeedConstant;
        },
        /**
         * Calculates ventilation coefficients for each plenum in `localPlenums`.
         * This method updates each plenum with its flow, air speed, and various pressure losses.
         * The calculation depends on several factors including room flow, plenum size,
         * maximum flow rate through a 77 tube, and pressure loss in a 77 tube.
         * The method assumes `calculateRoomFlow`, `calculateRoomAirSpeed`, and `roomInfo` are
         * defined in the context.
         *
         * Each plenum object should have `size`, `circuit_length`, and other properties.
         */
        calculateVentilationCoefficients() {
            this.localPlenums.forEach((plenum) => {
                plenum.flow = (this.calculateRoomFlow(plenum) * plenum.size).toFixed(2);
                plenum.airSpeed = this.calculateRoomAirSpeed(plenum);
                plenum.pressureLossPerLength =
                    (plenum.flow / plenum.size / parseFloat(this.maxFlow)) ** 2 *
                    parseFloat(this.pressureLoss) *
                    parseFloat(plenum.circuit_length);

                plenum.pressureLossPerGrill =
                    this.roomInfo.air_volume > 30 ? Math.max(0, plenum.flow * 0.3643 - 8.7143) : 0;
                plenum.pressureLossPerPlenum = (142.37 - Math.sqrt(20269.2169 - Math.pow(plenum.flow, 2))) * 0.42;
                plenum.pressureLossPerSection =
                    plenum.pressureLossPerLength + plenum.pressureLossPerGrill + plenum.pressureLossPerPlenum;
            });
        },
        /**
         * Sets the preferred type based on the provided type parameter.
         * If the selected tubing option is 75, the type is decremented by 1.
         * Otherwise, the type is set to 0.
         * Ensures that the type is within the valid range of options and is a number.
         * If the type is invalid, logs a warning message.
         *
         * @param {number} type - The type to be set as preferred.
         */
        setPreferredType(type) {
            if (this.selectedTubingOption === 75) {
                type = type - 1;
            } else {
                type = 0;
            }

            // Ensure that type is within the bounds of the options array length (0 to options.length - 1) and that type is a number
            if (type >= 0 && type < this.options.length && !isNaN(type)) {
                this.preferredType = type;
            } else {
                console.warn("Invalid type provided to setPreferredType: " + type);
            }
        },
        /**
         * Adds a new plenum to the `localPlenums` array, which is a reactive data property of the component.
         * This method creates a new plenum with default properties and
         * pushes it to the `localPlenums` array. The new plenum includes a unique `localId`, default `size`,
         * `count`, `name`, `shortName` (derived from `options`), `type`, `air` (based on `roomInfo`),
         * `grate`, and `circuit_length`. After adding the new plenum, the method calls `recordEdit`,
         * `calculateVentilationCoefficients`, `setPlenumDetails`, and `updatePlenums` to update the component's
         * state and recalculate relevant data. This method is typically used to handle user actions for adding
         * new plenums in the UI.
         *
         * @returns {void} Does not return a value but updates the component's state and potentially triggers re-render.
         */
        addPlenum() {
            let newPlenum = {
                localId: uuidv4(),
                item: {},
                size: 1,
                count: 1,
                name: this.options[this.preferredType].name,
                shortName: this.options[this.preferredType].shortName,
                type: this.options[this.preferredType].type,
                air: this.roomInfo.air !== 3 ? this.roomInfo.air : 1,
                grate: this.plenumGrateMapping[this.options[this.preferredType].type].defaultGrate,
                circuit_length: 15,
                is_manually_edited: false,
            };
            // Add a new plenum with size of 1
            this.localPlenums.push(cloneDeep(newPlenum));
            this.recordEdit(this.localPlenums[this.localPlenums.length - 1].localId, false);
            this.calculateVentilationCoefficients();
            this.setPlenumDetails();
            this.updatePlenums(true);
        },
        /**
         * Saves the previous value of a plenum field before it gets updated.
         *
         * @param {Object} item - The plenum item.
         * @param {string} fieldPath - The path of the field (e.g., 'area.sq_m').
         */
        savePreviousValue(item, fieldPath) {
            this.focusedLocalId = item.localId;
            if (!this.previousPlenumValues[item.localId]) {
                this.previousPlenumValues[item.localId] = {};
            }
            if (this.previousPlenumValues[item.localId][fieldPath] === undefined) {
                // Access the nested field safely
                const fieldParts = fieldPath.split('.');
                let value = item;
                for (let part of fieldParts) {
                    if (value[part] !== undefined) {
                        value = value[part];
                    } else {
                        value = undefined;
                        break;
                    }
                }
                this.previousPlenumValues[item.localId][fieldPath] = value;
            }
        },
        /**
         * Retrieves the value of a nested field using its path.
         *
         * @param {Object} obj - The object containing the field.
         * @param {string} fieldPath - The path of the field (e.g., 'area.sq_m').
         * @returns {*} - The value of the field or undefined if not found.
         */
        getNestedValue(obj, fieldPath) {
            const fieldParts = fieldPath.split('.');
            let value = obj;
            for (let part of fieldParts) {
                if (value[part] !== undefined) {
                    value = value[part];
                } else {
                    return undefined;
                }
            }
            return value;
        },
        /**
         * Updates a specified field for a plenum in the localPlenums array.
         * Determines if the field value has changed and handles subsequent actions.
         *
         * @param {Object} item - The plenum item containing the new field value.
         * @param {string} fieldPath - The path of the field to be updated (e.g., 'area.sq_m').
         * @param {boolean} isNumber - Indicates whether the field value should be treated as a number.
         */
        updateField(item, fieldPath, isNumber) {
            let valueHasChanged = false;
            let previousValue = null;

            // Retrieve the previous value

            if (this.previousPlenumValues[item.localId] && this.previousPlenumValues[item.localId][fieldPath] !== undefined) {
                previousValue = this.previousPlenumValues[item.localId][fieldPath];
                // Get the current value
                let currentValue = this.getNestedValue(item, fieldPath);

                if (isNumber) {
                    const newValue = parseFloat(currentValue);
                    valueHasChanged = previousValue !== newValue;
                } else {
                    valueHasChanged = previousValue !== currentValue;
                }
            }

            if (valueHasChanged) {
                // Find the plenum in localPlenums
                let plenum = this.localPlenums.find(pl => pl.localId === item.localId);
                if (plenum) {
                    // Mark as edited
                    if (['circuit_length', 'size', 'air'].includes(fieldPath) || ['circuit_length', 'size', 'air'].includes(fieldPath.split('.')[1])) {
                        plenum.is_manually_edited = true;
                    }

                    // Record the edit
                    this.recordEdit(plenum.localId, true);

                    // Handle specific field actions
                    if (fieldPath === 'type') {
                        this.filterGrateTypes(item, true);
                        this.revealChangeDialog(previousValue, item, 'type');
                    } else if (fieldPath === 'grate') {
                        this.revealChangeDialog(previousValue, item, 'grate');
                    } else if (['size', 'air'].includes(fieldPath) || ['size', 'air'].includes(fieldPath.split('.')[1])) {
                        this.calculateVentilationCoefficients();
                    } else {
                        // For other fields, perform general recalculations
                        this.isPlenumsEdited = true;
                        this.localPlenums = this.recalculateCircuitsAndPlenums();
                        this.calculateVentilationCoefficients();
                        this.setPlenumDetails();
                    }

                    // Emit updates
                    this.updatePlenums(valueHasChanged);
                }
            } else {
                // Even if value hasn't changed, you might still want to emit updates
                this.updatePlenums(valueHasChanged);
            }
        },
        /**
         * Prepares and emits data to update plenums and their circuit count.
         * It constructs an item object containing the current room ID, local plenums,
         * and the actual circuit count, and then emits two events,
         * 'update-plenums' and 'update-circuit-count', with this item as the payload.
         *
         * @param {boolean} isNewVersionRequired - Indicates whether a new version of the plenums is required.
         */
        updatePlenums(isNewVersionRequired) {
            let item = {};
            item.localId = this.roomId;
            item.air_volume = this.room.air_volume;
            item.plenums = this.localPlenums;
            item.circuit_no_VMC = this.actualCircuits;
            item.isNewVersionRequired = isNewVersionRequired;
            this.$emit("update-plenums", item);
            this.$emit("update-circuit-count", item);
        },
        /**
         * Gets the available sizes for a given plenum type.
         *
         * @param {number} type - The type of the plenum.
         * @returns {Array} - An array of available sizes.
         */
        availableSizes(type) {
            if (!type) return [];
            const selectedItem = this.options.find((item) => item.type === Number(type));
            return selectedItem ? selectedItem.sizes : [];
        },
        /**
         * Removes a plenum from the localPlenums array based on its index.
         * Recalculates ventilation coefficients and updates plenum details.
         *
         * @param {string} index - The identifier of the plenum to remove.
         */
        removePlenum(index) {
            let plenumIndex = this.localPlenums.findIndex((element) => element.localId === index);
            // Remove the plenum at the given index
            this.localPlenums.splice(plenumIndex, 1);
            // impossible to remove a plenum if recalculation happens every time a plenum is removed
            //this.recalculateCircuitsAndPlenums();
            this.calculateVentilationCoefficients();
            this.setPlenumDetails();
            this.updatePlenums(true);
        },
        /**
         * Opens the dialog, initializes grate types, and calculates ventilation coefficients.
         * Sets plenum details and updates plenums.
         */
        openDialog() {
            this.initializeGrateTypes(this.localPlenums);
            this.calculateVentilationCoefficients(this.roomInfo);
            this.setPlenumDetails();
            this.updatePlenums(false);
            this.dialog = true;
        },
        /**
         * Closes the dialog.
         */
        closeDialog() {
            this.dialog = false;
        },
        /**
         * Iterates over the `localPlenums` array, a reactive data property of the component, and updates each plenum's details.
         * This method assigns a unique identifier (`localId`) to each plenum using `uuidv4`. It then sets the `shortName` and `name`
         * for each plenum based on its `type`. This is done by matching the `type` with items in the `options` array, another
         * reactive property of the component. If a `short_name` is present in a plenum, it is used as the `shortName` and then removed.
         * This method is typically called when the component needs to update the details of plenums, potentially in response
         * to changes in `localPlenums` or `options`.
         *
         * @returns {void} This method does not return a value but updates the component's state.
         */
        setPlenumDetails() {
            this.localPlenums.forEach((plenum) => {
                if (!plenum.localId) {
                    plenum.localId = uuidv4();
                }
                if (plenum.type) {
                    let selectedItem;
                    if (this.selectedTubingOption === 75) {
                        selectedItem = this.options.find((item) => item.type === Number(plenum.type));
                        if (!selectedItem) {
                            selectedItem = this.options[5];
                        }
                    } else {
                        selectedItem = this.options.find((item) => item.type === Number(plenum.type));
                        if (!selectedItem) {
                            selectedItem = this.options[0];
                        }
                    }
                    plenum.shortName = plenum.short_name ? plenum.short_name : selectedItem.shortName;
                    plenum.name = selectedItem.name;
                    if (plenum.short_name) {
                        delete plenum.short_name;
                    }
                }
            });
        },
        setTubingValues(tubingOption) {
            switch (tubingOption) {
                case 75:
                    this.preferredType = 5;
                    this.options = OPTIONS_75;
                    this.plenumGrateMapping = PLENUM_GRATES_75;
                    this.airSpeedConstant = 12.69;
                    this.maxFlow = maxFlow.tube75.value;
                    this.pressureLoss = pressureLoss.tube75.value;
                    break;
                case 90:
                    this.preferredType = 0;
                    this.options = OPTIONS_90;
                    this.plenumGrateMapping = PLENUM_GRATES_90;
                    this.airSpeedConstant = 17.208;
                    this.maxFlow = maxFlow.tube90.value;
                    this.pressureLoss = pressureLoss.tube90.value;
                    break;
                case 117:
                    this.preferredType = 0;
                    this.options = OPTIONS_117;
                    this.plenumGrateMapping = PLENUM_GRATES_117;
                    this.airSpeedConstant = 14.48;
                    this.maxFlow = maxFlow.tube117.value;
                    this.pressureLoss = pressureLoss.tube117.value;
                    break;
            }
        },
    },
    watch: {
        /**
         * Watches for changes in the air volume of the room columns.
         * When the air volume changes, it resets the plenum edit state, updates room info,
         * recalculates circuits and plenums, recalculates ventilation coefficients,
         * sets plenum details, and updates plenums.
         *
         * @param {number} newV - The new air volume value.
         * @param {number} oldV - The old air volume value.
         */
        'room.air_volume': {
            handler: function (newV, oldV) {
                if (newV !== oldV) {
                    this.isPlenumsEdited = false;
                    this.roomInfo = JSON.parse(JSON.stringify(this.room));
                    this.localPlenums = JSON.parse(JSON.stringify(this.room.plenums));
                    this.localPlenums = this.recalculateCircuitsAndPlenums(true, true);
                    this.calculateVentilationCoefficients();
                    this.setPlenumDetails();
                    this.updatePlenums(false);
                }
            },
            deep: true,
        },
        /**
         * Watches for changes in the air type of the room columns.
         * When the air type changes, it updates room info and resets plenums.
         *
         * @param {number} newV - The new air type value.
         * @param {number} oldV - The old air type value.
         */
        "room.air": {
            handler: function (newV, oldV) {
                if (newV !== oldV) {
                    this.roomInfo = JSON.parse(JSON.stringify(this.room));
                    this.resetPlenums();
                }
            },
            deep: true,
        },
        /**
         * Watches for changes in the reset property.
         * When reset is set to true, it triggers the resetPlenums method.
         *
         * @param {boolean} newV - The new reset value.
         */
        reset: {
            handler: function (newV) {
                if (newV === true) {
                    this.resetPlenums();
                }
            },
            deep: true,
        },
        selectedTubingOption: {
            handler: function (newV, oldV) {
                if (newV !== oldV) {
                    this.setTubingValues(newV);
                    this.isPlenumsEdited = false;
                    this.roomInfo = JSON.parse(JSON.stringify(this.room));
                    this.localPlenums = JSON.parse(JSON.stringify(this.room.plenums));
                    this.localPlenums = this.recalculateCircuitsAndPlenums(true, true);
                    this.calculateVentilationCoefficients();
                    this.setPlenumDetails();
                    this.updatePlenums(false);
                }
            },
        },
        pressureLossLimit: {
            handler: function (oldV, newV) {
                if (newV !== oldV) {
                    this.isPlenumsEdited = false;
                    this.roomInfo = JSON.parse(JSON.stringify(this.room));
                    this.localPlenums = JSON.parse(JSON.stringify(this.room.plenums));
                    setTimeout(() => {
                        this.localPlenums = this.recalculateCircuitsAndPlenums(true, true);
                        this.calculateVentilationCoefficients();
                        this.setPlenumDetails();
                        this.updatePlenums(false);
                    }, 1000);
                }
            },
        },
    },
};
</script>
<style scoped>
.suffix-color .v-input__control .v-field .v-field__field .v-text-field__suffix {
    color: rgb(251, 140, 0); /* Or whatever color you desire */
}

.text-right * {
    text-align: right;
}

.no-arrows input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.no-arrows input::-webkit-outer-spin-button,
.no-arrows input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>
