<template>
    <v-container class="main-content d-flex align-items-center" fluid style="height: 100vh;">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4" class="d-flex align-items-center justify-content-center flex-column"
                   style="gap: 80px;">
                <v-container v-if="!$route.path.includes('login')" class="text-center d-flex flex-column">
                    <h2 class="text-white">Bun venit!</h2>
                    <span class="text-lead text-white intro-paragraph">Este responsabilitatea ta să faci clientul să te placă.</span>
                    <span class="text-lead text-white intro-paragraph">Dacă te plac o să te asculte.Dacă ascultă, vor crede.</span>
                    <span class="text-lead text-white intro-paragraph">Dacă cred vor cumpăra.</span>
                </v-container>
                <v-container v-else class="text-center d-flex flex-column">
                    <h2 class="text-white">Bun venit!</h2>
                </v-container>
                <v-card elevation="12" width="100%">
                    <v-toolbar color="white" dark flat>
                        <v-toolbar-title color="white">Autentificare</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-alert type="error" v-if="error" dense>{{ error }}</v-alert>
                        <v-form @submit.prevent="handleSubmit">
                            <v-text-field
                                v-model="username"
                                label="Email"
                                prepend-icon="mdi-email"
                                data-cy="username"
                                type="email"
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="password"
                                label="Parola"
                                prepend-icon="mdi-lock"
                                type="password"
                                data-cy="password"
                                required
                            ></v-text-field>

                            <v-btn
                                :loading="loading"
                                type="submit"
                                block
                                data-cy="sign-in"
                                style="background: #172b4d; color: white;"
                            >
                                Autentificare
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import apiClient, {deleteAuthToken, setAuthToken} from "@/utils/apiClient";
import {fetchData, fetchProducts, setUserPermissionsByRole} from "@/utils/utils";
import {mapGetters} from "vuex";

export default {
    name: 'UserLogin',
    data() {
        return {
            username: '',
            password: '',
            error: '',
            loading: false,
            externalRepowerUsers: [
                'comenzi@smartsun.ro',
                'electro_imed@yahoo.com'
            ]
        }
    },
    computed: {
        ...mapGetters(['userPermissions']),
    },
    created() {
        deleteAuthToken();
    },
    async mounted() {
        await apiClient.get('/api/clear_session_id/');
        sessionStorage.clear();
        localStorage.clear();
    },
    methods: {
        /**
         * Checks if the given email is part of the external Repower user list.
         *
         * @param {string} email - The email address to check.
         * @returns {boolean} - Returns `true` if the email is an external Repower user, otherwise `false`.
         */
        isExternalRepowerUser(email) {
            return this.externalRepowerUsers.includes(email);
        },
        /**
         * Sets default session values, including client types, for use across the application.
         * Saves the client types to session storage to standardize dropdowns or other UI components.
         */
        setDefaultValues() {
            //Set default values:
            let clientTypes = [
                {value: 1, text: 'Client Final'},
                {value: 2, text: 'Instalator'},
                {value: 3, text: 'Constructor'},
                {value: 4, text: 'Dezvoltator'},
                {value: 5, text: 'Magazin'},
                {value: 6, text: 'Agent'}
            ]
            sessionStorage.setItem('clientTypes', JSON.stringify(clientTypes))
        },
        /**
         * Handles the login form submission by authenticating the user.
         * Sends the login credentials to the API, stores the returned token and user details in storage,
         * and sets permissions and default values for the user. Redirects the user to the appropriate page based on their permissions.
         *
         * @async
         */
        async handleSubmit() {
            this.loading = true;
            const url = `/api/login/`;
            const data = {
                username: this.username,
                password: this.password,
            };

            await apiClient
                .post(url, data)
                .then(async (response) => {
                    localStorage.setItem('token', response.data.token);
                    sessionStorage.setItem('userId', response.data.user_id);
                    sessionStorage.setItem('superUser', response.data.super_user);
                    sessionStorage.setItem('technicalUser', response.data.technical_user);
                    sessionStorage.setItem('userEmail', response.data.email);
                    sessionStorage.setItem('userGroups', JSON.stringify(response.data.groups)); // Save groups in sessionStorage

                    let role = ''; // Determine role dynamically
                    if (response.data.super_user) {
                        this.$store.state.superUser = response.data.super_user;
                        role = 'superuser';
                    } else if (response.data.technical_user) {
                        role = 'technicalUser';
                    } else if (response.data.super_agent) {
                        role = 'superAgent';
                    } else if (response.data.groups && response.data.groups.length > 0) {
                        role = 'groupBased'; // Handle dynamically based on groups
                    } else {
                        role = 'agent';
                    }

                    setUserPermissionsByRole(this.$store, role, response.data.groups);

                    if (response.data.full_name) {
                        sessionStorage.setItem('userFullName', response.data.full_name);
                    } else {
                        sessionStorage.setItem('userFullName', response.data.email);
                    }

                    setAuthToken();
                    this.setDefaultValues();

                    if (this.userPermissions.general.canFetchAllData && this.userPermissions.general.canViewLiveApp) {
                        await fetchData('all', this).then(async () => {
                            await fetchProducts();
                        });
                    }

                    const redirect = this.$route.query.redirect
                    if (redirect) {
                        // Send them back to the originally requested route
                        this.$router.push(redirect);
                    } else {
                        // Otherwise default path
                        this.$router.push(this.userPermissions.defaultPath);
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    // If the login failed, display an error message
                    console.error(error);
                    this.error = 'Invalid login credentials';
                    this.loading = false;
                });
        },
    },
}
</script>

<style scoped>
.main-content {
    width: 100%;
    height: 100vh;
    background: linear-gradient(177deg, #7963e4 50%, #172b4d calc(50% + 1px));
}

.intro-paragraph {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.7;
    font-family: Open Sans, sans-serif;
}

</style>
