import {PDC_UNIT_TYPES, PDC_MANUFACTURERS, PDC_CURRENTS} from "../PDC/pdcData.js"

export const PDC_PRODUCT_TYPES = {
    UNIT: 1,
    TABLOU: 2,
    PUFFER: 3,
    TEAVA: 4,
    TAMPON: 5,
    TIMBRU: 6,
    FILTRU: 7,
    VANA: 8,
    SENZOR: 9,
    REZISTENTA: 10,
    BOILER: 11,
    INTERFATA: 12,
    VAS: 13,
    ROBINET: 14,
    RACORD: 15,
    BANDA: 16,
    CABLU: 17,
    SUPORT: 18,
    REZERVOR: 19,
    SCHIMBATOR: 20,
    POMPA: 21,
    REDUCTIE: 22,
    RELEU: 23
};

export const PDC_NON_STANDARD_MEASUREMENTS = {
    ONE_INCH: {
        length: 1,
        measurementUnit: "inch",
    },
    HALF_INCH: {
        length: 0.5,
        measurementUnit: "inch",
    },
    QUARTER_INCH: {
        length: 0.25,
        measurementUnit: "inch",
    },
    THREE_QUARTER_INCH: {
        length: 0.75,
        measurementUnit: "inch",
    },
}

/**
 * Adjusts the quantity of products and removes them if no ACM preparation is found.
 * Also handles the associated requirements similarly.
 *
 * @param {Object} context - The current processing context (this).
 * @param {String} productCode - The unique product code of the product to adjust.
 * @param {Array} associatedRequirements - An array of associated requirements to process.
 */
function adjustProductsForAcmPreparation(context, productCode, associatedRequirements = []) {
    const unitsWithoutAcmPreparation = context.selectedPdcUnits.filter(unit => unit.has_acm_preparation === false);

    if (unitsWithoutAcmPreparation.length > 0) {
        context.resultedProducts = context.resultedProducts.map(product => {
            // Check if the product matches the product code
            if (product.product_code.includes(productCode)) {
                product.quantity -= unitsWithoutAcmPreparation.length;

                // Remove product if quantity drops to zero or less
                if (product.quantity <= 0) {
                    return null; // Mark product for removal
                }
            }
            return product;
        }).filter(Boolean); // Remove null entries

        // Process associated requirements if provided
        associatedRequirements.forEach(reqToAdjust => {
            context.resultedProducts = context.resultedProducts.map(reqProduct => {
                if (
                    reqProduct.specifications.type === reqToAdjust.type &&
                    reqProduct.specifications.subtype === reqToAdjust.subtype &&
                    reqProduct.specifications.variant === reqToAdjust.variant
                ) {
                    // Reduce requirement quantity
                    reqProduct.quantity -= unitsWithoutAcmPreparation.length;

                    if (reqProduct.quantity <= 0) {
                        return null; // Mark requirement for removal
                    }
                }
                return reqProduct;
            }).filter(Boolean); // Remove null entries
        });
    }
}

// STANDARDS
// volume is in liters

export const pdcProducts = [
    {
        "id": 1,
        "product_code": "1001.08.57",
        "name": "Unitate exterioara \"Eco Inverter\" 4,0 kw (220V) 1x16A - nou",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 4.3,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "mono",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 60,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 2,
        "product_code": "1001.09.57",
        "name": "Unitate exterioara \"Eco Inverter\" 6,0 kw (220V) 1x16A - nou",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 5.7,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "mono",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 60,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 3,
        "product_code": "1001.10.57",
        "name": "Unitate exterioara \"Eco Inverter\" 7,5 kw (220V) 1x16A - nou",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 6,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "mono",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 60,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 4,
        "product_code": "1002.07.57",
        "name": "Unitate exterioara \"Zubadan\" 8,0 kw (380V) 3x16A (R32 cu racire)",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 8,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "tri",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 60,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
            
        ]
    },
    {
        "id": 5,
        "product_code": "1002.08.57",
        "name": "Unitate exterioara \"Zubadan\" 10,0 kw (380V) 3x16A (R32 cu racire)",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 10,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "tri",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 60,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
            
        ]
    },
    {
        "id": 6,
        "product_code": "1002.09.57",
        "name": "Unitate exterioara \"Zubadan\" 12,0 kw (380V) 3x16A (R32 cu racire)",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 12,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "tri",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 100,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
            
        ]
    },
    {
        "id": 7,
        "product_code": "1002.10.57",
        "name": "Unitate exterioara \"Zubadan\" 14,0 kw (380V) 3x16A (R32 cu racire)",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 14,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "tri",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 100,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
            
        ]
    },
    {
        "id": 8,
        "product_code": "1002.06.57",
        "name": "Unitate exterioara \"Zubadan\" 23,0 kw (380V) 3x25A",
        "specifications": {
            "type": PDC_UNIT_TYPES.SPLIT.value,
            "manufacturer": PDC_MANUFACTURERS.MITSUBISHI.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 22.9,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "tri",
                "variant": "25A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 100,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": "3/4\"",
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TEAVA,
                "subtype": "cupru",
                "variant": "izolata",
                "diameter": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
                "quantity": 5,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },            
        ]
    },
    {
        "id": 9,
        "product_code": "1010.09.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 4 Kw 1x16A",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 3.95,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "mono",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 60,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.ROBINET,
                "subtype": "bila",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.REDUCTIE,
                "subtype": "alama",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 10,
        "product_code": "1010.11.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 6 Kw",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 5.85,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "mono",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 60,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.ROBINET,
                "subtype": "bila",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.REDUCTIE,
                "subtype": "alama",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 11,
        "product_code": "1010.13.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 8 Kw",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 6.48,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "mono",
                "variant": "25A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 60,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.ROBINET,
                "subtype": "bila",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 12,
        "product_code": "1010.15.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 10 Kw",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 8.25,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "mono",
                "variant": "25A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 60,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.ROBINET,
                "subtype": "bila",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 13,
        "product_code": "1010.17.94",
        "name": "Pompa de caldura \"Sistema-Home DT\" H/C 10 Kw",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 8.25,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "tri",
                "variant": "10A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 60,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.ROBINET,
                "subtype": "bila",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 14,
        "product_code": "1010.19.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 12 Kw",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 8.94,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "mono",
                "variant": "25A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 100,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.ROBINET,
                "subtype": "bila",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 15,
        "product_code": "1010.21.94",
        "name": "Pompa de caldura \"Sistema-Home DT\" H/C 12 Kw",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 8.94,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "tri",
                "variant": "10A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 100,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.ROBINET,
                "subtype": "bila",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 16,
        "product_code": "1010.23.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 14 Kw",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 10.8,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "mono",
                "variant": "32A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 100,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.ROBINET,
                "subtype": "bila",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.REDUCTIE,
                "subtype": "alama",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 17,
        "product_code": "1010.25.94",
        "name": "Pompa de caldura \"Sistema-Home DT\" H/C 14 Kw",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 10.8,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "tri",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 100,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.ROBINET,
                "subtype": "bila",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.REDUCTIE,
                "subtype": "alama",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 18,
        "product_code": "1010.27.94",
        "name": "Pompa de caldura \"Sistema-Home D\" H/C 16 Kw",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 10.2,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 19,
        "product_code": "1010.29.94",
        "name": "Pompa de caldura \"Sistema-Home DT\" H/C 16 Kw",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 10.2,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 100,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 20,
        "product_code": "1010.31.94",
        "name": "Pompa de caldura \"Sistema-Home DT\" H/C 18 Kw",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.SISTEMA.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 11.8,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "tri",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 100,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.ROBINET,
                "subtype": "bila",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TAMPON,
                "subtype": "cauciuc",
                "quantity": 4,
            },
            {
                "type": PDC_PRODUCT_TYPES.REDUCTIE,
                "subtype": "alama",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 21,
        "product_code": "1010.01.85",
        "name": "Pompa de caldura Aer-Apa 6,21 kW (220V)",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.DOMUSA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 3.5,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "mono",
                "variant": "16A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.ROBINET,
                "subtype": "bila",
                "variant": "1",
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.REDUCTIE,
                "subtype": "alama",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.REZERVOR,
                "subtype": "inertial",
                "volume": 60,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 22,
        "product_code": "1010.06.85",
        "name": "Pompa de caldura Aer-Apa 16,80 kW (380V)",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.DOMUSA.value,
            "current": PDC_CURRENTS.TRI.value,
            "pump_power": 9,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "sistema",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TABLOU,
                "subtype": "tri",
                "variant": "10A",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.REDUCTIE,
                "subtype": "alama",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 2,
            },
            {
                "type": PDC_PRODUCT_TYPES.ROBINET,
                "subtype": "bila",
                "variant": "1",
                "quantity": 2,
            },
        ]
    },
    {
        "id": 23,
        "product_code": "1005.07.00",
        "name": "Tablou de siguranta monofazic 16A",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TABLOU,
            "subtype": "mono",
            "variant": "16A",
        },
        "requirements": []
    },
    {
        "id": 24,
        "product_code": "1007.17.85",
        "name": "Puffer cu serpentina 100 l",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.PUFFER,
            "subtype": "serpentina",
            "volume": 100,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.REZISTENTA,
                "subtype": "electrica",
                "heating_power": 3,
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 25,
        "product_code": "1008.01.37",
        "name": "Teava CU izolata 3/8\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TEAVA,
            "subtype": "cupru",
            "variant": "izolata",
            "diameter": "3/8\","
        },
        "requirements": []
    },
    {
        "id": 26,
        "product_code": "1008.02.37",
        "name": "Teava CU izolata 5/8\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TEAVA,
            "subtype": "cupru",
            "variant": "izolata",
            "diameter": "5/8\""
        },
        "requirements": []
    },
    {
        "id": 27,
        "product_code": "1008.03.37",
        "name": "Teava CU izolata 1/4\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TEAVA,
            "subtype": "cupru",
            "variant": "izolata",
            "diameter": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
        },
        "requirements": []
    },
    {
        "id": 28,
        "product_code": "1008.04.37",
        "name": "Teava CU izolata 1/2\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TEAVA,
            "subtype": "cupru",
            "variant": "izolata",
            "diameter": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        },
        "requirements": []
    },
    {
        "id": 29,
        "product_code": "1008.05.37",
        "name": "Teava CU izolata 3/4\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TEAVA,
            "subtype": "cupru",
            "variant": "izolata",
            "diameter": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
        },
        "requirements": []
    },
    {
        "id": 30,
        "product_code": "1008.06.78",
        "name": "Banda neadeziva PVC 25x100",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.BANDA,
            "subtype": "neadeziva",
            "variant": "PVC",
        },
        "requirements": []
    },
    {
        "id": 31,
        "product_code": "1008.07.65",
        "name": "Cablu MYYM 4x1,5",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.CABLU,
            "subtype": "MYYM",
        },
        "requirements": []
    },
    {
        "id": 32,
        "product_code": "1009.04.57",
        "name": "Tampon cauciuc antivibratii",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TAMPON,
            "subtype": "cauciuc",
        },
        "requirements": []
    },
    {
        "id": 33,
        "product_code": "1005.05.00",
        "name": "Tablou de siguranta trifazic 25A",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TABLOU,
            "subtype": "tri",
            "variant": "25A",
        },
        "requirements": []
    },
    {
        "id": 34,
        "product_code": "1003.02.57",
        "name": "Unitate interioara \"Hydrobox N14\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.UNIT,
            "subtype": "hydrobox",
            "variant": "N14",
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.INTERFATA,
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 35,
        "product_code": "1003.03.57",
        "name": "Unitate interioara \"Hydrobox N23\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.UNIT,
            "subtype": "hydrobox",
            "variant": "N23",
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.INTERFATA,
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VAS,
                "subtype": "expansiune",
                "variant": "50",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.SENZOR,
                "subtype": "ACM",
                "variant": "mitsubishi",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 36,
        "product_code": "1003.04.57",
        "name": "Unitate interioara \"Hydrobox P7\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.UNIT,
            "subtype": "hydrobox",
            "variant": "P7",
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.INTERFATA,
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
        ]
    },
    {
        "id": 37,
        "product_code": "1003.05.57",
        "name": "Unitate interioara \"Hydrobox P14\" - model nou",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.UNIT,
            "subtype": "hydrobox",
            "variant": "P14",
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.INTERFATA,
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.BOILER,
                "subtype": "serpentina",
                "variant": "300",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VANA,
                "subtype": "deviatie",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 95.20,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 38,
        "product_code": "1004.03.57",
        "name": "Unitate interioara \"Hydrotank N14 180\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.UNIT,
            "subtype": "hydrotank",
            "variant": "N14 180",
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.INTERFATA,
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 297.50,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 39,
        "product_code": "1004.04.57",
        "name": "Unitate interioara \"Hydrotank N14 230\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.UNIT,
            "subtype": "hydrotank",
            "variant": "N14 230",
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.INTERFATA,
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.FILTRU,
                "subtype": "magnetita",
                "variant": "1",
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 297.50,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 40,
        "product_code": "1004.11.57",
        "name": "Unitate interioara \"Hydrotank R7 200\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.UNIT,
            "subtype": "hydrotank",
            "variant": "R7 200",
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.INTERFATA,
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
            
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 297.50,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 41,
        "product_code": "1004.12.57",
        "name": "Unitate interioara \"Hydrotank R14 200\" - model nou",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.UNIT,
            "subtype": "hydrotank",
            "variant": "R14 200",
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.INTERFATA,
                "subtype": "wifi",
                "variant": "PAC61",
                "quantity": 1,
            },
        
            {
                "type": PDC_PRODUCT_TYPES.TIMBRU,
                "subtype": "verde",
                "variant": 297.50,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 43,
        "product_code": "1005.01.64",
        "name": "Interfata Wi-Fi - PAC61 (internet)",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.INTERFATA,
            "subtype": "wifi",
            "variant": "PAC61",
        },
        "requirements": []
    },
    {
        "id": 44,
        "product_code": "1007.02.68",
        "name": "Boiler 300 lt. (o serpentina)",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.BOILER,
            "subtype": "serpentina",
            "variant": "300",
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.REZISTENTA,
                "subtype": "electrica",
                "heating_power": 3,
                "variant": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VAS,
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 1,
            },
        ],
        "conditions": [
            (context) => {
                adjustProductsForAcmPreparation(context, '1007.02.68', [
                    {type: PDC_PRODUCT_TYPES.VAS, subtype: "expansiune", variant: "24"}
                ]);
                adjustProductsForAcmPreparation(context, '1007.07.67', [
                    {
                        type: PDC_PRODUCT_TYPES.REZISTENTA,
                        "subtype": "electrica",
                        "heating_power": 3,
                        "variant": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
                    }
                ]);
            }
        ],
    },
    {
        "id": 46,
        "product_code": "1007.10.68",
        "name": "Vas expansiune 24 lt.",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.VAS,
            "subtype": "expansiune",
            "variant": "24",
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SUPORT,
                "subtype": "perete",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
                "quantity": 1,
            },
        ],
        "conditions": [
            (context) => {
                adjustProductsForAcmPreparation(context, '1007.12.68', [
                    {
                        type: PDC_PRODUCT_TYPES.SUPORT,
                        subtype: "perete",
                        variant: PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
                    }
                ]);
            }
        ],
    },
    {
        "id": 47,
        "product_code": "1007.12.68",
        "name": "Suport perete vas expansiune 3/4",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.SUPORT,
            "subtype": "perete",
            "variant": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
        },
        "requirements": []
    },
    {
        "id": 48,
        "product_code": "1305.10.54",
        "name": "Filtru magnetita 1\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.FILTRU,
            "subtype": "magnetita",
            "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
        },
        "requirements": []
    },
    {
        "id": 49,
        "product_code": "607.08.39",
        "name": "Vana deviatie 1\" cu servomotor inclus",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.VANA,
            "subtype": "deviatie",
            "variant": "1",
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.RELEU,
                "subtype": "24V",
                "variant": "1 contact",
                "quantity": 1,
            }
        ],
        "conditions": [
            (context) => {
                adjustProductsForAcmPreparation(context, '607.08.39', [
                    {type: PDC_PRODUCT_TYPES.RELEU, subtype: "24V", variant: "1 contact"}
                ]);
            }
        ],
    },
    {
        "id": 50,
        "product_code": "1007.11.68",
        "name": "Vas expansiune 50 lt.",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.VAS,
            "subtype": "expansiune",
            "variant": "50",
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.SUPORT,
                "subtype": "perete",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
                "quantity": 1,
            },
        ],
        "conditions": [
            (context) => {
                adjustProductsForAcmPreparation(context, '1007.12.68', [
                    {
                        type: PDC_PRODUCT_TYPES.SUPORT,
                        subtype: "perete",
                        variant: PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
                    }
                ]);
            }
        ],
    },
    {
        "id": 51,
        "product_code": "1007.33.57",
        "name": "Senzor ACM",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.SENZOR,
            "subtype": "ACM",
            "variant": "mitsubishi",
        },
        "requirements": []
    },
    {
        "id": 52,
        "product_code": "1305.11.17",
        "name": "Filtru magnetita 1 1/2\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.FILTRU,
            "subtype": "magnetita",
            "variant": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        },
        "requirements": []
    },
    {
        "id": 53,
        "product_code": "607.09.80",
        "name": "Vana deviatie 1 1/4\" cu servomotor inclus",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.VANA,
            "subtype": "deviatie",
            "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
        },
        "requirements": []
    },
    {
        "id": 54,
        "product_code": "1305.05.12",
        "name": "Filtru magnetita 3/4\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.FILTRU,
            "subtype": "magnetita",
            "variant": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
        },
        "requirements": []
    },
    {
        "id": 55,
        "product_code": "1005.04.00",
        "name": "Tablou de siguranta trifazic 16A",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TABLOU,
            "subtype": "tri",
            "variant": "16A",
        },
        "requirements": []
    },
    {
        "id": 56,
        "product_code": "1010.33.94",
        "name": "Senzor temperatura ACM pdc \"Sistema-Home\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.SENZOR,
            "subtype": "ACM",
            "variant": "sistema",
        },
        "requirements": [],
        "conditions": [
            (context) => {
                adjustProductsForAcmPreparation(context, '1010.33.94');
            }
        ],
    },
    {
        "id": 57,
        "product_code": "1303.08.93",
        "name": "Robinet cu bila si fata plana 1\" IE",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.ROBINET,
            "subtype": "bila",
            "variant": PDC_NON_STANDARD_MEASUREMENTS.ONE_INCH,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.RACORD,
                "subtype": "preizolate",
                "variant": "1",
                "quantity": 1
            },
        ]
    },
    {
        "id": 58,
        "product_code": "1303.09.93",
        "name": "Robinet cu bila si fata plana 1 1/4\" IE",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.ROBINET,
            "subtype": "bila",
            "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.RACORD,
                "subtype": "preizolate",
                "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
                "quantity": 1,
            },
        ]
    },
    {
        "id": 59,
        "product_code": "1007.20.93",
        "name": "Racord flexibil preizolate 1\" II (600mm)",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.RACORD,
            "subtype": "preizolate",
            "variant": "1",
        },
        "requirements": []
    },
    {
        "id": 60,
        "product_code": "400202010",
        "name": "Easy Mag Maxi 1\" filtru antimagnetită cu plasă magnetică",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.FILTRU,
            "subtype": "easy",
            "variant": "1",
        },
        "requirements": []
    },
    {
        "id": 61,
        "product_code": "1005.03.00",
        "name": "Tablou de siguranta trifazic 10A",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TABLOU,
            "subtype": "tri",
            "variant": "10A",
        },
        "requirements": []
    },
    {
        "id": 62,
        "product_code": "1005.08.00",
        "name": "Tablou de siguranta monofazic 25A",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TABLOU,
            "subtype": "mono",
            "variant": "25A",
        },
        "requirements": []
    },
    {
        "id": 63,
        "product_code": "1005.09.00",
        "name": "Tablou de siguranta monofazic 32A",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TABLOU,
            "subtype": "mono",
            "variant": "32A",
        },
        "requirements": []
    },
    {
        "id": 64,
        "product_code": "1010.02.85",
        "name": "Pompa de caldura Monobloc Aer-Apa 8,9 kw (220V) - Domusa 1x16A",
        "specifications": {
            "type": PDC_UNIT_TYPES.MONOBLOCK.value,
            "manufacturer": PDC_MANUFACTURERS.DOMUSA.value,
            "current": PDC_CURRENTS.MONO.value,
            "pump_power": 9,
        },
        "requirements": []
    },
    {
        "id": 65,
        "product_code": "1007.07.67",
        "name": "Rezistenta electrica 3kw 1 1/2",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.REZISTENTA,
            "subtype": "electrica",
            "heating_power": 3,
            "variant": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        },
        "requirements": []
    },
    {
        "id": 66,
        "product_code": "1007.35.67",
        "name": "Rezistenta electrica 2kw 1 1/2\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.REZISTENTA,
            "subtype": "electrica",
            "heating_power": 2,
            "variant": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        },
        "requirements": []
    },
    {
        "id": 67,
        "product_code": "1007.08.67",
        "name": "Rezistenta electrica 4,5kw 1 1/2\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.REZISTENTA,
            "subtype": "electrica",
            "heating_power": 4.5,
            "variant": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        },
        "requirements": []
    },
    {
        "id": 68,
        "product_code": "1005.10.00m",
        "name": "Tablou automatizare rezistenta",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TABLOU,
            "subtype": "automatizare",
            "variant": "monofazic",
        },
        "requirements": []
    },
    {
        "id": 68,
        "product_code": "1005.10.00t",
        "name": "Tablou automatizare rezistenta",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TABLOU,
            "subtype": "automatizare",
            "variant": "trifazic",
        },
        "requirements": []
    },
    {
        "id": 69,
        "product_code": "TV1",
        "name": "Timbru verde",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TIMBRU,
            "subtype": "verde",
            "variant": 297.50,
        },
        "requirements": []
    },
    {
        "id": 70,
        "product_code": "TV2",
        "name": "Timbru verde",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.TIMBRU,
            "subtype": "verde",
            "variant": 95.20,
        },
        "requirements": []
    },
    {
        "id": 71,
        "product_code": "1007.37.67",
        "name": "Rezistenta electrica 3kw 1 1/4\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.REZISTENTA,
            "subtype": "electrica",
            "heating_power": 3,
            "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
        },
        "requirements": []
    },
    {
        "id": 72,
        "product_code": "1007.05.67",
        "name": "Rezervor inertial 60 lt.",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.REZERVOR,
            "subtype": "inertial",
            "volume": 60,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.VAS,
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 1,
            },
        ]
    },
    {
        "id": 73,
        "product_code": "1007.06.67",
        "name": "Rezervor inertial 100 lt.",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.REZERVOR,
            "subtype": "inertial",
            "volume": 100,
        },
        "requirements": [
            {
                "type": PDC_PRODUCT_TYPES.REZISTENTA,
                "subtype": "electrica",
                "heating_power": 3,
                "variant": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
                "quantity": 1,
            },
            {
                "type": PDC_PRODUCT_TYPES.VAS,
                "subtype": "expansiune",
                "variant": "24",
                "quantity": 1,
            },
        ]
    },
    {
        "id": 74,
        "product_code": "1007.45.47",
        "name": "Schimbator de caldura in placi 4-12 kW",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.SCHIMBATOR,
            "subtype": "caldura",
            "power": "4-12",
        },
    },
    {
        "id": 75,
        "product_code": "403.02.43",
        "name": "Pompa Sistema 25-60 180 \"clasa A\", cu turatie variabila",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.POMPA,
            "subtype": "sistema",
            "variant": "25-60",
        },
    },
    {
        "id": 76,
        "product_code": "408.05.39",
        "name": "Racorduri pompa",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.RACORD,
            "subtype": "pompa",
        },
    },
    {
        "id": 77,
        "product_code": "1007.46.47",
        "name": "Schimbator de caldura in placi 14-18 kW",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.SCHIMBATOR,
            "subtype": "caldura",
            "power": "14-18",
        },
    },
    {
        "id": 78,
        "product_code": "403.04.43",
        "name": "Pompa Sistema 25-70 180 \"clasa A\", cu turatie variabila",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.POMPA,
            "subtype": "sistema",
            "variant": "25-70",
        },
    },
    {
        "id": 79,
        "product_code": "1007.55.93",
        "name": "Racord flexibil preizolate 1/4\" II (600mm)",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.RACORD,
            "subtype": "preizolate",
            "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
        },
        "requirements": []
    },
    {
        "id": 80,
        "product_code": "1305.13.44",
        "name": "Filtru magnetita 1 1/4\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.FILTRU,
            "subtype": "magnetita",
            "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
        },
        "requirements": []
    },
    {
        "id": 81,
        "product_code": "1301.11.54",
        "name": "Reductie alama 1\"-3/4\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.REDUCTIE,
            "subtype": "alama",
            "variant": PDC_NON_STANDARD_MEASUREMENTS.THREE_QUARTER_INCH,
        },
        "requirements": []
    },
    {
        "id": 82,
        "product_code": "1301.12.54",
        "name": "Reductie alama 1\"1/4\"-1\"",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.REDUCTIE,
            "subtype": "alama",
            "variant": PDC_NON_STANDARD_MEASUREMENTS.QUARTER_INCH,
        },
        "requirements": []
    },
    {
        "id": 83,
        "product_code": "1007.41.67",
        "name": "Rezistenta electrica 6kw 1 1/2",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.REZISTENTA,
            "subtype": "electrica",
            "heating_power": 6,
            "variant": PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        },
        "requirements": []
    },
    {
        "id": 84,
        "product_code": "602.08.00",
        "name": "Releu 24V DC 1 contact NC/NO 6A",
        "specifications": {
            "type": PDC_PRODUCT_TYPES.RELEU,
            "subtype": "24V",
            "variant": "1 contact",
        },
        "requirements": []
    },
]