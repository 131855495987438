<template>
  <v-card class="mt-3">
    <v-toolbar>
      <v-toolbar-title> {{ title }} </v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <!-- Mobile View -->
      <v-container v-if="$vuetify.display.smAndDown" class="pa-2">
        <template v-if="historyData.length > 0">
          <div
            v-for="(entry, index) in historyData"
            :key="entry.changed_at"
            class="mb-2"
          >
            <p>
              <strong>{{ entry.field }}</strong
              >{{ entry.old_value }} → {{ entry.new_value }}
            </p>
            <p class="text-grey-darken-1 text-caption">
              Modificat de: <strong>{{ entry.changed_by }}</strong> la
              <strong>{{ entry.changed_at }}</strong>
            </p>
            <v-divider
              v-if="index !== historyData.length - 1"
              class="my-2"
            ></v-divider>
          </div>
        </template>
      </v-container>

      <!-- Desktop View -->
      <v-table v-else density="compact">
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="index"
              class="text-left"
            >
              {{ header.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(entry, index) in historyData" :key="index">
            <td v-for="(header, index) in headers" :key="index">
              <slot :name="header.value" :entry="entry">
                <!-- Default slot if no custom rendering is provided -->
                {{ entry[header.value] }}
              </slot>
            </td>
          </tr>
        </tbody>
      </v-table>

      <p v-if="!historyData.length" class="text-center text-grey mt-3">
        Nicio modificare înregistrată
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "RecordHistory",
  props: {
    title: {
      type: String,
      required: true,
    },
    headers: {
      type: Array, // Example: [{ label: "Câmp", value: "field" }, { label: "Modificat de", value: "changed_by" }]
      required: true,
    },
    historyData: {
      type: Array,
      required: true,
    },
  },
};
</script>
