<template>
    <v-card>
        <v-toolbar
            class="return-client-toolbar"
            style="justify-content: center"
        >
            <v-row justify="start" class="mt-2">
                <v-col
                    cols="12"
                    sm="3"
                    md="2"
                    lg="1"
                    class="mx-2"
                    style="
                        font-size: 1.25rem;
                        font-weight: 400;
                        letter-spacing: 0;
                        line-height: 1.75rem;
                        text-transform: none;
                        margin-left: 16px;
                    "
                >
                    Client {{ clientIndex + 1 }}:
                </v-col>
                <v-col cols="12" sm="7" md="8" lg="3" class="mr-9 mx-2">
                    <v-text-field
                        v-model="clientData.name"
                        label="Nume client"
                        variant="outlined"
                        density="compact"
                        persistent-hint
                        :error="!clientData.name"
                        hint="Trebuie să corespundă cu numele de pe factură"
                        @update:modelValue="emitClientUpdate"
                        :readonly="
                            !userPermissions.returnsManagement
                                .canEditClientName ||
                            (returnData.operated &&
                                !userPermissions.returnsManagement
                                    .canEditOperatedReturn)
                        "
                    ></v-text-field>
                </v-col>

                <!-- Search Bar -->
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    lg="3"
                    class="mx-2"
                    v-if="$vuetify.display.mdAndUp"
                >
                    <v-text-field
                        v-model="clientData.searchQuery"
                        variant="outlined"
                        density="compact"
                        hide-details
                        clearable
                        placeholder="Căutare produse"
                        @input="clientData.showOnlyWithQuantity = false"
                        @update:modelValue="emitClientUpdate"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon>mdi-magnify</v-icon>
                        </template>
                    </v-text-field>
                </v-col>

                <!-- Checkbox -->
                <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    lg="2"
                    v-if="$vuetify.display.mdAndUp"
                >
                    <v-checkbox
                        v-model="clientData.showOnlyWithQuantity"
                        label="Cu cantitate"
                        density="compact"
                        hide-details
                        @update:modelValue="emitClientUpdate"
                    ></v-checkbox>
                </v-col>
                <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    lg="2"
                    v-if="$vuetify.display.mdAndUp"
                >
                    <v-text-field
                        label="Nr. factură/facturi"
                        v-model="clientData.invoice_numbers"
                        variant="outlined"
                        density="compact"
                        :readonly="
                            !userPermissions.returnsManagement
                                .canEditInvoiceNumbers
                        "
                        :hide-details="!!clientData.invoice_numbers"
                        :error="
                            !clientData.invoice_numbers &&
                            userPermissions.returnsManagement
                                .canEditInvoiceNumbers
                        "
                        :error-messages="
                            !clientData.invoice_numbers &&
                            userPermissions.returnsManagement
                                .canEditInvoiceNumbers
                                ? 'Acest câmp este obligatoriu!'
                                : ''
                        "
                        :class="{
                            'pb-2': $vuetify.display.mdAndDown,
                            'pl-2': $vuetify.display.mdAndDown,
                        }"
                        @update:modelValue="emitClientUpdate"
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    v-if="$vuetify.display.mdAndUp"
                >
                    <v-textarea
                        :class="{
                            'ml-2': true,
                            'pb-2': $vuetify.display.mdAndDown,
                        }"
                        label="Note facturare"
                        v-model="clientData.invoice_notes"
                        variant="outlined"
                        density="compact"
                        :readonly="
                            !userPermissions.returnsManagement
                                .canEditInvoiceNumbers
                        "
                        :hide-details="true"
                        rows="1"
                        auto-grow
                        @update:modelValue="emitClientUpdate"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-toolbar-items>
                <v-btn
                    color="error"
                    icon
                    :readonly="
                        !userPermissions.returnsManagement.canDeleteClient ||
                        (returnData.operated &&
                            !userPermissions.returnsManagement
                                .canEditOperatedReturn)
                    "
                    @click.stop="$emit('remove-client', clientIndex)"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <!-- Items Table -->
        <v-data-table
            v-if="$vuetify.display.mdAndUp"
            :headers="headers"
            :items="getFilteredItems"
            item-key="product_code"
            class="elevation-1"
            :items-per-page="25"
        >
            <template v-slot:item="{ item }">
                <tr
                    :class="{
                        'warning-row': shouldHighlightRow(item),
                    }"
                >
                    <v-tooltip
                        v-if="item.added_by === 'acquisitionsOnly'"
                        activator="parent"
                        location="top"
                        >Acest produs a fost adăugat de user-ul Achiziții.
                    </v-tooltip>
                    <td v-if="!item.product_code.includes('000.00.00')">
                        {{ item.product_code }}
                    </td>
                    <td v-else></td>
                    <td>{{ item.product_name }}</td>
                    <td style="min-width: 180px">
                        <div class="d-flex flex-row">
                            <v-text-field
                                :readonly="!canEditQuantities(item)"
                                v-model="item.quantity"
                                variant="outlined"
                                density="compact"
                                hide-details
                                type="number"
                                @update:modelValue="
                                    () => {
                                        handleQuantityChange(item);
                                        checkShowOnlyWithQuantity(item);
                                    }
                                "
                                @blur="onQuantityBlur(item)"
                            >
                                <template v-slot:append-inner>
                                    {{
                                        measurementUnits.find(
                                            (unit) =>
                                                unit.value ===
                                                item.measurement_unit
                                        )?.text ?? item.measurement_unit
                                    }}
                                </template>
                            </v-text-field>
                            <v-checkbox
                                v-if="canEditRealQuantitiesOrReceived"
                                class="d-flex justify-content-center"
                                :readonly="
                                    !isCorrectQuantityCheckboxEditable(item)
                                "
                                v-model="item.is_correct_quantity"
                                variant="outlined"
                                density="compact"
                                :hide-details="
                                    !shouldEnforceRealOrCorrect(item)
                                "
                                :error="shouldEnforceRealOrCorrect(item)"
                                @update:modelValue="handleCorrectQuantity(item)"
                            >
                                <v-tooltip location="top" activator="parent">
                                    Cantitate corectă
                                </v-tooltip>
                            </v-checkbox>
                        </div>
                    </td>
                    <td
                        v-if="
                            this.userPermissions.returnsManagement
                                .canEditProductsRealQuantities ||
                            this.returnData.received
                        "
                    >
                        <v-text-field
                            v-show="canEditRealQuantitiesOrReceived"
                            :readonly="!isRealQuantityFieldEditable(item)"
                            v-model="item.real_quantity"
                            variant="outlined"
                            density="compact"
                            hide-details
                            type="number"
                            :error="shouldEnforceRealOrCorrect(item)"
                            @blur="
                                validateRealQuantity(
                                    item,
                                    item.real_quantity,
                                    item.quantity
                                )
                            "
                            @update:modelValue="handleRealQuantityChange(item)"
                        >
                            <template v-slot:append-inner>
                                {{
                                    measurementUnits.find(
                                        (unit) =>
                                            unit.value === item.measurement_unit
                                    )?.text ?? item.measurement_unit
                                }}
                            </template>
                        </v-text-field>
                    </td>
                    <td
                        :style="{
                            minWidth: $vuetify.display.mdAndUp
                                ? '300px'
                                : undefined,
                            maxWidth: $vuetify.display.mdAndUp
                                ? '400px'
                                : undefined,
                        }"
                        v-if="
                            this.userPermissions.returnsManagement
                                .canEditProductsRealQuantities ||
                            this.returnData.received
                        "
                    >
                        <v-text-field
                            :readonly="
                                !canEditRealQuantities || returnData.received
                            "
                            v-model="item.reception_observations"
                            variant="outlined"
                            density="compact"
                            :hide-details="'auto'"
                            @update:modelValue="emitClientUpdate"
                        ></v-text-field>
                    </td>
                    <td
                        :style="{
                            minWidth: $vuetify.display.mdAndUp
                                ? '250px'
                                : undefined,
                            maxWidth: $vuetify.display.mdAndUp
                                ? '400px'
                                : undefined,
                        }"
                    >
                        <v-text-field
                            :readonly="
                                !canEditQuantities(item) ||
                                this.userRole.includes('acquisitionsOnly')
                            "
                            v-model="item.observations"
                            variant="outlined"
                            density="compact"
                            hide-details
                            @update:modelValue="emitClientUpdate"
                        ></v-text-field>
                    </td>
                    <td>
                        <v-checkbox
                            v-model="item.is_for_service"
                            :readonly="isForServiceReadonly(item)"
                            class="d-flex justify-content-center"
                            @update:modelValue="emitClientUpdate"
                        ></v-checkbox>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <!-- Mobile Layout -->
        <v-virtual-scroll
            v-else
            :items="getFilteredItems"
            height="1150"
            key-field="product_code"
            class="scroller"
            :style="{
                height: clientData.showOnlyWithQuantity ? '470px' : '1150px',
                overflowY: 'auto',
            }"
            :variable-height="true"
        >
            <template v-slot="{ item, index }">
                <v-card class="mt-3">
                    <v-toolbar
                        class="return-client-toolbar pt-4 pb-3"
                        v-if="index === 0 && !$vuetify.display.mdAndUp"
                    >
                        <v-row>
                            <!-- Search Bar -->
                            <v-col cols="12" sm="10" md="4" lg="3" class="mx-2">
                                <v-text-field
                                    v-model="clientData.searchQuery"
                                    variant="outlined"
                                    density="compact"
                                    hide-details
                                    clearable
                                    placeholder="Căutare produse"
                                    @input="
                                        clientData.showOnlyWithQuantity = false
                                    "
                                    @update:modelValue="emitClientUpdate"
                                >
                                    <template v-slot:prepend-inner>
                                        <v-icon>mdi-magnify</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>

                            <!-- Checkbox -->
                            <v-col cols="12" md="3" sm="3" lg="2">
                                <v-checkbox
                                    v-model="clientData.showOnlyWithQuantity"
                                    label="Cu cantitate"
                                    density="compact"
                                    hide-details
                                    @update:modelValue="emitClientUpdate"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="3" sm="7" lg="2">
                                <v-text-field
                                    label="Nr. factură/facturi"
                                    v-model="clientData.invoice_numbers"
                                    variant="outlined"
                                    density="compact"
                                    :readonly="
                                        !userPermissions.returnsManagement
                                            .canEditInvoiceNumbers
                                    "
                                    :hide-details="!!clientData.invoice_numbers"
                                    :error="
                                        !clientData.invoice_numbers &&
                                        userPermissions.returnsManagement
                                            .canEditInvoiceNumbers
                                    "
                                    :error-messages="
                                        !clientData.invoice_numbers &&
                                        userPermissions.returnsManagement
                                            .canEditInvoiceNumbers
                                            ? 'Acest câmp este obligatoriu!'
                                            : ''
                                    "
                                    :class="{
                                        'pb-2': $vuetify.display.mdAndDown,
                                        'pl-2': $vuetify.display.mdAndDown,
                                    }"
                                    @update:modelValue="emitClientUpdate"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <v-textarea
                                    :class="{
                                        'ml-2': true,
                                        'pb-2': $vuetify.display.mdAndDown,
                                    }"
                                    label="Note facturare"
                                    v-model="clientData.invoice_notes"
                                    variant="outlined"
                                    density="compact"
                                    :readonly="
                                        !userPermissions.returnsManagement
                                            .canEditInvoiceNumbers
                                    "
                                    :hide-details="true"
                                    rows="1"
                                    auto-grow
                                    @update:modelValue="emitClientUpdate"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-toolbar>
                    <v-toolbar
                        class="mt-3"
                        density="compact"
                        :color="shouldHighlightRow(item) ? 'warning' : ''"
                    >
                        <v-toolbar-title>
                            <div class="wrap-title">
                                {{ item.product_code }} -
                                {{ item.product_name }}
                            </div>
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-subtitle
                        >{{
                            shouldHighlightRow(item)
                                ? "Produs adăugat de user-ul Achiziții!"
                                : ""
                        }}
                    </v-card-subtitle>
                    <v-card-text class="mt-3">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    :readonly="!canEditQuantities(item)"
                                    v-model="item.quantity"
                                    variant="outlined"
                                    density="compact"
                                    hide-details
                                    type="number"
                                    @update:modelValue="
                                        () => {
                                            handleQuantityChange(item);
                                            checkShowOnlyWithQuantity(item);
                                        }
                                    "
                                >
                                    <template v-slot:append-inner>
                                        {{
                                            measurementUnits.find(
                                                (unit) =>
                                                    unit.value ===
                                                    item.measurement_unit
                                            )?.text ?? item.measurement_unit
                                        }}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="item.observations"
                                    label="Observații"
                                    density="compact"
                                    variant="outlined"
                                    hide-details
                                    :readonly="!canEditQuantities(item)"
                                    @update:modelValue="emitClientUpdate"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                v-show="canEditRealQuantitiesOrReceived"
                            >
                                <v-text-field
                                    :readonly="
                                        !isRealQuantityFieldEditable(item)
                                    "
                                    v-model="item.real_quantity"
                                    variant="outlined"
                                    label="Cantitate reală"
                                    density="compact"
                                    hide-details
                                    type="number"
                                    :error="shouldEnforceRealOrCorrect(item)"
                                    @blur="
                                        validateRealQuantity(
                                            item,
                                            item.real_quantity,
                                            item.quantity
                                        )
                                    "
                                    @update:modelValue="
                                        handleRealQuantityChange(item)
                                    "
                                >
                                    <template v-slot:append-inner>
                                        {{
                                            measurementUnits.find(
                                                (unit) =>
                                                    unit.value ===
                                                    item.measurement_unit
                                            )?.text ?? item.measurement_unit
                                        }}
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                class="d-flex flex-column align-items-start"
                                v-show="canEditRealQuantitiesOrReceived"
                            >
                                <v-checkbox
                                    label="Cantitate corectă"
                                    class="d-flex justify-content-center"
                                    :readonly="
                                        !isCorrectQuantityCheckboxEditable(item)
                                    "
                                    v-model="item.is_correct_quantity"
                                    variant="outlined"
                                    density="compact"
                                    hide-details
                                    :error="shouldEnforceRealOrCorrect(item)"
                                    @update:modelValue="
                                        handleCorrectQuantity(item)
                                    "
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    :readonly="!canEditRealQuantities"
                                    v-model="item.reception_observations"
                                    variant="outlined"
                                    density="compact"
                                    hide-details="auto"
                                    label="Observații recepție"
                                    @update:modelValue="emitClientUpdate"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                class="d-flex flex-column align-items-start"
                            >
                                <v-checkbox
                                    label="Service"
                                    v-model="item.is_for_service"
                                    density="compact"
                                    hide-details
                                    class="d-flex justify-content-center"
                                    @update:modelValue="emitClientUpdate"
                                    :readonly="
                                        !userPermissions.returnsManagement
                                            .canEditProductService ||
                                        !item.quantity
                                    "
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>
        </v-virtual-scroll>
    </v-card>
</template>

<script>
import { showAlertModal, sortByProductCode } from "@/utils/utils";
import { mapGetters } from "vuex";

export default {
    name: "ReturnClientComponent",
    components: {},
    props: {
        client: {
            type: Object,
            required: true,
        },
        clientIndex: {
            type: Number,
            required: true,
        },
        products: {
            type: Array,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        returnData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            clientData: {
                ...this.client, // Deep copy
                isValid: true, // Initialize as true, will be validated below
            },
            measurementUnits: [
                { value: 1, text: "m²" },
                { value: 2, text: "m" },
                { value: 3, text: "pungă" },
                { value: 4, text: "buc" },
                { value: 5, text: "l" },
                { value: 6, text: "set" },
                { value: 7, text: "box" },
                { value: 8, text: "rolă" },
                { value: 9, text: "km" },
                { value: 10, text: "kg" },
                { value: 11, text: "ora" },
            ],
            allowedPartialProducts: [
                /^101\.01\.\d+$/,
                /^101\.02\.\d+$/,
                /^101\.03\.\d+$/,
                /^102\.01\.02$/,
                /^104\.02\.51$/,
                /^104\.03\.51$/,
                /^104\.04\.51$/,
                /^104\.05\.51$/,
                /^1101\.01\.61$/,
                /^1101\.02\.61$/,
                /^1101\.03\.61$/,
                /^1101\.05\.61$/,
            ],
            multipliers: {
                "101.01.02": 1.12,
                "101.02.02": 1.12,
                "101.03.02": 1.12,
                "101.01.102": 0.85,
                "101.02.102": 0.85,
                "101.03.102": 0.85,
                "102.01.02": 1.12,
                "104.02.51": 12.5,
                "1101.01.61": 2.4,
                "1101.02.61": 1.2,
                "1101.03.61": 0.6,
                "1101.05.61": 1.56,
            },
        };
    },
    emits: ["update-client", "remove-client"],
    computed: {
        ...mapGetters(["userPermissions", "userRole"]),
        /**
         * Checks if user can edit real quantities or if the return has been received.
         * @returns {boolean} True if user can edit real quantities or return is received, false otherwise.
         */
        canEditRealQuantitiesOrReceived() {
            return (
                this.userPermissions.returnsManagement
                    .canEditProductsRealQuantities || this.returnData.received
            );
        },
        /**
         * Checks if the user can edit real quantities based on user permissions.
         * @returns {boolean} True if the user can edit real quantities, false otherwise.
         */
        canEditRealQuantities() {
            return this.userPermissions.returnsManagement
                .canEditProductsRealQuantities;
        },
        /**
         * Filters, searches, and sorts the products for display.
         * @returns {Array} The filtered and sorted list of product data.
         */
        getFilteredItems() {
            const {
                productData,
                searchQuery,
                showOnlyWithQuantity,
                sortDirection = 1,
            } = this.clientData;

            return productData
                .filter((item) => {
                    // Apply search query
                    if (searchQuery) {
                        const query = searchQuery.toLowerCase();
                        return (
                            item.product_name.toLowerCase().includes(query) ||
                            item.product_code.toLowerCase().includes(query)
                        );
                    }
                    return true; // No search query
                })
                .filter((item) => {
                    // Apply quantity filter
                    if (showOnlyWithQuantity) {
                        return (
                            (item.quantity && item.quantity > 0) ||
                            (item.real_quantity && item.real_quantity > 0)
                        );
                    }
                    return true;
                })
                .sort((a, b) => sortByProductCode(a, b, sortDirection));
        },
    },
    mounted() {
        // Initialize productData if empty
        if (
            !this.clientData.productData ||
            this.clientData.productData.length === 0
        ) {
            this.clientData.productData = this.products.map((product) => ({
                ...product,
                quantity: null,
                observations: "",
                is_for_service: false,
                real_quantity: null,
                reception_observations: "",
                is_correct_quantity: false,
            }));
        }
    },
    methods: {
        isForServiceReadonly(item) {
            // 1) If the user canEditProductService → always can toggle
            if (this.userPermissions.returnsManagement.canEditProductService) {
                return false; // not read-only
            }

            // 2) If userRole includes 'acquisitionsOnly', can toggle
            //    only if item.added_by === 'acquisitionsOnly'
            if (
                this.userRole.includes("acquisitionsOnly") &&
                item.added_by === "acquisitionsOnly"
            ) {
                return false; // not read-only
            }

            // 3) Otherwise → read-only
            return true;
        },
        shouldHighlightRow(item) {
            return item.added_by === "acquisitionsOnly";
        },
        /**
         * Determines if the current user can edit the item's quantity.
         *
         * 1) Superuser => always can edit.
         * 2) If user canEditProductsQuantities & return is not operated => always can edit.
         * 3) If userRole includes 'acquisitionsOnly' & return not received =>
         *    can only edit if item.added_by is unset or equals 'acquisitionsOnly'.
         * 4) Otherwise, false.
         *
         * @returns {boolean} True if edit is allowed, else false.
         */
        canEditQuantities(item) {
            if (
                item.product_code.includes("000.00.00") &&
                this.userRole.includes("acquisitonsOnly")
            ) {
                return false;
            }
            // 1) Superuser can always edit
            if (this.userPermissions.isSuperuser) {
                return true;
            }

            // 2) If user can edit normal products & return isn't operated => always edit
            if (
                this.userPermissions.returnsManagement
                    .canEditProductsQuantities &&
                !this.returnData.operated
            ) {
                return true;
            }

            // 4) Otherwise, can't edit
            return false;
        },
        handleQuantityChange(item) {
            // Check if the product code is allowed for partial quantities
            const isPartialAllowed = this.allowedPartialProducts.some((regex) =>
                regex.test(item.product_code)
            );

            if (!isPartialAllowed) {
                // Round quantity to whole number if partial quantities are not allowed
                item.quantity = Math.round(item.quantity);
            }

            if (!item.quantity && item.quantity !== 0) {
                item.added_by = "";
            }

            if (item.product_code.includes("000.00.00")) {
                item.real_quantity = 0;
            }

            if (!item.added_by) {
                if (
                    this.userRole.includes("agent") ||
                    this.userRole.includes("returnsOperator")
                ) {
                    item.added_by = "agent";
                } else if (this.userRole.includes("superAgent")) {
                    item.added_by = "superAgent";
                } else if (this.userRole.includes("acquisitionsOnly")) {
                    item.added_by = "acquisitionsOnly";
                } else if (this.userRole.includes("superuser")) {
                    item.added_by = "superuser";
                }
            }

            this.emitClientUpdate();
        },
        handleRealQuantityChange(item) {
            if (
                !item.real_quantity &&
                item.real_quantity !== 0 &&
                !item.quantity
            ) {
                item.added_by = "";
            }
            // If there's no 'added_by' yet, set it based on the current user's role
            if (!item.added_by) {
                if (this.userRole.includes("superuser")) {
                    item.added_by = "superuser";
                } else if (this.userRole.includes("acquisitionsOnly")) {
                    item.added_by = "acquisitionsOnly";
                } else if (
                    this.userRole.includes("agent") ||
                    this.userRole.includes("returnsOperator")
                ) {
                    item.added_by = "agent";
                } else if (this.userRole.includes("superAgent")) {
                    item.added_by = "superAgent";
                }
            }

            this.emitClientUpdate(); // Let the parent know it changed
        },
        onQuantityBlur(item) {
            // Convert user input to a number (or 0 if blank).
            let parsedQty = parseFloat(item.quantity) || 0;
            const step = this.multipliers[item.product_code];

            if (step) {
                // --- Force multiples of 'step'
                const forcedQty = parseFloat(
                    (Math.round(parsedQty / step) * step).toFixed(4)
                );
                if (forcedQty !== parsedQty) {
                    showAlertModal(
                        this.$store,
                        `Cantitatea pentru produsul ${item.product_code} a fost ajustată la ${forcedQty} (multiplu de ${step}).`,
                        "warning",
                        5000
                    );
                    item.quantity = forcedQty;
                }
            }

            // Let parent know
            this.emitClientUpdate();
        },
        handleCorrectQuantity(item) {
            if (item.is_correct_quantity) {
                item.real_quantity = item.quantity;
            }
            this.emitClientUpdate();
        },
        /**
         * Determines if enforcing real_quantity or is_correct_quantity is required.
         * Condition: Not superuser, can edit real quantities, and product has quantity > 0.
         * In that scenario, either real_quantity or is_correct_quantity must be provided.
         * @param {Object} product - The product item.
         * @returns {boolean} True if enforcement is required and not met, else false.
         */
        shouldEnforceRealOrCorrect(product) {
            // 1) Skip enforcement if product_code includes "000.00.00" or added_by is "acquisitionsOnly"
            if (
                product.product_code.includes("000.00.00") ||
                product.added_by === "acquisitionsOnly"
            ) {
                return false;
            }

            // 2) Otherwise, run the usual check
            if (
                !this.userPermissions.isSuperuser &&
                this.canEditRealQuantities &&
                product.quantity &&
                product.quantity > 0
            ) {
                const realQty = parseInt(product.real_quantity, 10);
                const isCorrect = !!product.is_correct_quantity;
                const hasRealQty = !isNaN(realQty); // Instead of requiring realQty > 0
                return !(isCorrect || hasRealQty);
            }
            return false;
        },
        /**
         * Emits an event to update the client data to the parent component and revalidates the data.
         */
        emitClientUpdate() {
            this.$emit("update-client", {
                clientIndex: this.clientIndex,
                clientData: this.clientData,
            });
        },
        /**
         * Parses the quantity and real_quantity fields of a product into integers.
         * @param {Object} product - The product object with quantity and real_quantity.
         * @returns {{realQty: number, qty: number}} Parsed integer quantities.
         */
        parseQuantities(product) {
            const realQty = product.real_quantity;
            const qty = product.quantity;
            return { realQty, qty };
        },
        /**
         * Checks if reception_observations is required and missing.
         * If real_quantity differs from quantity, reception_observations must be filled.
         * @param {Object} product - The product object.
         * @returns {boolean} True if the field is required and not provided, else false.
         */
        shouldShowReceptionError(product) {
            // 1) If product_code includes "000.00.00", skip checks (return false)
            if (product.product_code.includes("000.00.00")) {
                return false;
            }

            // 2) Normal logic
            const { realQty, qty } = this.parseQuantities(product);
            if (isNaN(realQty) || isNaN(qty) || !realQty || !qty) return false;
            if (
                realQty !== qty &&
                !product.added_by.includes("acquisitionsOnly")
            ) {
                return (
                    !product.reception_observations ||
                    product.reception_observations.trim() === ""
                );
            }
            return false;
        },
        /**
         * Validates the real_quantity entered by the user.
         * Shows a warning if it equals the previous quantity and then updates the client data.
         * @param item
         * @param {number|string} realQuantity - The entered real quantity.
         * @param {number|string} previousQuantity - The previous quantity.
         */
        validateRealQuantity(item, realQuantity, previousQuantity) {
            if (
                realQuantity === previousQuantity &&
                !item.is_correct_quantity
            ) {
                item.is_correct_quantity = true;
            } else if (!previousQuantity) {
                item.quantity = 0;
            }
            this.emitClientUpdate();
        },
        /**
         * Checks if showOnlyWithQuantity should be enabled based on the item's quantity.
         * If the item quantity is > 0 and showOnlyWithQuantity is not explicitly false, it is set to true.
         * @param {Object} item - The table item containing raw product data.
         */
        checkShowOnlyWithQuantity(item) {
            if (this.clientData.showOnlyWithQuantity === false) return;
            if (!this.clientData.showOnlyWithQuantity && item.quantity > 0) {
                this.clientData.showOnlyWithQuantity = true;
            }
        },
        /**
         * Updates the validation state of the client data.
         * Sets clientData.isValid based on the validity check of product data.
         */
        updateValidationState() {
            this.clientData.isValid = this.checkClientValidity();
        },
        /**
         * Checks the overall validity of the client's product data.
         * Ensures that if real_quantity equals quantity, it's invalid.
         * If real_quantity differs from quantity and is provided, reception_observations must be filled.
         * @returns {boolean} True if all products are valid, else false.
         */
        checkClientValidity() {
            // Ensure the client has a name
            if (!this.clientData.name || this.clientData.name.trim() === "") {
                showAlertModal(
                    this.$store,
                    `Clientul ${
                        this.clientIndex + 1
                    } trebuie să aibă numele completat.`,
                    "danger",
                    12000
                );
                return false;
            }

            // Ensure at least one product has a valid quantity
            const hasAtLeastOneProduct = this.clientData.productData.some(
                (product) => {
                    const qty = parseFloat(product.quantity);
                    return !isNaN(qty) && qty > 0;
                }
            );

            if (!hasAtLeastOneProduct) {
                showAlertModal(
                    this.$store,
                    `Pentru clientul ${
                        this.clientIndex + 1
                    } trebuie să adăugați cel puțin un produs cu o cantitate mai mare decât zero.`,
                    "danger",
                    12000
                );
                return false;
            }

            // Check for validation errors on individual products
            if (this.userRole.includes("acquisitionsOnly")) {
                for (const product of this.clientData.productData) {
                    const realQty = parseFloat(product.real_quantity);
                    const qty = parseFloat(product.quantity);

                    if (
                        !isNaN(realQty) &&
                        !isNaN(qty) &&
                        realQty === qty &&
                        this.returnData.is_correct_quantity
                    ) {
                        showAlertModal(
                            this.$store,
                            `Există erori la clientul ${
                                this.clientIndex + 1
                            }, va rugăm verificați!`,
                            "danger",
                            12000
                        );
                        return false;
                    }

                    if (
                        !isNaN(realQty) &&
                        !isNaN(qty) &&
                        realQty === qty &&
                        this.returnData.is_correct_quantity
                    ) {
                        if (!product.added_by.includes("acquisitionsOnly")) {
                            showAlertModal(
                                this.$store,
                                `Există erori la clientul ${
                                    this.clientIndex + 1
                                }, va rugăm verificați!`,
                                "danger",
                                12000
                            );
                            return false;
                        }
                    }
                }
            }

            return true;
        },
        /**
         * Determines if the real_quantity field should be editable.
         * It is editable if canEditRealQuantities is true and is_correct_quantity is false.
         * @param {Object} product - The product object.
         * @returns {boolean} True if real_quantity field is editable, else false.
         */
        isRealQuantityFieldEditable(product) {
            // 1) If product_code includes "000.00.00",
            //    user is acquisitionsOnly,
            //    and item was NOT added by acquisitionsOnly → return false
            if (
                product.product_code.includes("000.00.00") &&
                this.userRole.includes("acquisitionsOnly") &&
                !product.added_by.includes("acquisitionsOnly")
            ) {
                return false;
            }

            // 2) Otherwise, check your normal conditions
            return (
                this.canEditRealQuantities &&
                !product.is_correct_quantity &&
                this.userPermissions.returnsManagement
                    .canEditProductsRealQuantities
            );
        },
        /**
         * Determines if the is_correct_quantity checkbox is editable.
         * It is editable if canEditRealQuantities is true and no real_quantity is set.
         * @returns {boolean} True if the is_correct_quantity checkbox is editable, else false.
         */
        isCorrectQuantityCheckboxEditable(item) {
            // 1) If product_code includes "000.00.00", user is acquisitionsOnly,
            //    and the item was NOT added by acquisitionsOnly → return false immediately.
            if (
                item.product_code.includes("000.00.00") &&
                this.userRole.includes("acquisitionsOnly") &&
                !item.added_by.includes("acquisitionsOnly")
            ) {
                return false;
            }

            // 2) Otherwise, continue with your usual checks
            return (
                this.canEditRealQuantities &&
                this.userPermissions.returnsManagement
                    .canEditIsProductQuantityCorrect &&
                !this.returnData.received &&
                !item.added_by.includes("acquisitionsOnly")
            );
        },
    },
    watch: {
        client: {
            handler(newClient) {
                // Update the local copy when the prop changes
                this.clientData = newClient;
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style>
.return-client-toolbar .v-toolbar__content {
    min-height: 100px !important;
    height: unset !important;
}

.warning-row {
    background-color: #fff6cc !important; /* A light yellow, adjust as needed */
}
.wrap-title {
    white-space: normal !important;
    word-break: break-word;
    overflow: visible !important;
    display: block;
    line-height: 1.4;
}
</style>
