<template>
    <BreadCrumbsWithButtons :buttons="buttons" />
    <ClientContract
        v-if="client.id"
        :client="client"
        :userPermissions="
            userPermissions.greenHouseSolarManagement.canEditClient
        "
        :statusHistory="statusHistory"
        @update-contract-status="client.contract_status = $event"

    />
    <v-card class="mt-3" style="z-index: 999; overflow: visible">
        <v-toolbar>
            <v-toolbar-title>
                {{ isEditing ? "Editare Client" : "Adăugare Client" }}
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-form ref="form" v-model="isValid">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="12" lg="3">
                            <v-autocomplete
                                v-model="client.agent"
                                :items="myUsers"
                                label="Agent"
                                variant="outlined"
                                density="compact"
                                item-title="full_name"
                                item-value="id"
                                :rules="[rules.required]"
                                hide-details="auto"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                clearable
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                                @update:modelValue="onUserChange"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.first_name"
                                variant="outlined"
                                density="compact"
                                hide-details="auto"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                label="Prenume"
                                :rules="[rules.required]"
                                required
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.last_name"
                                variant="outlined"
                                density="compact"
                                hide-details="auto"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                label="Nume"
                                required
                                :rules="[rules.required]"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="3" lg="2">
                            <v-text-field
                                variant="outlined"
                                label="Serie buletin"
                                density="compact"
                                :maxlength="2"
                                v-model="client.id_card_series"
                                :rules="[rules.id_card_series]"
                                :hide-details="
                                    isIdCardSeriesValid ? 'auto' : false
                                "
                                hint="Câmp obligatoriu"
                                persistent-hint
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" lg="2">
                            <v-text-field
                                variant="outlined"
                                label="Număr buletin"
                                density="compact"
                                :maxlength="6"
                                v-model="client.id_card_number"
                                :rules="[rules.id_card_number]"
                                hide-details="auto"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                label="CNP"
                                variant="outlined"
                                density="compact"
                                :maxlength="13"
                                v-model="client.cnp"
                                :rules="[rules.required, rules.cnp]"
                                :hide-details="isCnpValid ? 'auto' : false"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-autocomplete
                                clearable
                                label="Județ"
                                variant="outlined"
                                density="compact"
                                :items="regions"
                                v-model="client.region"
                                item-title="name"
                                item-value="id"
                                :rules="[rules.required]"
                                :hide-details="isRegionValid ? 'auto' : false"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                @update:modelValue="onRegionChange"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-combobox
                                clearable
                                label="Localitate"
                                variant="outlined"
                                density="compact"
                                :items="filteredCities"
                                v-model="client.city"
                                item-title="name"
                                item-value="id"
                                :rules="[rules.required]"
                                :hide-details="isCityValid ? 'auto' : false"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                @update:modelValue="handleCityChange"
                                allow-new
                                new-item-text="Adaugă noua localitate"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                label="Strada"
                                variant="outlined"
                                density="compact"
                                v-model="client.street"
                                :rules="[rules.required]"
                                :hide-details="isStreetValid ? 'auto' : false"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                label="Nr."
                                variant="outlined"
                                density="compact"
                                v-model="client.number"
                                :rules="[rules.required]"
                                hide-details="auto"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-text-field
                                label="Email"
                                variant="outlined"
                                density="compact"
                                v-model="client.email"
                                :rules="[rules.email]"
                                :hide-details="isEmailValid ? 'auto' : false"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                label="Telefon"
                                variant="outlined"
                                density="compact"
                                v-model="client.phone"
                                :rules="[rules.phone]"
                                :hide-details="isPhoneValid ? 'auto' : false"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-textarea
                                v-model="client.notes"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Note"
                                rows="1"
                                auto-grow
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.beneficiary_code_afm"
                                label="Cod Beneficiar AFM"
                                variant="outlined"
                                density="compact"
                                hide-details="auto"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                required
                                :rules="[rules.required]"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.file_number_afm"
                                variant="outlined"
                                density="compact"
                                hide-details="auto"
                                hint="Câmp obligatoriu"
                                persistent-hint
                                required
                                :rules="[rules.required]"
                                label="Nr. dosar AFM"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.file_number_cf"
                                variant="outlined"
                                density="compact"
                                hide-details="auto"
                                hint="Obligatoriu pentru vizualizare/trimitere"
                                persistent-hint
                                label="Nr. CF"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-menu
                                v-model="dateMenus.application_date"
                                transition="scale-transition"
                                offset-y
                                :close-on-content-click="false"
                                :disabled="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            >
                                <template v-slot:activator="{ props }">
                                    <v-text-field
                                        v-bind="props"
                                        placeholder="zz/ll/aaaa"
                                        label="Data înscriere în aplicație"
                                        variant="outlined"
                                        density="compact"
                                        v-model="
                                            formattedDates.application_date
                                        "
                                        @blur="
                                            handleManualDateInput(
                                                'application_date'
                                            )
                                        "
                                        @keydown.enter.prevent="
                                            handleEnterKey('application_date')
                                        "
                                        hide-details="auto"
                                        hint="Câmp obligatoriu"
                                        persistent-hint
                                        required
                                        :rules="[rules.required]"
                                        :readonly="
                                            !userPermissions
                                                .greenHouseSolarManagement
                                                .canEditClient
                                        "
                                    />
                                </template>

                                <v-date-picker
                                    v-model="client.application_date"
                                    @update:modelValue="
                                        handleDateSelection(
                                            'application_date',
                                            client.application_date
                                        )
                                    "
                                    locale="ro"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <div
                                class="d-flex flex-row align-center flex-wrap position-relative"
                            >
                                <v-menu
                                    v-model="dateMenus.cf_date"
                                    transition="scale-transition"
                                    offset-y
                                    :close-on-content-click="false"
                                    :disabled="
                                        !userPermissions
                                            .greenHouseSolarManagement
                                            .canEditClient
                                    "
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-bind="props"
                                            label="Data CF"
                                            placeholder="zz/ll/aaaa"
                                            variant="outlined"
                                            density="compact"
                                            v-model="formattedDates.cf_date"
                                            @blur="
                                                handleManualDateInput('cf_date')
                                            "
                                            @keydown.enter.prevent="
                                                handleEnterKey('cf_date')
                                            "
                                            hide-details="auto"
                                            :readonly="
                                                !userPermissions
                                                    .greenHouseSolarManagement
                                                    .canEditClient
                                            "
                                        />
                                    </template>

                                    <v-date-picker
                                        v-model="client.cf_date"
                                        @update:modelValue="
                                            handleDateSelection(
                                                'cf_date',
                                                client.cf_date
                                            )
                                        "
                                        locale="ro"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <div
                                class="d-flex flex-row align-center flex-wrap position-relative"
                            >
                                <v-menu
                                    v-model="
                                        dateMenus.identification_expiration_date
                                    "
                                    transition="scale-transition"
                                    offset-y
                                    :close-on-content-click="false"
                                    :disabled="
                                        !userPermissions
                                            .greenHouseSolarManagement
                                            .canEditClient
                                    "
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-bind="props"
                                            label="Data expirare buletin"
                                            placeholder="zz/ll/aaaa"
                                            variant="outlined"
                                            density="compact"
                                            v-model="
                                                formattedDates.identification_expiration_date
                                            "
                                            @blur="
                                                handleManualDateInput(
                                                    'identification_expiration_date'
                                                )
                                            "
                                            @keydown.enter.prevent="
                                                handleEnterKey(
                                                    'identification_expiration_date'
                                                )
                                            "
                                            hide-details="auto"
                                            :readonly="
                                                !userPermissions
                                                    .greenHouseSolarManagement
                                                    .canEditClient
                                            "
                                        />
                                    </template>

                                    <v-date-picker
                                        v-model="
                                            client.identification_expiration_date
                                        "
                                        @update:modelValue="
                                            handleDateSelection(
                                                'identification_expiration_date',
                                                client.identification_expiration_date
                                            )
                                        "
                                        locale="ro"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <div
                                class="d-flex flex-row align-center flex-wrap position-relative"
                            >
                                <v-menu
                                    v-model="
                                        dateMenus.fiscal_certificate_local_date
                                    "
                                    transition="scale-transition"
                                    offset-y
                                    :close-on-content-click="false"
                                    :disabled="
                                        !userPermissions
                                            .greenHouseSolarManagement
                                            .canEditClient
                                    "
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-bind="props"
                                            label="Data Certificat atestare fiscală - bugetul local"
                                            placeholder="zz/ll/aaaa"
                                            variant="outlined"
                                            density="compact"
                                            v-model="
                                                formattedDates.fiscal_certificate_local_date
                                            "
                                            @blur="
                                                handleManualDateInput(
                                                    'fiscal_certificate_local_date'
                                                )
                                            "
                                            @keydown.enter.prevent="
                                                handleEnterKey(
                                                    'fiscal_certificate_local_date'
                                                )
                                            "
                                            hide-details="auto"
                                            :readonly="
                                                !userPermissions
                                                    .greenHouseSolarManagement
                                                    .canEditClient
                                            "
                                        />
                                    </template>

                                    <v-date-picker
                                        v-model="
                                            client.fiscal_certificate_local_date
                                        "
                                        @update:modelValue="
                                            handleDateSelection(
                                                'fiscal_certificate_local_date',
                                                client.fiscal_certificate_local_date
                                            )
                                        "
                                        locale="ro"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <div
                                class="d-flex flex-row align-center flex-wrap position-relative"
                            >
                                <v-menu
                                    v-model="
                                        dateMenus.fiscal_certificate_state_date
                                    "
                                    transition="scale-transition"
                                    offset-y
                                    :close-on-content-click="false"
                                    :disabled="
                                        !userPermissions
                                            .greenHouseSolarManagement
                                            .canEditClient
                                    "
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-bind="props"
                                            label="Data Certificat atestare fiscală - bugetul de stat"
                                            placeholder="zz/ll/aaaa"
                                            variant="outlined"
                                            density="compact"
                                            v-model="
                                                formattedDates.fiscal_certificate_state_date
                                            "
                                            @blur="
                                                handleManualDateInput(
                                                    'fiscal_certificate_state_date'
                                                )
                                            "
                                            @keydown.enter.prevent="
                                                handleEnterKey(
                                                    'fiscal_certificate_state_date'
                                                )
                                            "
                                            hide-details="auto"
                                            :readonly="
                                                !userPermissions
                                                    .greenHouseSolarManagement
                                                    .canEditClient
                                            "
                                        />
                                    </template>

                                    <v-date-picker
                                        v-model="
                                            client.fiscal_certificate_state_date
                                        "
                                        @update:modelValue="
                                            handleDateSelection(
                                                'fiscal_certificate_state_date',
                                                client.fiscal_certificate_state_date
                                            )
                                        "
                                        locale="ro"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>
                        <v-col
                            v-if="isCollectiveLandRegistryUploaded"
                            cols="12"
                            md="6"
                            lg="3"
                        >
                            <div
                                class="d-flex flex-row align-center flex-wrap position-relative"
                            >
                                <v-menu
                                    v-model="
                                        dateMenus.collective_land_registry_date
                                    "
                                    transition="scale-transition"
                                    offset-y
                                    :close-on-content-click="false"
                                    :disabled="
                                        !userPermissions
                                            .greenHouseSolarManagement
                                            .canEditClient
                                    "
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-bind="props"
                                            label="Data Extras de carte funciară colectiv"
                                            placeholder="zz/ll/aaaa"
                                            variant="outlined"
                                            density="compact"
                                            v-model="
                                                formattedDates.collective_land_registry_date
                                            "
                                            @blur="
                                                handleManualDateInput(
                                                    'collective_land_registry_date'
                                                )
                                            "
                                            @keydown.enter.prevent="
                                                handleEnterKey(
                                                    'collective_land_registry_date'
                                                )
                                            "
                                            hide-details="auto"
                                            :readonly="
                                                !userPermissions
                                                    .greenHouseSolarManagement
                                                    .canEditClient
                                            "
                                        />
                                    </template>

                                    <v-date-picker
                                        v-model="
                                            client.collective_land_registry_date
                                        "
                                        @update:modelValue="
                                            handleDateSelection(
                                                'collective_land_registry_date',
                                                client.collective_land_registry_date
                                            )
                                        "
                                        locale="ro"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-checkbox
                                v-model="client.approved_contract"
                                label="Data aprobare dosar"
                                density="compact"
                                hide-details
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                                @update:modelValue="toggleFileApproval"
                            />
                        </v-col>

                        <v-col
                            cols="12"
                            md="6"
                            lg="3"
                            v-if="client.approved_contract"
                        >
                            <div
                                class="d-flex flex-row align-center flex-wrap position-relative"
                            >
                                <v-menu
                                    v-model="dateMenus.file_approval_date"
                                    transition="scale-transition"
                                    offset-y
                                    :close-on-content-click="false"
                                    :disabled="
                                        !userPermissions
                                            .greenHouseSolarManagement
                                            .canEditClient
                                    "
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-text-field
                                            v-bind="props"
                                            label="Data aprobare dosar"
                                            placeholder="zz/ll/aaaa"
                                            variant="outlined"
                                            density="compact"
                                            v-model="
                                                formattedDates.file_approval_date
                                            "
                                            @blur="
                                                handleManualDateInput(
                                                    'file_approval_date'
                                                )
                                            "
                                            @keydown.enter.prevent="
                                                handleEnterKey(
                                                    'file_approval_date'
                                                )
                                            "
                                            hide-details="auto"
                                            :readonly="
                                                !userPermissions
                                                    .greenHouseSolarManagement
                                                    .canEditClient
                                            "
                                        />
                                    </template>

                                    <v-date-picker
                                        v-model="client.file_approval_date"
                                        @update:modelValue="
                                            handleDateSelection(
                                                'file_approval_date',
                                                client.file_approval_date
                                            )
                                        "
                                        locale="ro"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col cols="12" md="6" lg="3">
                            <v-text-field
                                v-model="client.offer_kw"
                                variant="outlined"
                                density="compact"
                                label="Oferta kw"
                                :maxlength="5"
                                type="number"
                                hide-details="auto"
                                hint="Obligatoriu pentru vizualizare/trimitere"
                                persistent-hint
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-text-field
                                v-model="client.offer_storage_kw"
                                variant="outlined"
                                density="compact"
                                label="Oferta kw stocare"
                                type="number"
                                :maxlength="5"
                                hide-details="auto"
                                hint="Obligatoriu pentru vizualizare/trimitere"
                                persistent-hint
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.panel_count"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Nr. panouri"
                                type="number"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-select
                                v-model="client.panel_type"
                                :items="Object.keys(panelPowers)"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Tip panou"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.panel_power"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Putere totală panouri"
                                type="number"
                                suffix="kW"
                                readonly
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-select
                                v-model="client.connection"
                                :items="['Trifazic', 'Monofazic']"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Branșament"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-select
                                v-model="client.inverter_type"
                                :items="Object.keys(inverters)"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Tip invertor"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>

                        <v-col cols="12" md="6" lg="2">
                            <v-select
                                v-model="client.inverter_power"
                                :items="filteredInverterPowers"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Putere invertor"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-select
                                v-model="client.battery_model"
                                :items="filteredBatteryModels"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Model baterie"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.battery_quantity"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Cantitate baterii"
                                type="number"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6" lg="2">
                            <v-text-field
                                v-model="client.battery_power"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Putere totală baterii"
                                type="number"
                                suffix="kW"
                                readonly
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6" lg="3">
                            <v-text-field
                                v-model="client.inverter_user"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="User wifi invertor"
                                autocomplete="off"
                                inputmode="none"
                                name="fake-username"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>

                        <v-col cols="12" md="6" lg="3">
                            <v-text-field
                                v-model="client.inverter_user_password"
                                variant="outlined"
                                density="compact"
                                hide-details
                                label="Parola wifi invertor"
                                :type="'text'"
                                :append-inner-icon="
                                    showPassword ? 'mdi-eye-off' : 'mdi-eye'
                                "
                                @click:append-inner="togglePassword"
                                autocomplete="off"
                                name="not-a-password"
                                aria-hidden="true"
                                spellcheck="false"
                                ref="passwordField"
                                :readonly="
                                    !userPermissions.greenHouseSolarManagement
                                        .canEditClient
                                "
                            />
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                </v-container>
            </v-form>
        </v-card-text>
    </v-card>
    <v-card class="mt-3">
        <v-toolbar>
            <v-toolbar-title> De încarcat (documente PDF)</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <DocumentManager
                :document-options="editableDocumentFields"
                :documents="documentFiles"
                @add-file="handleAddFile"
                @delete-file="handleDeleteFile"
                @view-document="handleViewDocument"
                @update-contract-status="client.contract_status = $event"
            />
        </v-card-text>
    </v-card>
    <RecordHistory
        title="Istoric Statusuri"
        :headers="historyHeaders"
        :historyData="statusHistory"
    >
        <template v-slot:changed_at="{ entry }">
            <strong>{{
                new Date(entry.changed_at).toLocaleString("ro-RO")
            }}</strong>
        </template>
    </RecordHistory>
    <v-card class="mt-3">
        <v-toolbar>
            <v-toolbar-title> Scanner Coduri de Bare</v-toolbar-title>
        </v-toolbar>

        <!-- Button to Open Scanner Dialog -->
        <div class="d-flex justify-center mt-3">
            <v-btn color="primary" @click="isScanning = true"
                >Scanează cod</v-btn
            >
        </div>

        <!-- Dialog for Barcode Scanning -->
        <v-dialog v-model="isScanning" max-width="500px">
            <v-card>
                <v-card-title class="text-h6"
                    >Scanează Cod de Bare</v-card-title
                >
                <v-divider></v-divider>

                <v-card-text class="d-flex flex-column align-center">
                    <StreamQrcodeBarcodeReader
                        v-if="isScanning"
                        capture="shoot"
                        :formats="['code_128', 'code_39']"
                        :resolution="'high'"
                        @loaded="onLoaded"
                        @onloading="onLoading"
                        @result="onResult"
                        class="mt-4 scanner__container"
                        :scanArea="{ width: 400, height: 50 }"
                    />
                    <v-alert v-if="scannedCode" type="info" class="mt-4">
                        Cod scanat: <strong>{{ scannedCode }}</strong>
                    </v-alert>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="d-flex justify-end">
                    <v-btn color="error" text @click="isScanning = false"
                        >Anulează</v-btn
                    >
                    <v-btn v-if="scannedCode" color="success" @click="saveCode"
                        >Salvează
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-divider class="my-4"></v-divider>

        <!-- Display the Saved Codes -->
        <v-container v-if="scannedCodes.length">
            <v-row
                v-for="(code, index) in scannedCodes"
                :key="index"
                class="d-flex justify-space-between align-center mb-2"
            >
                <!-- Code on the left -->
                <div class="text-body-1">{{ code }}</div>

                <!-- Delete button on the right -->
                <v-btn icon @click="deleteCode(index)" variant="text">
                    <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";
import DocumentManager from "@/components/common/DocumentManager.vue";
import BreadCrumbsWithButtons from "@/components/common/BreadCrumbsWithButtons.vue";
import ClientContract from "@/components/clients/ClientContract.vue";
import { StreamQrcodeBarcodeReader } from "vue3-barcode-qrcode-reader";
import RecordHistory from "@/components/common/RecordHistory.vue";
import { mapGetters } from "vuex";
import { showAlertModal } from "@/utils/utils";

export default {
    name: "GreenHouseSolarClientCreate",
    components: {
        ClientContract,
        BreadCrumbsWithButtons,
        DocumentManager,
        StreamQrcodeBarcodeReader,
        RecordHistory,
    },
    props: {
        clientId: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            statusHistory: [],
            historyHeaders: [
                { label: "Valoare veche", value: "old_value" },
                { label: "Valoare nouă", value: "new_value" },
                { label: "Modificat de", value: "changed_by" },
                { label: "Data modificării", value: "changed_at" },
            ],
            client: this.getEmptyClient(),
            isValid: false,
            isEditing: false,
            showPassword: false,
            rules: {
                required: (value) => !!value || "Câmp obligatoriu",
                cnp: () => this.validateCnp(),
                phone: () => this.validatePhone(),
                email: () => this.validateEmail(),
                id_card_series: () => this.validateIdCardSeries(),
                id_card_number: () => this.validateIdCardNumber(),
            },
            buttons: [
                {
                    text: "Anulează",
                    onClick: this.cancel,
                    disabled: () => false,
                    color: "danger",
                    visible: () => true,
                },
                {
                    text: "Salvează",
                    onClick: this.submit,
                    disabled: () => false,
                    color: "success",
                    visible: () => true,
                },
            ],
            regions: [],
            subregions: [],
            cities: [],
            documentFiles: {
                id_card: [],
                fiscal_certificate_local: [],
                fiscal_certificate_state: [],
                land_registry: [],
                collective_land_registry: [],
                offer: [],
                proforma: [],
                advance_invoice: [],
                goods_delivery_annex: [],
                connection_certificate: [],
                final_invoice: [],
            },
            documentFields: [
                { key: "id_card", label: "Carte de identitate" },
                {
                    key: "fiscal_certificate_local",
                    label: "Certificat de atestare fiscală - bugetul local",
                },
                {
                    key: "fiscal_certificate_state",
                    label: "Certificat de atestare fiscală - bugetul de stat",
                },
                { key: "land_registry", label: "Extras de carte funciară" },
                {
                    key: "collective_land_registry",
                    label: "Extras de carte funciară colectiv",
                },
                { key: "offer", label: "Ofertă" },
                { key: "proforma", label: "Proformă" },
                { key: "advance_invoice", label: "Factură avans" },
                { key: "goods_delivery_annex", label: "Anexă marfă trimisă" },
                {
                    key: "connection_certificate",
                    label: "Certificat de racordare",
                },
                { key: "final_invoice", label: "Factură finală" },
            ],
            contentTypes: null,
            attachmentsToDelete: [],
            myUsers: [],
            dateMenus: {
                application_date: false,
                cf_date: false,
                identification_expiration_date: false,
                fiscal_certificate_local_date: false,
                fiscal_certificate_state_date: false,
                collective_land_registry_date: false,
                file_approval_date: false,
            },
            isScanning: false,
            scannedCode: "",
            scannedCodes: [],
            inverters: {
                Solax: {
                    Trifazic: [
                        "5kW",
                        "6kW",
                        "8kW",
                        "10kW",
                        "12kW",
                        "15kW",
                        "15kW - Ultra",
                        "20kW - Ultra",
                        "25kW - Ultra",
                        "30kW - Ultra",
                    ],
                    Monofazic: ["5kW", "6kW"],
                },
                Sungrow: {
                    Trifazic: ["6kW", "8kW", "10kW", "15kW", "20kW", "25kW"],
                    Monofazic: ["6kW"],
                },
            },
            batteries: {
                Solax: ["3kW", "5.8kW", "3.6kW"],
                Sungrow: ["3.2kW"],
            },
            panelPowers: {
                "Longi 430W": 0.43,
                "Longi 435W": 0.435,
            },
            previousContractStatus: "",
        };
    },
    computed: {
        ...mapGetters(["userPermissions"]),
        /**
         * Checks if the contract has ever reached a "Trimis" status in history.
         *
         * @returns {boolean} True if contract was sent.
         */
        hasContractBeenSent() {
            return this.statusHistory.some((entry) =>
                entry.new_value?.toLowerCase().startsWith("trimis")
            );
        },

        /**
         * Computes the available inverter power options based on the selected inverter type and connection type.
         *
         * - Returns an empty array if `inverter_type` or `connection` is not selected.
         * - Retrieves available power values from the `inverters` object based on the chosen type and connection.
         *
         * @returns {string[]} An array of available inverter power options.
         */
        filteredInverterPowers() {
            if (!this.client.inverter_type || !this.client.connection)
                return [];
            return (
                this.inverters[this.client.inverter_type][
                    this.client.connection
                ] || []
            );
        },
        /**
         * Computes the available battery models based on the selected inverter type.
         *
         * - Returns an empty array if no `inverter_type` is selected.
         * - Retrieves available battery models from the `batteries` object based on the chosen inverter type.
         *
         * @returns {string[]} An array of available battery models.
         */
        filteredBatteryModels() {
            return this.batteries[this.client.inverter_type] || [];
        },
        /**
         * Computes the document fields with an additional `isReadonly` property
         * based on the user's permissions.
         *
         * @returns {Array} The updated document fields with readonly status.
         */
        editableDocumentFields() {
            const isSent = this.hasContractBeenSent;

            return this.documentFields.map((field) => {
                if (field.key === "offer") {
                    const canEditClient =
                        this.userPermissions.greenHouseSolarManagement
                            .canEditClient;
                    const canEditOffer =
                        this.userPermissions.greenHouseSolarManagement
                            .canEditClientOffer;

                    return {
                        ...field,
                        isReadonly: !(
                            canEditClient ||
                            (canEditOffer && !isSent)
                        ),
                    };
                } else {
                    return {
                        ...field,
                        isReadonly:
                            !this.userPermissions.greenHouseSolarManagement
                                .canEditClient,
                    };
                }
            });
        },
        /**
         * Checks if the collective land registry document is uploaded.
         *
         * @returns {boolean} `true` if at least one collective land registry file exists, otherwise `false`.
         */
        isCollectiveLandRegistryUploaded() {
            return this.documentFiles.collective_land_registry.length > 0;
        },

        /**
         * Formats date properties of the `client` object into a human-readable string format.
         *
         * This computed property iterates over all properties of the `client` object.
         * If a property is an instance of `Date`, it is formatted using the `formatDate` method.
         * The formatted dates are stored in a new object and returned.
         *
         * @returns {Object} An object containing the formatted date properties of `client`.
         *                   The keys match the original `client` object, but the values are formatted date strings.
         */
        formattedDates() {
            const formatted = {};
            for (const key in this.client) {
                if (this.client[key] instanceof Date) {
                    formatted[key] = this.formatDate(this.client[key]);
                }
            }
            return formatted;
        },
        /**
         * Validates the client's CNP (national identification number) based on custom validation rules.
         * @computed
         * @returns {boolean} - True if the client's CNP is valid; otherwise, false.
         */
        isCnpValid() {
            return this.rules.cnp(this.client.cnp) === true;
        },
        /**
         * Checks if the client's region field meets the required rule.
         * @computed
         * @returns {boolean} - True if the client's region is selected; otherwise, false.
         */
        isRegionValid() {
            return this.rules.required(this.client.region) === true;
        },
        /**
         * Checks if the client's city field meets the required rule.
         * @computed
         * @returns {boolean} - True if the client's city is selected; otherwise, false.
         */
        isCityValid() {
            return this.rules.required(this.client.city) === true;
        },
        /**
         * Checks if the client's street field meets the required rule.
         * @computed
         * @returns {boolean} - True if the client's street field is valid; otherwise, false.
         */
        isStreetValid() {
            return this.rules.required(this.client.street) === true;
        },
        /**
         * Checks if the client's email is valid based on custom validation rules.
         * @computed
         * @returns {boolean} - True if the client's email is valid; otherwise, false.
         */
        isEmailValid() {
            return this.rules.email() === true;
        },
        /** Checks if the client's phone number is valid based on custom validation rules.
         * @computed
         * @returns {boolean} - True if the client's phone number is valid; otherwise, false.
         */
        isPhoneValid() {
            return this.rules.phone() === true;
        },
        /** Checks if the client's id_card_series is valid based on custom validation rules.
         * @computed
         * @returns {boolean} - True if the client's id_card_series is valid; otherwise, false.
         */
        isIdCardSeriesValid() {
            return this.rules.id_card_series() === true;
        },
        /**
         * Filters the list of cities based on the selected region.
         * @computed
         * @returns {Array<Object>} - A filtered list of cities that match the selected region.
         */
        filteredCities() {
            if (!this.client.region) {
                return [];
            }
            return this.cities.filter(
                (city) => city.region_id === this.client.region
            );
        },
    },
    async mounted() {
        await this.loadRegionsAndCities();
        await this.loadMyUsers();
        if (this.clientId) {
            await this.fetchClient();
            await this.fetchStatusHistory();
        } else {
            this.client.agent = JSON.parse(sessionStorage.getItem("userId"));
            this.initializeDates();
        }
        this.$nextTick(() => {
            const input = this.$refs.passwordField.$el.querySelector("input");
            if (input) {
                input.style.webkitTextSecurity = "disc"; // Initially hide text
            }
        });
    },
    methods: {
        /**
         * Toggles the file approval date field and clears it if hidden.
         * Called when the "Dosar aprobat" checkbox is checked/unchecked.
         *
         * @param {boolean} value - The new value of the checkbox.
         */

        toggleFileApproval(value) {
            if (value) {
                // Only store previous status once when checking the box
                if (
                    !this.previousContractStatus ||
                    this.previousContractStatus === "Aprobat"
                ) {
                    this.previousContractStatus = this.client.contract_status;
                }

                // Set file_approval_date only if it does not exist
                if (!this.client.file_approval_date) {
                    this.client.file_approval_date = new Date();
                }
                this.client.contract_status = "Aprobat";
            } else {
                this.client.file_approval_date = null;

                // Restore previous contract status if available
                if (
                    this.previousContractStatus &&
                    this.previousContractStatus !== "Aprobat"
                ) {
                    this.client.contract_status = this.previousContractStatus;
                }
            }
        },
        /**
         * Fetches the content type ID from the API and assigns it to `contentTypeId`.
         * Logs an error if the request fails.
         *
         * @async
         * @returns {Promise<void>} Resolves when the content type ID is fetched and assigned.
         */
        async fetchContentTypeId() {
            try {
                const response = await apiClient.get("/api/content-types/");
                this.contentTypeId = response.data.greenhousesolarclient; // Adjust key if needed
            } catch (error) {
                console.error("Error fetching content type ID:", error);
            }
        },
        /**
         * Fetches the status history for a specific client based on the content type ID.
         * Ensures the content type ID is available before making the request.
         * Logs an error if the request fails.
         *
         * @async
         * @returns {Promise<void>} Resolves when the status history is fetched and assigned.
         */
        async fetchStatusHistory() {
            if (!this.contentTypeId) {
                await this.fetchContentTypeId(); // Ensure content type ID is available
            }

            try {
                const response = await apiClient.get(
                    `/api/field-history/${this.contentTypeId}/${this.client.id}/`
                );
                this.statusHistory = response.data.history;
            } catch (error) {
                console.error("Error fetching status history:", error);
            }
        },
        /**
         * Toggles the visibility of the password field.
         * When `showPassword` is `false`, the password is masked using `-webkit-text-security: disc;`.
         * When `showPassword` is `true`, the text is fully visible.
         *
         * @returns {void}
         */
        togglePassword() {
            this.showPassword = !this.showPassword;
            this.$nextTick(() => {
                const input =
                    this.$refs.passwordField.$el.querySelector("input");
                if (!this.showPassword) {
                    input.style.webkitTextSecurity = "disc"; // Hide text when showPassword is false
                } else {
                    input.style.webkitTextSecurity = "none"; // Show text when showPassword is true
                }
            });
        },
        /**
         * Handles the Enter key event for date input fields.
         *
         * - Calls `handleManualDateInput()` to process the manually entered date.
         * - Uses `$nextTick` to ensure Vue reactivity updates before closing the date picker menu.
         *
         * @param {string} field - The key corresponding to the date field in `client`.
         */
        handleEnterKey(field) {
            this.handleManualDateInput(field);

            this.$nextTick(() => {
                this.dateMenus[field] = false; // 🔥 Now it's a reactive property!
            });
        },
        /**
         * Processes a manually entered date from an input field.
         *
         * - Parses the date string from `formattedDates[field]` using `parseDate()`.
         * - If the date is valid, updates `client[field]` with the parsed `Date` object.
         * - Uses `$nextTick` to ensure Vue updates before closing the date picker menu.
         * - Logs an error message if the entered date is invalid.
         *
         * @param {string} field - The key corresponding to the date field in `client`.
         */
        handleManualDateInput(field) {
            const parsedDate = this.parseDate(this.formattedDates[field]);

            if (parsedDate) {
                this.client[field] = parsedDate;

                // this.$nextTick(() => {
                //   this.dateMenus[field] = false; // 🔥 Close picker after Vue updates
                // });
            }
        },
        /**
         * Handles date selection from a date picker.
         *
         * - Updates `client[field]` with the selected date.
         * - Uses `$nextTick` to ensure the date picker menu closes properly.
         *
         * @param {string} field - The key corresponding to the date field in `client`.
         * @returns {Function} A function that accepts the selected date and updates the client object.
         */
        handleDateSelection(field, date) {
            this.client[field] = date;

            if (field === "file_approval_date") {
                if (date && this.client.approved_contract) {
                    // Only store status if it’s not already Aprobat
                    if (
                        !this.previousContractStatus ||
                        this.previousContractStatus === "Aprobat"
                    ) {
                        this.previousContractStatus =
                            this.client.contract_status;
                    }
                    this.client.contract_status = "Aprobat";
                } else {
                    // If cleared and we had a previous status, restore it
                    if (
                        this.previousContractStatus &&
                        this.previousContractStatus !== "Aprobat"
                    ) {
                        this.client.contract_status =
                            this.previousContractStatus;
                    }
                    // Clear the memory to prevent stale reuse
                    this.previousContractStatus = "";
                }
            }

            this.$nextTick(() => {
                this.dateMenus[field] = false;
            });
        },
        /**
         * Parses a date string in the format "dd/mm/yyyy" into a JavaScript `Date` object.
         *
         * - Splits the string into day, month, and year components.
         * - Constructs a `Date` object using the ISO format (`yyyy-mm-ddT00:00:00`).
         * - Returns `null` if the date is invalid.
         *
         * @param {string} dateStr - The date string in "dd/mm/yyyy" format.
         * @returns {Date|null} The parsed `Date` object if valid, otherwise `null`.
         */
        parseDate(dateStr) {
            const parts = dateStr.split("/");
            if (parts.length !== 3) return null;

            const parsedDate = new Date(
                `${parts[2]}-${parts[1]}-${parts[0]}T00:00:00`
            );
            return isNaN(parsedDate.getTime()) ? null : parsedDate;
        },
        /**
         * Starts the scanning process by setting the `isScanning` flag to true.
         */
        startScanning() {
            this.isScanning = true;
        },

        /**
         * Handles the component load event.
         * Logs a message when the component is fully loaded.
         */
        onLoaded() {
            console.log("Component loaded");
        },

        /**
         * Handles the component loading event.
         * Logs a message when the component is in the process of loading.
         */
        onLoading() {
            console.log("Component loading");
        },

        /**
         * Processes the scanned result and stores it.
         * @param {string} result - The scanned QR or barcode result.
         */
        onResult(result) {
            this.scannedCode = result;
        },

        /**
         * Saves the scanned code to the `scannedCodes` list if it exists.
         * Resets `scannedCode` and stops scanning.
         */
        saveCode() {
            if (this.scannedCode) {
                this.scannedCodes.unshift(this.scannedCode); // Adds to top of the list
            }
            this.scannedCode = "";
            this.isScanning = false;
        },

        /**
         * Deletes a scanned code from the `scannedCodes` list by index.
         * @param {number} index - The index of the code to be deleted.
         */
        deleteCode(index) {
            this.scannedCodes.splice(index, 1);
        },
        /**
         * Formats a given Date object into a "dd/mm/yyyy" format string.
         *
         * @param {Date} date - The date object to format.
         * @returns {string} - The formatted date string, or an empty string if invalid.
         */
        formatDate(date) {
            if (!date) return "";

            // Convert string date to Date object if needed
            if (typeof date === "string") {
                date = new Date(date);
            }

            if (!(date instanceof Date) || isNaN(date.getTime())) return ""; // Ensure valid date

            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },

        /**
         * Parses an ISO date string into a JavaScript Date object.
         * Returns null if the input is invalid or null.
         *
         * @param {string} date - The ISO date string to parse.
         * @returns {Date|null} - The parsed Date object or null.
         */
        parseDateFromISO(date) {
            return date ? new Date(date) : null;
        },
        /**
         * Initializes date fields in the client object to the current date.
         * This is useful for setting default values for date-related fields.
         */
        initializeDates() {
            this.client.application_date = new Date();
            this.client.cf_date = new Date();
            this.client.identification_expiration_date = new Date();
            this.client.fiscal_certificate_local_date = new Date();
            this.client.fiscal_certificate_state_date = new Date();
            this.client.collective_land_registry_date = new Date();
        },
        /**
         * Handles the selection of a new user for the client, updating the client's associated user ID.
         * @param {number} newUserId - The ID of the newly selected user.
         * @method
         */
        onUserChange(newUserId) {
            this.client.agent = newUserId;
        },
        /**
         * Loads the list of users who are agents, storing them in the `myUsers` array.
         * @method
         */
        loadMyUsers() {
            // Retrieve the list of users from session storage
            const users = JSON.parse(sessionStorage.getItem("myUsers")) || [];
            this.myUsers = users;
        },
        /**
         * Handles changes in the client's city selection, differentiating between selecting an existing city and creating a new one.
         * @param {(Object|string)} value - The selected city object or a custom city name string.
         * @method
         */
        handleCityChange(value) {
            // If the value is an object (existing city)
            if (typeof value === "object") {
                // Assign the city ID or name based on your backend expectations
                this.client.city = value?.name;
            }
            // If the value is a string (new custom city)
            else if (typeof value === "string") {
                this.client.city = value;
            }
        },
        /**
         * Resets the client's selected city when the region changes.
         * @method
         */
        onRegionChange() {
            // Reset city when region changes
            this.client.city = null;
        },
        /**
         * Loads regions and cities from an external JSON file, processing and sorting them for display.
         * @async
         * @method
         */
        async loadRegionsAndCities() {
            try {
                const response = await fetch("/cities.json");
                const data = await response.json();

                // Process regions and sort them alphabetically
                this.regions = data
                    .filter((item) => item.model === "cities_light.region")
                    .map((item) => ({
                        id: item.pk,
                        name: item.fields.name,
                        display_name: item.fields.display_name,
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                // Prefixes to remove from city names
                const prefixesToRemove = ["Comuna", "Oras", "Municipiul"];

                // Process cities and remove prefixes
                this.cities = data
                    .filter((item) => item.model === "cities_light.subregion")
                    .map((item) => {
                        let { name, display_name } = item.fields;

                        // Remove specified prefixes from name and display_name
                        prefixesToRemove.forEach((prefix) => {
                            const regex = new RegExp(`^${prefix}\\s+`, "i");
                            name = name.replace(regex, "");
                            display_name = display_name.replace(regex, "");
                        });

                        return {
                            id: item.pk,
                            name: name,
                            display_name: display_name,
                            region_id: item.fields.region,
                        };
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));
            } catch (error) {
                console.error("Error loading regions and cities:", error);
            }
        },
        getEmptyClient() {
            return {
                beneficiary_code_afm: "",
                file_number_afm: "",
                file_number_cf: "",
                application_date: "",
                cf_date: "",
                identification_expiration_date: "",
                fiscal_certificate_local_date: "",
                fiscal_certificate_state_date: "",
                collective_land_registry_date: "",
                file_approval_date: "",
                first_name: "",
                last_name: "",
                id_card_series: "",
                id_card_number: "",
                cnp: "",
                region: "",
                city: "",
                street: "",
                number: "",
                email: "",
                phone: "",
                notes: "",
                connection: "",
                panel_type: "",
                panel_count: "",
                panel_power: "",
                inverter_power: "",
                inverter_type: "",
                battery_model: "",
                battery_quantity: "",
                battery_power: "",
                inverter_user: "",
                inverter_user_password: "",
                offer_kw: "",
                offer_storage_kw: "",
                contract_status: "Eligibil",
                approved_contract: false,
            };
        },
        /**
         * Converts a date to ISO format if it exists.
         * @param {string | Date | null} date - The date to convert.
         * @returns {string | null} - The ISO string or null if the date is invalid.
         */
        toISODate(date) {
            if (!date) return null;
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, "0");
            const day = String(d.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        /**
         * Fetches client data from the server based on the client ID.
         * Populates client fields and maps attachments to document fields.
         * Initializes editing state and dynamically maps attachments to their document types.
         *
         * @async
         */
        async fetchClient() {
            if (!this.clientId) return;
            try {
                const response = await apiClient.get(
                    `/api/greenhouse-solar-clients/${this.clientId}/`
                );
                const clientData = response.data;

                // Assign client data
                this.client = clientData;

                this.client.application_date = this.parseDateFromISO(
                    clientData.application_date
                );
                this.client.cf_date = this.parseDateFromISO(clientData.cf_date);
                this.client.identification_expiration_date =
                    this.parseDateFromISO(
                        clientData.identification_expiration_date
                    );
                this.client.fiscal_certificate_local_date =
                    this.parseDateFromISO(
                        clientData.fiscal_certificate_local_date
                    );
                this.client.fiscal_certificate_state_date =
                    this.parseDateFromISO(
                        clientData.fiscal_certificate_state_date
                    );
                this.client.collective_land_registry_date =
                    this.parseDateFromISO(
                        clientData.collective_land_registry_date
                    );
                this.client.file_approval_date = this.parseDateFromISO(
                    clientData.file_approval_date
                );

                // Initialize documentFiles mapping dynamically
                for (const key in this.documentFiles) {
                    this.documentFiles[key] = [];
                }

                // Map attachments to document fields if `document_type` exists
                if (
                    clientData.attachments &&
                    clientData.attachments.length > 0
                ) {
                    for (const attachment of clientData.attachments) {
                        if (attachment.document_type) {
                            const fieldKey = attachment.document_type; // Use document_type for field mapping
                            if (this.documentFiles[fieldKey]) {
                                this.documentFiles[fieldKey].push(attachment);
                            }
                        }
                    }
                }

                this.isEditing = true;
            } catch (error) {
                console.error("Error fetching client:", error);
            }
        },
        /**
         * Handles the addition of new files for a given document type.
         * Associates files with their document type and updates the `documentFiles` array.
         *
         * @param {Object} param - Contains the key (document type) and the array of files to add.
         * @param {string} param.key - The document type key.
         * @param {File[]} param.files - The files to add.
         */
        handleAddFile({ key, files }) {
            if (!this.documentFiles[key]) {
                this.$set(this.documentFiles, key, []);
            }
            files.forEach((file) => {
                // Attach document type to each file
                file.document_type = key;
                this.documentFiles[key].push(file);
            });
        },
        /**
         * Handles the deletion of a specific file from the UI and prepares it for backend deletion if necessary.
         *
         * @param {Object} param - Contains the document type and file to delete.
         * @param {string} param.documentType - The type of the document.
         * @param {Object} param.file - The file object to be removed.
         */
        handleDeleteFile({ documentType, file }) {
            if (file.id) {
                // Add file to attachmentsToDelete
                this.attachmentsToDelete.push(file.id);
            }

            // Remove from UI regardless of deletion
            this.documentFiles[documentType] = this.documentFiles[
                documentType
            ].filter((existingFile) => existingFile !== file);
        },
        /**
         * Opens a specific document in a new browser tab for viewing.
         *
         * @param {Object} document - The document object containing the URL to open.
         */
        handleViewDocument(document) {
            // Open the document in a new tab or modal
            window.open(document.file_url, "_blank");
        },
        /**
         * Submits the client data to the backend for saving or updating.
         * Converts all date fields to ISO format and handles file uploads and attachments.
         *
         * @async
         */
        async submit() {
            try {
                const { valid } = await this.$refs.form.validate(); // Trigger validation
                if (!valid) {
                    return; // Stop submission if the form is invalid
                }

                // Ensure all date fields are converted to ISO format
                const formattedClient = { ...this.client };
                formattedClient.application_date = this.toISODate(
                    formattedClient.application_date
                );
                formattedClient.cf_date = this.toISODate(
                    formattedClient.cf_date
                );
                formattedClient.identification_expiration_date = this.toISODate(
                    formattedClient.identification_expiration_date
                );
                formattedClient.fiscal_certificate_local_date = this.toISODate(
                    formattedClient.fiscal_certificate_local_date
                );
                formattedClient.fiscal_certificate_state_date = this.toISODate(
                    formattedClient.fiscal_certificate_state_date
                );
                formattedClient.collective_land_registry_date = this.toISODate(
                    formattedClient.collective_land_registry_date
                );
                if (!this.client.approved_contract) {
                    formattedClient.file_approval_date = null;
                } else {
                    formattedClient.file_approval_date = this.toISODate(
                        formattedClient.file_approval_date
                    );
                }

                // Add attachments to delete
                if (this.attachmentsToDelete.length > 0) {
                    formattedClient.attachments_to_delete =
                        this.attachmentsToDelete;
                }

                const url = this.isEditing
                    ? `/api/greenhouse-solar-clients/${this.clientId}/`
                    : "/api/greenhouse-solar-clients/";
                const method = this.isEditing ? "put" : "post";

                let response = await apiClient[method](url, formattedClient);

                await this.uploadFiles(response.data.id); // Handle file uploads

                this.$router.push({ name: "GreenHouseSolarClientsList" }); // Navigate back
            } catch (error) {
                console.error("Error saving client:", error);

                if (error.response && error.response.data) {
                    const errors = error.response.data;

                    const messages = Object.entries(errors)
                        .map(([msgs]) =>
                            Array.isArray(msgs) ? msgs.join(" ") : msgs
                        )
                        .join("\n");

                    showAlertModal(
                        this.$store,
                        messages,
                        "danger",
                        12000,
                        error
                    );
                } else {
                    showAlertModal(
                        this.$store,
                        "A apărut o eroare neașteptată. Vă rugăm să reîncercați.",
                        "danger",
                        12000,
                        error
                    );
                }
            }
        },

        /**
         * Uploads all associated files for a client to the backend.
         * Attaches document type and content type information for each file.
         *
         * @param {number} clientId - The ID of the client to associate the uploaded files with.
         * @async
         */
        async uploadFiles(clientId) {
            for (const field of this.documentFields) {
                const files = this.documentFiles[field.key]?.filter(
                    (file) => file instanceof File
                ); // Only new files
                if (files && files.length > 0) {
                    const formData = new FormData();
                    const contentTypeId = await this.getContentTypeId(
                        "greenhousesolarclient"
                    );
                    formData.append("content_type", contentTypeId);
                    formData.append(
                        "object_id",
                        this.clientId ? this.clientId : clientId
                    );

                    files.forEach((file) => {
                        formData.append("files", file);
                        formData.append("document_type", field.key); // Add document type for each file
                    });

                    try {
                        await apiClient.post(
                            `/api/attachments/upload/`,
                            formData,
                            {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            }
                        );
                    } catch (error) {
                        console.error(
                            `Error uploading files for ${field.label}:`,
                            error
                        );
                    }
                }
            }
        },
        /**
         * Retrieves the content type ID for a given model name.
         * Used to identify the content type when uploading files.
         *
         * @param {string} modelName - The name of the model to fetch the content type ID for.
         * @returns {number} - The content type ID for the specified model.
         * @async
         */
        async getContentTypeId(modelName) {
            if (!this.contentTypes) {
                const response = await apiClient.get("/api/content-types/");
                this.contentTypes = response.data;
            }
            return this.contentTypes[modelName.toLowerCase()];
        },
        /**
         * Cancels the current action and navigates the user back to the GreenHouse Solar Clients List view.
         */
        cancel() {
            this.$router.push({ name: "GreenHouseSolarClientsList" });
        },
        /**
         * Validates the client's CNP (national identification number) based on format and length requirements.
         * @returns {(boolean|string)} - True if the CNP is valid, or an error message string if invalid.
         * @method
         */
        validateCnp() {
            const cnp = this.client.cnp;
            if (!cnp) {
                return "Introduceți un CNP valid.";
            }
            if (cnp.length !== 13) {
                return "CNP trebuie să aibă exact 13 caractere.";
            }
            if (!/^[1256]/.test(cnp)) {
                return "CNP trebuie să înceapă cu 1, 2, 5 sau 6.";
            }
            return true;
        },
        /**
         * Validates the client's phone number based on length and formatting requirements.
         * @returns {(boolean|string)} - True if the phone number is valid, or an error message string if invalid.
         * @method
         */
        validatePhone() {
            if (
                !/^\d{10}$/.test(this.client.phone) &&
                !/^\+\d{11}$/.test(this.client.phone)
            ) {
                return "Introduceti un număr de telefon valid, format din 10 cifre sau incepand cu + si 11 cifre.";
            }
            return true;
        },
        /**
         * Validates the client's email address format using a regex pattern.
         * @returns {(boolean|string)} - True if the email is valid, or an error message string if invalid.
         * @method
         */
        validateEmail() {
            const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            if (!pattern.test(this.client.email)) {
                return "Introduceti o adresă de email validă.";
            }
            return true;
        },
        /**
         * Validates the client's ID card series to ensure it contains at least 2 letters.
         * Allows only alphabetic characters.
         *
         * @returns {boolean|string} - True if valid, or an error message if invalid.
         */
        validateIdCardSeries() {
            const series = this.client.id_card_series;
            if (!series || series.length < 2) {
                return "Seria buletinului trebuie să conțină cel puțin 2 litere.";
            }
            if (!series || series.length > 2) {
                return "Seria buletinului trebuie să conțină maxim 2 litere.";
            }
            if (!/^[A-Za-z]+$/.test(series)) {
                return "Seria buletinului poate conține doar litere.";
            }
            return true;
        },
        /**
         * Validates the client's ID card number to ensure it contains exactly 6 digits.
         * Allows only numeric characters.
         *
         * @returns {boolean|string} - True if valid, or an error message if invalid.
         */
        validateIdCardNumber() {
            const number = this.client.id_card_number;
            if (!number || number.length !== 6) {
                return "Numărul buletinului trebuie să aibă exact 6 cifre.";
            }
            if (!/^\d{6}$/.test(number)) {
                return "Numărul buletinului trebuie să conțină doar cifre.";
            }
            return true;
        },
        /**
         * Updates the total power of the solar panels.
         *
         * - Retrieves the panel count and type from the `client` object.
         * - Calculates the total power using the predefined `panelPowers` values.
         * - Updates `client.panel_power` with the computed total (rounded to two decimal places).
         */
        updatePanelPower() {
            const count = parseInt(this.client.panel_count) || 0;
            const power = this.panelPowers[this.client.panel_type] || 0;
            this.client.panel_power = (count * power).toFixed(2);
        },
        /**
         * Updates the total power of the batteries.
         *
         * - Retrieves the battery quantity and model from the `client` object.
         * - Parses the battery model value as a float (since it represents power in kW).
         * - Computes the total battery power and updates `client.battery_power` (rounded to two decimal places).
         */
        updateBatteryPower() {
            const quantity = parseInt(this.client.battery_quantity) || 0;
            const power = parseFloat(this.client.battery_model) || 0;
            this.client.battery_power = (quantity * power).toFixed(2);
        },
    },
    watch: {
        "client.panel_count": "updatePanelPower",
        "client.panel_type": "updatePanelPower",
        "client.battery_model": "updateBatteryPower",
        "client.battery_quantity": "updateBatteryPower",
    },
};
</script>

<style scoped>
.scanner__container {
    width: 100%;
    max-width: 100%;
    height: 130px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scanner__container video {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.masked-password input {
    -webkit-text-security: disc; /* Hides the text like a password */
}
</style>
