import emitter from "@/utils/emitter";
import apiClient, {deleteAuthToken} from "@/utils/apiClient";
import router from "@/router";

/**
 * Deletes a client and updates sessionStorage to remove the client and their locations.
 * If a route is provided, the function redirects to the specified path after deletion.
 * Displays an alert in case of error or failure.
 *
 * @async
 * @function deleteClient
 * @param {number} clientID - The ID of the client to be deleted.
 * @param {string} pathToPush - The path to redirect to after deletion, if provided.
 * @param {Object} context - The application context, used for displaying alerts.
 */
export async function deleteClient(clientID, pathToPush, context) {
    try {
        const response = await apiClient.delete(`/api/delete_client/${clientID}/`);
        if (response.data.success) {
            // Remove client from sessionStorage
            const myClients = await JSON.parse(sessionStorage.getItem('myClients') || '[]');
            const updatedClients = myClients.filter(client => client.id !== clientID);
            await sessionStorage.setItem('myClients', JSON.stringify(updatedClients));

            // Remove client's locations from sessionStorage
            const myRecentLocations = await JSON.parse(sessionStorage.getItem('myRecentLocations') || '[]');
            const updatedLocations = myRecentLocations.filter(location => location.client.id !== clientID);
            await sessionStorage.setItem('myRecentLocations', JSON.stringify(updatedLocations));

            emitter.emit('clientDeleted', clientID);
            if (pathToPush) {
                await pushToRoute(pathToPush);
            }
        } else {
            console.error(response.data.message);
        }
    } catch (error) {
        console.error(error);
        showAlertModal(context.$store, 'A apărut o eroare la ștergerea clientului. Vă rugăm să încercați din nou.', 'danger', 12000, error);
    }
}

/**
 * Deletes a location and updates sessionStorage to remove the location.
 * Fetches updated location data after deletion and updates the local state.
 * Displays an alert in case of error or failure.
 *
 * @async
 * @function deleteLocation
 * @param {number} locationId - The ID of the location to be deleted.
 * @param {Object} currentClient - The current client object, which will be reset.
 * @param {Array} recentLocations - The list of recent locations, which will be updated.
 * @param {Array} location - The location data, which will be filtered.
 * @param {Object} context - The application context, used for displaying alerts.
 * @returns {Promise<Array>} - Returns the updated list of locations.
 */
export async function deleteLocation(context, locationId, currentClient, recentLocations, location) {
    try {
        await apiClient.delete(`/api/delete_location/${locationId}/`);
        currentClient = null;
        // Delete the location from sessionStorage's myRecentLocations array
        const myRecentLocations = JSON.parse(sessionStorage.getItem('myRecentLocations')) || [];
        const locationIndex = myRecentLocations.findIndex(location => location.id === locationId);
        if (locationIndex !== -1) {
            myRecentLocations.splice(locationIndex, 1);
            sessionStorage.setItem('myRecentLocations', JSON.stringify(myRecentLocations));
        }
        await fetchData('locations', context).then(() => {
            recentLocations = JSON.parse(sessionStorage.getItem('myRecentLocations'))
        });
        if (location) {
            location = location.filter(location => location.id !== locationId);
            return location
        } else {
            return recentLocations
        }
    } catch (error) {
        console.error('Error deleting location:', error);
        showAlertModal(context.$store, 'A apărut o eroare la ștergerea locației. Vă rugăm să încercați din nou.', 'danger', 12000, error);
    }
}

/**
 * Navigates to a specified route.
 * Uses the Vue router to push the user to the provided route.
 *
 * @async
 * @function pushToRoute
 * @param {string} route - The route to navigate to.
 */
export async function pushToRoute(route) {
    await router.push(route)
}

/**
 * Fetches data from various endpoints based on the specified type.
 * Updates sessionStorage with the fetched data (users, locations, or intermediaries).
 * Displays an alert in case of error or failure.
 *
 * @async
 * @function fetchData
 * @param {string} [type='all'] - The type of data to fetch ('all', 'users', 'locations', or 'intermediaries').
 * @param {Object} context - The application context, used for displaying alerts.
 */
export async function fetchData(type = 'all', context) {
    if (type === 'all' || type === 'users') {
        await apiClient.get('/api/users/')
            .then(response => {
                let myUsers = response.data
                sessionStorage.setItem('myUsers', JSON.stringify(myUsers));
            })
            .catch(error => {
                showAlertModal(context.$store, 'A apărut o eroare la fetch-ul utilizatorilor. Vă rugăm să încercați din nou.', 'danger', 12000, error);
                console.log(error);
            });
    }
    if (type === 'all' || type === 'locations') {
        await apiClient.get('/api/locations/last_modified/')
            .then(async response => {
                let recentLocations = await parseLocations(response.data);
                sessionStorage.setItem('myRecentLocations', JSON.stringify(recentLocations));
            })
            .catch(error => {
                showAlertModal(context.$store, 'A apărut o eroare la fetch-ul locațiilor. Vă rugăm să încercați din nou.', 'danger', 12000, error);
                console.log(error);
            });
    }
    if (type === 'all' || type === 'intermediaries') {
        let userId = JSON.parse(sessionStorage.getItem('userId'));
        let endpoint = '/api/intermediaries/';

        // Always include the agent_id query parameter, regardless of user role.
        if (userId !== null) {
            endpoint += `?agent_id=${userId}`;
        }

        await apiClient.get(endpoint)
            .then(response => {
                let intermediaries = response.data;
                sessionStorage.setItem('intermediaries', JSON.stringify(intermediaries));
            })
            .catch(error => {
                showAlertModal(
                    context.$store,
                    'A apărut o eroare la fetch-ul intermediarilor. Vă rugăm să încercați din nou.',
                    'danger',
                    12000,
                    error
                );
                console.log(error);
            });
    }
}

/**
 * Fetches the locations associated with a client.
 * Sends an API request to retrieve the locations for the given client ID.
 * If locations are returned, they are parsed and their total area is calculated.
 * Displays an alert in case of error or failure.
 *
 * @async
 * @function getClientLocations
 * @param {number} clientId - The ID of the client.
 * @param {Object} context - The application context, used for displaying alerts.
 * @returns {Promise<Array>} A list of locations for the client, or an empty array in case of error.
 */
export async function getClientLocations(clientId, context) {
    let locations = [];
    try {
        const locationsResponse = await apiClient.get(`/api/list_locations/${clientId}/`);
        if (locationsResponse.data.locations && locationsResponse.data.locations.length > 0) {
            locations = await parseLocations(locationsResponse.data.locations);
        }
    } catch (error) {
        showAlertModal(context.$store, 'A apărut o eroare la fetch-ul locațiilor clientului. Vă rugăm să încercați din nou.', 'danger', 12000, error);
        console.error(error)
    }
    return locations;
}

/**
 * Parses the list of locations and calculates the total area for each location.
 * Each location's rooms are summed up to get the total area for that location.
 *
 * @async
 * @function parseLocations
 * @param {Array} locations - The list of locations to be parsed.
 * @returns {Array} The parsed locations, each with an additional `totalArea` property.
 */
export async function parseLocations(locations) {
    return locations.map(location => {
        let totalArea = 0;
        if (location.rooms && location.rooms.length > 0) {
            totalArea = location.rooms.reduce((accumulator, room) => {
                const area = parseFloat(room.area);
                return accumulator + (isNaN(area) ? 0 : area);
            }, 0);
        }
        return {
            ...location,
            totalArea,
        };
    });
}

/**
 * Saves the selected products for an offer.
 * Filters out products with invalid prices or a price of "0.00".
 * Sends an API request to create offer items with the valid selected products.
 * Displays an alert in case of error or failure.
 *
 * @async
 * @function saveSelectedProducts
 * @param {Object} context - The application context, used for displaying alerts.
 * @param {Array} selectedProducts - The list of selected products to be saved.
 */
export async function saveSelectedProducts(context, selectedProducts) {
    try {
        // Filter out products with falsy price or "0.00"
        const validProducts = selectedProducts.filter(product => product.price && product.price !== "0.00");

        if (validProducts.length === 0) {
            showAlertModal(context.$store, 'A apărut o eroare. Vă rugăm să încercați din nou.', 'danger', 12000);
            return;
        }

        const response = await apiClient.post('/api/create_offer_items/', {
            selected_products: validProducts
        });

        if (response.status === 201) {
            console.log('All items saved successfully');
        } else {
            console.log('Error while saving items');
        }
    } catch (error) {
        console.error('Error while saving items', error);
        showAlertModal(context.$store, 'A apărut o eroare. Vă rugăm să încercați din nou.', 'danger', 12000, error);
    }
}

/**
 * Updates the selected products for an offer.
 * Filters out products with falsy prices or price equal to "0.00".
 * Sends an API request to update the offer items.
 * Displays an alert in case of error or failure.
 *
 * @async
 * @function updateSelectedProducts
 * @param {Object} context - The application context, used for displaying alerts.
 * @param {Array} selectedProducts - The list of products selected for the offer.
 * @returns {Promise<Object|undefined>} The updated products data or undefined in case of error.
 */
export async function updateSelectedProducts(context, selectedProducts) {
    try {
        // Filter out products with falsy price or "0.00"
        const validProducts = selectedProducts.filter(product => product.price && product.price !== "0.00");

        if (validProducts.length === 0) {
            showAlertModal(context.$store, 'A apărut o eroare. Vă rugăm să încercați din nou.', 'danger', 12000);
            return;
        }

        const response = await apiClient.put(`/api/update_offer_items/`, {
            updated_products: validProducts
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return alert('Error while updating items');
        }
    } catch (error) {
        console.error('Error while updating items', error);
        showAlertModal(context.$store, 'A apărut o eroare. Vă rugăm să încercați din nou.', 'danger', 12000, error);
    }
}

/**
 * Creates a new offer and saves the selected products.
 * Sends an API request to create a new offer and attaches necessary data such as client, location, and pricing details.
 * After the offer is created, it saves the selected products.
 * Displays an alert in case of error or failure.
 *
 * @async
 * @function createOffer
 * @param {Object} context - The application context, used for displaying alerts.
 * @param {number} offerType - The type of the offer (e.g. simple or VMC offer).
 * @param {Array} products - The list of products for the offer.
 * @param {Object} offer - The offer details including discount, notes, etc.
 * @param {number} clientId - The ID of the client.
 * @param {number} locationId - The ID of the location associated with the offer.
 * @param {number} agent_id - The ID of the agent creating the offer.
 * @param {number} offerPriceList - The ID of the price list for the offer.
 * @param {Array} [paymentTerms=[]] - Optional array of payment terms.
 * @param {Array} recommendedProducts - Recommended products for the offer.
 * @param {Array} units - Units data for the offer.
 * @returns {Promise<Object|undefined>} The created offer data or undefined in case of error.
 */
export async function createOffer(context, offerType, products, offer, clientId, locationId, agent_id, offerPriceList, paymentTerms = [], recommendedProducts, units) {
    try {
        const offerData = {
            agent_id: agent_id,
            client_id: clientId,
            location_id: locationId,
            discount: offer.discount,
            offer_type: offerType,
            notes: offer.notes,
            status: 0,
            invoice: 0,
            cap_money: 0,
            price_list_id: offerPriceList,
            parent_offer: offer.parent_offer,
            payment_terms: paymentTerms,
            units: units,
            list_price_value: offer.totalPriceWithoutDiscount,
            value_with_discount: offer.totalFinalPrice,
            is_discount_mixed: offer.isDiscountMixed ? offer.isDiscountMixed : false,
            custom_pdf_heading: offer.customPDFHeading,
            selected_customer_type: offer.selectedCustomerType,
        };

        if (offer.selectedCustomerType === 1) {
            // Remove the default 19% tax and apply 9% tax
            const totalPriceWithoutTax = offer.totalPriceWithoutDiscount / 1.19;
            offerData.list_price_value = totalPriceWithoutTax * 1.09;
        } else {
            offerData.list_price_value = offer.totalPriceWithoutDiscount; // No change if customer type is not 1
        }

        if (recommendedProducts) {
            offerData.recommended_products = recommendedProducts;
        }
        let response;
        if (offerType === 3) {
            response = await apiClient.post('/api/create-vmc-offer/', JSON.stringify(offerData), {
                headers: {'Content-Type': 'application/json'},
            });
        } else {
            response = await apiClient.post('/api/create-simple-offer/', JSON.stringify(offerData), {
                headers: {'Content-Type': 'application/json'},
            });
        }
        // Check if response status is 201 else return and throw error
        if (response.status !== 201) {
            alert('Eroare la crearea ofertei: ' + response.data.message);
            console.error('Error creating offer:', response);
            return;
        }
        const offerId = response.data.offer.id;

        const updatedProducts = products.map(product => {
            return {...product, offer_id: offerId, offer: offerId};
        });

        await saveSelectedProducts(context, updatedProducts);
        return response.data.offer;
    } catch (error) {
        console.error('Error creating offer:', error);
        showAlertModal(context.$store, 'A apărut o eroare la crearea ofertei. Vă rugăm să încercați din nou.', 'danger', 12000, error);
    }
}

/**
 * Updates an existing offer and its associated products.
 * Sends an API request to update the offer and its details such as pricing, units, and payment terms.
 * After the offer is updated, it updates the selected products.
 * Displays an alert in case of error or failure.
 *
 * @async
 * @function updateOffer
 * @param {Object} context - The application context, used for displaying alerts.
 * @param {number} offerType - The type of the offer (e.g. simple or VMC offer).
 * @param {number} offerId - The ID of the offer to be updated.
 * @param {Array} products - The list of products for the offer.
 * @param {Object} offer - The offer details including discount, notes, etc.
 * @param {number} priceListId - The ID of the price list for the offer.
 * @param {Array} paymentTerms - Array of payment terms for the offer.
 * @param {Array} recommendedProducts - Recommended products for the offer.
 * @param {Array} units - Units data for the offer.
 * @returns {Promise<Object|undefined>} The updated products data or undefined in case of error.
 */
export async function updateOffer(context, offerType, offerId, products, offer, priceListId, paymentTerms, recommendedProducts, units) {
    try {
        const offerData = {
            discount: offer.discount,
            notes: offer.notes,
            status: 0,
            invoice: 0,
            cap_money: 0,
            price_list_id: priceListId,
            payment_terms: paymentTerms,
            units: units,
            list_price_value: offer.totalPriceWithoutDiscount,
            value_with_discount: offer.totalFinalPrice,
            is_discount_mixed: offer.isDiscountMixed ? offer.isDiscountMixed : false,
            custom_pdf_heading: offer.customPDFHeading,
            selected_customer_type: offer.selectedCustomerType,
        };

        if (offer.selectedCustomerType === 1) {
            // Remove the default 19% tax and apply 9% tax
            const totalPriceWithoutTax = offer.totalPriceWithoutDiscount / 1.19;
            offerData.list_price_value = totalPriceWithoutTax * 1.09;
        } else {
            offerData.list_price_value = offer.totalPriceWithoutDiscount; // No change if customer type is not 1
        }

        if (recommendedProducts) {
            offerData.recommended_products = recommendedProducts;
        }

        if (offerType === 3) {
            await apiClient.post(`/api/update-vmc-offer/${offerId}/`, JSON.stringify(offerData), {
                headers: {'Content-Type': 'application/json'},
            });

        } else {
            await apiClient.post(`/api/update-simple-offer/${offerId}/`, JSON.stringify(offerData), {
                headers: {'Content-Type': 'application/json'},
            });
        }

        const updatedProducts = products.map(product => {
            return {...product, offer_id: offerId, offer: offerId};
        });

        return await updateSelectedProducts(context, updatedProducts);
    } catch (error) {
        console.error('Error updating offer:', error);
        showAlertModal(context.$store, 'A apărut o eroare la salvarea ofertei. Vă rugăm să încercați din nou.', 'danger', 12000, error);
    }
}

// this method checks if the value passed to it is indeed an object, not an Array or null as other type checks might
// return true when checking if an array is an object
export function isObject(value) {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
}

/**
 * Opens a PDF in a new tab.
 * @param {string} url - The URL of the PDF.
 */
export function openPDFInNewTab(url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 * Creates a PDF file for the offer.
 * @param {string} offerId - The ID of the offer.
 * @param {number} type - The type of the PDF (0 - with price, 1 - without price, 2 - general offer).
 */
export function createPDFOffer(offerId, type, sorting) {
    const pdfLink = `${process.env.VUE_APP_API_BASE_URL}/api/pdf/offer/${offerId}/${type}/${sorting}/`;
    openPDFInNewTab(pdfLink);
}

/**
 * Creates a PDF file for the contract and opens it in a new tab.
 * @param {string} clientId - The ID of the client.
 * @param {int} documentType - The type of the document that needs to be generated.
 * @param contractValue
 */
export function createPDFContract(clientId, documentType, contractValue) {
    const pdfLink = `${process.env.VUE_APP_API_BASE_URL}/api/pdf/generate_pdf_contract/?client_id=${clientId}&document_type=${documentType}&contract_value=${contractValue}`;
    openPDFInNewTab(pdfLink);
}

/**
 * Creates a PDF file for the summary and opens it in a new tab.
 * @param {string} summaryId - The ID of the client.
 */
export function createPDFSummary(summaryId) {
    const pdfLink = `${process.env.VUE_APP_API_BASE_URL}/api/pdf/summary/${summaryId}/`;
    openPDFInNewTab(pdfLink);
}

/**
 * Creates a PDF file for the dimensioning of the location.
 * @param {string} offerId - The ID of the offer.
 * @param {string} locationId - The ID of the location.
 * @param {number} sorting - The sorting type (0 - no sorting, 1 - by air type, 2 - by floor).
 */
export function createPDFDimensioning(offerId, locationId, sorting) {
    const pdfLink = `${process.env.VUE_APP_API_BASE_URL}/api/pdf/dimensioning/${offerId}/${locationId}/${sorting}`;
    openPDFInNewTab(pdfLink);
}

/**
 * Fetches price lists from the server and stores them in sessionStorage.
 * The function makes an asynchronous API call to retrieve the price lists.
 * If successful, it stores the data in sessionStorage under the key 'priceLists'.
 * @returns {Promise<Object[]|undefined>} A promise that resolves to an array of price list objects, or undefined if an error occurs.
 */
export async function fetchPriceLists(context, priceListType) {
    try {
        const response = await apiClient.get('/api/price_lists', {
            params: {
                type: priceListType,
            }
        });

        if (response.status === 200) {
            const priceLists = response.data;
            sessionStorage.setItem('priceLists', JSON.stringify(priceLists));
            return priceLists;
        } else {
            throw new Error(`Unexpected response status: ${response.status}`);
        }
    } catch (error) {
        showAlertModal(context.$store, 'A apărut o eroare la fetch-ul listelor de preț. Vă rugăm să încercați din nou.', 'danger', 12000, error);
        console.error('Error fetching price lists:', error);
    }
}

/**
 * Fetches product data from the server.
 * This function makes an asynchronous API call to retrieve products and their associated price list ID.
 * It assumes the response will contain an object with 'items' and 'price_list_id' properties.
 *
 * @returns {Promise<{items: Object[], priceListId: string}>} A promise that resolves to an object containing an array of items and a price list ID.
 * @throws Will throw an error if the API call is unsuccessful.
 */
export async function fetchProducts(context, priceListType) {

    let priceListProducts = JSON.parse(sessionStorage.getItem('PRICE_LIST_PRODUCTS'));
    if (!priceListProducts || priceListType !== priceListProducts.type) {
        try {
            const response = await apiClient.get('/api/price_lists/items/', {
                params: {
                    type: priceListType,
                }
            });
            if (!response || !response.data) {
                throw new Error('Failed to fetch product data');
            }

            sessionStorage.setItem("PRICE_LIST_PRODUCTS", JSON.stringify(response.data));

            return {
                items: response.data.items,
                price_list_id: response.data.price_list_id,
            };
        } catch (error) {
            console.error('Error fetching product data:', error);
            showAlertModal(context.$store, 'A apărut o eroare la fetch-ul produselor. Vă rugăm să încercați din nou.', 'danger', 12000, error);
            throw error;
        }
    } else {
        const priceListId = priceListProducts.price_list_id;
        try {
            const response = await apiClient.get(`/api/price_lists/${priceListId}/modified/`);
            if (!response || !response.data) {
                throw new Error('Failed to fetch modified date');
            }

            const backendModifiedDate = response.data.modified;
            const sessionModifiedDate = priceListProducts.modified;

            if (backendModifiedDate !== sessionModifiedDate) {
                const updatedResponse = await apiClient.get(`/api/price_lists/items/`);
                if (!updatedResponse || !updatedResponse.data) {
                    throw new Error('Failed to fetch updated product data');
                }

                sessionStorage.setItem("PRICE_LIST_PRODUCTS", JSON.stringify(updatedResponse.data));

                return {
                    items: updatedResponse.data.items,
                    price_list_id: updatedResponse.data.price_list_id,
                };
            } else {
                return priceListProducts;
            }
        } catch (error) {
            console.error('Error checking modified date or fetching product data:', error);
            showAlertModal(context.$store, 'A apărut o eroare la fetch-ul produselor. Vă rugăm să încercați din nou.', 'danger', 12000, error);
            throw error;
        }
    }
}

/**
 * Fetches the products associated with a specific price list by its ID.
 * Sends an API request to retrieve the products for the provided price list.
 *
 * @async
 * @function fetchPriceListProducts
 * @param {number|string} priceListId - The ID of the price list to fetch products for.
 * @param {object} context - The Vue.js context or component instance, used to access the store for error handling.
 * @returns {Promise<Array|undefined>} A promise that resolves to the list of products for the price list, or undefined if an error occurs.
 * @throws {Error} Will throw an error if the API request fails.
 */
export async function fetchPriceListProducts(priceListId, context) {
    try {
        const response = await apiClient.get(`/api/price_lists/${priceListId}/items/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching price list products:', error);
        showAlertModal(context.$store, 'A apărut o eroare la fetch-ul produselor pe baza unei liste de preț. Vă rugăm să încercați din nou.', 'danger', 12000, error);
        // Optionally, you can throw the error to be handled by the calling function
        throw error;
    }
}

/**
 * Retrieves client information based on the provided client ID.
 *
 * This function makes an asynchronous HTTP GET request to fetch client data. It expects
 * a client ID and returns the client's information if found. In case the client is not
 * found (404 error), it redirects to the "Page Not Found" route. For other errors, it
 * logs the error message to the console and throws an exception.
 *
 * @param {number|string} clientId - The unique identifier of the client.
 * @param context
 * @returns {Promise<Object|null>} A promise that resolves to the client's information object or null if not found.
 * @throws {Error} Throws an error for any network or server issues, except for 404 Not Found.
 */
export async function getClientInfo(clientId, context) {
    try {
        const clientResponse = await apiClient.get(`/api/get_client/${clientId}/`);
        return clientResponse.data.client;
    } catch (e) {
        if (e.response && e.response.status === 404) {
            // Push to the error route with a state indicating a missing client
            router.push({
                path: "/:pathMatch(.*)*",
                query: {error: 'client-not-found', clientId: clientId}
            });
            return null;
        } else {
            console.error('Error fetching client data:', e);
            showAlertModal(context.$store, 'A apărut o eroare la fetch-ul detaliilor clientului. Vă rugăm să încercați din nou.', 'danger', 12000, e);
            throw e; // Re-throw the error for caller to handle
        }
    }
}

/**
 * Fetches location details by location ID.
 *
 * This function first attempts to retrieve the location details from sessionStorage.
 * If not found in sessionStorage, it makes an asynchronous HTTP GET request to fetch
 * the location details from the server. If the location is found, it returns the location data.
 * In case the location is not found (404 error), it redirects to a "Page Not Found" route.
 * For other types of errors, it logs the error message and re-throws the error.
 *
 * @param {number|string} locationId - The unique identifier of the location.
 * @param context
 * @returns {Promise<Object|null>} A promise that resolves to the location's data object or null if the location is not found.
 * @throws {Error} Throws an error for any network or server issues, except for 404 Not Found.
 */
export async function fetchLocationById(locationId, context) {
    let sessionLocation = await JSON.parse(sessionStorage.getItem('location' + locationId))
    if (sessionLocation) {
        return sessionLocation
    } else {
        try {
            const locationResponse = await apiClient.get(`/api/location/${locationId}/`);
            return locationResponse.data;
        } catch (e) {
            if (e.response && e.response.status === 404) {
                router.push({
                    path: "/:pathMatch(.*)*",
                    query: {error: 'location-not-found', locationId: locationId}
                });
                return null;
            } else {
                showAlertModal(context.$store, 'A apărut o eroare la fetch-ul locatiei. Vă rugăm să încercați din nou.', 'danger', 12000, e);
                console.error('Error fetching location data:', e);
                throw e; // Re-throw the error for caller to handle
            }
        }
    }
}

/**
 * Fetches documents associated with a specific location by its ID.
 * Sends an API request to retrieve the documents for the provided location.
 *
 * @async
 * @function fetchDocuments
 * @param {number|string} locationId - The ID of the location to fetch documents for.
 * @param {object} context - The Vue.js context or component instance, used to access the store for error handling.
 * @returns {Promise<Array|undefined>} A promise that resolves to the list of documents for the location, or undefined if an error occurs.
 */
export async function fetchDocuments(locationId, context) {
    try {
        const docResponse = await apiClient
            .get("/api/locations/" + locationId + "/documents/")
        return docResponse.data;
    } catch (error) {
        showAlertModal(context.$store, 'A apărut o eroare la fetch-ul documentelor locatiei. Vă rugăm să încercați din nou.', 'danger', 12000, error);
        console.error(error);
    }
}

/**
 * Fetches an offer along with its items based on the provided offer ID.
 *
 * This function makes an asynchronous HTTP GET request to retrieve an offer and its associated items.
 * If the offer is found, it returns the offer data. In case the offer is not found (404 error),
 * it redirects to a "Page Not Found" route. For other types of errors, it logs the error and re-throws it.
 *
 * @param {number|string} offerId - The unique identifier of the offer.
 * @returns {Promise<Object|null>} A promise that resolves to the offer's data object or null if the offer is not found.
 * @throws {Error} Throws an error for any network or server issues, except for 404 Not Found.
 */
export async function fetchOfferWithItemsById(offerId, context) {
    try {
        const offerItemsResponse = await apiClient.get(`/api/offer_with_items/${offerId}/`);
        return offerItemsResponse.data;
    } catch (e) {
        if (e.response && e.response.status === 404) {
            router.push({
                path: "/:pathMatch(.*)*",
                query: {error: 'offer-not-found', offerId: offerId}
            });
            return null;
        } else {
            showAlertModal(context.$store, 'A apărut o eroare la fetch-ul ofertei și a produselor acesteia. Vă rugăm să încercați din nou.', 'danger', 12000, e);
            console.error('Error fetching offer data:', e);
            throw e;
        }
    }
}

/**
 * Triggers an alert modal in the application.
 * This function commits a series of mutations to the Vuex store to configure and show an alert modal.
 * @param {Object} context - The Vuex context object, which provides access to store properties and commit mutations.
 * @param {string} message - The message to be displayed in the alert modal.
 * @param {string} type - The type of the alert (e.g., 'success', 'error', 'warning').
 * @param {number} duration - The duration in milliseconds for which the alert should be displayed.
 * @param errorDetails
 */
export function showAlertModal(context, message, type, duration, errorDetails = '') {
    if (type === 'danger') {
        // Clear any existing alert and queue
        context.commit('clearCurrentAlert');
        context.dispatch('configureAlert', {message, type, duration, visible: true, errorDetails});
    } else {
        const currentAlertType = context.state.alert.type;
        if (currentAlertType === 'danger' && context.state.alert.show) {
            // Queue the alert if a danger alert is currently being displayed
            context.commit('queueAlert', {message, type, duration, errorDetails});
        } else {
            // Show the alert immediately
            context.dispatch('configureAlert', {message, type, duration, visible: true, errorDetails});
        }
    }
}


/**
 * Formats a number according to the locale and currency preferences.
 *
 * @param {Object} context - The Vue component context to access global state.
 * @param {number} number - The number to be formatted.
 * @param {boolean} [currency=true] - Whether to display currency symbol.
 * @param {number} [decimals=2] - The number of decimal places to display.
 * @returns {string} The formatted number string with or without currency symbol.
 */
export function formatNumber(context, number, currency = true, decimals = 2) {
    let currencyShort = currency ? context.$store.state.currency[context.$store.state.locale].code : '';
    return new Intl.NumberFormat(context.$store.state.locale, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(number) + currencyShort;
}

/**
 * Calculates the RGB color for a given value based on how far it is from the minimum or maximum limit.
 * The color transitions smoothly between red, green, and blue based on the value's percentage in the range.
 *
 * @function getTextScaleColor
 * @param {number} value - The current value to calculate the color for.
 * @param {number} maxLimit - The maximum limit of the value range.
 * @param {boolean} [ascending=true] - If true, colors will transition from lower to higher values; otherwise, the opposite.
 * @param {number} [minLimit=0] - The minimum limit of the value range. Default is 0.
 * @returns {string} A CSS-compatible RGB color string, representing the color based on the value's proximity to the limits.
 */
export function getTextScaleColor(value, maxLimit, ascending = true, minLimit = 0) {
    let red = Object.freeze({min: 129, max: 255, range: 255 - 129});
    let green = Object.freeze({min: 30, max: 212, range: 212 - 30});
    let blue = Object.freeze({min: 26, max: 60, range: 60 - 26});

    //Calculating percent of value from min-max range.
    let valuePercent = (value - minLimit) / (maxLimit - minLimit) * 100
    valuePercent = valuePercent < 0 ? 0 : valuePercent > 100 || maxLimit === minLimit ? 100 : valuePercent;

    // Calculating 2% of each color range and multiplying it with the valuePercent based on ascending boolean
    //The 2% raise allows each color to reach 100% of range when valuePercent reaches 50% of its range
    let redValue = red.range / 100 * 2 * (ascending ? valuePercent : valuePercent - 50);
    let greenValue = green.range / 100 * 2 * (ascending ? valuePercent - 50 : valuePercent);
    //Blue value is calculated by 1% raise
    let blueValue = blue.range / 100 * valuePercent;

    // Calculating total color value based on ascending boolean
    let redTotal = ascending ? red.min + redValue : red.max - redValue;
    let greenTotal = ascending ? green.max - greenValue : green.min + greenValue;
    let blueTotal = ascending ? blue.min + blueValue : blue.max - blueValue;

    // Limiting color values to their original min/max limits
    redTotal = redTotal < red.min ? red.min : redTotal > red.max ? red.max : redTotal;
    greenTotal = greenTotal < green.min ? green.min : greenTotal > green.max ? green.max : greenTotal;
    blueTotal = blueTotal < blue.min ? blue.min : blueTotal > blue.max ? blue.max : blueTotal;

    return `rgb(${redTotal},${greenTotal},${blueTotal})`
}

export function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

export function normalizeString(str) {
    const map = {
        'ă': 'a',
        'â': 'a',
        'î': 'i',
        'ș': 's',
        'ț': 't',
        'ȃ': 'a',
        'Ă': 'A',
        'Â': 'A',
        'Î': 'I',
        'Ș': 'S',
        'Ț': 'T',
        'Ȃ': 'A',
    };
    return str.replace(/ă|â|î|ș|ț|ȃ|Ă|Â|Î|Ș|Ț|Ȃ/g, match => map[match]);
}

/**
 * Sorts products by their product code. This method can handle both segmented (e.g., "106.01.06")
 * and non-segmented (e.g., "85060604") product codes. If both codes are non-segmented, they are compared
 * as strings. If both are segmented, it compares each segment numerically. If one code is segmented and the
 * other is not, segmented codes are either prioritized first or last based on the sort direction.
 *
 * @param {Object} a - The first product object to compare.
 * @param {Object} b - The second product object to compare.
 * @param {number} direction - The direction of the sort. Positive for ascending, negative for descending.
 * @returns {number} A negative number if `a` should come before `b`, a positive number if `a` should come after `b`,
 * or 0 if they are considered equal for sorting purposes.
 */
export function sortByProductCode(a, b, direction) {
    // Check if both codes are in a segmented format
    const isASegmented = a.product_code.includes(".");
    const isBSegmented = b.product_code.includes(".");

    if (!isASegmented && !isBSegmented) {
        // If both codes are non-segmented, compare them as strings
        return direction * a.product_code.localeCompare(b.product_code);
    } else if (isASegmented && isBSegmented) {
        // If both codes are segmented, proceed with the original numeric comparison
        const aParts = a.product_code.split(".").map(Number);
        const bParts = b.product_code.split(".").map(Number);
        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            // Compare each segment; treat missing segments as 0 for comparison
            const aPart = aParts[i] || 0;
            const bPart = bParts[i] || 0;
            if (aPart !== bPart) {
                return direction * (aPart - bPart);
            }
        }
    } else {
        // If one code is segmented and the other is not, prioritize segmented codes first or last based on direction
        return isASegmented ? -1 * direction : 1 * direction;
    }
    return 0;
}

/**
 * Retrieves the permissions based on the given role.
 *
 * @function getPermissionsByRole
 * @returns {Object} An object containing permission flags grouped by categories for the given role.
 * @param roleOrGroup
 */
export function getPermissionsByRole(roleOrGroup) {
    const permissions = {
        superuser: {
            isSuperuser: true,
            isAgent: false,
            defaultPath: '/home',
            general: {
                canViewLiveApp: true,
                canFetchAllData: true,
            },
            clientManagement: {
                canEditClientAgent: true,
                canViewAllClientsListHeaders: true,
                canAddManualProduct: true,
                canViewPriceListsPage: true,
                canAddAnyOfferTypeInSummary: true,
            },
            plenumManagement: {
                canEditPlenumSize: true,
                canDeletePlenum: true,
                canAddPlenum: true,
                canExcludeRoom: true,
            },
            unitManagement: {
                canEditUnitFloors: true,
                canEditTubingDiameters: true,
                canAddUnitsAboveNeeded: true,
                canViewAllUnitModels: true,
            },
            rePowerManagement: {
                canViewRePowerAgentFilter: true,
                canViewRePowerConfirmedStatus: true,
                canViewRePowerAgentName: true,
                canViewAllRePowerClients: true,
                canEditRePowerContractNumber: true,
                canEditRePowerClientAgent: true,
                canEditRePowerClientRectificationNotes: true,
                canViewConnectionCertificateCheck: true,
                canGenerateClientsExport: true,
            },
            returnsManagement: {
                canViewReturnsAgents: true,
                canViewAllReturns: true,
                canSetOperatedToFalse: true,
                canSetOperatedToTrue: true,
                canSetInvoicedToFalse: true,
                canEditProductsQuantities: true,
                canEditProductsRealQuantities: true,
                canViewReturnCreationDate: true,
                canViewReturnTransportationCost: true,
                canEditOperatedReturn: true,
                canEditReturnReceivedStatus: true,
                canSetReturnToInvoiced: true,
                canEditContactPerson: true,
                canEditPhoneNumber: true,
                canEditRegion: true,
                canEditCity: true,
                canEditAddress: true,
                canEditPickupDate: true,
                canEditPickupTimeStart: true,
                canEditPickupTimeEnd: true,
                canEditNotes: true,
                canEditInvoiceNumbers: true,
                canAddClient: true,
                canDeleteClient: true,
                canEditClientName: true,
                canEditIsProductQuantityCorrect: true,
                canEditProductService: true,
                canAddPackage: true,
                canDeletePackage: true,
                canEditPackageLength: true,
                canEditPackageWidth: true,
                canEditPackageHeight: true,
                canEditPackageWeight: true,
                canEditPackageType: true,
                canAddPackageImages: true,
                canRemovedPackageImages: true,
                canViewReturnHistoryLog: true,
                canDeleteReturn: true,
            },
            greenHouseSolarManagement: {
                canEditClient: true,
                canEditClientOffer: true,
                canViewAllClients: true,
            },
            tabAccess: {
                canViewClientsListTab: true,
                canViewIntermediariesTab: true,
                canViewRePowerTab: true,
                canViewReturnsTab: true,
                canViewGreenHouseSolarClientsTab: true,
            },
            intermediaries: {
                canViewAllIntermediaries: true,
            },
        },
        technicalUser: {
            isSuperuser: false,
            isAgent: false,
            defaultPath: '/home',
            general: {
                canViewLiveApp: true,
                canFetchAllData: true,
            },
            clientManagement: {
                canEditClientAgent: true,
                canViewAllClientsListHeaders: true,
                canAddManualProduct: true,
                canViewPriceListsPage: false,
                canAddAnyOfferTypeInSummary: true,
                canViewHimselfAsAgent: false,
            },
            plenumManagement: {
                canEditPlenumSize: true,
                canDeletePlenum: true,
                canAddPlenum: true,
                canExcludeRoom: true,
            },
            unitManagement: {
                canEditUnitFloors: true,
                canEditTubingDiameters: true,
                canAddUnitsAboveNeeded: true,
                canViewAllUnitModels: true,
            },
            rePowerManagement: {
                canViewRePowerAgentFilter: false,
                canViewRePowerConfirmedStatus: false,
                canViewRePowerAgentName: false,
                canViewAllRePowerClients: false,
                canEditRePowerContractNumber: false,
                canEditRePowerClientAgent: false,
                canEditRePowerClientRectificationNotes: true,
                canViewConnectionCertificateCheck: true,
                canGenerateClientsExport: false,
            },
            returnsManagement: {
                canViewReturnsAgents: false,
                canViewAllReturns: false,
                canSetOperatedToFalse: false,
                canSetOperatedToTrue: false,
                canSetInvoicedToFalse: false,
                canEditProductsQuantities: false,
                canEditProductsRealQuantities: false,
                canViewReturnCreationDate: true,
                canViewReturnTransportationCost: false,
                canEditOperatedReturn: true,
                canEditReturnReceivedStatus: false,
                canSetReturnToInvoiced: false,
                canEditContactPerson: true,
                canEditPhoneNumber: true,
                canEditRegion: true,
                canEditCity: true,
                canEditAddress: true,
                canEditPickupDate: true,
                canEditPickupTimeStart: true,
                canEditPickupTimeEnd: true,
                canEditNotes: true,
                canEditInvoiceNumbers: false,
                canAddClient: true,
                canDeleteClient: true,
                canEditClientName: true,
                canEditIsProductQuantityCorrect: false,
                canEditProductService: true,
                canAddPackage: true,
                canDeletePackage: true,
                canEditPackageLength: true,
                canEditPackageWidth: true,
                canEditPackageHeight: true,
                canEditPackageWeight: true,
                canEditPackageType: true,
                canAddPackageImages: true,
                canRemovedPackageImages: true,
                canViewReturnHistoryLog: false,
                canDeleteReturn: true,
            },
            greenHouseSolarManagement: {
                canEditClient: false,
                canEditClientOffer: false,
                canViewAllClients: false,
            },
            tabAccess: {
                canViewClientsListTab: true,
                canViewIntermediariesTab: true,
                canViewRePowerTab: true,
                canViewReturnsTab: true,
                canViewGreenHouseSolarClientsTab: false,
            },
            intermediaries: {
                canViewAllIntermediaries: false,
            },
        },
        agent: {
            isSuperuser: false,
            isAgent: true,
            defaultPath: '/home',
            general: {
                canViewLiveApp: true,
                canFetchAllData: true,
            },
            clientManagement: {
                canEditClientAgent: false,
                canViewAllClientsListHeaders: false,
                canAddManualProduct: false,
                canViewPriceListsPage: false,
                canAddAnyOfferTypeInSummary: false,
                canViewHimselfAsAgent: true,
            },
            plenumManagement: {
                canEditPlenumSize: true,
                canDeletePlenum: true,
                canAddPlenum: true,
                canExcludeRoom: true,
            },
            unitManagement: {
                canEditUnitFloors: false,
                canEditTubingDiameters: false,
                canAddUnitsAboveNeeded: false,
                canViewAllUnitModels: true,
            },
            rePowerManagement: {
                canViewRePowerAgentFilter: false,
                canViewRePowerConfirmedStatus: false,
                canViewRePowerAgentName: false,
                canViewAllRePowerClients: false,
                canEditRePowerContractNumber: false,
                canEditRePowerClientAgent: false,
                canEditRePowerClientRectificationNotes: true,
                canViewConnectionCertificateCheck: true,
                canGenerateClientsExport: false,
            },
            returnsManagement: {
                canViewReturnsAgents: false,
                canViewAllReturns: false,
                canSetOperatedToFalse: false,
                canSetOperatedToTrue: false,
                canSetInvoicedToFalse: false,
                canEditProductsQuantities: true,
                canEditProductsRealQuantities: false,
                canViewReturnCreationDate: true,
                canViewReturnTransportationCost: false,
                canEditOperatedReturn: false,
                canEditReturnReceivedStatus: false,
                canSetReturnToInvoiced: false,
                canEditContactPerson: true,
                canEditPhoneNumber: true,
                canEditRegion: true,
                canEditCity: true,
                canEditAddress: true,
                canEditPickupDate: true,
                canEditPickupTimeStart: true,
                canEditPickupTimeEnd: true,
                canEditNotes: true,
                canEditInvoiceNumbers: false,
                canAddClient: true,
                canDeleteClient: true,
                canEditClientName: true,
                canEditIsProductQuantityCorrect: false,
                canEditProductService: true,
                canAddPackage: true,
                canDeletePackage: true,
                canEditPackageLength: true,
                canEditPackageWidth: true,
                canEditPackageHeight: true,
                canEditPackageWeight: true,
                canEditPackageType: true,
                canAddPackageImages: true,
                canRemovedPackageImages: true,
                canViewReturnHistoryLog: false,
                canDeleteReturn: true,
            },
            greenHouseSolarManagement: {
                canEditClient:false,
                canEditClientOffer:true,
                canViewAllClients: false,
            },
            tabAccess: {
                canViewClientsListTab: true,
                canViewIntermediariesTab: true,
                canViewRePowerTab: true,
                canViewReturnsTab: true,
                canViewGreenHouseSolarClientsTab: true,
            },
            intermediaries: {
                canViewAllIntermediaries: false,
            },
        },
        superAgent: {
            isSuperuser: false,
            isAgent: true,
            defaultPath: '/home',
            general: {
                canViewLiveApp: true,
                canFetchAllData: true,
            },
            clientManagement: {
                canEditClientAgent: true,
                canViewAllClientsListHeaders: true,
                canAddManualProduct: false,
                canViewPriceListsPage: false,
                canAddAnyOfferTypeInSummary: false,
                canViewHimselfAsAgent: true,
            },
            plenumManagement: {
                canEditPlenumSize: true,
                canDeletePlenum: true,
                canAddPlenum: true,
                canExcludeRoom: true,
            },
            unitManagement: {
                canEditUnitFloors: false,
                canEditTubingDiameters: false,
                canAddUnitsAboveNeeded: false,
                canViewAllUnitModels: true,
            },
            rePowerManagement: {
                canViewRePowerAgentFilter: false,
                canViewRePowerConfirmedStatus: false,
                canViewRePowerAgentName: false,
                canViewAllRePowerClients: false,
                canEditRePowerContractNumber: false,
                canEditRePowerClientAgent: false,
                canEditRePowerClientRectificationNotes: true,
                canViewConnectionCertificateCheck: true,
                canGenerateClientsExport: false,
            },
            returnsManagement: {
                canViewReturnsAgents: false,
                canViewAllReturns: false,
                canSetOperatedToFalse: false,
                canSetOperatedToTrue: false,
                canSetInvoicedToFalse: false,
                canEditProductsQuantities: true,
                canEditProductsRealQuantities: false,
                canViewReturnCreationDate: true,
                canViewReturnTransportationCost: false,
                canEditOperatedReturn: false,
                canEditReturnReceivedStatus: false,
                canSetReturnToInvoiced: false,
                canEditContactPerson: true,
                canEditPhoneNumber: true,
                canEditRegion: true,
                canEditCity: true,
                canEditAddress: true,
                canEditPickupDate: true,
                canEditPickupTimeStart: true,
                canEditPickupTimeEnd: true,
                canEditNotes: true,
                canEditInvoiceNumbers: false,
                canAddClient: true,
                canDeleteClient: true,
                canEditClientName: true,
                canEditIsProductQuantityCorrect: false,
                canEditProductService: true,
                canAddPackage: true,
                canDeletePackage: true,
                canEditPackageLength: true,
                canEditPackageWidth: true,
                canEditPackageHeight: true,
                canEditPackageWeight: true,
                canEditPackageType: true,
                canAddPackageImages: true,
                canRemovedPackageImages: true,
                canViewReturnHistoryLog: false,
                canDeleteReturn: true,
            },
            greenHouseSolarManagement: {
                canEditClient: false,
                canEditClientOffer: true,
                canViewAllClients: false,
            },
            tabAccess: {
                canViewClientsListTab: true,
                canViewIntermediariesTab: true,
                canViewRePowerTab: true,
                canViewReturnsTab: true,
                canViewGreenHouseSolarClientsTab: true,
            },
            intermediaries: {
                canViewAllIntermediaries: false,
            },
        },
        greenHouseOnly: {
            isSuperuser: false,
            isAgent: false,
            defaultPath: '/clients-precontracts',
            general: {
                canViewLiveApp: false,
                canFetchAllData: false,
            },
            clientManagement: {
                canEditClientAgent: false,
                canViewAllClientsListHeaders: false,
                canAddManualProduct: false,
                canViewPriceListsPage: false,
                canAddAnyOfferTypeInSummary: false,
                canViewHimselfAsAgent: false,
            },
            plenumManagement: {
                canEditPlenumSize: false,
                canDeletePlenum: false,
                canAddPlenum: false,
                canExcludeRoom: false,
            },
            unitManagement: {
                canEditUnitFloors: false,
                canEditTubingDiameters: false,
                canAddUnitsAboveNeeded: false,
                canViewAllUnitModels: false,
            },
            rePowerManagement: {
                canViewRePowerAgentFilter: false,
                canViewRePowerConfirmedStatus: false,
                canViewRePowerAgentName: false,
                canViewAllRePowerClients: false,
                canEditRePowerContractNumber: false,
                canEditRePowerClientAgent: false,
                canEditRePowerClientRectificationNotes: false,
                canViewConnectionCertificateCheck: false,
                canGenerateClientsExport: false,
            },
            returnsManagement: {
                canViewReturnsAgents: false,
                canViewAllReturns: false,
                canSetOperatedToFalse: false,
                canSetOperatedToTrue: false,
                canSetInvoicedToFalse: false,
                canEditProductsQuantities: false,
                canEditProductsRealQuantities: false,
                canViewReturnCreationDate: false,
                canViewReturnTransportationCost: false,
                canEditOperatedReturn: false,
                canEditReturnReceivedStatus: false,
                canSetReturnToInvoiced: false,
                canEditContactPerson: false,
                canEditPhoneNumber: false,
                canEditRegion: false,
                canEditCity: false,
                canEditAddress: false,
                canEditPickupDate: false,
                canEditPickupTimeStart: false,
                canEditPickupTimeEnd: false,
                canEditNotes: false,
                canEditInvoiceNumbers: false,
                canAddClient: false,
                canDeleteClient: false,
                canEditClientName: false,
                canEditIsProductQuantityCorrect: false,
                canEditProductService: false,
                canAddPackage: false,
                canDeletePackage: false,
                canEditPackageLength: false,
                canEditPackageWidth: false,
                canEditPackageHeight: false,
                canEditPackageWeight: false,
                canEditPackageType: false,
                canAddPackageImages: false,
                canRemovedPackageImages: false,
                canViewReturnHistoryLog: false,
                canDeleteReturn: false,
            },
            greenHouseSolarManagement: {
                canEditClient: false,
                canEditClientOffer: false,
                canViewAllClients: false,
            },
            tabAccess: {
                canViewClientsListTab: false,
                canViewIntermediariesTab: false,
                canViewRePowerTab: true,
                canViewReturnsTab: false,
                canViewGreenHouseSolarClientsTab: false,
            },
            intermediaries: {
                canViewAllIntermediaries: false,
            },
        },
        repowerAndGreenHouseSolarSuperUser: {
            isSuperuser: false,
            isAgent: false,
            defaultPath: '/clients-precontracts',
            general: {
                canViewLiveApp: true,
                canFetchAllData: true,
            },
            clientManagement: {
                canEditClientAgent: false,
                canViewAllClientsListHeaders: false,
                canAddManualProduct: false,
                canViewPriceListsPage: false,
                canAddAnyOfferTypeInSummary: false,
                canViewHimselfAsAgent: false,
            },
            plenumManagement: {
                canEditPlenumSize: false,
                canDeletePlenum: false,
                canAddPlenum: false,
                canExcludeRoom: false,
            },
            unitManagement: {
                canEditUnitFloors: false,
                canEditTubingDiameters: false,
                canAddUnitsAboveNeeded: false,
                canViewAllUnitModels: false,
            },
            rePowerManagement: {
                canViewRePowerAgentFilter: true,
                canViewRePowerConfirmedStatus: true,
                canViewRePowerAgentName: true,
                canViewAllRePowerClients: true,
                canEditRePowerContractNumber: true,
                canEditRePowerClientAgent: true,
                canEditRePowerClientRectificationNotes: true,
                canViewConnectionCertificateCheck: true,
                canGenerateClientsExport: true,
            },
            returnsManagement: {
                canViewReturnsAgents: false,
                canViewAllReturns: false,
                canSetOperatedToFalse: false,
                canSetOperatedToTrue: false,
                canSetInvoicedToFalse: false,
                canEditProductsQuantities: false,
                canEditProductsRealQuantities: false,
                canViewReturnCreationDate: false,
                canViewReturnTransportationCost: false,
                canEditOperatedReturn: false,
                canEditReturnReceivedStatus: false,
                canSetReturnToInvoiced: false,
                canEditContactPerson: false,
                canEditPhoneNumber: false,
                canEditRegion: false,
                canEditCity: false,
                canEditAddress: false,
                canEditPickupDate: false,
                canEditPickupTimeStart: false,
                canEditPickupTimeEnd: false,
                canEditNotes: false,
                canEditInvoiceNumbers: false,
                canAddClient: false,
                canDeleteClient: false,
                canEditClientName: false,
                canEditIsProductQuantityCorrect: false,
                canEditProductService: false,
                canAddPackage: false,
                canDeletePackage: false,
                canEditPackageLength: false,
                canEditPackageWidth: false,
                canEditPackageHeight: false,
                canEditPackageWeight: false,
                canEditPackageType: false,
                canAddPackageImages: false,
                canRemovedPackageImages: false,
                canViewReturnHistoryLog: false,
                canDeleteReturn: false,
            },
            greenHouseSolarManagement: {
                canEditClient: true,
                canEditClientOffer: true,
                canViewAllClients: true,
            },
            tabAccess: {
                canViewClientsListTab: false,
                canViewIntermediariesTab: false,
                canViewRePowerTab: true,
                canViewReturnsTab: false,
                canViewGreenHouseSolarClientsTab: true,
            },
            intermediaries: {
                canViewAllIntermediaries: false,
            },
        },
        returnServiceSuperUser: {
            isSuperuser: false,
            isAgent: false,
            defaultPath: '/returns-tab',
            general: {
                canViewLiveApp: false,
                canFetchAllData: false,
            },
            clientManagement: {
                canEditClientAgent: false,
                canViewAllClientsListHeaders: false,
                canAddManualProduct: false,
                canViewPriceListsPage: false,
                canAddAnyOfferTypeInSummary: false,
                canViewHimselfAsAgent: false,
            },
            plenumManagement: {
                canEditPlenumSize: false,
                canDeletePlenum: false,
                canAddPlenum: false,
                canExcludeRoom: false,
            },
            unitManagement: {
                canEditUnitFloors: false,
                canEditTubingDiameters: false,
                canAddUnitsAboveNeeded: false,
                canViewAllUnitModels: false,
            },
            rePowerManagement: {
                canViewRePowerAgentFilter: false,
                canViewRePowerConfirmedStatus: false,
                canViewRePowerAgentName: false,
                canViewAllRePowerClients: false,
                canEditRePowerContractNumber: false,
                canEditRePowerClientAgent: false,
                canEditRePowerClientRectificationNotes: false,
                canViewConnectionCertificateCheck: false,
                canGenerateClientsExport: false,
            },
            returnsManagement: {
                canViewReturnsAgents: true,
                canViewAllReturns: true,
                canSetOperatedToFalse: true,
                canSetOperatedToTrue: true,
                canSetInvoicedToFalse: true,
                canEditProductsQuantities: true,
                canEditProductsRealQuantities: true,
                canViewReturnCreationDate: true,
                canViewReturnTransportationCost: true,
                canEditOperatedReturn: true,
                canEditReturnReceivedStatus: true,
                canSetReturnToInvoiced: true,
                canEditContactPerson: true,
                canEditPhoneNumber: true,
                canEditRegion: true,
                canEditCity: true,
                canEditAddress: true,
                canEditPickupDate: true,
                canEditPickupTimeStart: true,
                canEditPickupTimeEnd: true,
                canEditNotes: true,
                canEditInvoiceNumbers: true,
                canAddClient: true,
                canDeleteClient: true,
                canEditClientName: true,
                canEditIsProductQuantityCorrect: true,
                canEditProductService: true,
                canAddPackage: true,
                canDeletePackage: true,
                canEditPackageLength: true,
                canEditPackageWidth: true,
                canEditPackageHeight: true,
                canEditPackageWeight: true,
                canEditPackageType: true,
                canAddPackageImages: true,
                canRemovedPackageImages: true,
                canViewReturnHistoryLog: true,
                canDeleteReturn: true,
            },
            greenHouseSolarManagement: {
                canEditClient: false,
                canEditClientOffer: false,
                canViewAllClients: false,
            },
            tabAccess: {
                canViewClientsListTab: false,
                canViewIntermediariesTab: false,
                canViewRePowerTab: false,
                canViewReturnsTab: true,
                canViewGreenHouseSolarClientsTab: false,
            },
            intermediaries: {
                canViewAllIntermediaries: false,
            },
        },
        acquisitionsOnly: {
            isSuperuser: false,
            isAgent: false,
            defaultPath: '/returns-tab',
            general: {
                canViewLiveApp: false,
                canFetchAllData: false,
            },
            clientManagement: {
                canEditClientAgent: false,
                canViewAllClientsListHeaders: false,
                canAddManualProduct: false,
                canViewPriceListsPage: false,
                canAddAnyOfferTypeInSummary: false,
                canViewHimselfAsAgent: false,
            },
            plenumManagement: {
                canEditPlenumSize: false,
                canDeletePlenum: false,
                canAddPlenum: false,
                canExcludeRoom: false,
            },
            unitManagement: {
                canEditUnitFloors: false,
                canEditTubingDiameters: false,
                canAddUnitsAboveNeeded: false,
                canViewAllUnitModels: false,
            },
            rePowerManagement: {
                canViewRePowerAgentFilter: false,
                canViewRePowerConfirmedStatus: false,
                canViewRePowerAgentName: false,
                canViewAllRePowerClients: false,
                canEditRePowerContractNumber: false,
                canEditRePowerClientAgent: false,
                canEditRePowerClientRectificationNotes: false,
                canViewConnectionCertificateCheck: false,
                canGenerateClientsExport: false,
            },
            returnsManagement: {
                canViewReturnsAgents: true,
                canViewAllReturns: true,
                canSetOperatedToFalse: false,
                canSetOperatedToTrue: false,
                canSetInvoicedToFalse: false,
                canEditProductsQuantities: false,
                canEditProductsRealQuantities: true,
                canViewReturnCreationDate: true,
                canViewReturnTransportationCost: false,
                canEditOperatedReturn: true,
                canEditReturnReceivedStatus: true,
                canSetReturnToInvoiced: false,
                canEditContactPerson: false,
                canEditPhoneNumber: false,
                canEditRegion: false,
                canEditCity: false,
                canEditAddress: false,
                canEditPickupDate: false,
                canEditPickupTimeStart: false,
                canEditPickupTimeEnd: false,
                canEditNotes: false,
                canEditInvoiceNumbers: false,
                canAddClient: false,
                canDeleteClient: false,
                canEditClientName: false,
                canEditIsProductQuantityCorrect: true,
                canEditProductService: false,
                canAddPackage: false,
                canDeletePackage: false,
                canEditPackageLength: false,
                canEditPackageWidth: false,
                canEditPackageHeight: false,
                canEditPackageWeight: false,
                canEditPackageType: false,
                canAddPackageImages: true,
                canRemovedPackageImages: false,
                canViewReturnHistoryLog: false,
                canDeleteReturn: false,
            },
            greenHouseSolarManagement: {
                canEditClient: false,
                canEditClientOffer: false,
                canViewAllClients: false,
            },
            tabAccess: {
                canViewClientsListTab: false,
                canViewIntermediariesTab: false,
                canViewRePowerTab: false,
                canViewReturnsTab: true,
                canViewGreenHouseSolarClientsTab: false,
            },
            intermediaries: {
                canViewAllIntermediaries: false,
            },
        },
        logisticsOnly: {
            isSuperuser: false,
            isAgent: false,
            defaultPath: '/returns-tab',
            general: {
                canViewLiveApp: false,
                canFetchAllData: false,
            },
            clientManagement: {
                canEditClientAgent: false,
                canViewAllClientsListHeaders: false,
                canAddManualProduct: false,
                canViewPriceListsPage: false,
                canAddAnyOfferTypeInSummary: false,
                canViewHimselfAsAgent: false,
            },
            plenumManagement: {
                canEditPlenumSize: false,
                canDeletePlenum: false,
                canAddPlenum: false,
                canExcludeRoom: false,
            },
            unitManagement: {
                canEditUnitFloors: false,
                canEditTubingDiameters: false,
                canAddUnitsAboveNeeded: false,
                canViewAllUnitModels: false,
            },
            rePowerManagement: {
                canViewRePowerAgentFilter: false,
                canViewRePowerConfirmedStatus: false,
                canViewRePowerAgentName: false,
                canViewAllRePowerClients: false,
                canEditRePowerContractNumber: false,
                canEditRePowerClientAgent: false,
                canEditRePowerClientRectificationNotes: false,
                canViewConnectionCertificateCheck: false,
                canGenerateClientsExport: false,
            },
            returnsManagement: {
                canViewReturnsAgents: true,
                canViewAllReturns: true,
                canSetOperatedToFalse: false,
                canSetOperatedToTrue: true,
                canSetInvoicedToFalse: false,
                canEditProductsQuantities: false,
                canEditProductsRealQuantities: false,
                canViewReturnCreationDate: true,
                canViewReturnTransportationCost: true,
                canEditOperatedReturn: false,
                canEditReturnReceivedStatus: false,
                canSetReturnToInvoiced: false,
                canEditContactPerson: false,
                canEditPhoneNumber: false,
                canEditRegion: false,
                canEditCity: false,
                canEditAddress: false,
                canEditPickupDate: false,
                canEditPickupTimeStart: false,
                canEditPickupTimeEnd: false,
                canEditNotes: false,
                canEditInvoiceNumbers: false,
                canAddClient: false,
                canDeleteClient: false,
                canEditClientName: false,
                canEditIsProductQuantityCorrect: false,
                canEditProductService: false,
                canAddPackage: false,
                canDeletePackage: false,
                canEditPackageLength: false,
                canEditPackageWidth: false,
                canEditPackageHeight: false,
                canEditPackageWeight: false,
                canEditPackageType: false,
                canAddPackageImages: false,
                canRemovedPackageImages: false,
                canViewReturnHistoryLog: false,
                canDeleteReturn: false,
            },
            greenHouseSolarManagement: {
                canEditClient: false,
                canEditClientOffer: false,
                canViewAllClients: false,
            },
            tabAccess: {
                canViewClientsListTab: false,
                canViewIntermediariesTab: false,
                canViewRePowerTab: false,
                canViewReturnsTab: true,
                canViewGreenHouseSolarClientsTab: false,
            },
            intermediaries: {
                canViewAllIntermediaries: false,
            },
        },
        ordersOnly: {
            isSuperuser: false,
            isAgent: false,
            defaultPath: '/returns-tab',
            general: {
                canViewLiveApp: false,
                canFetchAllData: false,
            },
            clientManagement: {
                canEditClientAgent: false,
                canViewAllClientsListHeaders: false,
                canAddManualProduct: false,
                canViewPriceListsPage: false,
                canAddAnyOfferTypeInSummary: false,
                canViewHimselfAsAgent: false,
            },
            plenumManagement: {
                canEditPlenumSize: false,
                canDeletePlenum: false,
                canAddPlenum: false,
                canExcludeRoom: false,
            },
            unitManagement: {
                canEditUnitFloors: false,
                canEditTubingDiameters: false,
                canAddUnitsAboveNeeded: false,
                canViewAllUnitModels: false,
            },
            rePowerManagement: {
                canViewRePowerAgentFilter: false,
                canViewRePowerConfirmedStatus: false,
                canViewRePowerAgentName: false,
                canViewAllRePowerClients: false,
                canEditRePowerContractNumber: false,
                canEditRePowerClientAgent: false,
                canEditRePowerClientRectificationNotes: false,
                canViewConnectionCertificateCheck: false,
                canGenerateClientsExport: false,
            },
            returnsManagement: {
                canViewReturnsAgents: true,
                canViewAllReturns: true,
                canSetOperatedToFalse: false,
                canSetOperatedToTrue: false,
                canSetInvoicedToFalse: false,
                canEditProductsQuantities: false,
                canViewReturnCreationDate: true,
                canEditProductsRealQuantities: false,
                canViewReturnTransportationCost: false,
                canEditOperatedReturn: true,
                canEditReturnReceivedStatus: false,
                canSetReturnToInvoiced: true,
                canEditContactPerson: false,
                canEditPhoneNumber: false,
                canEditRegion: false,
                canEditCity: false,
                canEditAddress: false,
                canEditPickupDate: false,
                canEditPickupTimeStart: false,
                canEditPickupTimeEnd: false,
                canEditNotes: false,
                canEditInvoiceNumbers: true,
                canAddClient: false,
                canDeleteClient: false,
                canEditClientName: false,
                canEditIsProductQuantityCorrect: false,
                canEditProductService: false,
                canAddPackage: false,
                canDeletePackage: false,
                canEditPackageLength: false,
                canEditPackageWidth: false,
                canEditPackageHeight: false,
                canEditPackageWeight: false,
                canEditPackageType: false,
                canAddPackageImages: false,
                canRemovedPackageImages: false,
                canViewReturnHistoryLog: false,
                canDeleteReturn: false,
            },
            greenHouseSolarManagement: {
                canEditClient: false,
                canEditClientOffer: false,
                canViewAllClients: false,
            },
            tabAccess: {
                canViewClientsListTab: false,
                canViewIntermediariesTab: false,
                canViewRePowerTab: false,
                canViewReturnsTab: true,
                canViewGreenHouseSolarClientsTab: false,
            },
            intermediaries: {
                canViewAllIntermediaries: false,
            },
        },
        returnsOperator: {
            isSuperuser: false,
            isAgent: false,
            defaultPath: '/returns-tab',
            general: {
                canViewLiveApp: false,
                canFetchAllData: false,
            },
            clientManagement: {
                canEditClientAgent: false,
                canViewAllClientsListHeaders: false,
                canAddManualProduct: false,
                canViewPriceListsPage: false,
                canAddAnyOfferTypeInSummary: false,
                canViewHimselfAsAgent: false,
            },
            plenumManagement: {
                canEditPlenumSize: false,
                canDeletePlenum: false,
                canAddPlenum: false,
                canExcludeRoom: false,
            },
            unitManagement: {
                canEditUnitFloors: false,
                canEditTubingDiameters: false,
                canAddUnitsAboveNeeded: false,
                canViewAllUnitModels: false,
            },
            rePowerManagement: {
                canViewRePowerAgentFilter: false,
                canViewRePowerConfirmedStatus: false,
                canViewRePowerAgentName: false,
                canViewAllRePowerClients: false,
                canEditRePowerContractNumber: false,
                canEditRePowerClientAgent: false,
                canEditRePowerClientRectificationNotes: false,
                canViewConnectionCertificateCheck: false,
                canGenerateClientsExport: false,
            },
            returnsManagement: {
                canViewReturnsAgents: false,
                canViewAllReturns: false,
                canSetOperatedToFalse: false,
                canSetOperatedToTrue: false,
                canSetInvoicedToFalse: false,
                canEditProductsQuantities: true,
                canEditProductsRealQuantities: false,
                canViewReturnCreationDate: true,
                canViewReturnTransportationCost: false,
                canEditOperatedReturn: false,
                canEditReturnReceivedStatus: false,
                canSetReturnToInvoiced: false,
                canEditContactPerson: true,
                canEditPhoneNumber: true,
                canEditRegion: true,
                canEditCity: true,
                canEditAddress: true,
                canEditPickupDate: true,
                canEditPickupTimeStart: true,
                canEditPickupTimeEnd: true,
                canEditNotes: true,
                canEditInvoiceNumbers: false,
                canAddClient: true,
                canDeleteClient: true,
                canEditClientName: true,
                canEditIsProductQuantityCorrect: false,
                canEditProductService: true,
                canAddPackage: true,
                canDeletePackage: true,
                canEditPackageLength: true,
                canEditPackageWidth: true,
                canEditPackageHeight: true,
                canEditPackageWeight: true,
                canEditPackageType: true,
                canAddPackageImages: true,
                canRemovedPackageImages: true,
                canViewReturnHistoryLog: false,
                canDeleteReturn: true,
            },
            greenHouseSolarManagement: {
                canEditClient: false,
                canEditClientOffer: false,
                canViewAllClients: false,
            },
            tabAccess: {
                canViewClientsListTab: false,
                canViewIntermediariesTab: false,
                canViewRePowerTab: false,
                canViewReturnsTab: true,
                canViewGreenHouseSolarClientsTab: false,
            },
            intermediaries: {
                canViewAllIntermediaries: false,
            },
        },
    };


    return permissions[roleOrGroup] || {};
}

export function mergePermissions(groupPermissions) {
    const mergedPermissions = {};

    groupPermissions.forEach((groupPermission) => {
        for (const key in groupPermission) {
            // If the permission is true in any group, it remains true
            mergedPermissions[key] = mergedPermissions[key] || groupPermission[key];
        }
    });

    return mergedPermissions;
}

/**
 * Sets the user's permissions in the Vuex store based on the provided role.
 * Dispatches a Vuex action to set the permissions for the specified role.
 *
 * @function setUserPermissionsByRole
 * @param {Object} context - The Vue component context (usually `this`) for dispatching Vuex actions.
 * @param {string} role - The role of the user (e.g., 'superUser', 'technicalUser', 'superAgent', 'agent').
 * @param groups
 */
export function setUserPermissionsByRole(context, role, groups = []) {
    if (role === 'superuser') {
        context.dispatch('setUserPermissions', {
            role: 'superuser',
            permissions: getPermissionsByRole('superuser'),
        });
    } else if (role === 'technicalUser') {
        context.dispatch('setUserPermissions', {
            role: 'technicalUser',
            permissions: getPermissionsByRole('technicalUser'),
        });
    } else if (role === 'superAgent') {
        context.dispatch('setUserPermissions', {
            role: 'superAgent',
            permissions: getPermissionsByRole('superAgent'),
        });
    } else if (role === 'groupBased') {
        if (groups) {
            const groupPermissions = groups.map((group) => getPermissionsByRole(group));
            const mergedPermissions = mergePermissions(groupPermissions);

            context.dispatch('setUserPermissions', {
                role: groups.join(', '), // Combine groups for debugging
                permissions: mergedPermissions,
            });
        }
    } else {
        context.dispatch('setUserPermissions', {
            role: 'agent',
            permissions: getPermissionsByRole('agent')
        });
    }
}

/**
 * Updates the "last seen" version for the user.
 * Sends a POST request to update the user's last seen version.
 * If the request fails, an error modal is shown.
 *
 * @async
 * @function updateLastSeenUpdate
 * @param {Object} context - The Vue component context (usually `this`) for accessing Vuex store and other resources.
 * @returns {Promise<Object|undefined>} The response data if the request is successful, otherwise undefined.
 */
async function updateLastSeenUpdate(context) {
    try {
        const response = await apiClient.post('/api/update-last-seen/', {});
        return response.data;
    } catch (error) {
        if (context) {
            showAlertModal(context.$store, 'A apărut o eroare la update-ul ultimei versiuni. Vă rugăm să încercați din nou.', 'danger', 12000, error);
        }
        console.error('Error updating last seen update:', error);
    }
}

/**
 * Checks if there are any updates available for the application.
 * Sends a GET request to check for updates, and prompts the user to reload or re-login if an update is required.
 * - For a minor update, the user is asked to reload the page.
 * - For a major update, the user is required to log in again.
 *
 * @async
 * @function checkForUpdates
 * @param {Object} context - The Vue component context (usually `this`) for accessing Vuex store and other resources.
 * @returns {Promise<void>} No return value. The function handles reloading the page or redirecting based on the update type.
 */
export async function checkForUpdates(context) {
    try {
        // Check if 'isClientUser' exists and is set to 'true' in sessionStorage
        const isClientUser = sessionStorage.getItem('isClientUser') === 'true';
        if (isClientUser) {
            return; // Exit the function early if the user is a client user
        }

        const response = await apiClient.get('/api/check-for-updates/');
        const data = response.data;

        if (data.update_needed) {
            if (data.update_type === 'minor') {
                const updateNotification = confirm('Versiune nouă disponibilă. Vă rugăm reîncărcați pagina.');
                if (updateNotification) {
                    await updateLastSeenUpdate(context)
                        .then(() => {
                            window.location.reload();
                        });
                }
            } else if (data.update_type === 'major') {
                const reLoginNotification = confirm('Update important disponibil. Vă rugăm sa vă relogați pentru a realiza update-ul.');
                if (reLoginNotification) {
                    await updateLastSeenUpdate(context)
                        .then(() => {
                            window.location.href = '/login';
                        });
                    deleteAuthToken();
                    localStorage.clear();
                    sessionStorage.clear();
                }
            }
        }
    } catch (error) {
        if (context) {
            showAlertModal(context.$store, 'A apărut o eroare la verificarea ultimelor update-uri. Vă rugăm să încercați din nou.', 'danger', 12000, error);
        }
        console.error('Error checking for updates:', error);
    }
}

export async function fetchRegions() {
    try {
        const response = await fetch("/cities.json");
        const data = await response.json();
        let regions = data
            .filter((item) => item.model === "cities_light.region")
            .sort((a, b) => a.fields.name.localeCompare(b.fields.name))
            .map((item) => ({
                title: item.fields.name,
                value: item.pk,
            }));

        sessionStorage.setItem("regions", JSON.stringify(regions));

        return regions;
    } catch (error) {
        console.error("Error fetching regions:", error);
        return [];
    }
}

/**
 * Fetches and processes subregion data from a local JSON file, normalizing names by removing certain prefixes.
 * Caches the result in sessionStorage for future use.
 * Logs an error if data cannot be fetched or processed.
 */
export async function fetchSubregions() {
    try {
        const prefixesToRemove = ["Comuna", "Oraș", "Municipiul", "Oras", "Oraş"];
        const response = await fetch("/cities.json");
        const data = await response.json();
        const subregions = data
            .filter((item) => item.model === "cities_light.subregion")
            .map((item) => {
                let {name} = item.fields;
                prefixesToRemove.forEach((prefix) => {
                    const regex = new RegExp(`^${prefix}\\s+`, "i");
                    name = name.replace(regex, "");
                });
                return {
                    title: name,
                    value: item.pk,
                    region: item.fields.region,
                };
            })
            .sort((a, b) => a.title.localeCompare(b.title));
        sessionStorage.setItem("subregions", JSON.stringify(subregions));
        return subregions;
    } catch (error) {
        console.error("Error fetching subregions:", error);
    }
}

/**
 * Fetches and processes both regions and subregions from cities.json.
 *
 * @returns {Promise<{ regions: Array, subregions: Array }>}
 */
export async function loadRegionsAndSubregions() {
    const response = await fetch("/cities.json");
    const data = await response.json();

    // or adapt to your sorting / structure needs
    const regions = data
        .filter((item) => item.model === "cities_light.region")
        .map((item) => ({
            id: item.pk,
            name: item.fields.name,
            display_name: item.fields.display_name,
        }));

    const subregions = data
        .filter((item) => item.model === "cities_light.subregion")
        .map((item) => ({
            id: item.pk,
            name: item.fields.name,
            display_name: item.fields.display_name,
            region_id: item.fields.region,
        }));

    return {regions, subregions};
}

/**
 * Finds a region's name in the given `regions` array by ID.
 *
 * @param {Array} regions - An array of region objects, e.g. [{ id: 1, name: '...' }, ...].
 * @param {number} regionId - The region ID you want to find.
 * @returns {string} - The region name if found, otherwise an empty string.
 */
export function getRegionName(regions, regionId) {
    const region = regions.find((r) => r.id === regionId || r.value === regionId);
    return region ? region.name || region.title : "";
}

/**
 * Finds a subregion's name in the given `subregions` array by ID.
 *
 * @param {Array} subregions - An array of subregion objects, e.g. [{ id: 1, name: '...' }, ...].
 * @param {number} subregionId - The subregion ID you want to find.
 * @returns {string} - The subregion name if found, otherwise an empty string.
 */
export function getSubregionName(subregions, subregionId) {
    const sr = subregions.find((s) => s.id === subregionId || s.value === subregionId);
    return sr ? sr.name || sr.title : "";
}

/**
 * Sets default session values, including client types, for use across the application.
 * Saves the client types to session storage to standardize dropdowns or other UI components.
 */
export function setDefaultValues() {
    //Set default values:
    let clientTypes = [
        {value: 1, text: 'Client Final'},
        {value: 2, text: 'Instalator'},
        {value: 3, text: 'Constructor'},
        {value: 4, text: 'Dezvoltator'},
        {value: 5, text: 'Magazin'},
        {value: 6, text: 'Agent'}
    ]
    sessionStorage.setItem('clientTypes', JSON.stringify(clientTypes))
}
