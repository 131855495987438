<template>
    <a href="#" id="section1" ref="section1"></a>
    <CreateIntermediary
        @intermediary-created="onIntermediaryCreated"
    />

    <a href="#" id="section2" ref="section2"></a>
    <v-card class="my-4">
        <v-toolbar density="compact">
            <v-toolbar-title>
                Listă intermediari
            </v-toolbar-title>
            <v-text-field
                v-model="search"
                label="Caută intermediar:"
                hide-details
            ></v-text-field>
        </v-toolbar>
        <v-data-table
            v-show="filteredIntermediaries.length > 0"
            :headers="tableHeaders"
            :items="filteredIntermediaries"
            item-key="id"
            :search="search"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="openEditIntermediary(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="openDelete(item)">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:[`item.is_global`]="{ item }">
                {{ item.is_global ? 'Da' : 'Nu' }}
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialog"
            width="800px"
        >
            <v-card>
                <v-card-title>
                    Editează intermediarul
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="4" sm="12">
                                <v-text-field
                                    density="compact"
                                    :variant="this.$store.state.vuetifyFieldType"
                                    clearable
                                    v-model="selectedIntermediary.name"
                                    label="Nume"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="12">
                                <v-text-field
                                    density="compact"
                                    :variant="this.$store.state.vuetifyFieldType"
                                    clearable
                                    v-model="selectedIntermediary.phone"
                                    :error-messages="phoneError"
                                    label="Telefon"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="12">
                                <v-text-field
                                    density="compact"
                                    :variant="this.$store.state.vuetifyFieldType"
                                    clearable
                                    v-model="selectedIntermediary.email"
                                    label="E-mail"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="12">
                                <v-checkbox
                                    v-model="selectedIntermediary.is_global"
                                    label="Disponibil pentru toți agenții"
                                    dense
                                    hide-details
                                ></v-checkbox>

                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        variant="text"
                        @click="closeEditIntermediary"
                    >
                        Anulează
                    </v-btn>
                    <v-btn
                        color="primary"
                        variant="text"
                        @click="updateIntermediary"
                    >
                        Salvează
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" width="auto">
            <v-card>
                <v-card-title class="text-h5">Ești sigur că vrei să ștergi intermediarul?</v-card-title>
                <v-spacer></v-spacer>
                <v-container class="d-flex justify-content-end">
                    <v-btn
                        color="primary"
                        variant="text"
                        style="color: white;"
                        @click="closeDelete">Nu
                    </v-btn>
                    <v-btn
                        color="danger"
                        variant="text"
                        style="color: white;"
                        @click="deleteIntermediary">Da
                    </v-btn>
                </v-container>
                <v-spacer></v-spacer>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";
import {fetchData, showAlertModal} from "@/utils/utils";
import CreateIntermediary from "@/components/clients/CreateIntermediary.vue";
import {mapGetters} from "vuex";

export default {
    name: "IntermediateList",
    components: {CreateIntermediary},
    data() {
        return {
            intermediaries: JSON.parse(sessionStorage.getItem("intermediaries")) || [],
            showContent: true,
            newIntermediary: {
                name: "",
                phone: "",
                email: ""
            },
            search: "",
            sortKey: "name",
            sortOrder: "asc",
            editingIntermediaryId: null,
            error: '',
            sidebarLinks: [
                {
                    name: 'Creare intermediar',
                    link: '#section1',
                    id: 'section1'
                },
                {
                    name: 'Listă intermediari',
                    link: '#section2',
                    id: 'section2'
                },
            ],
            dialog: false,
            dialogDelete: false,
            selectedIntermediary: null,
            phoneError: "",
        };
    },
    computed: {
        ...mapGetters(['userPermissions', 'userRole']),
        tableHeaders() {
            const baseHeaders = [
                {title: 'Nume', key: 'name'},
                {title: 'Telefon', key: 'phone'},
                {title: 'E-mail', key: 'email'},
            ];

            if (this.userRole.includes('superuser')) {
                baseHeaders.push({
                    title: 'Intermediar global',
                    key: 'is_global',
                });
            }

            baseHeaders.push({title: 'Acțiuni', key: 'actions'});

            return baseHeaders;
        },
        filteredIntermediaries() {
            return this.intermediaries
                .filter((intermediary) =>
                    intermediary.name.toLowerCase().includes(this.search.toLowerCase())
                )
                .sort((a, b) => {
                    let order = this.sortOrder === "asc" ? 1 : -1;
                    if (this.sortKey === "name") {
                        return order * a.name.localeCompare(b.name, undefined, {sensitivity: "base"});
                    } else {
                        let phoneA = parseInt(a.phone.replace(/[^\d]/g, ""));
                        let phoneB = parseInt(b.phone.replace(/[^\d]/g, ""));
                        return order * (phoneA - phoneB);
                    }
                });
        },
    },
    mounted() {
        this.$store.state.sidebarLinks = this.sidebarLinks;
    },
    methods: {
        onIntermediaryCreated() {
            // We can simply re-fetch from sessionStorage or call your fetchData utility
            fetchData("intermediaries", this).then(() => {
                this.intermediaries = JSON.parse(sessionStorage.getItem("intermediaries"));
            });
        },
        /**
         * Opens the delete confirmation dialog for the selected intermediary.
         *
         * @param {Object} intermediary - The intermediary object to be deleted.
         */
        openDelete(intermediary) {
            this.selectedIntermediary = intermediary
            this.dialogDelete = true;
        },
        /**
         * Closes the delete confirmation dialog without performing any action.
         */
        closeDelete() {
            this.dialogDelete = false;
        },
        /**
         * Opens the edit dialog for the selected intermediary, setting its details in the form fields.
         *
         * @param {Object} item - The intermediary object to edit.
         */
        openEditIntermediary(item) {
            this.selectedIntermediary = JSON.parse(JSON.stringify(item));
            this.dialog = true;
        },
        /**
         * Closes the edit dialog and clears the form fields for editing intermediaries.
         */
        closeEditIntermediary() {
            this.dialog = false;
        },
        /**
         * Deletes the selected intermediary by sending a request to the backend.
         * Removes the intermediary from the list and updates the stored intermediaries upon success.
         *
         * @async
         */
        async deleteIntermediary() {
            try {
                let intermediary = this.selectedIntermediary
                await apiClient.delete(`/api/delete_intermediary/${intermediary.id}/`);
                this.intermediaries = this.intermediaries.filter(intermediate => intermediate.id !== intermediary.id);
                await fetchData('intermediaries', this)
                sessionStorage.setItem("intermediaries", JSON.stringify(this.intermediaries));
                this.dialogDelete = false;
            } catch (error) {
                showAlertModal(this.$store, 'A apărut o eroare la ștergerea intermediarului. Vă rugăm reîncercați.', 'danger', 12000, error);
                console.error("Error deleting intermediate:", error);
            }
        },
        /**
         * Updates the details of the selected intermediary by sending the updated data to the backend.
         * Refreshes the list of intermediaries and closes the edit dialog upon success.
         *
         * @async
         */
        async updateIntermediary() {
            try {
                // Clear any old phone error on each save attempt
                this.phoneError = "";

                let intermediary = this.selectedIntermediary;
                await apiClient.put(`/api/update_intermediary/${intermediary.id}/`, intermediary);

                // If success:
                await fetchData('intermediaries', this);
                this.intermediaries = JSON.parse(sessionStorage.getItem("intermediaries"));
                this.closeEditIntermediary();
                this.editingIntermediaryId = null;

            } catch (error) {
                console.error("Error updating intermediary:", error);

                // Default error message (if you want a fallback dialog or console message)
                let displayMessage = 'A apărut o eroare la salvarea intermediarului. Vă rugăm reîncercați.';

                // Check if it's a 400 with a server-provided message
                if (error.response && error.response.status === 400 && error.response.data) {
                    // If the message looks phone-related, set phoneError
                    this.phoneError = error.response.data.message;
                    // Or if needed, you might parse further to check if it's specifically a phone error
                } else {
                    // If it's not a 400, or has no data, or another status code:
                    showAlertModal(this.$store, displayMessage, 'danger', 12000, error);
                }
            }
        },
    }
};
</script>

<style scoped>
/* Add your styles here */
</style>
