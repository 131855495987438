<template>
  
    <v-autocomplete
      v-model="selectedValues"
      :items="items"
      item-title="text"
      item-value="value"
      :label="title"
      multiple
      clearable
      chips
      closable-chips
      variant="outlined"
      density="compact"
      hide-details
      @update:modelValue="emitSelectedValues"
    ></v-autocomplete>
</template>

<script>
export default {
  name: "AutocompleteFilter",
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
    },
  },
  computed: {
    selectedValues: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    emitSelectedValues() {
      this.$emit("filter-changed", this.selectedValues);
    },
  },
};
</script>
<style>
</style>
