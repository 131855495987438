<template>
  <div>
    <BreadCrumbsWithButtons
      :buttons="buttons"
      :pdfOptions="pdfOptions.simple"
    />
    <VacuumSetup
      v-if="location"
      :location="location"
      :offer-id="offerId"
      @update-vacuum-setup="handleVacuumSetupUpdate"
    />
    <ProductTable
      v-if="loadProductTable"
      :offer="offer"
      :offered-products="offeredProducts ?? []"
      :offer-type="offerType"
      :headers="productTableHeaders"
      :special-products="specialProducts"
      :customerType="
        offerInformation.selectedCustomerType
          ? offerInformation.selectedCustomerType
          : offer?.selected_customer_type
      "
      @sort-pdf-by-number="setPDFCustomSorting"
      @update-products="updateOfferProducts"
      @update-offer="updateOfferData"
      @selected-price-list="updateOfferPriceList"
      :price-list-type="this.$store.state.PRICE_LIST_TYPES.GENERAL.value"
      :showAddServicePIF="false"
    >
    </ProductTable>
  </div>
</template>

<script>
import {
  createPDFDimensioning,
  createPDFOffer,
  showAlertModal,
} from "@/utils/utils";
import offerMixin from "@/mixins/offerMixin";
import VacuumSetup from "@/components/offer/VacuumSetup.vue";
import emitter from "@/utils/emitter";
import { products } from "@/Vacuum/VacuumProducts";
import ProductTable from "@/components/offer/ProductTable.vue";
import {
  createEasySpecialRequirements,
  createEcoflexSetRequirements,
  createVacuumRequirements,
} from "@/Vacuum/VacuumLogic.js";
import BreadCrumbsWithButtons from "@/components/common/BreadCrumbsWithButtons.vue";
import { cloneDeep } from "lodash";

export default {
  name: "OfferVacuum",
  components: { BreadCrumbsWithButtons, ProductTable, VacuumSetup },
  data() {
    return {
      sidebarLinks: [
        {
          name: "Informații",
          link: "#section1",
          id: "section1",
        },
        {
          name: "Produse",
          link: "#section2",
          id: "section2",
        },
      ],
      pdfOptions: {
        simple: [
          {
            text: "Ofertă cu preț",
            onClick: () => createPDFOffer(this.offerId, 0, this.sortingPDF),
            disabled: () => !this.offerId,
            color: "primary",
          },
          {
            text: "Ofertă fără preț",
            onClick: () => createPDFOffer(this.offerId, 1, this.sortingPDF),
            disabled: () => !this.offerId,
            color: "primary",
          },
        ],
        vmc: [
          {
            text: "Ofertă cu preț",
            onClick: () => createPDFOffer(this.offerId, 0, 0),
            disabled: () => !this.offerId,
            color: "primary",
          },
          {
            text: "Ofertă fără preț",
            onClick: () => createPDFOffer(this.offerId, 1, 0),
            disabled: () => !this.offerId,
            color: "primary",
          },
          {
            text: "Ofertă generală",
            onClick: () => createPDFOffer(this.offerId, 2, 0),
            disabled: () => !this.offerId,
            color: "primary",
          },
          {
            text: "Dimensionare",
            onClick: () =>
              createPDFDimensioning(this.offerId, this.locationId, 0),
            disabled: () => !this.offerId,
            color: "primary",
          },
        ],
      },
      productTableHeaders: [
        {
          title: "Nr.",
          key: "number",
          align: "center",
          show: false,
          width: "100px",
        },
        {
          title: "Cod articol",
          key: "product_code",
          align: "center",
          show: true,
          width: "140px",
        },
        {
          title: "Nume articol",
          key: "product_name",
          align: "start",
          show: true,
        },
        {
          title: "Categorie",
          key: "assigned_sub_category",
          align: "center",
          show: true,
        },
        {
          title: "Cantitate",
          key: "quantity",
          align: "center",
          show: true,
          width: "130px",
        },
        {
          title: "UM",
          key: "measurement_unit",
          align: "center",
          show: true,
          width: "70px",
        },
        {
          title: "Preț listă cu TVA",
          key: "price",
          align: "end",
          show: true,
        },
        {
          title: "Discount",
          key: "discount",
          align: "center",
          show: true,
          width: "120px",
        },
        {
          title: "Preț final cu TVA",
          key: "final_price",
          align: "end",
          show: true,
        },
        {
          title: "Valoare finală cu TVA",
          key: "final_total_price",
          align: "end",
          show: true,
        },
      ],
      offerType: { type: "ASPIRARE", productCategories: ["ASPIRARE"] },
      vacuumSetup: {},
      requirementsObj: {
        requirements: [],
      },
      resultedProducts: [],
      finalResultedProducts: [],
      specialProducts: [],
      requirementsGetters: [
        "createVacuumRequirements",
        "createEcoflexSetRequirements",
        "createEasySpecialRequirements",
      ],
      vacuumProducts: products,
      products: [],
    };
  },
  mixins: [offerMixin],
  async created() {
    await this.getRecommendedProducts(this.offerType.type);
  },
  async mounted() {
    this.$store.state.sidebarLinks = this.sidebarLinks;
    const priceListItems = await this.fetchPriceListProducts();
    this.products = this.reassignSubCategories(
      this.setZeroQtyForBackendProducts(priceListItems.items)
    );
  },
  methods: {
    createVacuumRequirements,
    createEcoflexSetRequirements,
    createEasySpecialRequirements,
    /**
     * Handles the update of the vacuum setup.
     * This method deep clones the provided vacuum setup object and updates the component's state.
     *
     * @param {Object} vacuumSetup - The vacuum setup object to update.
     */
    handleVacuumSetupUpdate(vacuumSetup) {
      this.vacuumSetup = JSON.parse(JSON.stringify(vacuumSetup));
    },
    /**
     * Adds a vacuum unit to the resulted products array.
     * If the selected vacuum unit has associated requirements, they are added to the requirements object.
     */
    addVacuumUnit() {
      if (this.vacuumSetup.selected_vacuum_unit.product_code) {
        let matchingProduct = this.getUnitProduct(
          this.vacuumSetup.selected_vacuum_unit.product_code,
          this.vacuumProducts
        );
        if (matchingProduct) {
          matchingProduct.quantity = 1;
          this.resultedProducts.push(matchingProduct);

          // Check if requirements array exists and has items
          if (
            matchingProduct.requirements &&
            matchingProduct.requirements.length > 0
          ) {
            // Push each item in unitProduct.requirements to this.requirementsObj.requirements
            matchingProduct.requirements.forEach((requirement) => {
              requirement.processed = false;
              this.requirementsObj.requirements.push(requirement);
            });
          }
        }
      }
    },
    /**
     * Processes the required products based on the selected vacuum unit.
     * This method emits an event to trigger an overlay, then processes the requirements, calculates products,
     * adds recommended products, processes product conditions, and matches the final resulted products.
     * Finally, it updates the offered products and emits an event to set showOnlyOfferQty in the ProductTable.
     */
    processRequiredProducts() {
      if (
        this.vacuumSetup.selected_vacuum_unit &&
        this.vacuumSetup.selected_vacuum_unit.product_code &&
        this.vacuumSetup.selected_installation_type
      ) {
        const hasValidFloorArea = this.vacuumSetup.floor_areas.some(
          (floor) => floor.area > 0
        );

        if (!hasValidFloorArea) {
          showAlertModal(
            this.$store,
            `Nu există nici un etaj cu o suprafață validă.<br/>Produsele nu au putut fi calculate.`,
            "danger",
            12000
          );
          return;
        }

        emitter.emit("trigger-overlay");
        setTimeout(async () => {
          this.$store.commit("emptyVmcErrorArray");
          this.requirementsObj.requirements = [];
          this.resultedProducts = [];
          this.finalResultedProducts = [];
          this.recommendedProducts = [];
          this.addVacuumUnit();

          this.getRequirements();
          // this.addUnitProductCode(this.location.units);
          this.getProducts(this.requirementsObj, cloneDeep(products));

          this.addRecommendedProducts(this.resultedProducts, products);

          this.processProductConditions(this.resultedProducts);
          this.processResultedProducts();

          this.finalResultedProducts = this.matchProducts(
            this.products,
            this.finalResultedProducts
          );
          this.addOrUpdateOfferedProducts();

          // emitting event for setting showOnlyOfferQty on true in ProductTable
          emitter.emit("set-products-with-quantity-true");
          this.areUnitsProcessed = false;
        }, 550);
      } else if (!this.vacuumSetup.selected_installation_type) {
        showAlertModal(
          this.$store,
          `Nu există un tip de instalație selectat.<br/>Produsele nu au putut fi calculate.`,
          "danger",
          12000
        );
      } else if (
        !(
          this.vacuumSetup.selected_vacuum_unit &&
          this.vacuumSetup.selected_vacuum_unit.product_code
        )
      ) {
        showAlertModal(
          this.$store,
          `Nu există o unitate selectată.<br/>Produsele nu au putut fi calculate.`,
          "danger",
          12000
        );
      } else {
        showAlertModal(
          this.$store,
          `Produsele nu au putut fi calculate. Vă rugăm reîncercați.`,
          "danger",
          12000
        );
      }
    },
    /**
     * Retrieves the requirements by invoking methods listed in requirementsGetters.
     * The method ensures that quantities are summed for existing requirements and adds new requirements to the requirements object.
     */
    getRequirements() {
      for (let methodName of this.requirementsGetters) {
        if (this[methodName] && typeof this[methodName] === "function") {
          const newRequirements = this[methodName](this.vacuumSetup).map(
            (req) => ({
              ...req,
              processed: false,
            })
          );
          newRequirements.forEach((req) => {
            const existingReq = this.requirementsObj.requirements.find((r) =>
              this.compareRequirements(r, req)
            );
            if (existingReq) {
              existingReq.quantity =
                (existingReq.quantity || 0) +
                (req.quantity !== null && req.quantity !== undefined
                  ? req.quantity
                  : 1);
            } else {
              req.quantity =
                req.quantity !== null && req.quantity !== undefined
                  ? req.quantity
                  : 1;
              this.requirementsObj.requirements.push(req);
            }
          });
        }
      }
    },
    /**
     * Transforms the frontend vacuum setup data to the format used in the backend.
     * @param {Object} vacuumSetup - The vacuum setup data from the frontend.
     * @returns {Object} The transformed vacuum setup data for the backend.
     */
    transformVacuumSetup(vacuumSetup) {
      return {
        total_area: vacuumSetup.total_area,
        selected_connectors: vacuumSetup.selected_connectors.map(
          (connector) => ({
            type: connector.type,
            color: connector.color,
            electrical_dose: connector.electrical_dose,
            ornament: connector.ornament,
            ornament_support: connector.ornamentSupport,
            is_manually_added: connector.is_manually_added,
          })
        ),
        selected_vacuum_unit: {
          id: vacuumSetup.selected_vacuum_unit.id,
          product_code: vacuumSetup.selected_vacuum_unit.product_code,
          name: vacuumSetup.selected_vacuum_unit.name,
          type: vacuumSetup.selected_vacuum_unit.type,
          total_area: vacuumSetup.total_area,
        },
        selected_accessory_set: vacuumSetup.selected_accessory_set,
        selected_installation_type: vacuumSetup.selected_installation_type,
        floor_areas: vacuumSetup.floor_areas
          .filter((floor) => floor.floor && floor.name)
          .map((floor) => ({
            floor: floor.floor,
            name: floor.name,
            area: floor.area,
          })),
        needed_connectors: vacuumSetup.needed_connectors,
        has_ecoflex: vacuumSetup.has_ecoflex,
        last_connector_max_length: vacuumSetup.last_connector_max_length,
        selected_hose_type: vacuumSetup.selected_hose_type,
        offer: this.offerId,
      };
    },
  },
};
</script>

<style scoped></style>
