<template>
  <v-form ref="returnForm" v-model="formIsValid">
    <div>
      <BreadCrumbsWithButtons :buttons="buttons" />
      <a href="#" id="section1" ref="section1"></a>
      <!-- Return Information -->
      <v-card class="mt-3" style="z-index:400 ; overflow: visible">
        <v-toolbar>
          <v-toolbar-title>{{
            returnId ? "Editare retur" : "Creare retur"
          }}</v-toolbar-title>
          <v-toolbar-items v-if="$vuetify.display.mdAndDown">
            <v-btn
              v-if="
                userRole.includes('superuser') ||
                userRole.includes('returnServiceSuperUser')
              "
              size="large"
            >
              <v-icon>mdi-dots-vertical</v-icon>
              <v-menu activator="parent" :close-on-content-click="true">
                <v-list>
                  <v-list-item>
                    <v-switch
                      v-model="returnData.operated"
                      label="Operat"
                      color="success"
                      variant="outlined"
                      inset
                      density="compact"
                      :readonly="operatedReadonly"
                      hide-details
                      @update:modelValue="normalizeReturnStates"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item>
                    <v-switch
                      v-model="returnData.received"
                      label="Recepționat"
                      color="success"
                      variant="outlined"
                      inset
                      density="compact"
                      :readonly="receivedReadonly"
                      hide-details
                      @update:modelValue="normalizeReturnStates"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item>
                    <v-switch
                      v-model="returnData.invoiced"
                      label="Facturat"
                      color="success"
                      variant="outlined"
                      inset
                      density="compact"
                      :readonly="
                        !allClientsHaveInvoiceNumbers ||
                        !returnData.received ||
                        invoicedReadonly
                      "
                      hide-details
                      @update:modelValue="normalizeReturnStates"
                    ></v-switch>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>
          </v-toolbar-items>
          <v-toolbar-items
            v-else
            class="mr-8"
            style="height: 60%; align-self: unset"
          >
            <v-switch
              v-if="
                userRole.includes('superuser') ||
                userRole.includes('returnServiceSuperUser')
              "
              class="mr-4"
              v-model="returnData.operated"
              label="Operat"
              color="success"
              variant="outlined"
              inset
              density="compact"
              :readonly="operatedReadonly"
              hide-details
              @update:modelValue="normalizeReturnStates"
            ></v-switch>
            <v-switch
              v-if="
                userRole.includes('superuser') ||
                userRole.includes('returnServiceSuperUser')
              "
              class="mr-4"
              v-model="returnData.received"
              label="Recepționat"
              color="success"
              variant="outlined"
              inset
              density="compact"
              :readonly="receivedReadonly"
              hide-details
              @update:modelValue="normalizeReturnStates"
            ></v-switch>
            <v-switch
              v-if="
                userRole.includes('superuser') ||
                userRole.includes('returnServiceSuperUser')
              "
              class="mr-4"
              v-model="returnData.invoiced"
              label="Facturat"
              color="success"
              variant="outlined"
              inset
              density="compact"
              :readonly="invoicedReadonly"
              :disabled="!allClientsHaveInvoiceNumbers || !returnData.received"
              hide-details
              @update:modelValue="normalizeReturnStates"
            ></v-switch>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <!-- Return-level fields -->
          <v-row>
            <v-col cols="12" sm="12" md="4" lg="3">
              <v-combobox
                label="Persoana de contact"
                v-model="returnData.contact_person"
                :items="contactPersonOptions.map((cp) => cp.name)"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :rules="[rules.required]"
                :readonly="
                  !userPermissions.returnsManagement.canEditContactPerson ||
                  (returnData.operated &&
                    !userPermissions.returnsManagement.canEditOperatedReturn)
                "
                clearable
                @update:modelValue="
                  onContactPersonSelected(returnData.contact_person)
                "
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                label="Număr telefon"
                v-model="returnData.phone"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :rules="[rules.required, rules.phone]"
                :readonly="
                  !userPermissions.returnsManagement.canEditPhoneNumber ||
                  (returnData.operated &&
                    !userPermissions.returnsManagement.canEditOperatedReturn)
                "
                name="tel"
                type="tel"
                autocomplete="tel"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" md="2">
              <v-autocomplete
                label="Județ"
                :items="regions"
                v-model="returnData.region"
                variant="outlined"
                density="compact"
                hide-details="auto"
                item-title="title"
                item-value="value"
                @update:modelValue="filterSubregions"
                :rules="[rules.required]"
                :readonly="
                  !userPermissions.returnsManagement.canEditRegion ||
                  (returnData.operated &&
                    !userPermissions.returnsManagement.canEditOperatedReturn)
                "
                name="region"
                autocomplete="address-level1"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-autocomplete
                label="Localitate"
                :items="filteredSubregions"
                v-model="returnData.subregion"
                variant="outlined"
                density="compact"
                hide-details="auto"
                item-title="title"
                item-value="value"
                :rules="[rules.required]"
                :readonly="
                  !userPermissions.returnsManagement.canEditCity ||
                  (returnData.operated &&
                    !userPermissions.returnsManagement.canEditOperatedReturn)
                "
                name="subregion"
                autocomplete="address-level2"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3">
              <v-text-field
                label="Adresă preluare"
                v-model="returnData.address"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :rules="[rules.required]"
                :readonly="
                  !userPermissions.returnsManagement.canEditAddress ||
                  (returnData.operated &&
                    !userPermissions.returnsManagement.canEditOperatedReturn)
                "
                name="address"
                autocomplete="street-address"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" md="4" lg="2">
              <div class="d-flex flex-row align-center flex-wrap">
                <v-menu
                  v-model="dateMenu"
                  transition="scale-transition"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ props }">
                    <v-text-field
                      label="Dată preluare"
                      dirty
                      readonly
                      v-bind="props"
                      locale="ro"
                      variant="outlined"
                      density="compact"
                      hide-details
                      :disabled="
                        !userPermissions.returnsManagement.canEditPickupDate ||
                        (returnData.operated &&
                          !userPermissions.returnsManagement
                            .canEditOperatedReturn)
                      "
                      name="pickup_date"
                      autocomplete="off"
                    >
                      {{ formatDate(returnData.pickup_date) }}
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="returnData.pickup_date"
                    @update:modelValue="dateMenu = false"
                    locale="ro"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>

            <v-col cols="12" sm="4" md="4" lg="2">
              <div
                class="d-flex flex-row align-center flex-wrap position-relative"
              >
                <span class="vuetify-like-label">Oră preluare de la</span>
                <VueDatePicker
                  v-model="returnData.pickup_time_start"
                  :is-24="true"
                  :clearable="false"
                  time-picker
                  locale="ro"
                  text-input
                  cancel-text="Anulează"
                  select-text="Selectează"
                  :disabled="
                    !userPermissions.returnsManagement.canEditPickupTimeStart ||
                    (returnData.operated &&
                      !userPermissions.returnsManagement.canEditOperatedReturn)
                  "
                  name="pickup_time_start"
                  autocomplete="off"
                />
              </div>
            </v-col>

            <v-col cols="12" sm="4" md="4" lg="2">
              <div
                class="d-flex flex-row align-center flex-wrap position-relative"
              >
                <span class="vuetify-like-label">Până la</span>
                <VueDatePicker
                  v-model="returnData.pickup_time_end"
                  :is-24="true"
                  :clearable="false"
                  time-picker
                  locale="ro"
                  text-input
                  cancel-text="Anulează"
                  select-text="Selectează"
                  :disabled="
                    !userPermissions.returnsManagement.canEditPickupTimeEnd ||
                    (returnData.operated &&
                      !userPermissions.returnsManagement.canEditOperatedReturn)
                  "
                  name="pickup_time_end"
                  autocomplete="off"
                />
              </div>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              :md="calcColSpan(returnData.notes, 4, 2, 22, 6)"
              :lg="calcColSpan(returnData.notes, 2, 2, 22, 6)"
            >
              <v-textarea
                label="Note"
                v-model="returnData.notes"
                variant="outlined"
                density="compact"
                hide-details
                rows="1"
                auto-grow
                :readonly="
                  !userPermissions.returnsManagement.canEditNotes ||
                  (returnData.operated &&
                    !userPermissions.returnsManagement.canEditOperatedReturn)
                "
                name="notes"
                autocomplete="on"
              ></v-textarea>
            </v-col>

            <v-col
              v-if="
                returnData.operated ||
                userPermissions.returnsManagement
                  .canViewReturnTransportationCost
              "
              cols="12"
              sm="12"
              md="4"
              lg="2"
            >
              <v-text-field
                label="Cost transport"
                v-model="returnData.transportation_cost"
                variant="outlined"
                density="compact"
                suffix="RON"
                :readonly="
                  !userPermissions.returnsManagement
                    .canViewReturnTransportationCost
                "
                hide-details
                name="transportation_cost"
                autocomplete="on"
              ></v-text-field>
            </v-col>

            <v-col
              v-if="
                returnData.operated ||
                userPermissions.returnsManagement
                  .canViewReturnTransportationCost
              "
              cols="12"
              sm="12"
              :md="calcColSpan(returnData.transportation_notes, 4, 2, 22, 6)"
              :lg="calcColSpan(returnData.transportation_notes, 2, 2, 22, 6)"
            >
              <v-textarea
                label="Note transport"
                v-model="returnData.transportation_notes"
                variant="outlined"
                density="compact"
                rows="1"
                auto-grow
                :readonly="
                  !userPermissions.returnsManagement
                    .canViewReturnTransportationCost
                "
                hide-details
                name="transportation_notes"
                autocomplete="on"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <a href="#" id="section2" ref="section2"></a>
      <!-- Clients Section -->
      <v-card class="mt-3">
        <v-toolbar>
          <v-toolbar-title>Listă clienți</v-toolbar-title>
          <!-- Desktop/large screen version -->
  <v-toolbar-items v-if="$vuetify.display.mdAndUp">
    <v-checkbox
      :input-value="shouldShowWholeWarehouse"
      label="Arată toate produsele existente"
      hide-details
      density="compact"
      @click="toggleWholeWarehouse"
    ></v-checkbox>

    <v-btn
      :readonly="
        !userPermissions.returnsManagement.canAddClient ||
        (returnData.operated &&
          !userPermissions.returnsManagement.canEditOperatedReturn)
      "
      color="primary"
      @click="addClient"
    >
      Adaugă Client
    </v-btn>
  </v-toolbar-items>

  <!-- Mobile/tablet version in a menu -->
  <v-menu v-else location="bottom right">
    <template v-slot:activator="{ props }">
      <v-btn icon v-bind="props">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item>
        <v-list-item-title>
          <v-checkbox
            :input-value="shouldShowWholeWarehouse"
            label="Arată toate produsele existente"
            hide-details
            density="compact"
            @click="toggleWholeWarehouse"
          ></v-checkbox>
        </v-list-item-title>
      </v-list-item>

      <v-list-item>
        <v-btn
          :readonly="
            !userPermissions.returnsManagement.canAddClient ||
            (returnData.operated &&
              !userPermissions.returnsManagement.canEditOperatedReturn)
          "
          color="primary"
          @click="addClient"
          block
        >
          Adaugă Client
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
        </v-toolbar>

        <v-card-text>
          <ReturnClientComponent
            v-for="(client, clientIndex) in returnData.clients"
            :key="clientIndex"
            :ref="'returnClientChild' + clientIndex"
            :client="client"
            :clientIndex="clientIndex"
            :return-data="returnData"
            :products="products"
            :headers="returnClientHeaders"
            :class="
              returnData.clients &&
              returnData.clients.length > 0 &&
              clientIndex > 0
                ? 'mt-3'
                : ''
            "
            @remove-client="removeClient"
            @update-client="updateClient"
          />
        </v-card-text>
      </v-card>
      <a href="#" id="section3" ref="section3"></a>
      <!-- Packages Section -->
      <v-card class="mt-3">
        <v-toolbar>
          <v-toolbar-title>Listă pachete</v-toolbar-title>
          <v-toolbar-items>
            <v-btn
              color="primary"
              @click="addPackage"
              :readonly="
                !userPermissions.returnsManagement.canAddPackage ||
                (returnData.operated &&
                  !userPermissions.returnsManagement.canEditOperatedReturn)
              "
            >
              Adaugă Pachet
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <ReturnPackageComponent
            v-for="(packageItem, packageIndex) in returnData.packages"
            :key="packageIndex"
            :ref="'returnPackageChild' + packageIndex"
            :packageItem="packageItem"
            :packageIndex="packageIndex"
            :return-data="returnData"
            @remove-package="removePackage"
            @update-package="updatePackage"
          />
        </v-card-text>
      </v-card>
      <v-card
        v-if="
          returnId && userPermissions.returnsManagement.canViewReturnHistoryLog
        "
        class="mt-3"
      >
        <v-toolbar>
          <v-toolbar-title> Istoric </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <ReturnHistoryLog :return-id="returnId" />
        </v-card-text>
      </v-card>
    </div>
  </v-form>
</template>

<script>
import navMixin from "@/mixins/navMixin";
import apiClient from "@/utils/apiClient";
import { fetchRegions, fetchSubregions, showAlertModal } from "@/utils/utils";
import BreadCrumbsWithButtons from "@/components/common/BreadCrumbsWithButtons.vue";
import ReturnClientComponent from "@/components/clients/ReturnClientComponent.vue";
import ReturnPackageComponent from "@/components/clients/ReturnPackageComponent.vue";
import ReturnHistoryLog from "@/components/returns/ReturnHistoryLog.vue";
import { mapGetters } from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";

export default {
  name: "ReturnCreate",
  mixins: [navMixin],
  components: {
    VueDatePicker,
    BreadCrumbsWithButtons,
    ReturnClientComponent,
    ReturnPackageComponent,
    ReturnHistoryLog,
  },
  data() {
    return {
      timeTest: new Date(),
      sidebarLinks: [
        {
          name: "Informații retur",
          link: "#section1",
          id: "section1",
        },
        {
          name: "Clienți",
          link: "#section2",
          id: "section2",
        },
        {
          name: "Pachete",
          link: "#section3",
          id: "section3",
        },
      ],
      returnId: null,
      returnData: {
        address: "",
        pickup_date: null,
        pickup_time_start: null,
        pickup_time_end: null,
        notes: "",
        transportation_cost: null,
        transportation_notes: null,
        region: null,
        subregion: null,
        phone: "",
        clients: [],
        packages: [],
        operated: false,
        invoiced: false,
        received: false,
      },
      regions: [],
      subregions: [],
      products: [], // Master list of products
      headers: [
        {
          title: "Cod produs",
          key: "product_code",
          align: "start",
          width: "100px",
        },
        {
          title: "Nume produs",
          key: "product_name",
          align: "start",
          width: "250px",
        },
        {
          title: "Cantitate (UM)",
          key: "quantity",
          align: "center",
          width: "150px",
        },
        {
          title: "Observații",
          key: "observations",
          align: "center",
          minWidth: "100px",
        },
        {
          title: "Service",
          key: "is_for_service",
          align: "center",
          width: "50px",
        },
      ],
      buttons: [
        {
          text: "Înapoi",
          onClick: this.returnToReturnsList,
          color: "secondary",
          visible: () => true,
          disabled: () => this.loading,
        },
        {
          text: "Salvează returul",
          onClick: () => this.handleSaveReturn(),
          disabled: () => this.loading,
          color: "success",
          visible: () =>
            this.userRole.includes("superuser") ||
            this.userRole.includes("returnServiceSuperUser") ||
            (this.userRole.includes("acquisitionsOnly") &&
              this.returnData.received) ||
            (!this.returnData.operated &&
              !this.userRole.includes("logisticsOnly")),
        },
        {
          text: "Operează returul",
          onClick: () =>
            this.handleSaveReturn(() => {
              this.returnData.operated = true;
            }),
          disabled: () => this.loading || !this.returnData.transportation_cost,
          color: "success",
          visible: () =>
            this.userRole.includes("logisticsOnly") &&
            !this.returnData.operated,
        },
        {
          text: "Recepționează returul",
          onClick: () =>
            this.handleSaveReturn(() => {
              this.returnData.received = true;
            }),
          disabled: () => this.loading || !this.returnData.operated,
          color: "success",
          visible: () =>
            this.userRole.includes("acquisitionsOnly") &&
            !this.returnData.received,
        },
        {
          text: "Facturează returul",
          onClick: () =>
            this.handleSaveReturn(() => {
              this.returnData.invoiced = true;
            }),
          disabled: () => this.loading || !this.allClientsHaveInvoiceNumbers,
          color: "success",
          visible: () =>
            this.userRole.includes("ordersOnly") && !this.returnData.invoiced,
        },
      ],
      contentTypes: null,
      phoneErrorMessage: "",
      formattedPickupDate: null,
      dateMenu: false,
      timeStartMenu: false,
      timeEndMenu: false,
      loading: false,
      formIsValid: false,
      rules: {
        required: (v) => !!v || "Acest câmp este obligatoriu.",
        phone: (v) => {
          if (!v || !v.length) {
            return "Introduceți un număr de telefon valid.";
          }
          // Check either 10-digit or + followed by 11 digits
          if (!/^\d{10}$/.test(v) && !/^\+\d{11}$/.test(v)) {
            return "Introduceți un număr de telefon valid, format din 10 cifre sau începând cu + și 11 cifre.";
          }
          return true;
        },
        // Add more if needed, e.g. numeric-only, email format, etc.
      },
      shouldShowWholeWarehouse: false,
      showAllProductsDialog: false,
      contactPersonOptions: [],
      userId: null,
    };
  },
  computed: {
    ...mapGetters(["userPermissions", "userRole"]),
    /**
     * Determines whether the "operated" switch should be read-only.
     * Returns `true` if:
     * - The return is already operated, but the user does not have permission to set "operated" to false.
     * - The user does not have permission to view the transportation cost.
     * - The user does not have permission to set "operated" to true.
     *
     * This ensures that the switch cannot be toggled unless all the required permissions and conditions are met.
     */
    operatedReadonly() {
      return (
        (!this.userPermissions.returnsManagement.canSetOperatedToFalse &&
          this.returnData.operated) ||
        !this.userPermissions.returnsManagement
          .canViewReturnTransportationCost ||
        !this.userPermissions.returnsManagement.canSetOperatedToTrue
      );
    },
    /**
     * Determines whether the "received" switch should be read-only.
     * Returns `true` if:
     * - The user does not have permission to edit the return's received status.
     * - The return is already received, but the user does not have permission to set operated to false.
     * - The return cannot be received due to failing the "canReceive" condition (e.g., missing required quantities or real quantities).
     *
     * This ensures that the "received" switch cannot be toggled unless the user has the appropriate permissions and the return is in a state that allows it.
     */
    receivedReadonly() {
      return (
        !this.userPermissions.returnsManagement.canEditReturnReceivedStatus ||
        (!this.userPermissions.returnsManagement.canSetOperatedToFalse &&
          this.returnData.received) ||
        !this.canReceive
      );
    },
    /**
     * Determines whether the "invoiced" switch should be read-only.
     * Returns `true` if:
     * - The return is already invoiced, but the user does not have permission to set operated to false.
     * - The user does not have permission to set the return to invoiced.
     *
     * This ensures that the "invoiced" switch cannot be toggled unless the user has the appropriate permissions and the return is in the correct state.
     */
    invoicedReadonly() {
      return (
        (!this.userPermissions.returnsManagement.canSetOperatedToFalse &&
          this.returnData.invoiced) ||
        !this.userPermissions.returnsManagement.canSetReturnToInvoiced
      );
    },
    filteredSubregions() {
      if (this.returnData.region) {
        return this.subregions
          .filter((item) => item.region === this.returnData.region)
          .sort((a, b) => a.title.localeCompare(b.title));
      }
      return [];
    },
    returnClientHeaders() {
      let conditionedHeaders = [...this.headers]; // Clone headers array to prevent direct mutation

      // Check if headers already exist
      const realQuantityExists = conditionedHeaders.some(
        (header) => header.key === "real_quantity"
      );
      const receptionObservationsExists = conditionedHeaders.some(
        (header) => header.key === "reception_observations"
      );

      // Only add these headers if they are missing and the user has permission OR the return is received
      if (
        (this.userPermissions.returnsManagement.canEditProductsRealQuantities ||
          this.returnData.received) &&
        (!realQuantityExists || !receptionObservationsExists)
      ) {
        if (!realQuantityExists) {
          conditionedHeaders.splice(3, 0, {
            title: "Cantitate reală (UM)",
            key: "real_quantity",
            align: "center",
            width: "150px",
          });
        }

        if (!receptionObservationsExists) {
          conditionedHeaders.splice(4, 0, {
            title: "Observații recepție",
            key: "reception_observations",
            align: "center",
            minWidth: "150px",
          });
        }
      }

      return conditionedHeaders;
    },
    canReceive() {
      // If there's any client or item that doesn't meet the criteria, return false
      // Criteria: Each item must have either a real_quantity or is_correct_quantity == true
      for (const client of this.returnData.clients) {
        for (const item of client.productData) {
          if (item.quantity) {
            const hasRealQuantity =
              item.real_quantity && !isNaN(parseInt(item.real_quantity, 10));
            const hasCorrectQuantity = !!item.is_correct_quantity; // Convert to boolean

            if (!hasRealQuantity && !hasCorrectQuantity) {
              return false;
            }
          }
        }
      }
      return true;
    },
    isPhoneValid() {
      return this.validatePhone(this.returnData.phone) === true;
    },
    allClientsHaveInvoiceNumbers() {
      return this.returnData.clients.every(
        (client) =>
          client.invoice_numbers && client.invoice_numbers.trim() !== ""
      );
    },
  },
  async mounted() {
    this.$store.state.sidebarLinks = this.sidebarLinks;
    this.userId = sessionStorage.getItem("userId");
    await this.initializeContactPersons();
    this.returnId = this.$route.params.returnId;
    // Fetch regions and subregions
    this.regions = await fetchRegions();
    if (!sessionStorage.getItem("subregions")) {
      this.subregions = await fetchSubregions();
    } else {
      this.subregions = JSON.parse(sessionStorage.getItem("subregions"));
    }
    // Fetch products
    await this.fetchProducts();
    if (this.returnId) {
      await this.fetchReturn(this.returnId);
    } else {
      this.initializeDatesAndTime();
      this.addClient(); // Start with one client by default
      this.addPackage(); // Start with one package by default
    }
  },
  methods: {
    /**
     * Increments the column span by 2 for every 22 characters, starting at a base of 2,
     * and caps the maximum at 6.
     *
     * @param {string} text - The text to measure.
     * @param {number} base - The base column size (usually 2).
     * @param {number} step - How many columns to add per "chunk" (2 in this case).
     * @param {number} chunkSize - How many characters per step (22).
     * @param {number} maxVal - The maximum possible columns (6).
     * @returns {number} - The computed column span for Vuetify breakpoints.
     */
    calcColSpan(text, base = 2, step = 2, chunkSize = 22, maxVal = 6) {
      if (!text) {
        return base; // no text, just return the base
      }
      const increments = Math.floor(text.length / chunkSize);
      let colSpan = base + increments * step;
      if (colSpan > maxVal) {
        colSpan = maxVal;
      }
      return colSpan;
    },
    async initializeContactPersons() {
      try {
        // Fetch existing contact persons from your Django endpoint
        const resp = await apiClient.get("/api/returns/contact_persons/", {
          params: { user_id: this.userId },
        });
        this.contactPersonOptions = resp.data;
      } catch (error) {
        console.error("Error fetching contact persons:", error);
      }
    },
    onContactPersonSelected(value) {
      // The newly selected (or typed) value
      const found = this.contactPersonOptions.find((cp) => cp.name === value);

      if (found) {
        // If we found a match in the known contacts, fill other fields
        // If they are null in the DB, default to empty or null
        this.returnData.phone = found.phone || "";
        this.returnData.region = found.region || null;
        this.returnData.subregion = found.subregion || null;
        this.returnData.address = found.address || "";
      }
    },
    toggleWholeWarehouse() {
      // If it is already true, toggle it to false immediately.
      if (this.shouldShowWholeWarehouse) {
        this.shouldShowWholeWarehouse = false;
        this.fetchProducts();
      } else {
        // If false, open the confirmation dialog.
        this.showAllProductsDialog = true;
      }
    },
    // Called when the user confirms in the dialog.
    async confirmWholeWarehouse() {
      this.showAllProductsDialog = false;
      this.shouldShowWholeWarehouse = true;
      await this.fetchProducts();
    },
    /**
     * Validates cross-field conditions and array checks that extend beyond per-field rules.
     * Ensures that:
     * - Every client has a name.
     * - There is at least one product with a quantity > 0.
     * - If the user role is "agent" or "superAgent", at least one package is present
     *   and each package has valid dimensions and at least one attachment/photo.
     * - If the user is not a superuser, but can edit real quantities or set return as received,
     *   all clients must be valid (no errors with real quantities or reception observations).
     *
     * Returns `true` if all checks pass, `false` otherwise.
     */
    validateMultiField() {
      // Get references to all child client components
      // Collect all client components (handling indexed refs)
      const clientComponents = Object.keys(this.$refs)
        .filter((key) => key.startsWith("returnClientChild"))
        .flatMap((key) => this.$refs[key]); // Flatten array of arrays

      if (clientComponents.length === 0) {
        console.warn("No client components found!");
        return false;
      }

      // Ensure each client calls checkClientValidity()
      clientComponents.forEach((clientComponent) => {
        if (clientComponent) {
          clientComponent.updateValidationState();
        }
      });

      // Ensure every client is valid
      const allClientsValid = clientComponents.every(
        (clientComponent) => clientComponent.clientData.isValid
      );

      if (!allClientsValid) {
        return false;
      }

      // 4) If user is not a superuser but can edit real quantities or set return as received, do extra per-product checks
      const requiresRealQtyChecks =
        !this.userPermissions.isSuperuser &&
        (this.userPermissions.returnsManagement.canEditProductsRealQuantities ||
          this.userPermissions.returnsManagement.canEditReturnReceivedStatus);

      // If the userRole is 'agent' or 'superAgent', check for packages (unchanged)
      if (
        this.userRole.includes("agent") ||
        this.userRole.includes("superAgent") ||
        this.userRole.includes("returnsOperator")
      ) {
        if (this.returnData.packages.length === 0) {
          showAlertModal(
            this.$store,
            "Trebuie să existe cel puțin un colet.",
            "danger",
            12000
          );
          return false;
        }
        const allPackagesValid = this.returnData.packages.every(
          (pkg) =>
            pkg.width &&
            pkg.height &&
            pkg.length &&
            (pkg.attachments?.length > 0 || pkg.newPhotos?.length > 0)
        );
        if (!allPackagesValid) {
          showAlertModal(
            this.$store,
            "Toate pachetele trebuie să aibă dimensiuni valide și cel puțin o poză.",
            "danger",
            12000
          );
          return false;
        }
      }

      if (requiresRealQtyChecks) {
        for (const client of this.returnData.clients) {
          const productData = client.productData || [];
          for (const product of productData) {
            const qty = parseInt(product.quantity, 10);

            // Only enforce checks if quantity > 0
            if (qty > 0) {
              // Skip checks if product_code includes "000.00.00"
              const isAcqUser = this.userRole.includes("acquisitionsOnly");
            //   const isAcqProduct = product.added_by === "acquisitionsOnly";
              const skipRealQtyCheck =
                product.product_code.includes("000.00.00");

              // NEW: Allow acquisitionsOnly users to skip validation for their own products
              if (isAcqUser  || skipRealQtyCheck) {
                continue; // Skip all validation for this product
              }

              if (!product.is_correct_quantity) {
                const realQty = parseInt(product.real_quantity, 10);

                if (!(isAcqUser)) {
                  if (isNaN(realQty) || realQty <= 0) {
                    showAlertModal(
                      this.$store,
                      "Pentru produsele cu cantitate, trebuie să bifați 'Cantitate corectă' sau să completați cantitatea reală.",
                      "danger",
                      12000
                    );
                    return false;
                  }

                  if (realQty !== qty) {
                    if (
                      !product.reception_observations ||
                      product.reception_observations.trim() === ""
                    ) {
                      showAlertModal(
                        this.$store,
                        "Dacă cantitatea reală diferă de cea inițială, trebuie să completați observațiile de recepție.",
                        "danger",
                        12000
                      );
                      return false;
                    }
                  }
                }
              }
            }
          }
        }
      }

      return true;
    },
    /**
     * Attempts to either update or create a contact person based on name+phone uniqueness.
     * If an existing contact with the same (name, phone) is found, it updates that record.
     * Otherwise, it creates a new one. Finally, it updates `contactPersonOptions`.
     */
    async upsertContactPerson() {
      const typedName = this.returnData.contact_person;
      // If there's no name typed, do nothing.
      if (!typedName) return;

      // 1) Find if there's an existing contact with the same (name + phone)
      const existingContact = this.contactPersonOptions.find(
        (cp) => cp.name === typedName && cp.phone === this.returnData.phone
      );

      if (existingContact) {
        // 2) Update existing record
        try {
          const updateResp = await apiClient.put(
            `/api/returns/contact_persons/${existingContact.id}/`,
            {
              name: typedName,
              phone: this.returnData.phone,
              region: this.returnData.region,
              subregion: this.returnData.subregion,
              address: this.returnData.address,
              user_id: this.userId,
            }
          );
          // Update the local array
          const idx = this.contactPersonOptions.findIndex(
            (cp) => cp.id === existingContact.id
          );
          if (idx !== -1) {
            this.contactPersonOptions.splice(idx, 1, updateResp.data);
          }
        } catch (error) {
          console.error("Error updating existing contact person:", error);
          showAlertModal(
            this.$store,
            "Eroare la actualizarea persoanei de contact!",
            "danger",
            8000,
            error
          );
        }
      } else {
        // 3) Otherwise, create a new record
        try {
          const createResp = await apiClient.post(
            "/api/returns/contact_persons/",
            {
              name: typedName,
              phone: this.returnData.phone,
              region: this.returnData.region,
              subregion: this.returnData.subregion,
              address: this.returnData.address,
              user_id: this.userId,
            }
          );
          this.contactPersonOptions.push(createResp.data);
        } catch (error) {
          console.error("Error creating new contact person:", error);
          showAlertModal(
            this.$store,
            "Eroare la crearea unei persoane de contact noi!",
            "danger",
            8000,
            error
          );
        }
      }
    },
    /**
     * Handles the main save logic for a return.
     * Optionally executes a function before saving, then:
     * 1) Validates all per-field rules via the Vuetify v-form.
     * 2) Performs multi-field (cross-field or array) validations.
     * 3) Proceeds to save if all validations pass.
     *
     * @param {Function} [beforeSaveAction] - Optional function to execute before saving.
     */
    async handleSaveReturn(beforeSaveAction) {
      const basicFormValid = await this.$refs.returnForm.validate();
      if (!basicFormValid.valid) {
        showAlertModal(this.$store, "Formular invalid!", "danger", 6000);
        return;
      }
      if (!this.validateMultiField()) {
        return;
      }
      if (beforeSaveAction) {
        beforeSaveAction();
      }

      // 1) Do the contact person upsert logic
      await this.upsertContactPerson();

      // 2) Then proceed to save the Return object
      await this.saveReturn();
    },
    /**
     * Normalizes the return's operated, received, and invoiced states according to the logic:
     * 1. If operated = false, received and invoiced = false.
     * 2. If received = false, invoiced = false.
     * 3. If invoiced = true, then operated and received must be true.
     * 4. If received = true, operated must be true.
     *
     * Additionally, if transportation_cost is required for operating or invoicing, enforce that as well.
     */
    normalizeReturnStates() {
      let { operated, received, invoiced } = this.returnData;

      // 1. If operated = false, force received and invoiced = false
      if (!operated) {
        received = false;
        invoiced = false;
      }

      // 2. If received = false, invoiced must be false
      if (!received) {
        invoiced = false;
      }

      // 3. If invoiced = true, operated and received must be true
      if (invoiced) {
        operated = true;
        received = true;
      }

      // 4. If received = true, operated must be true
      if (received && !operated) {
        operated = true;
      }

      // Update returnData
      this.returnData.operated = operated;
      this.returnData.received = received;
      this.returnData.invoiced = invoiced;

      // If certain fields must be present for the transitions:
      // For example, if transportation_cost must be set before operated can be true:
      if (this.returnData.operated && !this.returnData.transportation_cost) {
        // Reset operated and invoiced to false if no transportation_cost
        this.returnData.operated = false;
        this.returnData.invoiced = false;
        showAlertModal(
          this.$store,
          "Costul transportului trebuie completat înainte de a seta returul ca operat.",
          "warning",
          6000
        );
      }
    },
    /**
     * Converts { hours, minutes, seconds } object to an ISO timestamp.
     */
    timeObjectToIso(date, timeObj) {
      if (!timeObj || typeof timeObj.hours === "undefined") return null;

      const isoDate = new Date(date);
      isoDate.setHours(timeObj.hours, timeObj.minutes, timeObj.seconds || 0, 0);
      return isoDate.toISOString();
    },
    /**
     * Converts an ISO timestamp to { hours, minutes, seconds } object.
     */
    isoToTimeObject(isoString) {
      if (!isoString) return null;

      const date = new Date(isoString);
      return {
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
      };
    },
    /**
     * Initializes pickup date and times.
     */
    initializeDatesAndTime() {
      const now = new Date();
      this.returnData.pickup_date = now; // Store full Date object

      // Force pickup_time_start to 09:00:00
      this.returnData.pickup_time_start = {
        hours: 9,
        minutes: 0,
        seconds: 0,
      };

      // Force pickup_time_end to 17:00:00
      this.returnData.pickup_time_end = {
        hours: 17,
        minutes: 0,
        seconds: 0,
      };
    },
    /**
     * Formats a given Date object into a "dd/mm/yyyy" format string.
     *
     * @param {Date} date - The date object to format.
     * @returns {string} - The formatted date string, or an empty string if invalid.
     */
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      return `${day}/${month}/${year}`;
    },
    /**
     * Formats a given Date object to show only the time in "HH:mm" format.
     *
     * @param {Date} date - The date object to format.
     * @returns {string} - The formatted time string, or an empty string if invalid.
     */
    formatTime(date) {
      if (!date) return "";
      return date.toLocaleTimeString("ro-RO", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    /**
     * Fetches products from the backend.
     * When shouldShowWholeWarehouse is true, it fetches all products and then updates the
     * clients' productData by merging in any existing quantities.
     */
    async fetchProducts() {
      try {
        let url = "/api/items/";
        if (this.shouldShowWholeWarehouse) {
          // Append the query parameter to get all items
          url += "?whole_warehouse=true";
        }
        const response = await apiClient.get(url);
        // The response is always an array of items
        this.products = response.data;
        this.showAllProductsDialog = false;

        // After fetching the new products list, update each client's productData.
        // This will replace the list while preserving any existing entered values.
        this.updateClientsProductData();
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    /**
     * Updates each client's productData by merging the new products list with the existing data.
     * If an existing product (matched by product_code) has a valid quantity or real_quantity in either
     * the client’s items array or its productData array, that data is preserved.
     * Additionally, if a product is present in client.items but not in the global products list (this.products),
     * it is added to productData.
     */
    updateClientsProductData() {
      this.returnData.clients.forEach((client) => {
        // Build a lookup (by product_code) from both client.items and client.productData.
        // We consider an entry "valid" if it has a non-null/undefined quantity or real_quantity.
        const existingDataMap = {};

        // First, add valid entries from client.items.
        if (client.items && client.items.length) {
          client.items.forEach((item) => {
            if (
              (item.quantity !== null && item.quantity !== undefined) ||
              (item.real_quantity !== null && item.real_quantity !== undefined)
            ) {
              existingDataMap[item.product_code] = item;
            }
          });
        }

        // Then, add valid entries from client.productData (if not already added).
        if (client.productData && client.productData.length) {
          client.productData.forEach((item) => {
            if (
              (item.quantity !== null && item.quantity !== undefined) ||
              (item.real_quantity !== null && item.real_quantity !== undefined)
            ) {
              if (!existingDataMap[item.product_code]) {
                existingDataMap[item.product_code] = item;
              }
            }
          });
        }

        // Create a set of product codes coming from the global products list.
        const globalProductCodes = new Set(
          this.products.map((product) => product.product_code)
        );

        // Start with all global products...
        let mergedProducts = [...this.products];

        // ...then add any products from the client's items (or previous productData)
        // that are not already in the global products list.
        Object.values(existingDataMap).forEach((existingItem) => {
          if (!globalProductCodes.has(existingItem.product_code)) {
            mergedProducts.push(existingItem);
          }
        });

        // Now, rebuild productData by merging each product with any existing data.
        client.productData = mergedProducts.map((product) => {
          const existing = existingDataMap[product.product_code] || {};
          return {
            ...product,
            // Preserve values if already set; otherwise default.
            quantity: existing.quantity != null ? existing.quantity : null,
            observations: existing.observations || "",
            is_for_service: existing.is_for_service || false,
            real_quantity:
              existing.real_quantity != null ? existing.real_quantity : null,
            reception_observations: existing.reception_observations || "",
            is_correct_quantity: existing.is_correct_quantity || false,
            added_by: existing.added_by || "",
          };
        });
      });
    },
    /**
     * Clears the selected subregion whenever a new region is chosen, ensuring the user
     * must explicitly re-select a matching subregion.
     */
    filterSubregions() {
      this.returnData.subregion = null;
    },
    /**
     * Adds a new, empty client object to the returnData.clients array.
     * Each client starts with an empty productData array constructed from the products list.
     */
    addClient() {
      this.returnData.clients.push({
        name: "",
        phone: "",
        notes: "",
        items: [],
        invoice_numbers: "",
        invoice_notes: "",
        productData: this.products.map((product) => ({
          ...product,
          quantity: null,
          observations: "",
          is_for_service: false,
          real_quantity: null,
          reception_observations: "",
          is_correct_quantity: false,
        })),
      });
    },
    /**
     * Updates a specific client's data within returnData.clients based on the provided payload.
     *
     * @param {Object} payload - Contains `clientIndex` and `clientData`.
     */
    updateClient({ clientIndex, clientData }) {
      Object.assign(this.returnData.clients[clientIndex], clientData);
    },
    /**
     * Removes a client from the returnData.clients array based on the given index.
     *
     * @param {number} clientIndex - The index of the client to remove.
     */
    removeClient(clientIndex) {
      this.returnData.clients.splice(clientIndex, 1);
    },
    /**
     * Adds a new, empty package object to the returnData.packages array.
     */
    addPackage() {
      this.returnData.packages.push({
        length: null,
        width: null,
        height: null,
        weight: null,
        type: "Colet",
        attachments: [],
        photos: [],
        newPhotos: [],
      });
    },
    /**
     * Updates a specific package's data within returnData.packages based on the provided payload.
     *
     * @param {Object} payload - Contains `packageIndex` and `packageData`.
     */
    updatePackage({ packageIndex, packageData }) {
      Object.assign(this.returnData.packages[packageIndex], packageData);
    },
    /**
     * Removes a package from the returnData.packages array based on the given index.
     *
     * @param {number} packageIndex - The index of the package to remove.
     */
    removePackage(packageIndex) {
      this.returnData.packages.splice(packageIndex, 1);
    },
    /**
     * Validates a phone number. Must be either 10 digits or start with '+' followed by 11 digits.
     *
     * @param {string} value - The phone number input.
     * @returns {string|boolean} - Returns an error message if invalid, otherwise `true`.
     */
    validatePhone(value) {
      const phone = value;
      if (!phone || phone.length === 0) {
        return "Introduceți un număr de telefon valid.";
      }
      if (!/^\d{10}$/.test(phone) && !/^\+\d{11}$/.test(phone)) {
        return "Introduceți un număr de telefon valid, format din 10 cifre sau începând cu + și 11 cifre.";
      }
      return true;
    },
    /**
     * Navigates back to the returns list view.
     */
    returnToReturnsList() {
      this.$router.push("/returns-tab");
    },
    /**
     * Checks if a given Date object is valid.
     *
     * @param {Date} d - The date object to check.
     * @returns {boolean} - `true` if valid, otherwise `false`.
     */
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    /**
     * Attempts to parse a given value into a valid Date object.
     * Returns `null` if the date is invalid.
     *
     * @param {string|Date} value - The value to convert into a Date object.
     * @returns {Date|null} - The valid Date object or `null` if invalid.
     */
    getValidDate(value) {
      let dateObj = value;
      if (!(dateObj instanceof Date)) {
        dateObj = new Date(dateObj);
      }

      if (!this.isValidDate(dateObj)) {
        console.error("Invalid date:", value);
        return null;
      }
      return dateObj;
    },
    /**
     * Converts a date to ISO format if it exists.
     * @param {string | Date | null} date - The date to convert.
     * @returns {string | null} - The ISO string or null if the date is invalid.
     */
    toISODate(date) {
      if (!date) return null;
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    /**
     * Formats the clients list before saving, filtering only items with quantities and preparing the structure
     * to match the backend's expected format.
     *
     * @returns {Array} - A formatted list of clients and their products.
     */
    formatClients() {
      return this.returnData.clients.map((client) => ({
        id: client.id,
        name: client.name,
        invoice_numbers: client.invoice_numbers || null,
        invoice_notes: client.invoice_notes || null,
        items: client.productData
          .filter(
            (product) =>
              (product.quantity && product.quantity > 0) ||
              (product.real_quantity && product.real_quantity > 0)
          )
          .map((product) => ({
            id: product.id,
            product_code: product.product_code,
            product_name: product.product_name,
            quantity: product.quantity,
            observations: product.observations,
            is_for_service: product.is_for_service,
            real_quantity: product.real_quantity,
            reception_observations: product.reception_observations,
            is_correct_quantity: product.is_correct_quantity,
            added_by: product.added_by,
            measurement_unit: product.measurement_unit,
          })),
      }));
    },
    /**
     * Formats the packages list before saving, including attachments and removal lists,
     * to match the backend's expected format.
     *
     * @returns {Array} - A formatted list of packages.
     */
    formatPackages() {
      return this.returnData.packages.map((packageItem) => ({
        id: packageItem.id,
        length: packageItem.length,
        width: packageItem.width,
        height: packageItem.height,
        weight: packageItem.weight,
        type: packageItem.type,
        real_quantity: packageItem.real_quantity,
        reception_observations: packageItem.reception_observations,
        is_correct_quantity: packageItem.is_correct_quantity,
        attachments_to_delete: packageItem.attachmentsToDelete
          ? packageItem.attachmentsToDelete.map((a) => a.id)
          : [],
      }));
    },
    /**
     * Returns a fully formatted return data object ready to be sent to the backend API.
     * Includes properly formatted dates, times, clients, and packages.
     * If the pickup_date is invalid, returns `undefined`.
     *
     * @returns {Object|undefined} - The formatted return data or `undefined` if date is invalid.
     */
    getFormattedReturnData() {
      const pickupDate = this.getValidDate(this.returnData.pickup_date);
      if (!pickupDate) {
        // handle invalid pickup_date as needed
        return;
      }

      return {
        address: this.returnData.address,
        pickup_date: this.toISODate(pickupDate),
        pickup_time_start: this.timeObjectToIso(
          pickupDate,
          this.returnData.pickup_time_start
        ), // Convert HH:mm to ISO
        pickup_time_end: this.timeObjectToIso(
          pickupDate,
          this.returnData.pickup_time_end
        ), // Convert HH:mm to ISO
        contact_person: this.returnData.contact_person,
        notes: this.returnData.notes,
        transportation_cost: this.returnData.transportation_cost,
        transportation_notes: this.returnData.transportation_notes,
        region: this.returnData.region,
        subregion: this.returnData.subregion,
        phone: this.returnData.phone,
        operated: this.returnData.operated,
        invoiced: this.returnData.invoiced,
        received: this.returnData.received,
        clients: this.formatClients(),
        packages: this.formatPackages(),
      };
    },
    /**
     * Saves the return data to the backend. Handles both create (POST) and update (PUT) operations.
     * On success, updates internal IDs, uploads package photos if any, and refetches the return data.
     * On failure, shows an error message.
     */
    async saveReturn() {
      this.loading = true;

      try {
        const formattedReturnData = this.getFormattedReturnData();

        let response;
        if (this.returnId) {
          // Update existing return
          response = await apiClient.put(
            `/api/returns/${this.returnId}/`,
            formattedReturnData
          );
        } else {
          // Create new return
          response = await apiClient.post(
            "/api/returns/create/",
            formattedReturnData
          );
          this.returnId = response.data.id.toString();
        }

        // Update IDs and upload any package photos if needed
        this.updateReturnDataIds(response.data);
        await this.uploadPackagePhotos();
        await this.fetchReturn(this.returnId);

        setTimeout(() => {
          showAlertModal(
            this.$store,
            "Returul a fost salvat cu succes!",
            "success",
            5000
          );
          this.loading = false;
        }, 500);
      } catch (error) {
        console.error("Error saving return:", error);
        showAlertModal(
          this.$store,
          "A intervenit o eroare la salvarea returului. Vă rugăm reîncercați.",
          "danger",
          12000,
          error
        );
        this.loading = false;
      }
    },
    /**
     * Uploads any new photos added to packages after the main return data has been saved.
     * Uses the content type and object ID to associate attachments with the correct return packages.
     */
    async uploadPackagePhotos() {
      for (let i = 0; i < this.returnData.packages.length; i++) {
        const packageItem = this.returnData.packages[i];
        if (packageItem.newPhotos && packageItem.newPhotos.length > 0) {
          const formData = new FormData();
          const contentTypeId = await this.getContentTypeId("returnpackage");
          formData.append("content_type", contentTypeId);
          formData.append("object_id", packageItem.id);

          // Loop through newPhotos which now contains wrapper objects
          packageItem.newPhotos.forEach((photoWrapper) => {
            // Extract the File object from the wrapper.
            // If photoWrapper.file exists, use it; otherwise fallback to photoWrapper.
            const fileToUpload = photoWrapper.file || photoWrapper;
            formData.append("files", fileToUpload);
            // Append the corresponding added_by field.
            // This assumes your backend can handle multiple "added_by" fields in the same order as "files"
            formData.append("added_by", photoWrapper.added_by);
          });

          await apiClient.post(`/api/attachments/upload/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          // Clear the newPhotos array after upload
          packageItem.newPhotos = [];
        }
      }
    },
    /**
     * Updates return and related items' IDs after saving or updating on the backend,
     * ensuring the client and package arrays in `returnData` stay in sync with the backend.
     *
     * @param {Object} responseData - The data returned by the backend after saving.
     */
    updateReturnDataIds(responseData) {
      // Update return ID
      this.returnData.id = responseData.id;

      // Update package IDs
      responseData.packages.forEach((responsePackage, index) => {
        if (this.returnData.packages[index]) {
          this.returnData.packages[index].id = responsePackage.id;
        }
      });

      // Update client IDs and their items
      responseData.clients.forEach((responseClient, clientIndex) => {
        if (this.returnData.clients[clientIndex]) {
          this.returnData.clients[clientIndex].id = responseClient.id;

          // Map item IDs
          responseClient.items.forEach((responseItem) => {
            const product = this.returnData.clients[
              clientIndex
            ].productData.find(
              (p) => p.product_code === responseItem.product_code
            );
            if (product) {
              product.id = responseItem.id;
            }
          });
        }
      });
    },
    /**
     * Fetches the content type ID for a given model name. Used for uploading attachments.
     * If contentTypes is not cached, it first fetches it from the server.
     *
     * @param {string} modelName - The model name (e.g., "returnpackage").
     * @returns {number} - The content type ID for the given model name.
     */
    async getContentTypeId(modelName) {
      if (!this.contentTypes) {
        const response = await apiClient.get("/api/content-types/");
        this.contentTypes = response.data;
      }
      return this.contentTypes[modelName.toLowerCase()];
    },
    /**
     * Fetches the full return details from the backend for a given returnId.
     * Re-formats dates, times, and re-builds clients and packages arrays so the UI can display them properly.
     * Logs an error if fetching fails.
     *
     * @param {number} returnId - The ID of the return to fetch.
     */
    async fetchReturn(returnId) {
      try {
        const response = await apiClient.get(`/api/returns/${returnId}/`);
        this.returnData = response.data;

        // Convert pickup_date from "YYYY-MM-DD" to Date object
        if (this.returnData.pickup_date) {
          this.returnData.pickup_date = new Date(this.returnData.pickup_date);
        }

        // Convert ISO timestamps to { hours, minutes, seconds } format for VueDatePicker
        if (this.returnData.pickup_time_start) {
          this.returnData.pickup_time_start = this.isoToTimeObject(
            this.returnData.pickup_time_start
          );
        }

        if (this.returnData.pickup_time_end) {
          this.returnData.pickup_time_end = this.isoToTimeObject(
            this.returnData.pickup_time_end
          );
        }

        // Handle clients
        this.returnData.clients.forEach((client, clientIndex) => {
          const savedItemsMap = {};
          client.items.forEach((savedItem) => {
            savedItemsMap[savedItem.product_code] = savedItem;
          });
          client.showOnlyWithQuantity = true;

          // Build a set of product codes from the global products list.
          const globalProductCodes = new Set(
            this.products.map((product) => product.product_code)
          );

          // Start with all global products...
          let unionProducts = [...this.products];

          // ...then add any saved items that are not in the global list.
          Object.values(savedItemsMap).forEach((savedItem) => {
            if (!globalProductCodes.has(savedItem.product_code)) {
              unionProducts.push(savedItem);
            }
          });

          // Rebuild productData by merging each product with any saved data.
          client.productData = unionProducts.map((product) => {
            const existing = savedItemsMap[product.product_code] || {};
            return {
              ...product,
              // If there is saved data for this product, use it; otherwise, default values.
              quantity: existing.quantity != null ? existing.quantity : null,
              observations: existing.observations || "",
              is_for_service: existing.is_for_service || false,
              real_quantity:
                existing.real_quantity != null ? existing.real_quantity : null,
              reception_observations: existing.reception_observations || "",
              is_correct_quantity: existing.is_correct_quantity || false,
              added_by: existing.added_by || "",
              measurement_unit:
                existing.measurement_unit || product.measurement_unit,
            };
          });

          if (client.region) {
            this.$nextTick(() => {
              const childComponent =
                this.$refs["returnClientChild" + clientIndex];
              if (childComponent && childComponent[0]) {
                childComponent[0].filterSubregions();
              }
            });
          }
        });

        // Handle packages
        this.returnData.packages.forEach((packageItem) => {
          packageItem.attachments = packageItem.attachments || [];
          packageItem.newPhotos = [];
          packageItem.attachmentsToDelete = [];
        });
      } catch (error) {
        console.error("Error fetching return:", error);
      }
    },
  },
};
</script>

<style>
.vuetify-like-label {
  position: absolute;
  top: -11px;
  left: 12px;
  z-index: 1;
  font-size: 0.75rem;
  color: rgba(120, 120, 120, 1);
  background-color: white;
  padding: 0 4px 0 4px;
}
</style>
