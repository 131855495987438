<template xmlns:v-expand-transition="http://www.w3.org/1999/html">
  <v-dialog v-model="showModal" width="auto">
    <ClientLocation :location="selectedLocation"></ClientLocation>
  </v-dialog>
  <a href="#" id="section1" ref="section1"></a>
  <v-card class="mb-4 mt-4">
    <v-toolbar
      @click="isRecentLocationsCollapsed = !isRecentLocationsCollapsed"
      class="cursor-pointer"
    >
      <v-container>
        <h5>Locații recente</h5>
      </v-container>
    </v-toolbar>
    <v-expand-transition>
      <v-container v-show="!isRecentLocationsCollapsed">
        <v-row>
          <v-col
            cols="12"
            md="12"
            sm="12"
            lg="6"
            v-for="location in recentLocations"
            :key="location.id"
          >
            <LocationCard
              :active-link="true"
              :location="location"
              :client="location.client"
              :renderedByClientsList="true"
              :titleInfo="'clientTitle'"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-expand-transition>
  </v-card>
  <v-card class="mb-4">
    <v-toolbar
      @click="isRecentOffersCollapsed = !isRecentOffersCollapsed"
      class="cursor-pointer"
    >
      <v-container>
        <h5>Oferte recente</h5>
      </v-container>
    </v-toolbar>
    <v-expand-transition>
      <v-container v-show="!isRecentOffersCollapsed">
        <v-row>
          <v-col
            v-for="offer in recentOffers"
            :key="offer.id"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card :elevation="3">
              <v-card-title>
                {{
                  offer.location.client
                    ? offer.location.client.full_name
                    : "necunoscut"
                }}
              </v-card-title>
              <v-card-subtitle>
                {{ showDateTime(offer.modified) }}
              </v-card-subtitle>
              <v-card-text>
                <h6>Oferta nr. {{ offer.id }}</h6>
                <div>
                  <strong>Tip: </strong>
                  <span v-if="offer.type === 4">
                    {{
                      offer.custom_pdf_heading
                        ? offer.custom_pdf_heading + " / "
                        : ""
                    }}
                    {{ getOfferName(offer.type) }}
                  </span>
                  <span v-else>{{ getOfferName(offer.type) }}</span>
                </div>
                <div>
                  <strong>Total preț listă: </strong
                  >{{ formatNumber(this, offer.list_price_value, true) }}
                </div>
                <div>
                  <strong>Discount:</strong> {{ offer.discount || "0.00" }}%
                </div>
                <div>
                  <strong>Total preț cu discount: </strong
                  >{{ formatNumber(this, offer.value_with_discount, true) }}
                </div>
              </v-card-text>
              <v-card-actions class="d-flex justify-content-end">
                <v-btn
                  text
                  color="info"
                  @click="goToClientDetails(offer.client)"
                  >Vezi Detalii</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-expand-transition>
  </v-card>
  <a href="#" id="section2" ref="section2"></a>
  <v-card>
    <v-toolbar class="sticky-top" height="auto">
      <v-container>
        <v-row class="d-flex justify-content-between flex-row">
          <v-col
            cols="12"
            md="12"
            lg="2"
            class="order-first d-flex align-items-center"
          >
            <h5 class="mb-0 cursor-pointer" data-cy="clientsList-title">
              {{ getClientsListTitle }}
            </h5>
          </v-col>
          <v-col
            cols="12"
            md="12"
            lg="4"
            class="d-flex justify-content-end align-items-center"
          >
            <v-text-field
              class="clientsList-input"
              label="Nume client"
              density="compact"
              clearable
              :variant="this.$store.state.vuetifyFieldType"
              v-model="searchQuery"
              @click:clear="handleNameSearchQuery"
              @keydown.enter="handleNameSearchQuery"
              prepend-inner-icon="mdi-magnify"
            >
              <template v-slot:append>
                <v-btn
                  @click="handleNameSearchQuery"
                  color="primary"
                  variant="text"
                  class="mx-2"
                >
                  Caută
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="12"
            lg="2"
            class="d-flex justify-content-end align-items-center"
          >
            <v-select
              :items="this.offerTypes"
              item-title="name"
              item-value="type"
              label="Tip ofertă"
              density="compact"
              :variant="this.$store.state.vuetifyFieldType"
              v-model="searchOfferType"
              hide-details
              dirty
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="12"
            lg="3"
            class="d-flex justify-content-end align-items-center"
          >
            <v-text-field
              class="clientsList-input"
              density="compact"
              :variant="this.$store.state.vuetifyFieldType"
              label="Număr ofertă"
              clearable
              v-model="searchOfferIdValue"
              prepend-inner-icon="mdi-magnify"
              @keydown.enter="findOffers"
            >
              <template v-slot:append>
                <v-btn
                  @click="findOffers"
                  color="primary"
                  variant="text"
                  class="mx-2"
                  :disabled="searchOfferType === 0 && !searchOfferIdValue"
                  :loading="loadingOffers"
                >
                  Caută
                </v-btn>
              </template>
            </v-text-field>
            <div class="dropdown-menu" v-if="searchOfferIdHistory.length">
              <a
                href="#"
                class="dropdown-item"
                v-for="(suggestion, index) in searchOfferIdHistory"
                :key="suggestion"
                @click.prevent="
                  searchOfferIdValue = searchOfferIdHistory[index]
                "
                >{{ suggestion }}</a
              >
            </div>
          </v-col>
          <v-theme-provider theme="sistemaLightTheme">
            <v-col
              cols="6"
              md="12"
              lg="1"
              class="d-flex justify-content-center align-items-center order-2 order-lg-4"
            >
              <v-btn icon @click="refreshClients()">
                <v-icon color="success">mdi-refresh</v-icon>
                <v-tooltip activator="parent" location="top">
                  Reîmprospătare listă clienți
                </v-tooltip>
              </v-btn>
              <v-btn
                color="primary"
                @click="addClient"
                data-cy="add-client"
                variant="text"
                icon
              >
                <v-icon>mdi-plus</v-icon>
                <v-tooltip activator="parent" location="top">
                  Adaugă client
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-theme-provider>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-container>
      <div v-if="$vuetify.display.mdAndDown">
        <div
          v-for="(client, index) in displayedClients"
          :key="index"
          class="my-4"
        >
          <ClientCard :active-link="true" :client="client" />
          <OffersCard
            v-if="client.offers?.length"
            :searchBy="{ criteria: 'client', value: client.id }"
            :collapsed="false"
            :search-offer-type="searchOfferType"
          ></OffersCard>
        </div>
      </div>
      <ClientsTableList
        v-if="!searchOffersDialog && !$vuetify.display.mdAndDown"
        :clients="displayedClients"
        :loading="loadingClients"
        :agents="agents"
        :intermediaries="intermediaries"
        :total-clients="totalClients"
        :current-page="currentPage"
        :items-per-page="itemsPerPage"
        :search-offer-type="searchOfferType"
        @updated-sorting="handleSortingChange"
        @updated-items-per-page="handleClientsItemPerPageUpdate"
        @updated-page="handleClientsUpdatePage"
      >
        <template v-slot:agentMenu>
          <v-list-item class="table-dropdown-input-item">
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              label="Nume agent"
              v-model="agentFilter"
              @click.stop
            ></v-text-field>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="agent in filteredAgents"
            :value="agent.id"
            :key="agent.id"
            :active="isActiveFilter('agent__id', agent.id)"
            active-class="active-item"
            @click="addFilter('agent__id', agent.id)"
            class="list-item"
          >
            <div
              class="d-flex justify-content-between align-items-center flex-row"
            >
              <v-list-item-title>
                {{ agent.full_name !== "" ? agent.full_name : agent.email }}
              </v-list-item-title>
              <v-icon
                color="danger"
                v-if="isActiveFilter('agent__id', agent.id)"
                >mdi-delete
              </v-icon>
            </div>
          </v-list-item>
        </template>
        <template v-slot:clientMenu>
          <v-list-item>
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              label="Nume client"
              v-model="searchQuery"
              @click.stop
            ></v-text-field>
          </v-list-item>
        </template>
        <template v-slot:clientTypeMenu>
          <v-list-item
            v-for="clientType in clientTypes"
            :value="clientType.value"
            :key="clientType"
            :active="isActiveFilter('type', clientType.value)"
            active-class="active-item"
            @click="addFilter('type', clientType.value)"
          >
            {{ clientType.text }}
          </v-list-item>
        </template>
        <template
          v-slot:intermediaryMenu
          v-if="filteredIntermediaries && filteredIntermediaries.length > 0"
        >
          <v-list-item>
            <v-text-field
              variant="outlined"
              density="compact"
              hide-details
              label="Nume"
              v-model="intermediaryFilter"
              @click.stop
            ></v-text-field>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="intermediary in filteredIntermediaries"
            :value="intermediary.id"
            :key="intermediary.id"
            :active="isActiveFilter('intermediate__id', intermediary.id)"
            active-class="active-item"
            @click="addFilter('intermediate__id', intermediary.id)"
          >
            <div
              class="d-flex justify-content-between align-items-center flex-row"
            >
              <v-list-item-title>
                {{ intermediary.name }}
              </v-list-item-title>
              <v-icon color="danger"> mdi-delete </v-icon>
            </div>
          </v-list-item>
        </template>
        <template v-slot:dateMenu>
          <v-list-item density="compact">
            <input
              type="date"
              class="form-control m-0"
              v-model="filterDate"
              @click.stop
              @change="addFilter('modified', filterDate)"
            />
          </v-list-item>
          <v-list v-if="offersWithClients.length > 0" class="no-bullets">
            <v-divider></v-divider>
            <v-list-item density="compact">
              <h6 class="my-0 py-0">Oferte:</h6>
            </v-list-item>
            <v-list-item
              v-for="date in dateOptions"
              :key="date"
              :value="date.value"
              :active="date.value === selectedDateOption"
              active-class="active-item"
              density="compact"
              @click="updateSelectedDate(date.value)"
            >
              {{ date.text }}
            </v-list-item>
            <v-list-item density="compact">
              <input type="date" class="form-control" v-model="selectedDate" />
            </v-list-item>
          </v-list>
        </template>
      </ClientsTableList>
      <SearchOffersResults
        v-if="searchOffersDialog"
        :offers="offersWithClients"
        :total-offers="offersSearchTotalOffersCount"
        :page="offersSearchPage"
        :items-per-page="offerSearchItemsPerPage"
        @update-page="handleOffersSearchUpdatePage"
        @update-items-per-page="handleOffersSearchUpdateItemsPerPage"
        @update-sorting="handleOffersSearchSortingUpdate"
      ></SearchOffersResults>
    </v-container>
  </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";
import ClientCard from "@/components/clients/ClientCard.vue";
import OffersCard from "@/components/clients/OffersCard.vue";
import emitter from "@/utils/emitter";
import ClientsTableList from "@/components/clients/ClientsTableList.vue";
import LocationCard from "@/components/clients/LocationCard.vue";
import ClientLocation from "@/components/clients/ClientLocation.vue";
import {
  checkForUpdates,
  deleteLocation,
  fetchData,
  formatNumber,
  showAlertModal,
} from "@/utils/utils";
import navMixin from "@/mixins/navMixin";
import SearchOffersResults from "@/components/offer/SearchOffersResults.vue";
import { mapGetters } from "vuex";

export default {
  name: "ClientsList",
  mixins: [navMixin],
  components: {
    SearchOffersResults,
    LocationCard,
    ClientCard,
    ClientsTableList,
    ClientLocation,
    OffersCard,
  },
  data() {
    return {
      agentFilter: "",
      intermediaryFilter: "",
      agents: JSON.parse(sessionStorage.getItem("myUsers")),
      dateOptions: [
        { value: "all", text: "Toate datele" },
        { value: "today", text: "Azi" },
        { value: "currentWeek", text: "Săptămâna curentă" },
        { value: "currentMonth", text: "Luna curentă" },
        { value: "currentYear", text: "Anul curent" },
      ],
      clientTypes: JSON.parse(sessionStorage.getItem("clientTypes")),
      clients: [],
      searchQuery: "",
      searchQueryTimeout: null,
      searchQueryHistory: [],
      selectedClientType: "",
      currentClient: null,
      recentLocations: [],
      currentPage: 1,
      itemsPerPage: 25,
      showModal: false,
      selectedLocation: null,
      location: {},
      renderedByClientsList: true,
      isRecentLocationsCollapsed: true,
      isRecentOffersCollapsed: true,
      clientsListCollapsed: false,
      selectedAgent: "",
      selectedIntermediary: "",
      showAddClientModal: false,
      searchBy: "clientName",
      searchOfferIdValue: null,
      searchOfferIdHistory: [],
      offersWithClients: [],
      selectedDate: "",
      selectedDateOption: "all",
      filterDate: "",
      sidebarLinks: [
        {
          name: "Locații recente",
          link: "#section1",
          id: "section1",
        },
        {
          name: "Listă clienți",
          link: "#section2",
          id: "section2",
        },
      ],
      offerTypes: [
        {
          name: "Toate",
          type: 0,
          routeName: "",
          color: "#e32829",
        },
        {
          name: "IPA",
          type: 1,
          routeName: "",
          color: "#e32829",
        },
        {
          name: "Sanitare",
          type: 2,
          routeName: "OfferPlumbing",
          color: "#8774B3",
        },
        {
          name: "VMC",
          type: 3,
          routeName: "OfferVMC",
          color: "#57c0da",
        },
        {
          name: "Simplă",
          type: 4,
          routeName: "OfferSimple",
          color: "black",
        },
        {
          name: "C.T.",
          type: 5,
          routeName: "OfferTechnicalRoom",
          color: "#8D9B6C",
        },
        {
          name: "Tavan",
          type: 6,
          routeName: "OfferCeiling",
          color: "#3777BB",
        },
        {
          name: "Pereti",
          type: 7,
          routeName: "OfferWalls",
          color: "#F8AC8C",
        },
        {
          name: "Automatizare",
          type: 8,
          routeName: "OfferAutomation",
          color: "#1F4690",
        },
        {
          name: "Ventiloconvec.",
          type: 9,
          routeName: "OfferFanCoilUnit",
          color: "#AB9E9A",
        },
        {
          name: "Canal T.",
          type: 10,
          routeName: "OfferThermalChannel",
          color: "#b269ab",
        },
        {
          name: "Pompe de C.",
          type: 11,
          routeName: "OfferPDC",
          color: "#F37928",
        },
        {
          name: "Aspirare",
          type: 12,
          routeName: "OfferVacuum",
          color: "red",
        },
        {
          name: "Servicii",
          type: 13,
          routeName: "OfferServices",
          color: "blue",
        },
      ],
      searchOfferType: null,
      loadingOffers: false,
      loadingClients: true,
      totalClients: 0,
      sortDirection: null,
      sortKey: null,
      clientFilters: [],
      searchOffersDialog: false,
      offersSearchPage: 1,
      offerSearchItemsPerPage: 25,
      offersSearchTotalOffersCount: 0,
      offersSearchSort: [
        {
          key: "modified",
          order: "desc",
        },
      ],
      recentOffers: [],
    };
  },
  async mounted() {
    if (this.clients.length === 0) {
      await this.fetchClients();
      this.recentLocations = JSON.parse(
        sessionStorage.getItem("myRecentLocations")
      );
    }
    await this.fetchLatestOffers();
    emitter.on("refresh-clients", async () => {
      await this.refreshClients();
    });
    emitter.on("clientDeleted", async () => {
      this.clients = [];
      this.recentLocations = [];
      this.clients = JSON.parse(sessionStorage.getItem("myClients"));
      this.recentLocations = JSON.parse(
        sessionStorage.getItem("myRecentLocations")
      );
    });
    emitter.on("client-saved", async () => {
      this.clients = JSON.parse(sessionStorage.getItem("myClients"));
    });
    emitter.on("edit-client", (client) => {
      this.editingClient(client);
    });
    emitter.on("client-updated", async () => {
      await this.sortClientsByModifiedDate();
      this.clients = JSON.parse(sessionStorage.getItem("myClients"));
    });
    emitter.on("location-saved", async () => {
      this.showModal = false;
      await fetchData("locations", this);
      this.clients = JSON.parse(sessionStorage.getItem("myClients"));
      this.recentLocations = JSON.parse(
        sessionStorage.getItem("myRecentLocations")
      );
      this.selectedLocation = null;
    });
    emitter.on("delete-location-clientsList", async (clientId) => {
      this.recentLocations = await deleteLocation(
        this,
        clientId,
        this.currentClient,
        this.recentLocations
      );
    });
    emitter.on("edit-location", this.openEditLocationModal);
    await checkForUpdates(this);
    this.searchOfferType = this.offerTypes[0].type;
  },
  unmounted() {
    emitter.off("clientDeleted");
    emitter.off("client-saved");
    emitter.off("edit-client");
    emitter.off("client-updated");
    emitter.off("location-saved");
    emitter.off("delete-location-clientsList");
    emitter.off("edit-location");
    emitter.off("refresh-clients");
  },
  computed: {
    ...mapGetters(["userPermissions", "userRole"]),

    emitter() {
      return emitter;
    },
    getClientsListTitle() {
      return this.searchOffersDialog
        ? "Rezultate căutare clienți"
        : "Listă clienți";
    },
    displayedClients() {
      if (this.clients && this.clients.length > 0) {
        return JSON.parse(JSON.stringify(this.clients));
      }
      return [];
    },
    filteredIntermediaries() {
      // Extract IDs of intermediaries that are active in filters
      const activeIntermediaryIds = new Set(
        this.clientFilters
          .filter((filter) => filter.key === "intermediate__id")
          .map((filter) => filter.value)
      );

      // Split intermediaries into two groups based on active filters
      const activeIntermediaries = [];
      const otherIntermediaries = [];

      this.intermediaries.forEach((intermediary) => {
        if (activeIntermediaryIds.has(intermediary.id)) {
          activeIntermediaries.push(intermediary);
        } else {
          otherIntermediaries.push(intermediary);
        }
      });

      // Apply textual filter to both groups if intermediaryFilter is set
      const filter = this.intermediaryFilter.toLowerCase();
      const filterActive = (intermediary) =>
        intermediary.name.toLowerCase().includes(filter);

      return [
        ...activeIntermediaries.filter(filterActive),
        ...otherIntermediaries.filter(filterActive),
      ];
    },
    filteredAgents() {
      let agentsList = this.sortedAgents;
      let currentUserId = JSON.parse(sessionStorage.getItem("userId"));

      // If the user is a superAgent, filter only their assigned agents
      if (this.userRole === "superAgent") {
        agentsList = agentsList.filter(
          (agent) =>
            agent.parent_agent === currentUserId || agent.id === currentUserId
        );
      }

      // Extract IDs of agents that are active in filters
      const activeAgentIds = new Set(
        this.clientFilters
          .filter((filter) => filter.key === "agent__id")
          .map((filter) => filter.value)
      );

      // Split agents into two groups based on active filters
      const activeAgents = [];
      const otherAgents = [];

      agentsList.forEach((agent) => {
        if (activeAgentIds.has(agent.id)) {
          activeAgents.push(agent);
        } else {
          otherAgents.push(agent);
        }
      });

      // Apply textual filter only to the active agents if agentFilter is set
      const filter = this.agentFilter.toLowerCase();
      const filterActive = (agent) =>
        agent.full_name.toLowerCase().includes(filter) ||
        agent.email.toLowerCase().includes(filter);

      const finalAgents = [
        ...activeAgents.filter(filterActive),
        ...otherAgents.filter(filterActive), // Always include all other agents
      ];

      return finalAgents;
    },
    sortedAgents() {
      // return this.agents sorted alphabetically ascending
      return this.agents.slice().sort((a, b) => {
        if (a.full_name < b.full_name) {
          return -1;
        }
        if (a.full_name > b.full_name) {
          return 1;
        }
        return 0;
      });
    },
    intermediaries() {
      return JSON.parse(sessionStorage.getItem("intermediaries")) || [];
    },
  },
  methods: {
    formatNumber,
    /**
     * Fetches the latest offers for the logged-in user from the API and updates the `recentOffers` array.
     *
     * @async
     * @function fetchLatestOffers
     * @returns {Promise<void>} A promise that resolves when the API call is complete.
     */
    async fetchLatestOffers() {
      let userId = sessionStorage.getItem("userId");
      await apiClient.get(`/api/latest_offers/${userId}/`).then((response) => {
        this.recentOffers = response.data;
      });
    },
    /**
     * Formats a given date string or timestamp into a human-readable date and time string.
     * The date is formatted based on Romanian locale ('ro-RO').
     *
     * @function showDateTime
     * @param {string|number|Date} value - The date or timestamp to format.
     * @returns {string} The formatted date and time string.
     */
    showDateTime(value) {
      let options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return new Date(value).toLocaleDateString("ro-RO", options);
    },
    /**
     * Opens a new tab in the browser with the client details page, based on the provided client ID.
     *
     * @function goToClientDetails
     * @param {number|string} clientId - The ID of the client to view.
     */
    goToClientDetails(clientId) {
      const url = this.$router.resolve({
        name: "ClientDetails",
        params: { id: parseInt(clientId) },
      }).href;
      window.open(url, "_blank");
    },
    /**
     * Retrieves the offer name based on the offer type.
     *
     * @function getOfferName
     * @param {number} type - The type of the offer to find.
     * @returns {string} The name of the offer, or '--' if the type is not found.
     */
    getOfferName(type) {
      const offer = this.offerTypes.find(
        (offerType) => offerType.type === type
      );
      return offer ? offer.name : "--";
    },
    /**
     * Updates the sorting criteria for offers and triggers the search with the new sorting.
     *
     * @function handleOffersSearchSortingUpdate
     * @param {object} newSorting - The new sorting configuration containing sorting key and direction.
     */
    handleOffersSearchSortingUpdate(newSorting) {
      this.offersSearchSort = newSorting;
      this.findOffers();
    },
    /**
     * Handles the name search query input with a delay, updating the filter after 300ms of inactivity.
     *
     * @function handleNameSearchQuery
     */
    handleNameSearchQuery() {
      // Clear the existing timeout if there is one
      clearTimeout(this.searchQueryTimeout);

      // Set a new timeout to add the filter after 300ms
      this.searchQueryTimeout = setTimeout(async () => {
        if (this.offersWithClients && this.offersWithClients.length > 0) {
          await this.refreshClients();
        }
        this.addFilter("nameSearch", this.searchQuery);
      }, 300);
    },
    /**
     * Checks if a given filter with the specified key and value is active.
     *
     * @function isActiveFilter
     * @param {string} key - The filter key to check.
     * @param {string|number|boolean} value - The filter value to check.
     * @returns {boolean} Returns true if the filter is active, false otherwise.
     */
    isActiveFilter(key, value) {
      return this.clientFilters.some(
        (filter) => filter.key === key && filter.value === value
      );
    },
    /**
     * Handles sorting changes by updating the sort key and direction, and refetching the client list.
     *
     * @async
     * @function handleSortingChange
     * @param {object} event - The event object containing the sorting key and direction.
     * @returns {Promise<void>} A promise that resolves once the clients are fetched.
     */
    async handleSortingChange(event) {
      this.sortKey = event.key;
      this.sortDirection = event.direction;
      await this.fetchClients();
    },
    /**
     * Fetches the list of clients from the API based on the current filters, pagination, and sorting.
     * Updates the clients list and stores it in session storage.
     *
     * @async
     * @function fetchClients
     * @returns {Promise<void>} A promise that resolves once the clients are fetched and updated.
     */
    async fetchClients() {
      this.loadingClients = true;
      const filters = JSON.stringify(this.clientFilters);

      const params = {
        page: this.currentPage,
        page_size: this.itemsPerPage,
        sort: this.sortKey,
        dir: this.sortDirection,
        filters: filters, // Send filters as a JSON string
      };

      try {
        const response = await apiClient.get("/api/clients/", { params });
        this.clients = response.data.results;
        sessionStorage.setItem(
          "myClients",
          JSON.stringify(response.data.results)
        );
        this.totalClients = response.data.count;
      } catch (error) {
        console.error(
          "Error fetching the clients:",
          error.response ? error.response.data : error.message
        );
        showAlertModal(
          this.$store,
          "A intervenit o eroare la obținerea clienților. Vă rugăm reîmprospătați pagina.",
          "danger",
          12000,
          error
        );
      } finally {
        this.loadingClients = false;
      }
    },
    /**
     * Updates the number of items per page for the clients list and fetches the updated list.
     * Resets the current page to 1 when the page size changes.
     *
     * @function handleClientsItemPerPageUpdate
     * @param {number} itemsPerPage - The number of items to display per page.
     */
    handleClientsItemPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.currentPage = 1; // Reset to first page with new page size
      this.fetchClients();
    },
    /**
     * Updates the current page for the clients list and fetches the clients for that page.
     *
     * @function handleClientsUpdatePage
     * @param {number} page - The page number to update to.
     */
    handleClientsUpdatePage(page) {
      this.currentPage = page;
      this.fetchClients();
    },
    /**
     * Updates the current page for the offers search results and triggers the search.
     *
     * @function handleOffersSearchUpdatePage
     * @param {number} page - The page number to update to.
     */
    handleOffersSearchUpdatePage(page) {
      this.offersSearchPage = page;
      this.findOffers();
    },
    /**
     * Updates the number of items per page for the offers search results and triggers a new search.
     *
     * @function handleOffersSearchUpdateItemsPerPage
     * @param {number} itemsPerPage - The number of offers to display per page.
     */
    handleOffersSearchUpdateItemsPerPage(itemsPerPage) {
      this.offerSearchItemsPerPage = itemsPerPage;
      this.findOffers();
    },
    /**
     * Asynchronously refreshes the client data by fetching it and updating the component's state.
     * This method retrieves a list of clients from sessionStorage, previously stored under the key 'myClients'.
     * Additionally, it fetches user details from 'myUsers' in sessionStorage to enrich each client's information
     * with the corresponding agent's full name and email. This is achieved by matching each client's 'agent_id'
     * with user IDs. The updated list of clients is then used to update the component's state.
     *
     * @returns {Promise<void>} - A promise that resolves once the clients have been refreshed with additional agent information,
     *                            and the component's state has been updated. The method does not return a value.
     */
    async refreshClients() {
      this.offersWithClients = [];
      this.searchOffersDialog = false;
      await this.fetchClients();
      this.clients = JSON.parse(sessionStorage.getItem("myClients"));
      let users = await JSON.parse(sessionStorage.getItem("myUsers"));
      this.clients = this.clients.map((client) => {
        const matchingUser = users.find((user) => user.id === client.agent_id);
        const agent_name = matchingUser ? matchingUser.full_name : "";
        const agent_email = matchingUser ? matchingUser.email : "";
        return { ...client, agent_email, agent_name };
      });
    },
    /**
     * Adds or removes a filter based on the key and value, and then fetches the updated client list.
     * If the filter key is 'nameSearch' or 'modified', it replaces any existing filter with the same key.
     *
     * @function addFilter
     * @param {string} key - The filter key to add or remove.
     * @param {string|number|boolean} value - The value of the filter to add or remove.
     */
    addFilter(key, value) {
      let filter = {
        key: key,
        value: value,
      };
      // Find the index of an existing filter with the same key and value
      const index = this.clientFilters.findIndex(
        (f) => f.key === key && f.value === value
      );

      if (key === "nameSearch" || key === "modified") {
        const index = this.clientFilters.findIndex((f) => f.key === key);
        if (index > -1) {
          this.clientFilters.splice(index, 1);
        }
        this.clientFilters.push(filter);
        this.fetchClients();
      } else if (index > -1) {
        // If the filter is found, remove it
        this.clientFilters.splice(index, 1);
        this.fetchClients();
      } else {
        // If the filter is not found, add it
        this.clientFilters.push(filter);
        this.fetchClients();
      }
      // Clear the agent filter input after selecting an agent
      if (key === "agent__id") {
        this.agentFilter = "";
      }
    },
    /**
     * Maintains a search history by adding a new query if it doesn't already exist.
     * The history is limited to the last 5 queries.
     *
     * @function maintainSearchHistory
     * @param {string} query - The search query to add to history.
     * @param {Array<string>} history - The array that holds the search history.
     */
    maintainSearchHistory(query, history) {
      // verify if query is already in history
      const queryIndex = history.findIndex((item) => item === query);
      // if query is not already in history, add it
      if (queryIndex === -1) {
        history.push(query);
      }
      // limit history array to last 5 items
      if (history.length > 5) {
        history.shift();
      }
    },
    /**
     * Redirects to the 'Client' route for adding a new client.
     *
     * @function addClient
     */
    addClient() {
      this.$router.push({ name: "Client" });
    },
    /**
     * Updates the selected date filter based on a predefined option (e.g., 'today', 'currentWeek').
     * Sets the selected date to a specific range or 'all' for no date filtering.
     *
     * @function updateSelectedDate
     * @param {string} date - The selected date option to apply (e.g., 'today', 'currentWeek', 'currentMonth', 'currentYear').
     */
    updateSelectedDate(date) {
      const today = new Date();
      const endOfDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59,
        999
      );
      let startDate;

      this.selectedDateOption = date;
      switch (this.selectedDateOption) {
        case "today":
          this.selectedDate = this.formatDate(today);
          break;
        case "currentWeek":
          startDate = new Date(today.setDate(today.getDate() - today.getDay()));
          this.selectedDate = [
            this.formatDate(startDate),
            this.formatDate(endOfDay),
          ];
          break;
        case "currentMonth":
          startDate = new Date(today.getFullYear(), today.getMonth(), 1);
          this.selectedDate = [
            this.formatDate(startDate),
            this.formatDate(endOfDay),
          ];
          break;
        case "currentYear":
          startDate = new Date(today.getFullYear(), 0, 1);
          this.selectedDate = [
            this.formatDate(startDate),
            this.formatDate(endOfDay),
          ];
          break;
        default:
          this.selectedDate = "all";
          break;
      }
    },
    formatDate(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = ("0" + (d.getMonth() + 1)).slice(-2);
      const day = ("0" + d.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    /**
     * Asynchronously initiates the process to update and retrieve offers based on the current search parameters.
     * This method handles several steps in sequence to ensure that the application's state reflects the latest
     * data and user interactions:
     * 1. It sets a loading state to indicate that data fetching is in progress.
     * 2. It calls `refreshClients` to update client data from the server or a local source.
     * 3. Upon successful refresh, it proceeds to `fetchOffersForFilteredClients` to retrieve offers
     *    that match current search criteria.
     * 4. Concurrently, it updates the search history based on the current search offer ID.
     * 5. Finally, it resets the loading state to indicate that the data fetching process is complete.
     *
     * @returns {Promise<void>} - Completes all operations without returning a specific value, primarily
     *                            managing state updates within the component.
     */
    async findOffers() {
      this.loadingOffers = true;
      await this.refreshClients().then(() => {
        this.fetchOffersForFilteredClients();
        this.maintainSearchHistory(
          this.searchOfferIdValue,
          this.searchOfferIdHistory
        );
      });
    },
    /**
     * Fetches offers based on current search parameters and updates the clients with matching offers.
     * This method constructs an API endpoint with query parameters (if they are valid and present),
     * requests the offers, and then filters them to find matches against the currently listed clients.
     * Offers are filtered by date if a specific date is selected. Errors in fetching are handled gracefully
     * with user notifications.
     *
     * @returns {Promise<void>} - A promise that resolves once the offers have been fetched and processed,
     *                            or fails with an error notification to the user.
     */
    async fetchOffersForFilteredClients() {
      this.loadingOffers = true; // Start loading
      try {
        let apiUrl = `/api/get_offer_data/?`;
        const params = [];

        if (
          Number.isInteger(this.searchOfferType) &&
          this.searchOfferType !== 0
        ) {
          params.push(`offerType=${this.searchOfferType}`);
        }
        if (this.searchOfferIdValue) {
          params.push(`searchId=${this.searchOfferIdValue}`);
        }
        if (this.offerSearchItemsPerPage) {
          params.push(`itemsPerPage=${this.offerSearchItemsPerPage}`);
        }
        if (this.offersSearchPage) {
          params.push(`page=${this.offersSearchPage}`);
        }
        if (this.offersSearchSort.length) {
          params.push(`sortKey=${this.offersSearchSort[0].key}`);
          params.push(`sortDir=${this.offersSearchSort[0].order}`);
        }

        apiUrl += params.join("&");

        const response = await apiClient.get(apiUrl);
        if (response.data.results && response.data.results.length === 0) {
          showAlertModal(
            this.$store,
            "Nu s-au găsit oferte pentru filtrele selectate.",
            "danger",
            5000
          );
        } else {
          this.offersWithClients =
            response.data.results || response.data.offers;
          this.offersSearchTotalOffersCount =
            response.data.count || response.data.offers.length;
          this.searchOffersDialog = true;
        }
      } catch (error) {
        console.error("Failed to fetch offers:", error);
        showAlertModal(
          this.$store,
          "A apărut o eroare la încercarea de a prelua ofertele. Vă rugăm reîncărcați pagina.",
          "danger",
          12000,
          error
        );
      } finally {
        this.loadingOffers = false; // End loading
      }
    },
    /**
     * Sorts the list of clients by the 'modified' date in descending order and updates the session storage.
     *
     * @function sortClientsByModifiedDate
     */
    sortClientsByModifiedDate() {
      this.clients.sort((a, b) => new Date(b.modified) - new Date(a.modified));
      sessionStorage.setItem("myClients", JSON.stringify(this.clients));
    },
    /**
     * Opens a modal for editing a location by setting the selected location and showing the modal.
     *
     * @function openEditLocationModal
     * @param {object} location - The location object to be edited.
     */
    openEditLocationModal(location) {
      this.selectedLocation = location;
      this.showModal = true;
    },
  },
};
</script>
<style scoped>
ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.active-item {
  background-color: #0d6efd;
  color: white;
}
</style>
<style>
.clientsList-input .v-input__details {
  display: none !important;
}

.table-dropdown-menu .v-list-item {
  padding: 5px 10px !important;
  min-height: auto !important;
}

.table-dropdown-menu .v-divider {
  margin: 0px 5px !important;
}
</style>
