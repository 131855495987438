<template>
  <BreadCrumbsWithButtons :buttons="buttons" :pdfOptions="pdfOptions.simple" />
  <a href="#" id="section1" ref="section1"></a>
  <OfferInformation
    v-if="client && location"
    :client="client"
    :location="location"
    :offer="offer"
    :offerId="offerId"
    :customerTypes="customerTypes"
    @update="updateOfferData"
    @offer-buttons-in-view="isOfferButtonsInView = $event"
  >
  </OfferInformation>
  <PDCSetup @selected-unit-model="setPdcUnits" />
  <a href="#" id="section2" ref="section2"></a>
  <ProductTable
    v-if="loadProductTable"
    :offer="offer"
    :offered-products="offeredProducts ?? []"
    :offer-type="{ type: 'PDC', productCategories: ['PDC'] }"
    :headers="productTableHeaders"
    :customerType="
      offerInformation.selectedCustomerType
        ? offerInformation.selectedCustomerType
        : offer?.selected_customer_type
    "
    @sort-pdf-by-number="setPDFCustomSorting"
    @update-products="updateOfferProducts"
    @update-offer="updateOfferData"
    @selected-price-list="updateOfferPriceList"
    :price-list-type="this.$store.state.PRICE_LIST_TYPES.GENERAL.value"
  >
  </ProductTable>
  <OfferPaymentTerms
    :offer="offer"
    @update_payment_terms="updatePaymentTerms"
  ></OfferPaymentTerms>
</template>

<script>
import navMixin from "@/mixins/navMixin";
import OfferInformation from "@/components/offer/OfferInformation.vue";
import offerMixin from "@/mixins/offerMixin";
import ProductTable from "@/components/offer/ProductTable.vue";
import OfferPaymentTerms from "@/components/offer/OfferPaymentTerms.vue";
import PDCSetup from "@/components/offer/PDCSetup.vue";
import {
  PDC_NON_STANDARD_MEASUREMENTS,
  PDC_PRODUCT_TYPES,
  pdcProducts,
} from "@/PDC/pdcProducts";
import {
  createPDCUnitRequirements,
  HEAT_EXCHANGE_PRODUCT_REQUIREMENTS,
} from "@/PDC/pdcLogic";
import emitter from "@/utils/emitter";
import { showAlertModal } from "@/utils/utils";
import BreadCrumbsWithButtons from "@/components/common/BreadCrumbsWithButtons.vue";
import { cloneDeep } from "lodash";

export default {
  name: "OfferPDC",
  mixins: [navMixin, offerMixin],
  components: {
    BreadCrumbsWithButtons,
    OfferPaymentTerms,
    ProductTable,
    OfferInformation,
    PDCSetup,
  },
  data() {
    return {
      sidebarLinks: [
        {
          name: "Informații",
          link: "#section1",
          id: "section1",
        },
        {
          name: "Produse",
          link: "#section2",
          id: "section2",
        },
      ],
      productTableHeaders: [
        {
          title: "Nr.",
          key: "number",
          align: "center",
          show: false,
          width: "100px",
        },
        {
          title: "Cod articol",
          key: "product_code",
          align: "center",
          show: true,
          width: "140px",
        },
        {
          title: "Nume articol",
          key: "product_name",
          align: "start",
          show: true,
        },
        {
          title: "Categorie",
          key: "assigned_sub_category",
          align: "center",
          show: true,
        },
        {
          title: "Cantitate",
          key: "quantity",
          align: "center",
          show: true,
          width: "130px",
        },
        {
          title: "UM",
          key: "measurement_unit",
          align: "center",
          show: true,
          width: "70px",
        },
        {
          title: "Preț listă cu TVA",
          key: "price",
          align: "end",
          show: true,
        },
        {
          title: "Discount",
          key: "discount",
          align: "center",
          show: true,
          width: "120px",
        },
        {
          title: "Preț final cu TVA",
          key: "final_price",
          align: "end",
          show: true,
        },
        {
          title: "Valoare finală cu TVA",
          key: "final_total_price",
          align: "end",
          show: true,
        },
      ],
      requirementsObj: {
        requirements: [],
      },
      requirementsGetters: ["createPDCUnitRequirements"],
      products: [],
      pdcProducts,
      offerType: { type: "PDC", productCategories: ["PDC"] },
      selectedPdcUnits: [],
      customerTypes: [
        { text: "Persoana fizica (TVA 9%)", value: 1 },
        { text: "Persoana juridica (TVA 19%)", value: 2 },
      ],
      areUnitsProcessed: false,
    };
  },
  async created() {
    const priceListItems = await this.fetchPriceListProducts();
    this.products = this.reassignSubCategories(
      this.setZeroQtyForBackendProducts(priceListItems.items)
    );
    this.$router.beforeEach((to, from, next) => {
      if (
        from.name === "OfferPDC" &&
        to.name !== "OfferPDC" &&
        this.isProductsChanged === true
      ) {
        // Replace 'FormPage' with your form's route name
        const shouldLeave = this.checkFormBeforeLeave();
        if (!shouldLeave) {
          next(false); // Prevent route change
        } else {
          this.isProductsChanged = false;
          next(); // Proceed with the route change
        }
      } else {
        next(); // Proceed with the route change for routes other than the form page
      }
    });
  },
  mounted() {
    this.$store.state.sidebarLinks = this.sidebarLinks;
  },
  methods: {
    createPDCUnitRequirements,
    setPdcUnits(pdcUnits) {
      pdcUnits.forEach((pdcUnit) => {
        pdcUnit.quantity = 1;
      });
      this.selectedPdcUnits = JSON.parse(JSON.stringify(pdcUnits));
    },
    /**
     * Checks the availability of internal units for a selected split-type unit.
     * Validates that each internal unit has a 'selectedInternalUnitModel' property with a valid 'product_code'.
     * If the selected unit is of type 'Split' and either lacks internal units or any internal units do not meet the criteria,
     * an error message is displayed, and the method returns false. If all checks pass, the method returns true.
     *
     * @param {Object} unit - The unit object to check, which should include properties for the selected unit and its internal units.
     * @returns {boolean} Returns true if all internal units are correctly configured with a 'selectedInternalUnitModel' that includes a 'product_code'; otherwise, it triggers an alert modal with an error message and returns false.
     */
    checkSplitInternalUnitsAvailability(unit) {
      let errorMessage =
        "Una sau mai multe dintre unitățile selectate sunt de tip Split și nu au nicio unitate internă selectată sau o unitate internă nu are un model selectat. Vă rugăm să ajustați.";
      // Check if the main unit is selected and is of type 'Split'
      if (unit.selectedUnit && unit.selectedUnit.type === 2) {
        // Ensure there are internal units
        if (unit.internalUnits && unit.internalUnits.length > 0) {
          // Check each internal unit for a selectedInternalUnit with a product_code
          for (const internalUnit of unit.internalUnits) {
            if (
              !internalUnit.selectedInternalUnitModel ||
              !internalUnit.selectedInternalUnitModel.product_code
            ) {
              showAlertModal(this.$store, errorMessage, "danger", 12000);
              return false;
            }
          }
          return true; // All checks passed
        } else {
          showAlertModal(this.$store, errorMessage, "danger", 12000);
          return false;
        }
      } else {
        return true;
      }
    },
    /**
     * Attempts to add PDC units and related products, and returns a boolean status based on availability checks.
     * @returns {boolean} Returns true if all units are available, otherwise false.
     */
    async addPdcUnit() {
      let hasError = false;

      this.selectedPdcUnits.forEach((unit) => {
        if (!unit.selectedUnit) {
          unit.error = true;
          hasError = true;
        } else {
          unit.error = false;
        }
      });

      if (hasError) {
        showAlertModal(
          this.$store,
          "Câmpul 'Model unitate PDC' este obligatoriu!",
          "danger",
          8000
        );
        return;
      }

      // Iterate over each selected PDC unit.
      for (const selectedPdcUnit of this.selectedPdcUnits) {
        // Check if the unit's availability returns false.
        if (!this.checkSplitInternalUnitsAvailability(selectedPdcUnit)) {
          this.areUnitsProcessed = false; // Return false immediately if availability check fails.
        }
        if (selectedPdcUnit.heatExchange) {
          this.addHeatExchangeProducts(
            selectedPdcUnit.selectedUnit,
            HEAT_EXCHANGE_PRODUCT_REQUIREMENTS
          );
        }

        // Clone the product information from the selected unit.
        let unitProduct = await JSON.parse(
          JSON.stringify(
            this.getUnitProduct(
              selectedPdcUnit.selectedUnit.product_code,
              this.pdcProducts
            )
          )
        );

        if (unitProduct) {
          unitProduct.quantity = 1;
          this.resultedProducts.push(unitProduct);
          this.processUnitRequirements(unitProduct);

          // Process any internal units if they exist.
          this.processInternalUnits(selectedPdcUnit);

          this.processResistance(selectedPdcUnit);
        }
      }
      this.areUnitsProcessed = true;
    },
    addHeatExchangeProducts(unit, HEAT_EXCHANGE_PRODUCTS_REQUIREMENTS) {
      const power = unit.default_pump_power;

      if (power >= 4 && power <= 12) {
        HEAT_EXCHANGE_PRODUCTS_REQUIREMENTS.LOW_POWER.forEach(
          (productRequirement) => {
            if (
              !productRequirement.condition ||
              productRequirement.condition(unit)
            ) {
              this.requirementsObj.requirements.push(
                JSON.parse(JSON.stringify(productRequirement))
              );
            }
          }
        );
      } else if (power >= 14 && power <= 18) {
        HEAT_EXCHANGE_PRODUCTS_REQUIREMENTS.HIGH_POWER.forEach(
          (productRequirement) => {
            if (
              !productRequirement.condition ||
              productRequirement.condition(unit)
            ) {
              this.requirementsObj.requirements.push(
                JSON.parse(JSON.stringify(productRequirement))
              );
            }
          }
        );
      }
    },
    processUnitRequirements(unitProduct) {
      if (unitProduct.requirements && unitProduct.requirements.length > 0) {
        unitProduct.requirements.forEach((requirement) => {
          requirement.processed = false;
          this.requirementsObj.requirements.push(
            JSON.parse(JSON.stringify(requirement))
          );
        });
      }
    },
    processInternalUnits(selectedPdcUnit) {
      if (selectedPdcUnit.internalUnits) {
        for (const internalUnit of selectedPdcUnit.internalUnits) {
          if (
            internalUnit.selectedInternalUnitModel &&
            internalUnit.selectedInternalUnitModel.product_code
          ) {
            let internalUnitProduct = this.getUnitProduct(
              internalUnit.selectedInternalUnitModel.product_code,
              this.pdcProducts
            );
            internalUnitProduct.quantity = 1;
            this.resultedProducts.push(internalUnitProduct);
            this.processUnitRequirements(internalUnitProduct);
          }
        }
      }
    },
    processResistance(selectedPdcUnit) {
      let resistanceRequirement = {
        type: PDC_PRODUCT_TYPES.REZISTENTA,
        subtype: "electrica",
        heating_power: 3,
        variant: PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH,
        quantity: 1,
      };
      let resistanceAutomatization = {
        type: PDC_PRODUCT_TYPES.TABLOU,
        subtype: "automatizare",
        variant: null,
        quantity: 1,
      };

      // Set heating power from selectedResistance
      if (
        selectedPdcUnit.selectedResistance &&
        selectedPdcUnit.selectedResistance.value
      ) {
        resistanceRequirement.heating_power =
          selectedPdcUnit.selectedResistance.value;
      }

      // Determine resistanceAutomatization.variant based on selectedResistance.value
      const resistanceValue = selectedPdcUnit.selectedResistance.value;

      if ([0, 2, 3].includes(resistanceValue)) {
        resistanceAutomatization.variant = "monofazic";
      } else if ([4, 5, 6].includes(resistanceValue)) {
        resistanceAutomatization.variant = "trifazic";
      }

      // Special condition for manufacturer and resistance value
      if (
        selectedPdcUnit.selectedUnit.manufacturer === 1 &&
        selectedPdcUnit.selectedResistance.value === 0
      ) {
        resistanceRequirement.variant = PDC_NON_STANDARD_MEASUREMENTS.HALF_INCH;
      }

      // Add resistance requirement to requirements list
      this.requirementsObj.requirements.push(
        JSON.parse(JSON.stringify(resistanceRequirement))
      );

      if (selectedPdcUnit.selectedResistance.value > 0) {
        this.requirementsObj.requirements.push(
          JSON.parse(JSON.stringify(resistanceAutomatization))
        );
      }
    },
    /**
     * Processes the required products by initializing arrays, fetching requirements,
     * adding product codes, fetching products, and processing the final product list.
     * This method orchestrates several steps to prepare and process product data,
     * including fetching, filtering, and matching products based on specific criteria
     * such as location and unit product codes. It also handles the addition of
     * recommended products and updates the offered products accordingly.
     */
    processRequiredProducts() {
      emitter.emit("trigger-overlay");

      setTimeout(async () => {
        this.$store.commit("emptyVmcErrorArray");
        this.requirementsObj.requirements = [];
        this.resultedProducts = [];
        this.finalResultedProducts = [];
        this.recommendedProducts = [];
        await this.addPdcUnit();
        if (this.areUnitsProcessed) {
          this.getRequirements();
          this.getProducts(this.requirementsObj, cloneDeep(this.pdcProducts));

          this.addRecommendedProducts(this.resultedProducts, this.pdcProducts);

          // Add this to process product conditions, similar to the other component
          this.processProductConditions(this.resultedProducts);

          this.processResultedProducts();

          this.finalResultedProducts = this.matchProducts(
            this.products,
            this.finalResultedProducts
          );
          this.addOrUpdateOfferedProducts();

          // Emitting event for setting showOnlyOfferQty on true in ProductTable
          emitter.emit("set-products-with-quantity-true");
          this.areUnitsProcessed = false;
        } else {
          emitter.emit("trigger-overlay");
          this.areUnitsProcessed = false;
        }
      }, 550);
    },
    getRequirements() {
      for (let methodName of this.requirementsGetters) {
        if (this[methodName] && typeof this[methodName] === "function") {
          const newRequirements = this[methodName](this.selectedPdcUnits).map(
            (req) => ({
              ...req,
              processed: false,
            })
          );
          newRequirements.forEach((req) => {
            const existingReq = this.requirementsObj.requirements.find((r) =>
              this.compareRequirements(r, req)
            );
            if (existingReq) {
              existingReq.quantity =
                (existingReq.quantity || 0) +
                (req.quantity !== null && req.quantity !== undefined
                  ? req.quantity
                  : 1);
            } else {
              req.quantity =
                req.quantity !== null && req.quantity !== undefined
                  ? req.quantity
                  : 1;
              this.requirementsObj.requirements.push(req);
            }
          });
        }
      }
    },
    checkFormBeforeLeave() {
      return confirm(
        "Sunteți sigur că doriți să părăsiți formularul? Modificările nesalvate vor fi pierdute."
      );
    },
  },
};
</script>
