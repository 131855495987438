<template>
  <v-row class="justify-start">
    <!-- Iterate over document options -->
    <template v-for="(option, index) in documentOptions" :key="index">
      <!-- Iterate over existing files for this document type -->
      <template
        v-for="(file, fileIndex) in getDocumentFiles(option.key)"
        :key="`${option.key}-${fileIndex}`"
      >
        <v-col cols="auto">
          <!-- Card with file -->
          <v-card
            class="document-card mb-2 d-flex flex-column justify-content-evenly"
            style="position: relative"
            :color="fileNeedsSave(file) ? 'grey lighten-1' : 'success'"
            @mouseenter="setHoveredCard(`${option.key}-${fileIndex}`, true)"
            @mouseleave="setHoveredCard(`${option.key}-${fileIndex}`, false)"
            @click="viewDocument(file)"
          >
            <v-card-title
              class="text-pre-wrap"
              style="font-size: 1rem; line-height: 1.25rem"
            >
              {{ option.label }}
              {{ getDocumentFiles(option.key).length > 1 ? fileIndex + 1 : "" }}
            </v-card-title>
            <v-card-subtitle v-if="fileNeedsSave(file)">
              Necesită salvare
            </v-card-subtitle>

            <!-- Delete Button -->
            <v-btn
              class="ml-1"
              style="position: absolute; top: 0; right: 0"
              color="error"
              size="small"
              variant="plain"
              icon
              @click.stop="deleteFile(option.key, file)"
            >
              <v-icon>mdi-close</v-icon>
              <v-tooltip location="top" activator="parent">
                Ștergere
              </v-tooltip>
            </v-btn>

            <!-- View Button -->
            <v-tooltip
              v-if="!fileNeedsSave(file)"
              location="top"
              v-model="hoverStates[`${option.key}-${fileIndex}`]"
            >
              <template v-slot:activator="{ props }">
                <v-card-actions
                  class="d-flex justify-content-end"
                  style="position: absolute; bottom: 5px; right: 10px"
                  v-bind="props"
                >
                  <v-icon title="Click to view document"
                    >mdi-eye-outline</v-icon
                  >
                </v-card-actions>
              </template>
              Vizualizare
            </v-tooltip>
          </v-card>
        </v-col>
      </template>

      <!-- Placeholder if no files -->
      <v-col v-if="getDocumentFiles(option.key).length === 0" cols="auto">
        <v-card
          :disabled="isReadonly"
          class="document-card mb-2 d-flex flex-column justify-content-evenly"
          style="position: relative"
          color="warning"
          :readonly="option.isReadonly"
          @click="!option.isReadonly && triggerFileInput(option.key)"
        >
          <v-card-title
            class="text-pre-wrap"
            style="font-size: 1rem; line-height: 1.25rem"
          >
            {{ option.label }}
          </v-card-title>
          <v-card-actions
            class="d-flex justify-content-end"
            style="position: absolute; bottom: 5px; right: 10px"
          >
            Fără fișier
          </v-card-actions>
        </v-card>
        <!-- Hidden File Input -->
        <v-file-input
          :ref="'fileInputs' + option.key"
          v-show="false"
          :accept="option.key === 'offer' ? '.xls,.xlsx' : 'application/pdf'"
          :multiple="true"
          @change="addFiles($event, option.key)"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { showAlertModal } from "@/utils/utils";
export default {
  name: "DocumentManager",
  props: {
    documentOptions: {
      type: Array,
      required: true,
    },
    documents: {
      type: Object,
      required: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hoverStates: {},
      statusMapping: {
        advance_invoice: "Factură avans achitată",
        goods_delivery_annex: "Anexă marfă trimisă",
        connection_certificate: "Certificat de racordare",
        final_invoice: "Factură finală",
      },
    };
  },
  methods: {
    getDocumentFiles(documentType) {
      return this.documents[documentType] || [];
    },
    fileNeedsSave(file) {
      return file.isFileField || !file.id;
    },
    triggerFileInput(key) {
      this.$refs["fileInputs" + key][0].click();
    },
    addFiles(event, key) {
      const files = Array.from(event.target.files);

      // Restrict file type for "offer" to only Excel files
      if (key === "offer") {
        const allowedExtensions = ["xls", "xlsx"];
        const invalidFiles = files.some(
          (file) =>
            !allowedExtensions.includes(
              file.name.split(".").pop().toLowerCase()
            )
        );

        if (invalidFiles) {
          showAlertModal(
            this.$store,
            "Doar fișiere Excel (.xls, .xlsx) sunt permise pentru 'Ofertă'.",
            "warning",
            5000
          );
          event.target.value = ""; // Reset input
          return;
        }
      }

      this.$emit("add-file", { key, files });

      //  Emit event only if the file belongs to one of the tracked categories
      if (this.statusMapping[key] && key !== "offer") {
        this.$emit("update-contract-status", this.statusMapping[key]);
      }

      event.target.value = ""; // Reset input
    },
    deleteFile(documentType, file) {
      this.$emit("delete-file", { documentType, file });
    },
    viewDocument(document) {
      this.$emit("view-document", document);
    },
    setHoveredCard(key, state) {
      this.hoverStates[key] = state;
    },
  },
};
</script>

<style scoped>
.gray-background:hover {
  background: rgba(30, 30, 30, 0.1);
}

.gray-background {
  background: rgba(30, 30, 30, 0.1);
}

.document-card {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: 140px; /* Fixed width for all cards */
  height: 199px; /* Fixed height for all cards */
}
</style>
